<mat-card appearance="outlined" class="education-card h-100">
  <img
    *ngIf="education?.imageUrl"
    [src]="education.imageUrl"
    class="education-picture pointer"
  />
  <div *ngIf="!education?.imageUrl" class="education-picture"></div>
  <mat-card-content
    class="d-flex flex-column justify-content-between education-content"
  >
    <div class="education-title">
      {{ education.title }}
    </div>
    <div class="card-content mb-4">
      {{ education.description }}
    </div>

    <a [href]="education?.originalUrl" target="_blank">
      <button mat-flat-button color="primary" class="btn-sm d-block">
        Visit page
      </button>
    </a>
  </mat-card-content>
</mat-card>
