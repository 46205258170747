import { Action, createReducer, on } from '@ngrx/store';
import { createFormGroupState, FormGroupState, formStateReducer, setValue, updateGroup, reset } from 'ngrx-forms';
import { getUserSuccess } from 'src/app/ngrx-store/requests/user/get-user/get-user.actions';
import { User } from '../../user/user.model';
import { UserFormsState, UserRole, EmploymentStatus } from '../userForms.model';
import { ProfileEditForm } from './forms.profileEdit.model';
import { submitProfileEditForm } from './forms.profileEdit.actions';

export const initialProfileEditForm: ProfileEditForm = {
  firstName: '',
  lastName: '',
  about: '',
  region: '',
  country: '',
  role: UserRole.Member,
  roles: Object.values(UserRole).map((k) => k),
  employmentStatuses: Object.values(EmploymentStatus).map((x) => x),
  employmentStatus: EmploymentStatus.Employee,
  newsletter: true,
  platform: true,
  shareData: true,
  functionLabel: '',
  linkedinLink: '',
  instagramLink: '',
  facebookLink: '',
  twitterLink: '',
};

export const initialProfileEditFormState = createFormGroupState<ProfileEditForm>(
  'PROFILE_EDIT_FORM',
  initialProfileEditForm
);

const updateForm = createReducer<FormGroupState<ProfileEditForm>>(
  initialProfileEditFormState,
  on(getUserSuccess, (state, action) => prefillForm(state, action.user)),
  on(submitProfileEditForm, (state) => ({
    ...reset(state),
  }))
);

const validateForm = (form: FormGroupState<ProfileEditForm>, state: UserFormsState) => {
  return form;
};

const prefillForm = (state: FormGroupState<ProfileEditForm>, user: User) => {
  if (!user) {
    return state;
  }

  return updateGroup<ProfileEditForm>(state, {
    firstName: setValue(user.firstName),
    lastName: setValue(user.lastName),
    about: setValue(user.about),
    region: setValue(user.region),
    country: setValue(user.country),
    role: setValue(Object.values(UserRole)[user.role].toString()),
    employmentStatus: setValue(Object.values(EmploymentStatus)[user.employmentStatus].toString()),
    newsletter: setValue(user.receiveNewsLetter),
    platform: setValue(user.receivePlatformMails),
    shareData: setValue(user.shareData),
    functionLabel: setValue(user.functionLabel || ''),
    linkedinLink: setValue(user.linkedinSocialMedia),
    instagramLink: setValue(user.instagramSocialMedia),
    facebookLink: setValue(user.facebookSocialMedia),
    twitterLink: setValue(user.twitterSocialMedia),
  });
};

export const profileEditFormReducer = (state: UserFormsState, action: Action) => {
  const profileEditForm: FormGroupState<ProfileEditForm> = state.profileEditForm;

  // use the out of the box reducer to process form actions into the store
  let form = formStateReducer(profileEditForm, action);

  // Process non-form actions
  form = updateForm(form, action);

  // form validation using the new state
  form = validateForm(form, state);

  return form === profileEditForm ? profileEditForm : form;
};
