import { Component, OnInit } from '@angular/core';
import { selectCompanies } from '../../ngrx-store/components/company/company.selectors';
import { AppRoutes } from 'src/app/app-routing.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { getCompanies } from '../../ngrx-store/requests/company/get-company/get-company.actions';
import { getVacancies } from 'src/app/ngrx-store/components/vacancy/vacancy.actions';
import { getVacanciesInterests } from '../../ngrx-store/components/vacancy/vacancy.actions';

@Component({
  selector: 'app-career-opportunities',
  templateUrl: './career-opportunities.component.html',
  styleUrls: ['./career-opportunities.component.scss'],
})
export class CareerOpportunitiesComponent implements OnInit {
  public companiesState$ = this.store.select(selectCompanies);
  public AppRoutes = AppRoutes;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(getCompanies());
    this.store.dispatch(getVacancies());
    this.store.dispatch(getVacanciesInterests());
  }
}
