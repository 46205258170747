import { Action, createReducer } from '@ngrx/store';
import { CompanyFormsState } from './companyForms.model';
import { createCompanyFormReducer, initialCreateCompanyFormState } from './createCompany/forms.createCompany.reducer';
import { companyDetailsFormReducer, initialCompanyDetailsFormState } from './companyDetails/forms.companyDetails.reducer';
import { companyContactPersonFormReducer, initialCompanyContactPersonState } from './companyContactPerson/forms.companyContactPerson.reducer';
import { initialCompanyEventFormState, companyEventFormReducer } from './companyEvent/forms.companyEvent.reducer';


export const initialCompanyFormsState: CompanyFormsState = {
  createCompanyForm: initialCreateCompanyFormState,
  companyDetailsForm: initialCompanyDetailsFormState,
  companyContactPersonForm: initialCompanyContactPersonState,
  companyEventForm: initialCompanyEventFormState,
};

const actionReducer = createReducer<CompanyFormsState>(
  initialCompanyFormsState
);

const joinedReducer = (state: CompanyFormsState | undefined, action: Action): CompanyFormsState => {
  const newState: CompanyFormsState = actionReducer(state, action);

  return {
    ...newState,
    createCompanyForm: createCompanyFormReducer(newState, action),
    companyDetailsForm: companyDetailsFormReducer(newState, action),
    companyContactPersonForm: companyContactPersonFormReducer(newState, action),
    companyEventForm: companyEventFormReducer(newState, action),
  };
};

export function companyFormsReducer(state: CompanyFormsState | undefined, action: Action) {
  return joinedReducer(state, action);
}
