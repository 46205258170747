import { createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';
import { CompanyState } from './company.model';

const selectCompanyState = (state: AppState) => state.companyState;

export const selectCompanyCreationDetails = createSelector(selectCompanyState, (state: CompanyState) => {
  return {
    companyCode: state.companyCode,
    companyName: state.companyName,
    companyError: state.companyError
  };
});

export const selectCompany = createSelector(selectCompanyState, (state: CompanyState) => {
  return state.company;
});

export const selectCompanies = createSelector(selectCompanyState, (state: CompanyState) => {
  return state.companies;
});
