<div *ngIf="companies.length > 0">
  <div class="match-cards">
    <div *ngFor="let company of followedCompanies">
      <app-companycard class="h-100" [company]="company"></app-companycard>
    </div>
    <div *ngFor="let company of preferedCompanies">
      <app-companycard class="h-100" [company]="company"></app-companycard>
    </div>
    <div *ngFor="let company of nonPreferedCompanies">
      <app-companycard class="h-100" [company]="company"></app-companycard>
    </div>
  </div>
</div>

