import {Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {Store, ScannedActionsSubject} from '@ngrx/store';
import {AppState} from 'src/app/ngrx-store/app.state';
import {getMentees, getMenteesSuccess} from 'src/app/ngrx-store/requests/match/get-mentee/get-mentee.actions';
import {getMentors, getMentorsSuccess} from 'src/app/ngrx-store/requests/match/get-mentor/get-mentor.actions';
import {selectMatchModel} from 'src/app/ngrx-store/components/match/match.selectors';
import {User} from 'src/app/ngrx-store/components/user/user.model';
import {SearchFilter} from 'src/app/ngrx-store/components/match/match.model';
import {ofType} from '@ngrx/effects';
import {Subscription} from 'rxjs';
import {getMembers, getMembersSuccess} from 'src/app/ngrx-store/requests/match/get-members/get-members.actions';
import {UserRole} from 'src/app/ngrx-store/components/userForms/userForms.model';

@Component({
  selector: 'app-find-and-connect',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.scss'],
})
export class MatchesComponent implements OnDestroy, OnInit {
  matchModel$ = this.store.select(selectMatchModel);

  filterApplied = false;
  previousFilter: { filter: SearchFilter; selectedRole: UserRole };

  callbackSubscription: Subscription;
  filteredUsers: User[] | undefined = undefined;
  loading = 1;
  filterEmpty = true;

  @ViewChild('container') container: ElementRef;

  constructor(private store: Store<AppState>, private cdr: ChangeDetectorRef, private actions$: ScannedActionsSubject) {
    this.callbackSubscription = this.actions$
      .pipe(ofType(getMenteesSuccess, getMentorsSuccess, getMembersSuccess))
      .subscribe(() => {
        if (this.loading > 0) {
          this.loading--;
        }
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.callbackSubscription.unsubscribe();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  applyFilter() {
    this.filterApplied = true;
    this.container.nativeElement.classList.remove('purple-background-container');
    this.container.nativeElement.classList.add('search-background-color');
    if (this.previousFilter) {
      this.filterChange(this.previousFilter);
    }
  }

  filterChange(newFilter: { filter: SearchFilter; selectedRole: UserRole }) {
    if (this.filterApplied) {
      this.filterEmpty = !newFilter.filter.company && !newFilter.filter.name && !newFilter.filter.country && !newFilter.filter.region &&
        newFilter.filter.digitalAreaExpertiseNames?.length === 0 && newFilter.filter.educationNames?.length === 0 &&
        newFilter.filter.digitalAreaInterestNames?.length === 0 && newFilter.filter.industryExperienceNames?.length === 0 &&
        newFilter.filter.industryInterestNames?.length === 0 &&
        newFilter.filter.workExperience === undefined && newFilter.selectedRole === 'Community member';

      this.loading = 0;

      if (newFilter.selectedRole === UserRole.Mentor || newFilter.selectedRole === UserRole.MentorAndMentee) {
        this.store.dispatch(getMentors({filter: newFilter.filter, limit: undefined, bestMatches: false}));
        this.loading++;
      }

      if (newFilter.selectedRole === UserRole.Mentee || newFilter.selectedRole === UserRole.MentorAndMentee) {
        this.store.dispatch(getMentees({filter: newFilter.filter, limit: undefined, bestMatches: false}));
        this.loading++;
      }

      if (newFilter.selectedRole === UserRole.Member) {
        this.store.dispatch(getMembers({filter: newFilter.filter, limit: undefined, bestMatches: false}));
        this.loading++;
      }

      this.cdr.detectChanges();
    } else {
      this.previousFilter = newFilter;
    }
  }

  updateList(newList: User[]) {
    this.filteredUsers = newList;
    this.cdr.detectChanges();
  }
}
