<div class="signup-company-container p-4">
  <div class="dashboard-title">
    <div class="underlined-title">
      <p>Company details</p>
      <div></div>
    </div>
  </div>
  <div class="intro-text pb-4">This information will be visible on <b>your company profile.</b> You will be able to check your page after submitting before it's published</div>
  <div class="create-company-form-container">
    <div *ngIf="companyDetailsForm$ | async as form">
      <div *ngIf="companyContactPersonForm$ | async as contactForm">

      <div class="text-primary section-title pb-3">General</div>
      <app-company-details-name></app-company-details-name>
      <app-company-details-introduction [isSignUp]="true"></app-company-details-introduction>
      <app-company-details-about-us [isSignUp]="true"></app-company-details-about-us>
      <app-company-details-careers [isSignUp]="true"></app-company-details-careers>
      <app-company-details-industries [isSignUp]="true"></app-company-details-industries>
      <app-company-details-country [isSignUp]="true"></app-company-details-country>

      <div class="text-primary section-title pb-3">Choose up to 3 ambassadors for your company</div>
      <app-company-ambassadors [isSignUp]="true"></app-company-ambassadors>

      <div class="text-primary section-title pb-3">Photos & videos</div>
      <app-company-details-media [isSignUp]="true"></app-company-details-media>

      <div class="text-primary section-title pb-3">Social media</div>
      <app-company-social-media [isSignUp]="true"></app-company-social-media>

      <div class="text-primary section-title pb-3">Add a contact person for open applications (Optional). More contacts can be added later once the company page is live. </div>
      <app-company-contact-person-form></app-company-contact-person-form>

      <div class="d-flex justify-content-center">
        <button
          class="btn-sm mx-auto"
          mat-flat-button
          color="primary"
          [disabled]="form.isInvalid || (isContactFormFilled(contactForm.value) && contactForm.isInvalid)"
          (click)="submitForm(form, contactForm)">
          <div class="d-flex justify-content-center align-items-center submit-button">
            <span class="pe-3">{{ isSubmitted ? "Adding your company" : "Finish"}}</span>
            <mat-spinner
              *ngIf="isSubmitted"
              mode="indeterminate"
              diameter="20"
            ></mat-spinner>
          </div>
        </button>
      </div>
    </div>
    </div>
  </div>
</div>

