<ng-container *ngIf="self || digitalAreaBackgrounds.length > 0">
  <h3 class="section-title">Digital areas</h3>
  <p class="mt-3">Digital expertise</p>
  <mat-chip-listbox class="d-block my-2" aria-label="List items">
    <mat-chip-option
      color="primary"
      selected
      style="color: white"
      *ngFor="let item of digitalAreaBackgrounds; let i = index"
    >
      {{ item.name }}
    </mat-chip-option>
    <a *ngIf="self" [routerLink]="'../edit/digitalAreas/background'" class="add-button"
      ><img src="../../../../assets/images/add-button.svg"
    /></a>
  </mat-chip-listbox>
</ng-container>
<ng-container *ngIf="self || digitalAreaInterests.length > 0">
  <p class="mt-3">Digital areas of interest</p>
  <mat-chip-listbox class="d-block my-2" aria-label="List items">
    <mat-chip-option
      color="primary"
      selected
      style="color: white"
      *ngFor="let item of digitalAreaInterests; let i = index"
    >
      {{ item.name }}
    </mat-chip-option>
    <a *ngIf="self" [routerLink]="'../edit/digitalAreas/interest'" class="add-button"
      ><img src="../../../../assets/images/add-button.svg"
    /></a>
  </mat-chip-listbox>
</ng-container>
