import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {catchError, exhaustMap, map, switchMap, withLatestFrom} from 'rxjs/operators';
import { of } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { AppState } from '../../app.state';
import { saveAs } from 'file-saver';

import {
  getUsersSuccess,
  adminRequestError,
  getUsers,
  getUserExcel,
  getUserExcelSuccess,
  setIndustries,
  setIndustriesSuccess,
  setDigitalAreas,
  setDigitalAreasSuccess,
  setRights,
  setRightsSuccess,
  setIndustry,
  setDigitalArea, getEventRegistrations, getEventRegistrationsSuccess, deleteCompany, deleteCompanySuccess,
} from './admin.actions';
import { selectIndustries, selectDigitalAreas } from '../userEntities/userEntities.selectors';
import { addCompany, addCompanySuccess, getPartnerExcel, getPartnerExcelSuccess } from './admin.actions';

@Injectable()
export class AdminEffects {
  constructor(private actions$: Actions, private store: Store<AppState>, private adminService: AdminService) {}

  getAllUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUsers),
      exhaustMap((a) => {
        return this.adminService.getUsers().pipe(
          map((users) => {
            return getUsersSuccess({ users });
          }),
          catchError((error: Error) => {
            return of(adminRequestError({ error: error.message }));
          })
        );
      })
    )
  );

  getUserExcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserExcel),
      exhaustMap((a) => {
        return this.adminService.getUserExcel().pipe(
          map((data) => {
            const blob = new Blob([data.body], { type: data.headers.get('content-type') });
            const fileName = 'users.xlsx';
            const file = new File([blob], fileName, { type: data.headers.get('content-type') });
            saveAs(file);

            return getUserExcelSuccess();
          }),
          catchError((error: Error) => {
            return of(adminRequestError({ error: error.message }));
          })
        );
      })
    )
  );

  getPartnerExcel$ = createEffect(() =>
  this.actions$.pipe(
    ofType(getPartnerExcel),
    exhaustMap((a) => {
      return this.adminService.getPartnerExcel().pipe(
        map((data) => {
          const blob = new Blob([data.body], { type: data.headers.get('content-type') });
          const fileName = 'partner_analysis.xlsx';
          const file = new File([blob], fileName, { type: data.headers.get('content-type') });
          saveAs(file);

          return getPartnerExcelSuccess();
        }),
        catchError((error: Error) => {
          return of(adminRequestError({ error: error.message }));
        })
      );
    })
  )
);

  getEventRegistrations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEventRegistrations),
      switchMap((a) => {
        return this.adminService.getEventExport(a.eventId).pipe(
          map((data) => {
            const blob = new Blob([data.body], { type: data.headers.get('content-type') });
            const fileName = 'event-registrations-export.xlsx';
            const file = new File([blob], fileName, { type: data.headers.get('content-type') });
            saveAs(file);

            return getEventRegistrationsSuccess();
          }),
          catchError((error: Error) => {
            return of(adminRequestError({ error: error.message }));
          })
        );
      })
    )
  );

  setIndustries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setIndustries),
      exhaustMap((a) => {
        return this.adminService.setIndustries(a.industries).pipe(
          map((industries) => {
            return setIndustriesSuccess({ industries });
          }),
          catchError((error: Error) => {
            return of(adminRequestError({ error: error.message }));
          })
        );
      })
    )
  );

  setIndustry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setIndustry),
      withLatestFrom(this.store.pipe(select(selectIndustries))),
      exhaustMap(([a, industries]) => {
        const nameExists = industries.find((i) => i.name === a.industry.name && i.id !== a.industry.id);
        if (nameExists) {
          return of(setIndustriesSuccess({ industries }));
        }

        const idExists = industries.find((i) => i.id === a.industry.id);

        if (idExists) {
          industries = industries.map((i) => (i.id === a.industry.id ? { id: i.id, name: a.industry.name } : i));
        } else {
          industries = industries.concat(a.industry);
        }

        industries = industries.filter((i) => i.name?.length > 0);

        return this.adminService.setIndustries(industries).pipe(
          map((industryResult) => {
            return setIndustriesSuccess({ industries: industryResult });
          }),
          catchError((error: Error) => {
            return of(adminRequestError({ error: error.message }));
          })
        );
      })
    )
  );

  setDigitalAreas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDigitalAreas),
      exhaustMap((a) => {
        return this.adminService.setDigitalAreas(a.digitalAreas).pipe(
          map((digitalAreas) => {
            return setDigitalAreasSuccess({ digitalAreas });
          }),
          catchError((error: Error) => {
            return of(adminRequestError({ error: error.message }));
          })
        );
      })
    )
  );

  setDigitalArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDigitalArea),
      withLatestFrom(this.store.pipe(select(selectDigitalAreas))),
      exhaustMap(([a, digitalAreas]) => {
        const nameExists = digitalAreas.find((i) => i.name === a.digitalArea.name && i.id !== a.digitalArea.id);
        if (nameExists) {
          return of(setDigitalAreasSuccess({ digitalAreas }));
        }

        const idExists = digitalAreas.find((i) => i.id === a.digitalArea.id);

        if (idExists) {
          digitalAreas = digitalAreas.map((i) =>
            i.id === a.digitalArea.id ? { id: i.id, name: a.digitalArea.name } : i
          );
        } else {
          digitalAreas = digitalAreas.concat(a.digitalArea);
        }

        digitalAreas = digitalAreas.filter((i) => i.name?.length > 0);

        return this.adminService.setDigitalAreas(digitalAreas).pipe(
          map((digitalAreasResult) => {
            return setDigitalAreasSuccess({ digitalAreas: digitalAreasResult });
          }),
          catchError((error: Error) => {
            return of(adminRequestError({ error: error.message }));
          })
        );
      })
    )
  );

  setRights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setRights),
      exhaustMap((a) => {
        return this.adminService.setRights(a.id, a.rights).pipe(
          map(() => {
            return setRightsSuccess({ id: a.id });
          }),
          catchError((error: Error) => {
            return of(adminRequestError({ error: error.message }));
          })
        );
      })
    )
  );

  deleteUserFromCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCompany),
      exhaustMap((a) => {
        return this.adminService.removeUserFromCompany(a.id).pipe(
          map(() => {
            return deleteCompanySuccess();
          }),
          catchError((error: Error) => {
            return of(adminRequestError({ error: error.message }));
          })
        );
      })
    )
  );

  addUserToCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCompany),
      exhaustMap((a) => {
        return this.adminService.addUserToCompany(a.id, a.companyId).pipe(
          map(() => {
            return addCompanySuccess();
          }),
          catchError((error: Error) => {
            return of(adminRequestError({ error: error.message }));
          })
        );
      })
    )
  );
}
