export const AppRoutes = {
  LOGIN: 'login',
  ONBOARDING: 'onboarding',
  PROFILE: 'profile',
  CHATS: 'latest-chats',
  MATCHES: 'matches',
  CONNECTIONS: 'connections',
  ADMIN: 'admin-panel',
  SETTINGS: 'settings',
  DASHBOARD: 'dashboard',
  STORIES: 'stories',
  STORY: 'story',
  EVENTS: 'events',
  EVENT: 'event',
  EDUCATION: 'education',
  NOTIFICATIONS: 'notifications',
  COMPANY: 'company',
  COMPANIES: 'companies',
  USER: 'user',
  VACANCIES: 'vacancies',
  BLOGITEMS: 'blogs',
  BLOGITEM: 'blog',
};

export type AppRouteUrl =
  | 'login'
  | 'onboarding'
  | 'profile'
  | 'latest-chats'
  | 'matches'
  | 'connections'
  | 'admin-panel'
  | 'settings'
  | 'dashboard'
  | 'stories'
  | 'story'
  | 'event'
  | 'education'
  | 'notifications'
  | 'events'
  | 'company'
  | 'vacancies'
  | 'companies';
