<div *ngIf="companyDetailsForm$ | async as form">
  <app-company-details-header
    *ngIf="!isSignUp && (hasSocialMedia || !company)"
    title="Social media"
    [canEdit]="!company"
    [modify]="modify"
    (onModify)="toggleModify($event)"
    data-cy="company-social-media-title"
  ></app-company-details-header>

  <div *ngIf="company; else self">
    <table>
      <tr>
        <td *ngIf="company.socialMedia?.linkedinSocialMedia?.trim()">
          <a [href]="company.socialMedia?.linkedinSocialMedia?.trim().toLowerCase().startsWith('linkedin') ? 'https://' + company.socialMedia?.linkedinSocialMedia.trim() : company.socialMedia?.linkedinSocialMedia?.trim()" target="_blank">
            <img src="../../../../assets/images/brands/linkedin.svg" class=" social-media-icon" />
          </a>
        </td>
        <td *ngIf="company.socialMedia?.instagramSocialMedia?.trim()">
          <a [href]="company.socialMedia?.instagramSocialMedia?.trim().toLowerCase().startsWith('instagram') ? 'https://' + company.socialMedia?.instagramSocialMedia.trim() : company.socialMedia?.instagramSocialMedia?.trim()" target="_blank">
            <img src="../../../../assets/images/brands/instagram.svg" class=" social-media-icon" />
          </a>
        </td>
        <td *ngIf="company.socialMedia?.facebookSocialMedia?.trim()">
          <a [href]="company.socialMedia?.facebookSocialMedia?.trim().toLowerCase().startsWith('facebook') ? 'https://' + company.socialMedia?.facebookSocialMedia.trim() : company.socialMedia?.facebookSocialMedia?.trim()" target="_blank">
            <img src="../../../../assets/images/brands/facebook.svg" class=" social-media-icon" />
          </a>
        </td>
        <td *ngIf="company.socialMedia?.twitterSocialMedia?.trim()">
          <a [href]="company.socialMedia?.twitterSocialMedia?.trim().toLowerCase().startsWith('twitter') ? 'https://' + company.socialMedia?.twitterSocialMedia.trim() : company.socialMedia?.twitterSocialMedia?.trim()" target="_blank">
            <img src="../../../../assets/images/brands/twitter.svg" class=" social-media-icon" />
          </a>
        </td>
      </tr>
    </table>
  </div>

  <ng-template #self>
    <table *ngIf="!modify && !isSignUp">
      <tr>
        <td *ngIf="form.controls.linkedIn.value.trim()">
          <a [href]="form.controls.linkedIn.value.trim().toLowerCase().startsWith('linkedin') ? 'https://' + form.controls.linkedIn.value.trim() : form.controls.linkedIn.value.trim()" target="_blank">
            <img src="../../../../assets/images/brands/linkedin.svg" class=" social-media-icon" />
          </a>
        </td>
        <td *ngIf="form.controls.instagram.value.trim()">
          <a [href]="form.controls.instagram.value.trim().toLowerCase().startsWith('instagram') ? 'https://' + form.controls.instagram.value.trim() : form.controls.instagram.value.trim()" target="_blank">
            <img src="../../../../assets/images/brands/instagram.svg" class=" social-media-icon" />
          </a>
        </td>
        <td *ngIf="form.controls.facebook.value.trim()">
          <a [href]="form.controls.facebook.value.trim().toLowerCase().startsWith('facebook') ? 'https://' + form.controls.facebook.value.trim() : form.controls.facebook.value.trim()" target="_blank">
            <img src="../../../../assets/images/brands/facebook.svg" class=" social-media-icon" />
          </a>
        </td>
        <td *ngIf="form.controls.twitter.value.trim()">
          <a [href]="form.controls.twitter.value.trim().toLowerCase().startsWith('twitter') ? 'https://' + form.controls.twitter.value.trim() : form.controls.twitter.value.trim()" target="_blank">
            <img src="../../../../assets/images/brands/twitter.svg" class=" social-media-icon" />
          </a>
        </td>
        <td *ngIf="!company">
          <div (click)="toggleModify(true)"><img src="../../../../assets/images/add-button.svg"/></div>
        </td>
      </tr>
    </table>
    <ng-container *ngIf="modify || isSignUp">
      <div *ngIf="companyDetailsForm$ | async as companyDetailsForm" class="mb-1">
        <app-input-field
          [form]="companyDetailsForm"
          [formControl]="companyDetailsForm.controls.linkedIn"
          [label]="'LinkedIn'"
          [placeholder]="'Link to Linkedin page'"
          patternErrorMessage="Please provide a valid LinkedIn url"
        >
        </app-input-field>
        <app-input-field
          [form]="companyDetailsForm"
          [formControl]="companyDetailsForm.controls.facebook"
          [label]="'Facebook'"
          [placeholder]="'Link to Facebook page'"
          patternErrorMessage="Please provide a valid Facebook url"
        >
        </app-input-field>
        <app-input-field
          [form]="companyDetailsForm"
          [formControl]="companyDetailsForm.controls.instagram"
          [label]="'Instagram'"
          [placeholder]="'Link to Instagram page'"
          patternErrorMessage="Please provide a valid Instagram url"
        >
        </app-input-field>
        <app-input-field
          [form]="companyDetailsForm"
          [formControl]="companyDetailsForm.controls.twitter"
          [label]="'Twitter'"
          [placeholder]="'Link to Twitter page'"
          patternErrorMessage="Please provide a valid Twitter url"
        >
        </app-input-field>
      </div>
    </ng-container>
  </ng-template>
</div>
