import { createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';
import { ConnectionState } from './connection.model';

const selectConnectionState = (state: AppState) => state.connections;

export const selectConnections = createSelector(selectConnectionState, (state: ConnectionState) => state.connections);

export const selectPendingConnections = createSelector(selectConnectionState, (state: ConnectionState) => state.pendingConnections);

export const selectConfirmedConnections = createSelector(selectConnectionState, (state: ConnectionState) =>
  state.connections?.filter((c) => !c.isPending)
);
