<ng-container
  *ngIf="personalDetailsForm$ | async as personalDetailsForm"
  style="overflow: visible"
>
  <div class="step">
    <div class="container step-content">
      <form>
        <div class="col-12 p-0">
          <p style="font-size: 18px">
            This information will be visible on <b>your profile</b>.
          </p>
          <br />
          <app-input-select
            [form]="personalDetailsForm"
            [formControl]="personalDetailsForm.controls.employmentStatus"
            [label]="'Career status'"
            [selectionList]="
              personalDetailsForm.controls.employmentStatuses.value
            "
            [requiredErrorMessage]="'Title is required'"
          ></app-input-select>
          <div
            *ngIf="
              personalDetailsForm.controls.employmentStatus.value ===
                employmentStatus.Student;
              else nostudent
            "
          >
            <app-input-field
              [form]="personalDetailsForm"
              [formControl]="personalDetailsForm.controls.education"
              [label]="'Educational institution'"
              [placeholder]="'Enter your Educational institution'"
              [requiredErrorMessage]="'Educational institution is required'"
            ></app-input-field>
            <app-input-field
              [form]="personalDetailsForm"
              [formControl]="personalDetailsForm.controls.study"
              [label]="'Study'"
              [placeholder]="'Study field and level'"
              [requiredErrorMessage]="'Study is required'"
            ></app-input-field>
            <app-input-date
              [form]="personalDetailsForm"
              [formControl]="personalDetailsForm.controls.startDate"
              [label]="'Start date Educational institution'"
              [requiredErrorMessage]="'Please enter a valid date'"
            ></app-input-date>
          </div>
          <ng-template #nostudent>
            <app-input-auto-complete
              [form]="personalDetailsForm"
              [formControl]="personalDetailsForm.controls.company"
              [inputList]="companies"
              [label]="
                personalDetailsForm.controls.employmentStatus.value ===
                employmentStatus.In_Between_Jobs
                  ? 'Last company'
                  : 'Current company'
              "
              [placeholder]="'Enter your company'"
              [requiredErrorMessage]="'Company name is required'"
            ></app-input-auto-complete>
            <app-input-field
              [form]="personalDetailsForm"
              [formControl]="personalDetailsForm.controls.companyPosition"
              [label]="
                personalDetailsForm.controls.employmentStatus.value ===
                employmentStatus.In_Between_Jobs
                  ? 'Last position'
                  : 'Current position'
              "
              [placeholder]="'Enter your position'"
              [requiredErrorMessage]="'Position is required'"
            ></app-input-field>
            <app-input-field
              [form]="personalDetailsForm"
              [formControl]="personalDetailsForm.controls.careerStartingYear"
              label="Starting year of your career"
              requiredErrorMessage="Please provide a valid starting year"
            ></app-input-field>
          </ng-template>

          <app-input-country
            [form]="personalDetailsForm"
            [formControl]="personalDetailsForm.controls.country"
            [label]="'Country'"
            [placeholder]="'Select your Country'"
            [requiredErrorMessage]="'Country is required'"
          ></app-input-country>
          <app-input-auto-complete
            *ngIf="personalDetailsForm.controls.country.value === 'Netherlands'"
            [form]="personalDetailsForm"
            [formControl]="personalDetailsForm.controls.region"
            [inputList]="provincies"
            [label]="'Region'"
            [placeholder]="'Enter your Region'"
            [requiredErrorMessage]="'Region is required'"
          ></app-input-auto-complete>
          <div>
            <mat-label
              ><b> How did you find out about RightBrains?</b></mat-label
            >
          </div>
          <div class="reference-container">
            <mat-radio-group
              aria-label="Select an option"
              class="radio-group"
              [ngrxFormState]="personalDetailsForm"
              [ngrxFormControlState]="
                personalDetailsForm.controls.referenceType
              "
            >
              <mat-radio-button
                *ngFor="let option of basicReferenceTypes"
                [value]="option"
              >
                {{ option }}
              </mat-radio-button>
              <mat-radio-button [value]="referenceType.Relationship">
                <div class="relationship-container">
                  {{ referenceType.Relationship }}
                </div>
              </mat-radio-button>
              <app-input-field
                class="w-100"
                *ngIf="
                  personalDetailsForm.controls.referenceType?.value ==
                  referenceType.Relationship
                "
                [form]="personalDetailsForm"
                [formControl]="personalDetailsForm.controls.reference"
                tooltipText="RightBrains awards people who actively support our mission. Please let us know who introduced you to our community."
                label="Please specify their first and last name"
                placeholder="Jane Doe"
              >
              </app-input-field>

              <mat-radio-button [value]="referenceType.Other">
                {{ referenceType.Other }}
              </mat-radio-button>
              <app-input-field
                class="w-100"
                *ngIf="
                  personalDetailsForm.controls.referenceType?.value ==
                  referenceType.Other
                "
                [form]="personalDetailsForm"
                [formControl]="personalDetailsForm.controls.reference"
                label="Please elaborate"
                placeholder="Through ..."
              ></app-input-field>
            </mat-radio-group>
          </div>
        </div>
      </form>

      <mat-label><b>Upload profile photo (max. 2MB, PNG/JPEG)</b></mat-label>
      <app-input-file
        fileName="Upload"
        (onFileUpload)="addPhoto($event)"
        [canDelete]="false"
      ></app-input-file>
      <div *ngIf="selectUserAvatar$ | async as avatar" class="image-container">
        <img
          *ngIf="avatar"
          [src]="avatar | safe: 'url'"
          class="profile-picture"
        />
      </div>

      <div class="button-area w-100">
        <button
          *ngIf="!submittingStep"
          mat-flat-button
          [disabled]="
            personalDetailsForm.isInvalid ||
            !(selectUserAvatar$ | async) ||
            personalDetailsForm.controls.referenceType.value ===
              referenceType.None
          "
          color="primary"
          type="submit"
          class="btn-sm"
          (click)="submitFormAndPost(personalDetailsForm.value)"
        >
          Next step
        </button>
        <div *ngIf="submittingStep">
          <mat-spinner [diameter]="35"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</ng-container>
