<div *ngIf="pendingConnections$ | async as conns; else noRequests">
  <ng-container *ngIf="conns.length > 0; else noRequests">
    <div *ngFor="let conn of conns; let i = index">
      <app-connection-request *ngIf="i < displayLimit * (showMoreCount + 1)"
                              [connection]="conn">
      </app-connection-request>
    </div>

    <div class="d-flex mt-4">
      <button
        class="mx-auto"
        *ngIf="conns.length > displayLimit * (showMoreCount + 1)"
        mat-flat-button
        color="primary"
        (click)="showMoreCount = showMoreCount + 1"
      >
        Show more
      </button>
    </div>
  </ng-container>
</div>
<ng-template #noRequests>
  <p>No pending requests.</p>
</ng-template>
