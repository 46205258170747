<div class="container color-background-primary">
  <h2 class="text-primary align-center">Our partners</h2>

  <a class='back-arrow' [routerLink]="'/'">
    <span></span>
    <mat-icon>arrow_back</mat-icon>
  </a>
  <div class="bevel mb-4"></div>
  <ng-container *ngIf="companiesState$ | async as companiesState; else loadingSpinner">
    <div *ngIf="companiesState.length > 0">
      <div class="companies-container companies">
        <app-companycards [companies]="companiesState"></app-companycards>
      </div>
    </div>
  </ng-container>
  <ng-template #loadingSpinner>
    <div class="w-100 d-flex justify-content-center">
      <mat-spinner class="margin-auto" [diameter]="65"></mat-spinner>
    </div>
  </ng-template>
</div>

