import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppRoutes } from 'src/app/app-routing.model';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectUserModel } from 'src/app/ngrx-store/components/user/user.selectors';
import { submitProfileEditForm } from 'src/app/ngrx-store/components/userForms/profileEdit/forms.profileEdit.actions';
import {selectFindMatchForm, selectProfileEditForm} from 'src/app/ngrx-store/components/userForms/userForms.selectors';
import {postUser, postUserSuccess} from 'src/app/ngrx-store/requests/user/post-user/post-user.actions';
import { take } from 'rxjs/operators';
import { PROVINCIES_LIST } from 'src/app/shared/utilities/data/provincies.data';
import Timeout = NodeJS.Timeout;
import {Actions, ofType} from '@ngrx/effects';
import {UserRole} from '../../../ngrx-store/components/userForms/userForms.model';
import {changeRole, setFindInRegionUser, setFindMoreExperiencedUser} from '../../../ngrx-store/components/user/user.actions';
import { Subscription } from 'rxjs';
import {
  setFindInRegion,
  setFindMoreExperienced,
} from '../../../ngrx-store/components/userForms/onboardingFindMatch/forms.findMatch.actions';
import {UserAttributes} from '../../../ngrx-store/components/user/user.model';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss'],
})
export class ProfileEditComponent implements AfterViewInit {

  public userModel$ = this.store.select(selectUserModel);
  public profileEditForm$ = this.store.select(selectProfileEditForm);
  public findMatchForm$ = this.store.select(selectFindMatchForm);

  public approutes = AppRoutes;

  public provincies = PROVINCIES_LIST;

  editingFunctionLabel = false;
  saveTimer: Timeout;
  saveText = '';
  roles = UserRole;
  selectedRole: UserRole;
  subscriber: Subscription;
  Q1 = false;
  Q2 = false;

  @ViewChild('member') member: ElementRef;
  @ViewChild('mentee') mentee: ElementRef;
  @ViewChild('mentor') mentor: ElementRef;
  @ViewChild('mentormentee') mentormentee: ElementRef;

  constructor(private readonly store: Store<AppState>, private actions$: Actions) {
    this.actions$.pipe(ofType(postUserSuccess)).subscribe(() => this.saveText = 'Saved');
  }

  ngAfterViewInit(): void {
    this.store.pipe(select(selectUserModel)).pipe(take(1)).subscribe((user) => {
      if (typeof user.user !== 'boolean' && typeof user.user !== 'undefined') {
        this.setRoleBackground(Object.values(UserRole)[user.user.role]);
      } else {
        this.setRoleBackground(Object.values(UserRole)[0]);
      }
    });
    this.actions$.pipe(ofType(postUserSuccess)).subscribe((user) => this.setRoleBackground(Object.values(UserRole)[user.user.role]));
    }

  public saveProfile() {
    this.profileEditForm$.pipe(take(1)).subscribe((form) => {
      this.store.dispatch(submitProfileEditForm({ formValues: form.value }));
      this.store.dispatch(postUser());
    });
  }

  public startTimer() {
    this.saveText = 'Saving...';
    clearTimeout(this.saveTimer);
    this.saveTimer = setTimeout(() => this.saveProfile(), 500);
  }

  public setRoleBackground(role: string) {
    if (this.member) {
      this.member.nativeElement.classList.remove('role-chip-selected');
      this.mentor.nativeElement.classList.remove('role-chip-selected');
      this.mentee.nativeElement.classList.remove('role-chip-selected');
      this.mentormentee.nativeElement.classList.remove('role-chip-selected');
      switch (role) {
        case UserRole.Member:
          this.member.nativeElement.classList.add('role-chip-selected');
          break;
        case UserRole.Mentor:
          this.mentor.nativeElement.classList.add('role-chip-selected');
          break;
        case UserRole.Mentee:
          this.mentee.nativeElement.classList.add('role-chip-selected');
          break;
        case UserRole.MentorAndMentee:
          this.mentormentee.nativeElement.classList.add('role-chip-selected');
      }
    }
  }

  public selectRole(role: UserRole) {
    this.selectedRole = role;
    this.store.dispatch(changeRole({role}));
    this.store.dispatch(postUser());
    switch (role) {
      case UserRole.Member:
        this.Q1 = false;
        this.Q2 = false;
        break;
      case UserRole.Mentor:
        this.Q1 = false;
        this.Q2 = true;
        break;
      case UserRole.Mentee:
        this.Q1 = true;
        this.Q2 = true;
        break;
      case UserRole.MentorAndMentee:
        this.Q1 = true;
        this.Q2 = true;
    }
  }

  public findMoreExperienced(value: boolean) {
    this.store.dispatch(setFindMoreExperiencedUser({ value }));
    this.store.dispatch(setFindMoreExperienced({ value }));
    this.store.dispatch(postUser());
  }

  public findInRegion(value: boolean) {
    this.store.dispatch(setFindInRegionUser({ value }));
    this.store.dispatch(setFindInRegion({ value }));
    this.store.dispatch(postUser());
  }
}
