import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserEntityService as UserEntityService } from '../../../services/userEntity.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  getDigitalAreas,
  getDigitalAreasError,
  getDigitalAreasSuccess,
  getIndustries,
  getIndustriesError,
  getIndustriesSuccess
} from './get-userEntities.actions';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GetUserEntitiesEffects {
  constructor(
    private actions$: Actions,
    private userEntityService: UserEntityService) {
  }

  getDigitalAreas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDigitalAreas),
      switchMap(() => {
        return this.userEntityService.getDigitalAreasList().pipe(
          map((digitalAreas) => getDigitalAreasSuccess({ digitalAreas })),
          catchError((error: HttpErrorResponse) => of(getDigitalAreasError({ error: error.message })))
        );
      })
    ));

  getIndustries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getIndustries),
      switchMap(() => {
        return this.userEntityService.getIndustriesList().pipe(
          map((industries) => getIndustriesSuccess({ industries })),
          catchError((error: HttpErrorResponse) => of(getIndustriesError({ error: error.message })))
        );
      })
    ));
}
