<div class="step" *ngFor="let step of steps; let i = index; let isLast = last">
  <h2 class="text-primary" *ngIf="showLabel">
    <h2 class="text-primary">
      <strong>{{ steps.first.stepLabel }}</strong>
    </h2>
  </h2>
  <app-stepper-progress
    class="stepper-progress"
    [stepIndex]="1"
    [totalSteps]="3"
    *ngIf="showProgress"
  ></app-stepper-progress>
  <div
    class="vertical-content-container"
    [class.stepper-vertical-line]="!isLast"
    [class.hide-overflow]="isLast"
    [class.d-none]="selectedIndex !== i"
  >
    <div
      class="vertical-stepper-content"
      role="tabpanel"
      [attr.tabindex]="selectedIndex === i ? 0 : null"
      [@stepTransition]="_getAnimationDirection(i)"
      (@stepTransition.done)="animationDone.next($event)"
      [id]="_getStepContentId(i)"
      [attr.aria-labelledby]="_getStepLabelId(i)"
      [attr.aria-expanded]="selectedIndex === i"
    >
      <div class="vertical-content">
        <ng-container [ngTemplateOutlet]="step.content"></ng-container>
      </div>
    </div>
  </div>
</div>
