import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import {
  addDigitalAreaToVacancy,
  addIndustryToVacancy,
  clearVacancy,
  removeDigitalAreaFromVacancy,
  removeIndustryFromVacancy,
  submitVacancy,
} from 'src/app/ngrx-store/components/vacancyForm/vacancyForm.actions';
import { CreateVacancyForm } from 'src/app/ngrx-store/components/vacancyForm/vacancyForm.model';
import { selectCreateVacancyForm } from '../../../ngrx-store/components/vacancyForm/vacancyForm.selectors';
import { ExperienceOption } from '../../../ngrx-store/components/userForms/userForms.model';
import { VacancyType } from '../../../ngrx-store/components/vacancy/vacancy.model';
import {
  addPhotoToVacancy,
  removePhotoFromVacancy,
} from '../../../ngrx-store/components/vacancyForm/vacancyForm.actions';
import {
  getIndustries,
  getDigitalAreas,
} from '../../../ngrx-store/requests/attributes/get-userEntities.actions';
import {
  selectDigitalAreas,
  selectIndustries,
} from '../../../ngrx-store/components/userEntities/userEntities.selectors';

@Component({
  selector: 'app-create-vacancy-dialog',
  templateUrl: './create-vacancy-dialog.component.html',
  styleUrls: ['./create-vacancy-dialog.component.scss'],
})
export class CreateVacancyDialogComponent implements OnInit {
  public createVacancyForm$ = this.store.select(selectCreateVacancyForm);
  public digitalAreas$ = this.store.select(selectDigitalAreas);
  public industries$ = this.store.select(selectIndustries);
  public isEdit = false;
  public careerPhases = Object.values(ExperienceOption);
  public careerPhaseValues = [...Array(this.careerPhases.length).keys()];
  public vacancyTypes = Object.keys(VacancyType).filter((v) => isNaN(+v));
  public vacancyTypeValues = Object.keys(VacancyType)
    .map(Number)
    .filter((v) => !isNaN(v));

  constructor(
    private readonly store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) data?: { isEdit?: boolean }
  ) {
    this.isEdit = data?.isEdit ?? false;
  }

  ngOnInit() {
    this.store.dispatch(getIndustries());
    this.store.dispatch(getDigitalAreas());
  }

  addVacancy(formValues: CreateVacancyForm) {
    this.store.dispatch(submitVacancy({ formValues }));
    this.clearVacancyForm();
  }

  public addDigitalArea(digitalArea: string) {
    this.store.dispatch(
      addDigitalAreaToVacancy({ digitalArea: { name: digitalArea } })
    );
  }

  public removeDigitalArea(digitalArea: string) {
    this.store.dispatch(
      removeDigitalAreaFromVacancy({ digitalArea: { name: digitalArea } })
    );
  }

  public addIndustry(industry: string) {
    this.store.dispatch(addIndustryToVacancy({ industry: { name: industry } }));
  }

  public removeIndustry(industry: string) {
    this.store.dispatch(
      removeIndustryFromVacancy({ industry: { name: industry } })
    );
  }

  public addPhoto(photo: File) {
    this.store.dispatch(addPhotoToVacancy({ photo }));
  }

  public deletePhoto() {
    this.store.dispatch(removePhotoFromVacancy());
  }

  public clearVacancyForm() {
    this.store.dispatch(clearVacancy());
  }
}
