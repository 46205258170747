import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Story} from '../ngrx-store/components/story/story.model';

@Injectable()
export class StoryService {
  constructor(
    protected http: HttpClient
  ) {}

  public getStories(): Observable<Story[]> {
    return this.http.get<Story[]>(`${environment.apiBaseUrl}stories`);
  }

  getStoryById(id: string) {
    return this.http.get<Story[]>(`${environment.apiBaseUrl}story/${id}`);
  }
}
