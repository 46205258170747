import { FormGroupState } from 'ngrx-forms';
import { PersonalDetailsForm } from './onboardingPersonalDetails/forms.personalDetails.model';
import { FindMatchForm } from './onboardingFindMatch/forms.findMatch.model';
import { ProfileEditForm } from './profileEdit/forms.profileEdit.model';
import { ExperienceForm } from './profileEditExperience/forms.experience.model';
import { AttributeListForm } from './profileEditAttributeList/forms.editAttributeList.model';
import { EducationForm } from './profileEditEducation/forms.education.model';
import { MailPreferencesForm } from './mailPreferences/userForms.mailPreferences.model';

export interface UserFormsState {
  mailPreferencesForm: FormGroupState<MailPreferencesForm>;
  personalDetailsForm: FormGroupState<PersonalDetailsForm>;
  findMatchForm: FormGroupState<FindMatchForm>;
  profileEditForm: FormGroupState<ProfileEditForm>;
  experienceForm: FormGroupState<ExperienceForm>;
  educationForm: FormGroupState<EducationForm>;
  attributeListForm: FormGroupState<AttributeListForm>;
}

export enum UserRole {
  Member = 'Community member',
  Mentor = 'Mentor',
  Mentee = 'Mentee',
  MentorAndMentee = 'Mentor and Mentee',
}

export const UserRolesText: string[] = [
  'No preference/as a community member',
  'As a mentor',
  'As a mentee',
  'As a mentor and mentee',
];

export enum ExperienceOption {
  Entry_Level = 'Entry Level (0-2 years)',
  Young_Professional = 'Young professional (3-9 years)',
  Mid_Career = 'Mid-career (10-25 years)',
  Late_Career = 'Late-career (25+ years)',
}

export enum ReferenceType {
  None = 'None',
  Social_Media = 'Social media',
  Google_Search = 'Google search',
  Relationship = 'Relationship',
  Other = 'Other',
}

export enum EmploymentStatus {
  Employee = 'Employee',
  Freelancer = 'Freelancer',
  Entrepreneur = 'Entrepreneur',
  Student = 'Student',
  In_Between_Jobs = 'In between jobs',
}
