import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AppRoutes } from 'src/app/app-routing.model';
import {BlogItem} from '../../../ngrx-store/components/blogitem/blogitem.model';

@Component({
  selector: 'app-blogitemcard',
  templateUrl: './blogitemcard.component.html',
  styleUrls: ['./blogitemcard.component.scss']
})
export class BlogitemcardComponent implements AfterViewInit {
  public AppRoutes = AppRoutes;
  @Input() blogitem: BlogItem;

  @ViewChild('blogitemTitle') blogitemTitle: ElementRef;
  @ViewChild('blogitemCardContent') blogitemCardContent: ElementRef;

  ngAfterViewInit(): void {
    this.blogitemTitle.nativeElement.innerHTML = this.blogitem.title;
    const wordArray = this.blogitem.title.split(' ');
    while (this.blogitemCardContent.nativeElement.scrollHeight > this.blogitemCardContent.nativeElement.offsetHeight && wordArray.length > 0) {
      wordArray.pop();
      if (wordArray.length > 0) {
        this.blogitemTitle.nativeElement.innerHTML = wordArray.join(' ') + '...';
      } else {
        this.blogitemTitle.nativeElement.innerHTML = '';
      }
    }
  }

}
