import { Directive, Input } from '@angular/core';
import { FormControlState, createFormControlState } from 'ngrx-forms';

@Directive({
  selector: '[appNgrxFormElement]'
})
export class NgrxFormElementDirective {
  @Input() public form: FormControlState<any> = createFormControlState('EMPTY', '');
  @Input() public formControl: FormControlState<string> = createFormControlState('EMPTY', '');
}
