<h1 *ngIf="!hideTitle" md-dialog-title>Confirm</h1>
<div md-dialog-content>{{ confirmMessage }}</div>
<div md-dialog-actions class="mt-3">
  <button
    mat-flat-button
    color="primary"
    data-cy="confirm-button"
    class="btn-xs my-1 me-3"
    mat-dialog-close
    (click)="callback(true)"
  >
    {{ noCancel ? "Ok" : "Confirm" }}
  </button>
  <button
    *ngIf="!noCancel"
    mat-stroked-button
    color="primary"
    class="btn-xs my-1"
    mat-dialog-close
    (click)="callback(false)"
  >
    Cancel
  </button>
</div>
