import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import { getEducations, getEducationsSuccess, getEducationsError } from './education.actions';
import { EducationService } from '../../../services/education.service';

@Injectable()
export class GetEducationEffects {
  constructor(private actions$: Actions, private educationService: EducationService) {}

  getStories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEducations),
      switchMap(() => {
        return this.educationService.getEducations().pipe(
          map((result) => getEducationsSuccess({educations: result})),
          catchError((error: HttpErrorResponse) => of(getEducationsError({error: error.message})))
        );
      })
    ));
}
