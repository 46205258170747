<div class="title">
  {{ label }}
</div>
<ng-multiselect-dropdown
  [settings]="dropdownSettings"
  [placeholder]="inputPlaceholder"
  [data]="inputList"
  [(ngModel)]="selectedItemsModel"
  (onSelect)="selectItem($event)"
  (onDeSelect)="deselectItem($event)"
>
</ng-multiselect-dropdown>
