<mat-card appearance="outlined" class="story-card">
  <img class="story-picture" src="{{story.headerImageUrl}}">
  <mat-card-content class="profile-card-content">
    <div class="story-date">
      {{story.publicationDate | date:'d LLLL y' }}
    </div>
    <div #storyCardContent class="card-content">
      <div #storyTitle class="story-title">
      </div>
    </div>

    <a [routerLink]="'/' + AppRoutes.STORY + '/' + story.id">
      <button
        mat-flat-button
        color="primary"
        class="btn-sm d-block"
      >
        Read story
      </button>
    </a>
  </mat-card-content>
</mat-card>
