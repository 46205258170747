import { createReducer, on } from '@ngrx/store';
import { getDigitalAreasSuccess, getIndustriesSuccess } from '../../requests/attributes/get-userEntities.actions';
import { UserEntitiesState } from './userEntities.model';
import { setIndustriesSuccess, setDigitalAreasSuccess } from '../admin/admin.actions';

export const initialUserEntitiesState: UserEntitiesState = {
  industries: [],
  digitalAreas: [],
};

export const userEntitiesReducer = createReducer<UserEntitiesState>(
  initialUserEntitiesState,
  on(getIndustriesSuccess, (state, action) => ({
    ...state,
    industries: action.industries,
  })),
  on(getDigitalAreasSuccess, (state, action) => ({
    ...state,
    digitalAreas: action.digitalAreas,
  })),
  on(setIndustriesSuccess, (state, action) => ({
    ...state,
    industries: action.industries,
  })),
  on(setDigitalAreasSuccess, (state, action) => ({
    ...state,
    digitalAreas: action.digitalAreas,
  }))
);
