<div *ngIf="companyDetailsForm$ | async as form">
  <div
    *ngIf="
      !company || (company && filterActiveEvents(company.events).length > 0)
    "
  >
    <app-company-details-header
      *ngIf="!isSignUp"
      title="Events"
      editText="Add event"
      [canEdit]="!company"
      [modify]="modify"
      (onModify)="toggleModify($event)"
      data-cy="company-events-title"
    ></app-company-details-header>

    <div *ngIf="company; else self">
      <div class="event-cards">
        <app-eventcards
          [showPartnerLabel]="false"
          [viewAllButton]="false"
          [events]="filterActiveEvents(company.events)"
        ></app-eventcards>
      </div>
    </div>

    <ng-template #self>
      <div *ngIf="company$ | async as ownCompany">
        <div *ngIf="filterActiveEvents(ownCompany.events).length == 0">
          No events planned
        </div>
        <div
          class="d-flex justify-content-between align-items-center py-3"
          *ngFor="let event of filterActiveEvents(ownCompany.events)"
        >
          <a
            [routerLink]="'/' + AppRoutes.EVENT + '/' + event.id"
            target="_blank"
          >
            <div class="d-flex">
              {{ event.eventDate | date : "d LLLL y" }} - {{ event.title }}
            </div>
          </a>
          <div class="d-flex align-items-end">
            <img
              (click)="editEvent(event)"
              class="pe-2 edit-pencil"
              src="../../../../assets/images/dashboard/pencil.svg"
              data-cy="edit-company-event-button"
            />
            <mat-icon
              (click)="removeEvent(event)"
              class="pointer"
              color="primary"
              data-cy="remove-company-event-button"
              >delete</mat-icon
            >
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
