import { MessageState } from './message.model';
import { createReducer, on } from '@ngrx/store';
import {
  postMessageSuccess,
  addMessagePhotoSuccess,
} from '../../requests/message/message.actions';
import { submitMessage } from '../messageForm/messageForm.actions';
import { deleteMessagePhoto } from '../../requests/message/message.actions';
import {
  getMessagesSingleSuccess,
  deleteMessageSuccess,
} from '../../requests/message/message.actions';
import {
  getMessagesSuccess,
  postReactionSuccess,
} from '../../requests/message/message.actions';

export const initialMessageState: MessageState = {
  messages: [],
  newMessage: { content: '' },
};

export const messageReducer = createReducer<MessageState>(
  initialMessageState,
  on(getMessagesSuccess, (state, action) => ({
    ...state,
    messages: action.messages,
  })),
  on(getMessagesSingleSuccess, (state, action) => ({
    ...state,
    messages: [
      ...state.messages.filter((m) => m.id !== action.message.id),
      action.message,
    ],
  })),
  on(deleteMessageSuccess, (state, action) => ({
    ...state,
    messages: action.message
      ? [
          ...state.messages.filter((m) => m.id !== action.message?.id),
          action.message,
        ]
      : [...state.messages.filter((m) => m.id !== action.messageId)],
  })),
  on(postReactionSuccess, (state, action) => ({
    ...state,
    messages: [
      ...state.messages.filter((m) => m.id !== action.message.id),
      action.message,
    ],
  })),
  on(postMessageSuccess, (state, action) => ({
    messages: [
      ...state.messages.filter((m) => m.id !== action.message.id),
      action.message,
    ],
    newMessage: {
      content: '',
    },
  })),
  on(addMessagePhotoSuccess, (state, action) => ({
    ...state,
    newMessage: state.newMessage
      ? { ...state.newMessage, photo: action.reference }
      : { content: '', photo: action.reference },
  })),
  on(deleteMessagePhoto, (state, action) => ({
    ...state,
    newMessage: state.newMessage
      ? { ...state.newMessage, photo: undefined }
      : { content: '', photo: undefined },
  })),
  on(submitMessage, (state, action) => ({
    ...state,
    newMessage: state.newMessage
      ? {
          ...state.newMessage,
          content: action.formValues.message,
          video: action.formValues.video,
        }
      : { content: action.formValues.message, video: action.formValues.video },
  }))
);
