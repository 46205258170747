<form [ngrxFormState]="form">
  <ng-container class="row">
    <div [class.invalid]="formControl.isDirty && formControl.isInvalid">
      <mat-form-field class="w-100" floatLabel="always">
        <mat-label>{{ label }}</mat-label>
        <textarea
          matInput
          [ngrxFormControlState]="formControl"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          [cdkAutosizeMinRows]="minRows"
          [cdkAutosizeMaxRows]="maxRows"
          [placeholder]="placeholder"
        ></textarea>
        <mat-error *ngIf="!!formControl.errors?.required">
          {{ requiredErrorMessage }}
        </mat-error>
        <mat-error *ngIf="!!formControl.errors?.maxLength">
          {{ maxLengthErrorMessage }}
        </mat-error>
      </mat-form-field>
    </div>
  </ng-container>
</form>
