import { Component, ElementRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectUserModel } from '../../../../ngrx-store/components/user/user.selectors';
import { AppState } from '../../../../ngrx-store/app.state';
import { uploadAvatar } from '../../../../ngrx-store/requests/user/post-user/post-user.actions';

@Component({
  selector: 'app-edit-avatar',
  templateUrl: './edit-avatar.component.html',
  styleUrls: ['./edit-avatar.component.scss'],
})
export class EditAvatarComponent {
  private maxFileSize = 2000000; // 2 MB

  public userModel$ = this.store.pipe(select(selectUserModel));

  @ViewChild('uploadButton', { read: ElementRef }) uploadButton: ElementRef;
  @ViewChild('helpText', { read: ElementRef }) helpText: ElementRef;

  constructor(
    public store: Store<AppState>,
  ) {}

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      if (
        (file.type === 'image/png' || file.type === 'image/jpeg') &&
        file.size <= this.maxFileSize
      ) {
        if (this.uploadButton && this.helpText) {
          this.uploadButton.nativeElement.innerHTML = 'Uploading...';
          this.helpText.nativeElement.innerHTML = '';
        }
        this.store.dispatch(uploadAvatar({ file }));
        if (this.uploadButton && this.helpText) {
          this.uploadButton.nativeElement.innerHTML = 'Upload';
          this.helpText.nativeElement.innerHTML = 'Upload complete!';
        }
      } else if (file.size > this.maxFileSize) {
        if (this.uploadButton && this.helpText) {
          this.uploadButton.nativeElement.innerHTML = 'Upload';
          this.helpText.nativeElement.innerHTML =
            'File size too large! The size can not exceed ' +
            this.maxFileSize / 1000000 +
            ' MB.';
        }
      } else {
        if (this.uploadButton && this.helpText) {
          this.uploadButton.nativeElement.innerHTML = 'Upload';
          this.helpText.nativeElement.innerHTML =
            'Unsupported file type! Please upload a .png, .jpg, or .jpeg file.';
        }
      }
    }
  }
}
