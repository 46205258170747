import {createReducer, on} from '@ngrx/store';
import { EducationState } from './education.model';
import { getEducations, getEducationsSuccess } from '../../requests/education/education.actions';

export const initialEducationState: EducationState = {
  educations: []
};

export const educationReducer = createReducer<EducationState>(
  initialEducationState,

  on(getEducations,
    (state, action) => ({
      ...state,
      educations: []
    })),

  on(getEducationsSuccess,
    (state, action) => ({
      ...state,
      educations: action.educations,
    })
  )
);
