import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from '../../services/event.service';
import { AppRoutes } from 'src/app/app-routing.model';
import { RightbrainsEvent } from '../../ngrx-store/components/event/event.model';
import { AppState } from '../../ngrx-store/app.state';
import { Store } from '@ngrx/store';
import {
  getRegistration,
  getRegistrationError,
  getRegistrationSuccess,
  registerError,
  registerEvent,
  registerSuccess,
  signOutError,
  signOutEvent,
  signOutSuccess,
} from '../../ngrx-store/requests/event/get-event.actions';
import { Actions, ofType } from '@ngrx/effects';
import { getEventRegistrations } from '../../ngrx-store/components/admin/admin.actions';
import { selectAdminPartner } from '../../ngrx-store/components/user/user.selectors';
import { MatSnackBar } from '@angular/material/snack-bar';
import { trackClick } from 'src/app/ngrx-store/requests/user/post-user/post-user.actions';
import { User } from 'src/app/ngrx-store/components/user/user.model';
import { getConnections } from 'src/app/ngrx-store/components/connection/connection.actions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit, OnDestroy {
  public eve: RightbrainsEvent | undefined = undefined;
  public attendees: User[] = [];
  public registered = false;

  public AppRoutes = AppRoutes;
  private subscription = new Subscription();
  admin: { isAdmin?: boolean; isPartner?: boolean };

  @ViewChild('registerButton', { read: ElementRef }) registerButton: ElementRef;
  @ViewChild('signOutButton', { read: ElementRef }) signOutButton: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private router: Router,
    private store: Store<AppState>,
    private snackbar: MatSnackBar,
    private actions$: Actions
  ) {
    let sub;
    sub = this.actions$.pipe(ofType(registerSuccess)).subscribe((res) => {
      // @ts-ignore
      if (res.status.message === 'OK') {
        this.snackbar.open('Registration successful', undefined, {
          duration: 5000,
          panelClass: ['success-snackbar'],
        });
        this.registered = true;
      } else {
        this.snackbar.open('Registration failed', undefined, {
          duration: 5000,
          panelClass: ['failed-snackbar'],
        });
      }
    });
    this.subscription.add(sub);

    sub = this.actions$.pipe(ofType(registerError)).subscribe(() => {
      this.snackbar.open('Registration failed', undefined, {
        duration: 5000,
        panelClass: ['failed-snackbar'],
      });
    });
    this.subscription.add(sub);

    sub = this.actions$.pipe(ofType(getRegistrationSuccess)).subscribe((res) => {
      // @ts-ignore
      if (res.status?.message === 'REGISTERED') {
        this.registered = true;
      }
      if (
        this.eve &&
        new Date(this.eve.eventDate).setHours(0, 0, 0, 0) <
          new Date().setHours(0, 0, 0, 0)
      ) {
        if (this.registered) {
          this.signOutButton.nativeElement.disabled = true;
        } else {
          this.registerButton.nativeElement.innerHTML =
            'Registration not available';
          this.registerButton.nativeElement.disabled = true;
        }
      }
    });
    this.subscription.add(sub);

    sub = this.actions$.pipe(ofType(getRegistrationError)).subscribe(() => {
      this.registered = false;
      if (
        this.eve &&
        new Date(this.eve.eventDate).setHours(0, 0, 0, 0) <
          new Date().setHours(0, 0, 0, 0)
      ) {
        this.registerButton.nativeElement.innerHTML =
          'Registration not available';
        this.registerButton.nativeElement.disabled = true;
      }
    });
    this.subscription.add(sub);

    sub = this.actions$.pipe(ofType(signOutSuccess)).subscribe(() => {
      this.registered = false;
      this.snackbar.open('Registration cancelled', undefined, {
        duration: 5000,
        panelClass: ['success-snackbar'],
      });
    });
    this.subscription.add(sub);

    sub = this.actions$.pipe(ofType(signOutError)).subscribe(() => {
      this.snackbar.open('Cancel registration failed', undefined, {
        duration: 5000,
        panelClass: ['failed-snackbar'],
      });
    });
    this.subscription.add(sub);
  }

  ngOnInit(): void {
    let sub;
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.store.dispatch(getConnections({ filter: undefined }));
      sub = this.eventService.getEventById(id).subscribe((eve) => {
        if (eve) {
          this.eve = eve;
          this.attendees = eve.users ?? [];
          this.store.dispatch(getRegistration({ eventId: this.eve.id }));
          this.scrollToTop();
        } else {
          this.router.navigateByUrl(`/${AppRoutes.DASHBOARD}`);
        }
      });
      this.subscription.add(sub);
    } else {
      this.router.navigateByUrl(`/${AppRoutes.DASHBOARD}`);
    }

    sub = this.store.select(selectAdminPartner).subscribe((a) => {
      this.admin = a;
    });

    this.subscription.add(sub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toTime(timeString: string) {
    const timeTokens = timeString.split(':');
    const date = new Date(
      1970,
      0,
      1,
      Number(timeTokens[0]),
      Number(timeTokens[1]),
      0
    );
    return date
      .toLocaleTimeString('en', { hour: 'numeric', minute: 'numeric' })
      .toLowerCase();
  }

  registerEvent(): void {
    if (this.eve) {
      this.registered = true;
      this.store.dispatch(registerEvent({ eventId: this.eve.id }));
    }
  }

  signOutEvent(): void {
    if (this.eve) {
      this.store.dispatch(signOutEvent({ eventId: this.eve.id }));
    }
  }

  getRegistrationsExcel() {
    if (this.eve) {
      this.store.dispatch(getEventRegistrations({ eventId: this.eve.id }));
    }
  }

  trackClick() {
    this.store.dispatch(
      trackClick({
        eventType: 'go to partner event page',
        companyId: this.eve?.companyId,
      })
    );
  }

  get safeWebsite() {
    return this.eve?.eventUrl?.replace(/(^\w+:|^)\/\//, '');
  }

  private scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
