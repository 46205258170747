<div class="reactions-container">
  <div *ngFor="let reaction of ReactionTypes | keyvalue">
    <div
      class="reaction-block"
      *ngIf="reactionCounts[reaction.value] > 0"
      (click)="emitAddReaction(reaction.value, $event)"
    >
      <span [innerHTML]="reactionToIcon[toReaction(reaction.value)]"></span>
      {{ reactionCounts[reaction.value] }}
    </div>
  </div>
  <div class="reaction-block add-reaction" (click)="toggleMenu($event)">
    <i class="far fa-smile"></i>&nbsp;+
  </div>
</div>

<div
  class="reactions-container mt-2"
  *ngIf="showMenu"
  (click)="toggleMenu($event)"
>
  <div *ngFor="let reaction of ReactionTypes | keyvalue">
    <div
      class="reaction-block"
      (click)="emitAddReaction(reaction.value, $event)"
    >
      <span [innerHTML]="reactionToIcon[toReaction(reaction.value)]"></span>
    </div>
  </div>
</div>
