import { Component, OnInit } from '@angular/core';
import { CompanyDetailsBaseComponent } from '../company-details-base/company-details-base.component';
import { selectIndustries } from 'src/app/ngrx-store/components/userEntities/userEntities.selectors';
import { getIndustries } from 'src/app/ngrx-store/requests/attributes/get-userEntities.actions';

@Component({
  selector: 'app-company-details-industries',
  templateUrl: './company-details-industries.component.html',
})
export class CompanyDetailsIndustriesComponent extends CompanyDetailsBaseComponent implements OnInit {
  public industries$ = this.store.select(selectIndustries);

  ngOnInit() {
    this.store.dispatch(getIndustries());
  }
}
