import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx-store/app.state';
import { slideFadeIn } from '../../shared/animations/list-anims';
import { ReactionType } from '../../ngrx-store/components/message/message.model';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/ngrx-store/components/user/user.model';
import { ProfileDialogComponent } from 'src/app/shared/components/profile-dialog/profile-dialog.component';
import { selectMessages } from '../../ngrx-store/components/message/message.selectors';
import { selectUser } from '../../ngrx-store/components/user/user.selectors';
import {
  deleteMessage,
  getMessages,
  postReaction,
} from '../../ngrx-store/requests/message/message.actions';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss'],
  animations: [slideFadeIn],
})
export class ChatsComponent implements OnInit {
  public messages$ = this.store.select(selectMessages);
  public user$ = this.store.select(selectUser);

  constructor(private store: Store<AppState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.store.dispatch(getMessages());
  }

  openProfile(profile: User) {
    this.dialog.open(ProfileDialogComponent, {
      data: {
        profile,
      },
    });
  }

  addReaction(reaction: { messageId: string; reaction: ReactionType }) {
    this.store.dispatch(postReaction(reaction));
  }

  deleteMessage(messageId: string) {
    this.store.dispatch(deleteMessage({ messageId }));
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
