import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectDigitalAreas } from 'src/app/ngrx-store/components/userEntities/userEntities.selectors';
import {
  addAreaBackground,
  addDigitalAreaInterest,
  removeAreaBackground,
  removeDigitalAreaInterest,
} from 'src/app/ngrx-store/components/userForms/onboardingFindMatch/forms.findMatch.actions';
import { selectEditAttributeListForm } from 'src/app/ngrx-store/components/userForms/userForms.selectors';
import { getDigitalAreas } from 'src/app/ngrx-store/requests/attributes/get-userEntities.actions';
import {
  submitEditAttributeListForm,
} from 'src/app/ngrx-store/components/userForms/profileEditAttributeList/forms.editAttributeList.actions';
import { AttributeListForm } from 'src/app/ngrx-store/components/userForms/profileEditAttributeList/forms.editAttributeList.model';
import { AppRoutes } from 'src/app/app-routing.model';
import { postUser } from 'src/app/ngrx-store/requests/user/post-user/post-user.actions';

@Component({
  selector: 'app-edit-digital-areas',
  templateUrl: './edit-digital-areas.component.html',
})
export class EditDigitalAreasComponent implements OnInit, AfterViewInit {
  public isBackground = true;

  public attributeListForm$ = this.store.select(selectEditAttributeListForm);
  public digitalAreas$ = this.store.select(selectDigitalAreas);

  constructor(private readonly store: Store<AppState>, private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.data.subscribe((data) => {
      this.isBackground = data.isBackground;
    });
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.store.dispatch(getDigitalAreas());
  }

  public submitForm(formValues: AttributeListForm) {
    this.store.dispatch(submitEditAttributeListForm({ formValues }));
    this.store.dispatch(postUser());
    this.router.navigateByUrl(`${AppRoutes.PROFILE}/view`);
  }

  public cancel() {
    this.router.navigateByUrl(`${AppRoutes.PROFILE}/view`);
  }

  public addDigitalArea(industry: string) {
    if (this.isBackground) {
      this.addDigitalAreaBackground(industry);
    } else {
      this.addDigitalAreaInterest(industry);
    }
  }

  public removeDigitalArea(digitalArea: string) {
    if (this.isBackground) {
      this.removeDigitalAreaBackground(digitalArea);
    } else {
      this.removeDigitalAreaInterest(digitalArea);
    }
  }

  private addDigitalAreaBackground(digitalArea: string) {
    this.store.dispatch(addAreaBackground({ digitalArea: { name: digitalArea } }));
  }

  private removeDigitalAreaBackground(digitalArea: string) {
    this.store.dispatch(removeAreaBackground({ digitalArea: { name: digitalArea } }));
  }

  private addDigitalAreaInterest(digitalArea: string) {
    this.store.dispatch(addDigitalAreaInterest({ digitalArea: { name: digitalArea } }));
  }

  private removeDigitalAreaInterest(digitalArea: string) {
    this.store.dispatch(removeDigitalAreaInterest({ digitalArea: { name: digitalArea } }));
  }
}
