import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { getCompanies } from '../../../ngrx-store/requests/company/get-company/get-company.actions';
import { selectCompanies } from '../../../ngrx-store/components/company/company.selectors';

@Component({
  selector: 'app-company-selection-dialog',
  templateUrl: './company-selection-dialog.component.html',
  styleUrls: ['./company-selection-dialog.component.scss']
})
export class CompanySelectionDialogComponent implements OnInit {
  callback: (companyId: string, companyName: string) => void;
  companies$ = this.store.select(selectCompanies);

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { callback: (companyId: string) => void }, private store: Store<AppState>
  ) {
    this.callback = data.callback;
    store.dispatch(getCompanies());
  }

  ngOnInit(): void {}
}
