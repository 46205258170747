import { Component, ElementRef, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { User } from '../../../ngrx-store/components/user/user.model';
import { AppState } from '../../../ngrx-store/app.state';
import { AppRoutes } from 'src/app/app-routing.model';
import { Store } from '@ngrx/store';
import { getMembers, getMembersError, getMembersSuccess } from '../../../ngrx-store/requests/match/get-members/get-members.actions';
import { getMentees, getMenteesError, getMenteesSuccess } from '../../../ngrx-store/requests/match/get-mentee/get-mentee.actions';
import { getMentors, getMentorsError, getMentorsSuccess } from '../../../ngrx-store/requests/match/get-mentor/get-mentor.actions';
import { selectUser } from '../../../ngrx-store/components/user/user.selectors';
import { selectMatchModel } from '../../../ngrx-store/components/match/match.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { postDismissMatch } from '../../../ngrx-store/requests/match/post-dismiss-match/post-dismiss-match.actions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bestmatches',
  templateUrl: './bestmatches.component.html',
  styleUrls: ['./bestmatches.component.scss']
})
export class BestmatchesComponent implements OnInit, OnDestroy {
  public AppRoutes = AppRoutes;
  public currentTypeMatch = 'members';

  public userModel$ = this.store.select(selectUser);
  public matchModel$ = this.store.select(selectMatchModel);

  public loading = true;
  public isCollapsed = false;

  private subscriptions = new Subscription();

  @Input() title = true;
  @Input() viewAllButton = true;
  @Input() spinnerColorSecondary = false;
  @Input() collapsable = false;

  @ViewChild('menteesButton', {read: ElementRef}) menteesButton: ElementRef;
  @ViewChild('mentorsButton', {read: ElementRef}) mentorsButton: ElementRef;

  constructor(private store: Store<AppState>,
              private actions: Actions) { }

  ngOnInit(): void {
    // Backend gets the best matches based on filter
    this.store.dispatch(getMembers({filter: undefined, limit: 3, bestMatches: true}));
    this.store.dispatch(getMentees({filter: undefined, limit: 3, bestMatches: true}));
    this.store.dispatch(getMentors({filter: undefined, limit: 3, bestMatches: true}));

    this.subscriptions.add(
      this.actions.pipe(ofType(getMenteesSuccess)).subscribe(() => this.loading = false)
    );
    this.subscriptions.add(
      this.actions.pipe(ofType(getMembersSuccess)).subscribe(() => this.loading = false)
    );
    this.subscriptions.add(
      this.actions.pipe(ofType(getMentorsSuccess)).subscribe(() => this.loading = false)
    );
    this.subscriptions.add(
      this.actions.pipe(ofType(getMenteesError)).subscribe(() => this.loading = false)
    );
    this.subscriptions.add(
      this.actions.pipe(ofType(getMembersError)).subscribe(() => this.loading = false)
    );
    this.subscriptions.add(
      this.actions.pipe(ofType(getMentorsError)).subscribe(() => this.loading = false)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  filterMatches(type: string) {
    this.menteesButton?.nativeElement.classList.add('filter-matches-button-inactive');
    this.mentorsButton?.nativeElement.classList.add('filter-matches-button-inactive');
    if (type === this.currentTypeMatch) {
      this.currentTypeMatch = 'members';
      return;
    }

    if (type === 'mentors') {
      this.currentTypeMatch = 'mentors';
      this.mentorsButton?.nativeElement.classList.remove('filter-matches-button-inactive');
      return;
    }

    this.currentTypeMatch = 'mentees';
    this.menteesButton?.nativeElement.classList.remove('filter-matches-button-inactive');
  }

  dismissMatch(match: User) {
    this.store.dispatch(postDismissMatch({matchId: match.id}));
  }
}
