<div *ngIf="companyDetailsForm$ | async as form">
  <div *ngIf="company; else self">
    <a
      *ngIf="company.websiteUrl"
      target="_blank"
      [href]="'//' + safeWebsite | safe : 'url'"
    >
      <img
        *ngIf="company?.logo"
        [src]="company.logo"
        class="company-logo my-4"
      />
    </a>
    <img
      *ngIf="company?.logo && !company.websiteUrl"
      [src]="company.logo"
      class="company-logo my-4"
    />

    <app-company-details-follow
      *ngIf="company"
      [company]="company"
    ></app-company-details-follow>

    <ngb-carousel
      *ngIf="company.videoUrl || company.photos.length > 0"
      [interval]="0"
      class="py-2"
    >
      <ng-template ngbSlide *ngIf="company.videoUrl">
        <div class="picsum-img-wrapper">
          <iframe
            class="w-100"
            [src]="company.videoUrl | safeVideo"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </ng-template>
      <ng-template ngbSlide *ngFor="let photo of company.photos">
        <div class="picsum-img-wrapper">
          <img [src]="photo.photoUrl" class="w-100" />
        </div>
      </ng-template>
    </ngb-carousel>
  </div>

  <ng-template #self>
    <app-company-details-header
      *ngIf="!isSignUp"
      title=""
      editText="Edit photos/video"
      [showLine]="false"
      [canEdit]="true"
      [modify]="modify"
      (onModify)="toggleModify($event)"
    ></app-company-details-header>
    <div *ngIf="modify || isSignUp">
      <div class="my-2">
        <app-input-file
          [uploadedReference]="form.controls.logo.value"
          fileName="Current logo"
          (onFileUpload)="addLogo($event)"
          (onFileDelete)="deleteLogo()"
          label="Upload your company logo (max. 2MB, PNG/JPEG)"
        ></app-input-file>
      </div>
      <div class="my-2">
        <mat-label
          ><b
            >Add up to 3 photos about your company (max. 2MB per photo,
            PNG/JPEG)</b
          ></mat-label
        >
        <app-input-file
          *ngFor="let photo of form.controls.photos.value; let i = index"
          [fileName]="'Image ' + (i + 1)"
          [uploadedReference]="photo.photoUrl"
          (onFileUpload)="addPhoto($event)"
          (onFileDelete)="deletePhoto($event)"
        ></app-input-file>
        <app-input-file
          *ngIf="form.controls.photos.value.length < 3"
          (onFileUpload)="addPhoto($event)"
          (onFileDelete)="deletePhoto($event)"
        ></app-input-file>
      </div>
      <div class="my-2">
        <app-input-field
          [form]="form"
          [formControl]="form.controls.videoUrl"
          label="Add video about your company"
          placeholder="Copy youtube or vimeo link"
          patternErrorMessage="Please provide a youtube or vimeo link"
        ></app-input-field>
      </div>
      <div class="my-2">
        <app-input-field
          [form]="form"
          [formControl]="form.controls.websiteUrl"
          label="Add website"
          placeholder="Type URL"
          patternErrorMessage="Please provide a valid URL"
        ></app-input-field>
      </div>
    </div>

    <div *ngIf="!modify && !isSignUp">
      <div *ngIf="company$ | async as ownCompany">
        <a
          *ngIf="ownCompany.websiteUrl"
          target="_blank"
          [href]="'//' + ownCompany.websiteUrl"
        >
          <img
            *ngIf="ownCompany?.logo"
            [src]="ownCompany.logo"
            class="company-logo my-4"
          />
        </a>
        <img
          *ngIf="ownCompany?.logo && !ownCompany.websiteUrl"
          [src]="ownCompany.logo"
          class="company-logo my-4"
        />

        <ngb-carousel
          *ngIf="ownCompany.videoUrl || ownCompany.photos.length > 0"
          [interval]="0"
          class="py-2"
        >
          <ng-template ngbSlide *ngIf="ownCompany.videoUrl">
            <div class="picsum-img-wrapper">
              <iframe
                class="w-100"
                height="300"
                [src]="ownCompany.videoUrl | safeVideo"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </ng-template>
          <ng-template ngbSlide *ngFor="let photo of ownCompany.photos">
            <div class="picsum-img-wrapper">
              <img [src]="photo.photoUrl" class="w-100" height="300" />
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </ng-template>
</div>
