import { createAction, props } from '@ngrx/store';

export enum StorageActionTypes {
  UPLOAD_IMAGE = '[Storage] Upload image',
  UPLOAD_IMAGE_SUCCESS = '[Storage] Upload image success',
  UPLOAD_IMAGE_ERROR = '[Storage] Upload image error',
}

export const uploadImage = createAction(StorageActionTypes.UPLOAD_IMAGE, props<{ image: File }>());
export const uploadImageSuccess = createAction(StorageActionTypes.UPLOAD_IMAGE_SUCCESS, props<{ reference: string }>());
export const uploadImageError = createAction(StorageActionTypes.UPLOAD_IMAGE_ERROR, props<{ error: string }>());
