<ng-container *ngIf="eve">
  <div class="container">
    <div class="event-image-container">
      <img class="event-image" src="{{ eve.imageUrl }}" />
      <div *ngIf="eve.companyId" class="partner-event-label">Partner event</div>
      <a [routerLink]="'/' + AppRoutes.EVENTS">
        <img
          class="story-close-button"
          src="../../../assets/images/close-story.svg"
        />
      </a>
    </div>
    <div class="story-date">
      {{ eve.eventDate | date : "d LLLL y" }}
    </div>
    <div class="event-time" *ngIf="eve.beginTime && eve.endTime">
      {{ toTime(eve.beginTime) }} - {{ toTime(eve.endTime) }} CET
    </div>
    <div class="story-title">
      {{ eve.title }}
    </div>
    <div
      *ngIf="!eve.companyId"
      class="story-content"
      [innerHTML]="eve.content"
    ></div>
    <div *ngIf="eve.companyId" class="story-content">
      {{ eve.content }}
    </div>
    <div class="align-center">
      <a
        *ngIf="eve.eventUrl"
        [href]="'//' + safeWebsite | safe : 'url'"
        target="_blank"
        class="me-3"
      >
        <button
          (click)="trackClick()"
          class="btn-sm mx-auto mt-3"
          mat-flat-button
          color="primary"
        >
          Go to event page
        </button>
      </a>
      <button
        *ngIf="!eve.eventUrl"
        #registerButton
        class="btn-sm mx-auto mt-3"
        mat-flat-button
        color="primary"
        (click)="registerEvent()"
        [class.d-none]="registered"
      >
        Register
      </button>
      <button
        *ngIf="!eve.eventUrl"
        #signOutButton
        class="btn-sm mx-auto mt-3"
        mat-flat-button
        color="primary"
        [class.d-none]="!registered"
        (click)="signOutEvent()"
      >
        Cancel registration
      </button>
      <br />
      <button
        *ngIf="admin.isAdmin && !eve.eventUrl"
        class="btn-sm mx-auto mt-3"
        mat-flat-button
        color="primary"
        (click)="getRegistrationsExcel()"
      >
        Export registrations
      </button>
    </div>
  </div>
</ng-container>
