<div *ngIf="companyContactPersonForm$ | async as form">
  <app-input-file
    [uploadedReference]="form.controls.photo.value"
    title="Profile photo (max. 2MB per photo, PNG/JPEG)"
    fileName="Current profile photo"
    (onFileUpload)="addPhoto($event)"
    (onFileDelete)="deletePhoto($event)"
    label="Photo"
  ></app-input-file>
  <app-input-field
    [form]="form"
    [formControl]="form.controls.firstName"
    label="First name"
    data-cy="company-recruit-contact-first-name"
  ></app-input-field>
  <app-input-field
    [form]="form"
    [formControl]="form.controls.lastName"
    label="Last name"
    data-cy="company-recruit-contact-last-name"
  ></app-input-field>
  <app-input-field
    [form]="form"
    [formControl]="form.controls.role"
    label="Role"
    data-cy="company-recruit-contact-role"
  ></app-input-field>
  <app-input-field
    [form]="form"
    [formControl]="form.controls.email"
    label="Email"
    requiredErrorMessage="Please provide a valid email"
    emailErrorMessage="Please provide a valid email"
    data-cy="company-recruit-contact-email"
  ></app-input-field>
</div>
