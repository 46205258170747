<form [ngrxFormState]="form">
  <ng-container class="row">
    <div [class.invalid]="formControl?.isTouched && formControl?.isInvalid">
      <mat-form-field class="col-12 p-0">
        <mat-label>{{ label }}</mat-label>
        <mat-select
          [ngrxFormControlState]="formControl"
          (selectionChange)="selectionChange.emit($event)"
        >
          <mat-option>
            <ngx-mat-select-search
              [formControl]="filter"
              [placeholderLabel]="'Type to search'"
              [noEntriesFoundLabel]="'No countries found'"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngIf="emptyOption" [value]="emptyOption">
            {{ emptyOption }}
          </mat-option>
          <mat-option
            *ngFor="let item of filteredCountries"
            [value]="item.name"
          >
            <span
              class="m2-2 flag-icon flag-icon-{{
                item.code.toLocaleLowerCase()
              }}"
            ></span>
            <span>{{ item.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
</form>
