import { AppState } from '../../app.state';
import { createSelector } from '@ngrx/store';
import { Message, MessageState } from './message.model';

const selectMessageState = (state: AppState) => state.messageState;

export const selectMessages = createSelector(
  selectMessageState,
  (state: MessageState) => state.messages
);

export const selectMessage = (id: string) =>
  createSelector(selectMessages, (messages: Message[]) =>
    messages.find((m) => m.id === id)
  );

export const selectNewMessage = createSelector(
  selectMessageState,
  (state: MessageState) => state.newMessage
);

export const selectMessageImage = createSelector(
  selectMessageState,
  (state: MessageState) => state.newMessage.photo
);
