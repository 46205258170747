import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StoryService} from '../../services/story.service';
import {Story} from '../../ngrx-store/components/story/story.model';
import {AppRoutes} from '../../app-routing.model';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss']
})
export class StoryComponent implements OnInit {
  public story: Story | undefined = undefined;

  public AppRoutes = AppRoutes;

  constructor(private route: ActivatedRoute,
              private storyService: StoryService,
              private router: Router) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.storyService.getStoryById(id).subscribe((stories) => {
        if (stories.length === 0) {
          this.router.navigateByUrl(`/${AppRoutes.STORIES}`);
        } else {
          this.story = stories[0];
        }
      });
    } else {
      this.router.navigateByUrl(`/${AppRoutes.STORIES}`);
    }
  }
}
