import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {MatchService} from '../../../../services/match.service';
import {catchError, switchMap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {of} from 'rxjs';
import {postDismissMatch, postDismissMatchError, postDismissMatchSuccess} from './post-dismiss-match.actions';
import {getMembers} from '../get-members/get-members.actions';
import {getMentees} from '../get-mentee/get-mentee.actions';
import {getMentors} from '../get-mentor/get-mentor.actions';

@Injectable()
export class PostDismissMatchEffects {
  constructor(private actions$: Actions, private matchService: MatchService) {}

  getMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postDismissMatch),
      switchMap((a) => {
        return this.matchService.postDismissMatch(a.matchId).pipe(
          switchMap(() => [
            postDismissMatchSuccess(),
            getMembers({filter: undefined, limit: 3, bestMatches: true}),
            getMentees({filter: undefined, limit: 3, bestMatches: true}),
            getMentors({filter: undefined, limit: 3, bestMatches: true})
          ]),
          catchError((error: HttpErrorResponse) => of(postDismissMatchError({ error: error.message })))
        );
      })
    )
  );
}
