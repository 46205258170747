import { AppState } from '../../app.state';
import { UserFormsState } from './userForms.model';
import { createSelector } from '@ngrx/store';

const selectUserFormsState = (state: AppState) => state.userFormsState;

export const selectMailPreferencesForm = createSelector(selectUserFormsState, (state: UserFormsState) => state.mailPreferencesForm);
export const selectPersonalDetailsForm = createSelector(selectUserFormsState, (state: UserFormsState) => state.personalDetailsForm);
export const selectFindMatchForm = createSelector(selectUserFormsState, (state: UserFormsState) => state.findMatchForm);
export const selectProfileEditForm = createSelector(selectUserFormsState, (state: UserFormsState) => state.profileEditForm);
export const selectExperienceForm = createSelector(selectUserFormsState, (state: UserFormsState) => state.experienceForm);
export const selectEducationForm = createSelector(selectUserFormsState, (state: UserFormsState) => state.educationForm);
export const selectEditAttributeListForm = createSelector(selectUserFormsState, (state: UserFormsState) => state.attributeListForm);
