import { AfterViewInit, Component, ElementRef, Input, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { nextQuestion, previousQuestion } from 'src/app/ngrx-store/components/onboarding/onboarding.actions';
import { selectOnboardingModel } from 'src/app/ngrx-store/components/onboarding/onboarding.selectors';

@Component({
  selector: 'app-onboarding-question-buttons',
  templateUrl: './onboarding-question-buttons.component.html',
  styleUrls: ['./onboarding-question-buttons.component.scss']
})
export class OnboardingQuestionButtonsComponent {
  public onboardingModel$ = this.store.pipe(select(selectOnboardingModel));

  @Output() finish: EventEmitter<any> = new EventEmitter();
  @Output() previousStep: EventEmitter<any> = new EventEmitter();
  @Input() canPressNext = true;
  @Input() finishText = 'Finish';

  constructor(private store: Store<AppState>) { }

  public nextQuestion() {
    this.store.dispatch(nextQuestion());
  }

  public previousQuestion(questionIndex: number) {
    if (questionIndex === 0) {
      this.previousStep.emit();
    } else {
      this.store.dispatch(previousQuestion());
    }
  }
}

