import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from '../../../services/message.service';
import { selectNewMessage } from '../../components/message/message.selectors';
import {
  postMessageSuccess,
  addMessagePhoto,
  addMessagePhotoSuccess,
} from './message.actions';
import {
  postReaction,
  postReactionSuccess,
  postMessagesError,
  postMessage,
} from './message.actions';
import {
  getMessages,
  getMessagesSuccess,
  getMessagesError,
} from './message.actions';
import { clearMessage } from '../../components/messageForm/messageForm.actions';
import { StorageService } from '../../../services/storage.service';
import {
  getMessagesSingle,
  getMessagesSingleSuccess,
  deleteMessage,
  deleteMessageSuccess,
} from './message.actions';

@Injectable()
export class MessageEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private messageService: MessageService,
    private storageService: StorageService
  ) {}

  getMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMessages),
      switchMap(() => {
        return this.messageService.getMessages().pipe(
          map((messages) => getMessagesSuccess({ messages })),
          catchError((error: HttpErrorResponse) =>
            of(getMessagesError({ error: error.message }))
          )
        );
      })
    )
  );

  getMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMessagesSingle),
      switchMap(({ messageId }) => {
        return this.messageService.getMessage(messageId).pipe(
          map((message) => getMessagesSingleSuccess({ message })),
          catchError((error: HttpErrorResponse) =>
            of(getMessagesError({ error: error.message }))
          )
        );
      })
    )
  );

  deleteMEssage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMessage),
      switchMap(({ messageId }) => {
        return this.messageService.deleteMessage(messageId).pipe(
          map((message) => deleteMessageSuccess({ messageId, message })),
          catchError((error: HttpErrorResponse) =>
            of(getMessagesError({ error: error.message }))
          )
        );
      })
    )
  );

  postReaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postReaction),
      mergeMap(({ messageId, reaction }) => {
        return this.messageService.postReaction(messageId, reaction).pipe(
          map((message) => postReactionSuccess({ message })),
          catchError((error: HttpErrorResponse) =>
            of(postMessagesError({ error: error.message }))
          )
        );
      })
    )
  );

  clearMessage = createEffect(() =>
    this.actions$.pipe(
      ofType(postMessage),
      map(() => clearMessage())
    )
  );

  postMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postMessage),
      withLatestFrom(this.store.select(selectNewMessage)),
      mergeMap(([{ parentMessageId }, newMessage]) => {
        return this.messageService
          .postMessage(newMessage, parentMessageId)
          .pipe(
            map((message) => postMessageSuccess({ message })),
            catchError((error: HttpErrorResponse) =>
              of(postMessagesError({ error: error.message }))
            )
          );
      })
    )
  );

  addMessagePhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addMessagePhoto),
      mergeMap(({ photo }) => {
        return this.storageService.uploadImage(photo).pipe(
          map(({ reference }) => addMessagePhotoSuccess({ reference })),
          catchError((error: HttpErrorResponse) =>
            of(postMessagesError({ error: error.message }))
          )
        );
      })
    )
  );
}
