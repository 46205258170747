import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { submitCompanyDetailsForm } from 'src/app/ngrx-store/components/companyForms/companyDetails/forms.companyDetails.actions';
import { CompanyDetailsForm } from 'src/app/ngrx-store/components/companyForms/companyDetails/forms.companyDetails.model';
import { selectCompanyDetailsForm } from 'src/app/ngrx-store/components/companyForms/companyForms.selector';
import { postDetailsCompany } from 'src/app/ngrx-store/requests/company/post-company/post-company.actions';
import { selectCompanyContactPersonForm } from 'src/app/ngrx-store/components/companyForms/companyForms.selector';
import { submitCompanyContactPersonForm } from 'src/app/ngrx-store/components/companyForms/companyContactPerson/forms.companyContactPerson.actions';
import { CompanyContactPersonForm } from 'src/app/ngrx-store/components/companyForms/companyContactPerson/forms.companyContactPerson.model';
import { FormGroupState } from 'ngrx-forms';

@Component({
  selector: 'app-company-signup',
  templateUrl: './company-signup.component.html',
  styleUrls: ['./company-signup.component.scss']
})
export class CompanySignupComponent {
  public isSubmitted = false;

  public companyDetailsForm$ = this.store.select(selectCompanyDetailsForm);
  public companyContactPersonForm$ = this.store.select(selectCompanyContactPersonForm);

  constructor(private readonly store: Store<AppState>) {
  }

  public isContactFormFilled(form: CompanyContactPersonForm) {
    return form.email.length > 0
    || form.firstName.length > 0
    || form.lastName.length > 0
    || form.photo.length > 0
    || form.role.length > 0;
  }

  public submitForm(detailForm: FormGroupState<CompanyDetailsForm>, contactForm: FormGroupState<CompanyContactPersonForm>) {
    if (contactForm.isTouched) {
      this.store.dispatch(submitCompanyContactPersonForm({ formValues: contactForm.value }));
    }
    this.store.dispatch(submitCompanyDetailsForm({ formValues: detailForm.value }));
    this.store.dispatch(postDetailsCompany());
    this.isSubmitted = true;
  }
}
