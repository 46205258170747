import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppRoutes } from 'src/app/app-routing.model';
import { AppState } from 'src/app/ngrx-store/app.state';
import {
  deleteMessage,
  postReaction,
} from '../../../ngrx-store/requests/message/message.actions';
import { selectMessage } from '../../../ngrx-store/components/message/message.selectors';
import { Observable } from 'rxjs';
import { getMessagesSingle } from '../../../ngrx-store/requests/message/message.actions';
import {
  Message,
  ReactionType,
} from '../../../ngrx-store/components/message/message.model';
import { selectUser } from 'src/app/ngrx-store/components/user/user.selectors';

@Component({
  selector: 'app-thread',
  templateUrl: './thread.component.html',
  styleUrls: ['./thread.component.scss'],
})
export class ThreadComponent implements OnInit {
  public user$ = this.store.select(selectUser);

  readonly AppRoutes = AppRoutes;
  public message$?: Observable<Message | undefined>;
  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.store.dispatch(getMessagesSingle({ messageId: id }));
      this.message$ = this.store.select(selectMessage(id));
    } else {
      this.navigateBack();
    }
  }

  public navigateBack(): void {
    this.router.navigateByUrl(`/${AppRoutes.CHATS}`);
  }

  addReaction(reaction: { messageId: string; reaction: ReactionType }) {
    this.store.dispatch(postReaction(reaction));
  }

  deleteMessage(messageId: string) {
    this.store.dispatch(deleteMessage({ messageId }));
  }
}
