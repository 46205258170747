<div class="container">
  <div [hidden]="showFilter">
    <h2 class="text-primary align-center">Connections</h2>

    <a class='back-arrow' [routerLink]="'/'">
      <span></span>
      <mat-icon>arrow_back</mat-icon>
    </a>
    <div class="bevel"></div>

    <div class="underlined-title">
      <p class="text-primary">Connection requests</p>
      <div></div>
    </div>

    <div id="pending-connections">
      <app-pending-connections [displayLimit]="4"></app-pending-connections>
    </div>

    <br>
    <div class="underlined-title">
      <p class="text-primary">All connections</p>
      <div></div>
    </div>
    <div class="input-field">
      <app-input-field
        #nameField
        [placeholder]="'Fill in a name'"
        [label]="'Search for connections'"
        (input)="nameFieldChange($event.target.value)"></app-input-field>
      <img class="settings-icon" src="../../../assets/images/settings.svg" (click)="toggleFilters()"/>
    </div>
    <app-connection-list [connections]="filteredConnections" [page]="this.filter ? this.filter.page : 0"
                         (showMore)="showMore()" *ngIf="!loading"></app-connection-list>
    <div id="loading-wrapper" *ngIf="loading">
      <mat-spinner [diameter]="65"></mat-spinner>
    </div>
  </div>
  <div [hidden]="!showFilter">
    <h2 class="text-primary align-center">Filter options</h2>

    <a class='back-arrow' (click)="toggleFilters()">
      <span></span>
      <mat-icon>arrow_back</mat-icon>
    </a>
    <div class="bevel"></div>
    <br>
    <app-filter-user-list
      #filterList
      [connections]="connections"
      [collapseOnDesktop]="true"
      [allowFilterName]="true"
      (filterChange)="filterChangeWrapper($event)"
      (dataChange)="updateList($event)"
    ></app-filter-user-list>
    <div class="align-center">
      <button
        mat-flat-button
        color="primary"
        type="submit"
        class="btn-sm "
        (click)="toggleFilters()"
      >
        Search
      </button>
    </div>
  </div>
</div>
