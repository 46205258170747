import { createAction, props } from '@ngrx/store';
import { Message, ReactionType } from '../../components/message/message.model';
export enum MessageActionTypes {
  GET = '[Message] Get all messages',
  SUCCESS = '[Message] Get all messages success',
  ERROR = '[Message] Get messages error',

  DELETE = '[Message] Delete single message',
  DELETE_SUCCESS = '[Message] Delete single message success',

  GET_SINGLE = '[Message] Get single message',
  GET_SINGLE_SUCCESS = '[Message] Get single message success',

  POST_REACTION = '[Message] Post message reaction',
  POST_REACTION_SUCCESS = '[Message] Post message reaction success',

  POST_MESSAGE = '[Message] Post message',
  POST_MESSAGE_SUCCESS = '[Message] Post message success',
  POST_MESSAGE_ERROR = '[Message] Post message error',

  POST_REPLY = '[Message] Post message reply',
  POST_REPLY_SUCCESS = '[Message] Post message reply success',

  ADD_PHOTO = '[MessageForms] Add photo',
  ADD_PHOTO_SUCCESS = '[MessageForms] Add photo success',
  DELETE_PHOTO = '[MessageForms] Delete photo',
}

export const getMessages = createAction(MessageActionTypes.GET);
export const getMessagesSuccess = createAction(
  MessageActionTypes.SUCCESS,
  props<{ messages: Message[] }>()
);
export const getMessagesSingle = createAction(
  MessageActionTypes.GET_SINGLE,
  props<{ messageId: string }>()
);
export const getMessagesSingleSuccess = createAction(
  MessageActionTypes.GET_SINGLE_SUCCESS,
  props<{ message: Message }>()
);

export const deleteMessage = createAction(
  MessageActionTypes.DELETE,
  props<{ messageId: string }>()
);
export const deleteMessageSuccess = createAction(
  MessageActionTypes.DELETE_SUCCESS,
  props<{ messageId: string; message?: Message }>()
);

export const postReaction = createAction(
  MessageActionTypes.POST_REACTION,
  props<{ messageId: string; reaction: ReactionType }>()
);
export const postReactionSuccess = createAction(
  MessageActionTypes.POST_REACTION_SUCCESS,
  props<{ message: Message }>()
);

export const postMessage = createAction(
  MessageActionTypes.POST_MESSAGE,
  props<{ parentMessageId?: string }>()
);
export const postMessageSuccess = createAction(
  MessageActionTypes.POST_MESSAGE_SUCCESS,
  props<{ message: Message }>()
);

export const getMessagesError = createAction(
  MessageActionTypes.ERROR,
  props<{ error: string }>()
);
export const postMessagesError = createAction(
  MessageActionTypes.POST_MESSAGE_ERROR,
  props<{ error: string }>()
);
export const addMessagePhoto = createAction(
  MessageActionTypes.ADD_PHOTO,
  props<{ photo: File }>()
);
export const addMessagePhotoSuccess = createAction(
  MessageActionTypes.ADD_PHOTO_SUCCESS,
  props<{ reference: string }>()
);
export const deleteMessagePhoto = createAction(MessageActionTypes.DELETE_PHOTO);
