import { Component, OnInit } from '@angular/core';
import { AppRoutes } from 'src/app/app-routing.model';
import { Store } from '@ngrx/store';
import { selectEducations } from '../../ngrx-store/components/education/education.selectors';
import { AppState } from 'src/app/ngrx-store/app.state';
import { getEducations } from '../../ngrx-store/requests/education/education.actions';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {
  public educations$ = this.store.select(selectEducations);
  public AppRoutes = AppRoutes;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.store.dispatch(getEducations());
  }
}
