import {
  Component,
  Output,
  Input,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UserEntity } from 'src/app/ngrx-store/components/userEntities/userEntities.model';
import { selectEditAttributeListForm } from 'src/app/ngrx-store/components/userForms/userForms.selectors';

@Component({
  selector: 'app-input-list-builder',
  templateUrl: './input-list-builder.component.html',
  styleUrls: ['./input-list-builder.component.scss'],
})
export class InputListBuilderComponent implements OnInit, OnChanges {
  @Output() addInput: EventEmitter<string> = new EventEmitter();
  @Output() removeInput: EventEmitter<string> = new EventEmitter();

  @Input() inputPlaceholder = '';
  @Input() label = '';

  @Input() inputList: UserEntity[] = [];
  @Input() selectedItems: string[];

  selectedItemsModel: UserEntity[] = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    allowSearchFilter: false,
    itemsShowLimit: 25,
    enableCheckAll: false,
  };

  ngOnInit(): void {
    this.updateModel(this.inputList, this.selectedItems);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const inputList = changes.inputList?.currentValue as UserEntity[];

    if (inputList?.length > 0) {
      this.selectedItemsModel = this.selectedItemsModel.map((u, i) => ({
        id: inputList.find((item) => item.name === u.name)?.id || i,
        name: u.name,
      }));
    }

    this.updateModel(
      inputList || this.inputList,
      changes.selectedItems?.currentValue as string[]
    );
  }

  updateModel(inputList: UserEntity[], selectedItems: string[]) {
    if (selectedItems && inputList) {
      this.selectedItemsModel = selectedItems.map((u, i) => ({
        id: inputList.find((item) => item.name === u)?.id || i,
        name: u,
      }));
    }
  }

  selectItem(item: UserEntity) {
    this.addInput.emit(item.name);
  }

  deselectItem(item: UserEntity) {
    this.removeInput.emit(item.name);
  }
}
