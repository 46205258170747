import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from '../environments/environment';

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_sign_in_up',
    resetPassword: 'B2C_1_reset_v3',
    editProfile: 'B2C_1_edit_profile_v2',
  },
  authorities: {
    signUpSignIn: {
      authority: environment.signUpSignInAuthUrl,
    },
    resetPassword: {
      authority: environment.resetPasswordAuthUrl,
    },
    // editProfile: {
    //   authority: environment.signUpSignInAuthUrl,
    // },
  },
  authorityDomain: environment.authorityDomain,
};

export const msalConfig: Configuration = {
  auth: {
    // Move client ID to variable for each environment; see b2c app registration app application client id
    clientId: environment.msalClientId, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: environment.b2cRedirectUri, // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: '/', // Points to window.location.origin by default.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    /**
     * Below you can configure MSAL.js logs. For more information, visit:
     * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
     */
    // loggerOptions: {
    //   loggerCallback(logLevel: LogLevel, message: string) {
    //     console.log(message);
    //   },
    //   logLevel: LogLevel.Verbose,
    //   piiLoggingEnabled: false
    // }
  }
};

export const protectedResources = {
  backendApi: {
    endpoint: `${environment.apiBaseUrl}`,
    scopes: environment.protectedScopes,
  },
};
