import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectCompany } from '../../../ngrx-store/components/company/company.selectors';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routing.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company-onboarding',
  templateUrl: './company-onboarding.component.html',
  styleUrls: ['./company-onboarding.component.scss']
})
export class CompanyOnboardingComponent implements OnDestroy {
  public company$ = this.store.select(selectCompany);
  private subscriptions = new Subscription();

  constructor(private readonly store: Store<AppState>, private readonly router: Router) {
    const sub = this.company$.subscribe((company) => {
      if (company?.onboardingCompleted) {
        this.router.navigateByUrl(`/${AppRoutes.COMPANY}`);
      }
    });
    this.subscriptions.add(sub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
