import { userEffects } from './components/user/user.effects';
import { matchEffects } from './components/match/match.effects';
import { userEntitiesEffects } from './components/userEntities/userEntities.effects';
import { connectionEffects } from './components/connection/connection.effects';
import { OnboardingEffects } from './components/onboarding/onboarding.effects';
import { AdminEffects } from './components/admin/admin.effects';
import { eventEffects } from './components/event/event.effects';
import { storyEffects } from './components/story/story.effects';
import { companyEffects } from './components/company/company.effects';
import { StorageEffects } from './requests/storage/storage.effects';
import { educationEffects } from './components/education/education.effects';
import { MessageEffects } from './requests/message/message.effects';
import { VacancyEffects } from './components/vacancy/vacancy.effects';
import { blogitemEffects } from './components/blogitem/blogitem.effects';

export const effects = [
  ...userEffects,
  ...matchEffects,
  ...eventEffects,
  ...userEntitiesEffects,
  ...connectionEffects,
  ...storyEffects,
  ...educationEffects,
  ...companyEffects,
  ...blogitemEffects,
  VacancyEffects,
  OnboardingEffects,
  AdminEffects,
  StorageEffects,
  MessageEffects,
];
