import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {Connection} from '../ngrx-store/components/connection/connection.model';
import {User} from '../ngrx-store/components/user/user.model';
import {SearchFilter} from '../ngrx-store/components/match/match.model';

@Injectable()
export class MatchService {
  constructor(protected http: HttpClient) {
  }

  private static getQueryString(limit: number | undefined, bestMatches: boolean): string {
    const limitString = limit ? 'limit=' + limit : '';
    const bestMatchesString = bestMatches ? 'bestmatches=1' : '';
    return limitString && bestMatchesString ? `?${limitString}&${bestMatchesString}` :
      limitString ? '?' + limitString :
        bestMatchesString ? '?' + bestMatchesString : '';
  }

  public getMentors(filter: SearchFilter, limit: number | undefined, bestMatches: boolean): Observable<User[]> {
    return this.http.post<User[]>(`${environment.apiBaseUrl}connection/mentors${MatchService.getQueryString(limit, bestMatches)}`, filter);
  }

  public getMentees(filter: SearchFilter, limit: number | undefined, bestMatches: boolean): Observable<User[]> {
    return this.http.post<User[]>(`${environment.apiBaseUrl}connection/mentees${MatchService.getQueryString(limit, bestMatches)}`, filter);
  }

  public getCommunityMembers(filter: SearchFilter, limit: number | undefined, bestMatches: boolean): Observable<User[]> {
    return this.http.post<User[]>(`${environment.apiBaseUrl}connection/members${MatchService.getQueryString(limit, bestMatches)}`, filter);
  }

  public sendConnectionRequest(
    id: string,
    message: string = ''
  ): Observable<Connection> {
    return this.http.post<Connection>(
      `${environment.apiBaseUrl}connection/request/${id}`,
      message
    );
  }

  public acceptConnectionRequest(id: string): Observable<Connection> {
    return this.http.post<Connection>(`${environment.apiBaseUrl}connection/accept/${id}`, {});
  }

  public denyConnectionRequest(id: string): Observable<string> {
    return this.http.post<string>(`${environment.apiBaseUrl}connection/deny/${id}`, {});
  }

  public removeConnection(id: string): Observable<any> {
    return this.http.post<any>(`${environment.apiBaseUrl}connection/remove/${id}`, {});
  }

  public getConnections(filter: SearchFilter): Observable<Connection[]> {
    return this.http.post<Connection[]>(`${environment.apiBaseUrl}connections`, filter);
  }

  public getPendingConnections(): Observable<Connection[]> {
    return this.http.get<Connection[]>(`${environment.apiBaseUrl}connections/pending`);
  }

  public postDismissMatch(matchId: string): Observable<any> {
    return this.http.post<User>(`${environment.apiBaseUrl}connection/dismiss/${matchId}`, {});
  }
}
