import { createAction, props } from '@ngrx/store';
import { CompanyContactPerson } from './company.model';
import { RightbrainsEvent } from '../event/event.model';

export enum CompanyActionTypes {
  DELETE_CONTACT = '[Company] Delete contact',
  DELETE_EVENT = '[Company] Delete event',
  SET_ONBOARDING_COMPLETE = '[Company] Set onboarding completed',
}

export const deleteContact = createAction(CompanyActionTypes.DELETE_CONTACT, props<{ contact: CompanyContactPerson }>());
export const deleteEvent = createAction(CompanyActionTypes.DELETE_EVENT, props<{ event: RightbrainsEvent }>());
export const setOnboardingComplete = createAction(CompanyActionTypes.SET_ONBOARDING_COMPLETE);
