import { Component } from '@angular/core';
import { CompanyDetailsBaseComponent } from '../company-details-base/company-details-base.component';


@Component({
  selector: 'app-company-details-name',
  templateUrl: './company-details-name.component.html',
})
export class CompanyDetailsNameComponent extends CompanyDetailsBaseComponent {
}
