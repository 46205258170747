import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControlState } from 'ngrx-forms';
import { extend } from 'underscore';
import { NgrxFormElementDirective } from '../ngrx-form-element/ngrx-form-element.directive';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-input-auto-complete',
  templateUrl: './input-auto-complete.component.html',
})
export class InputAutoCompleteComponent extends NgrxFormElementDirective
  implements OnInit {
  @Input() label = '';
  @Input() placeholder = 'test';
  @Input() inputList!: string[];

  @Input() requiredErrorMessage = '';
  @Input() patternErrorMessage = '';
  @Input() emailErrorMessage = '';

  @Output() inputChanged = new EventEmitter();
  @Output() optionSelected = new EventEmitter<MatAutocompleteSelectedEvent>();

  public options: string[];

  constructor() {
    super();
  }

  ngOnInit(): void {}

  public filterList(value: string, exclude: boolean = false) {
    const filterValue = value.toLowerCase().trim();
    if (filterValue === '') {
      this.options = [];
    } else {
      this.options = this.inputList
        // Filter everything that doesn't match our filterValue
        .filter((option) =>
          option.toLowerCase().includes(filterValue) ? !exclude : false
        )
        .slice(0, 20);
    }
  }
}
