import {createAction, props} from '@ngrx/store';
import {Story} from '../../components/story/story.model';

export enum GetStoryActionTypes {
  GET = '[Story] Get Story',
  SUCCESS = '[Story] Get Story success',
  ERROR = '[Story] Get Story error',
}

export const getStories = createAction(GetStoryActionTypes.GET);
export const getStoriesSuccess = createAction(GetStoryActionTypes.SUCCESS,
                                                    props<{ stories: Story[] }>());
export const getStoriesError = createAction(GetStoryActionTypes.ERROR, props<{ error: string }>());
