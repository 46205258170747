import { Component, Inject } from '@angular/core';
import { selectCompanyEventForm } from '../../../ngrx-store/components/companyForms/companyForms.selector';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { submitCompanyEventForm } from '../../../ngrx-store/components/companyForms/companyEvent/forms.companyEvent.actions';
import { postDetailsCompany } from '../../../ngrx-store/requests/company/post-company/post-company.actions';
import { CompanyEventForm } from '../../../ngrx-store/components/companyForms/companyEvent/forms.companyEvent.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-event-dialog',
  templateUrl: './event-dialog.component.html',
  styleUrls: ['./event-dialog.component.scss']
})
export class EventDialogComponent {

  public companyEventForm$ = this.store.select(selectCompanyEventForm);
  public isEdit = false;

  constructor(private readonly store: Store<AppState>, @Inject(MAT_DIALOG_DATA) data?: { isEdit?: boolean}) {
    this.isEdit = data?.isEdit ?? false;
  }

  addEvent(formValues: CompanyEventForm) {
    this.store.dispatch(submitCompanyEventForm({ formValues }));
    this.store.dispatch(postDetailsCompany());
  }
}
