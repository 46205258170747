import { createAction, props } from '@ngrx/store';
import { CompanyDetailsForm } from './forms.companyDetails.model';
import { CompanyAmbassador, CompanyPhoto } from '../../company/company.model';

export enum CompanyDetailsFormActionTypes {
  ADD_LOGO = '[CompanyForms] Add company logo',
  ADD_LOGO_SUCCESS = '[CompanyForms] Add company logo success',
  ADD_PHOTO = '[CompanyForms] Add company photo',
  ADD_PHOTO_SUCCESS = '[CompanyForms] Add company photo success',
  DELETE_PHOTO = '[CompanyForms] Delete company photo',
  ADD_AMBASSADOR = '[CompanyForms] Add ambassador',
  DELETE_AMBASSADOR = '[CompanyForms] Delete ambassador',
  SUBMIT = '[CompanyForms] Submit companyDetails',
}

export const addCompanyLogo = createAction(CompanyDetailsFormActionTypes.ADD_LOGO, props<{ logo: File }>());
export const addCompanyLogoSuccess = createAction(CompanyDetailsFormActionTypes.ADD_LOGO_SUCCESS, props<{ reference: string }>());
export const addCompanyPhoto = createAction(CompanyDetailsFormActionTypes.ADD_PHOTO, props<{ photo: File }>());
export const addCompanyPhotoSuccess = createAction(CompanyDetailsFormActionTypes.ADD_PHOTO_SUCCESS, props<{ companyPhoto: CompanyPhoto }>());
export const deleteCompanyPhoto = createAction(CompanyDetailsFormActionTypes.DELETE_PHOTO, props<{ companyPhoto: CompanyPhoto }>());
export const addAmbassador = createAction(CompanyDetailsFormActionTypes.ADD_AMBASSADOR, props<{ ambassador: CompanyAmbassador }>());
export const deleteAmbassador = createAction(CompanyDetailsFormActionTypes.DELETE_AMBASSADOR, props<{ ambassador: CompanyAmbassador }>());
export const submitCompanyDetailsForm = createAction(CompanyDetailsFormActionTypes.SUBMIT, props<{ formValues: CompanyDetailsForm }>());
