<div *ngIf="events?.length == 0; else eventcards">
  <div class="align-center">
    <mat-card appearance="outlined" class="event-card float-none">
      <mat-card-content>
        No planned events at this moment. <br />
        Please check again later.
      </mat-card-content>
    </mat-card>
  </div>
</div>
<ng-template #eventcards>
  <div class="event-cards">
    <div *ngFor="let e of events">
      <!-- do not slice here, please pass sliced lists to this component -->
      <app-eventcard
        [event]="e"
        [showPartnerLabel]="showPartnerLabel"
      ></app-eventcard>
    </div>
  </div>
  <br />
  <div class="all-matches-button" *ngIf="viewAllButton">
    <a [routerLink]="'/' + AppRoutes.EVENTS">
      <button class="btn-sm mx-auto" mat-flat-button color="primary">
        View all events
      </button>
    </a>
  </div>
</ng-template>
