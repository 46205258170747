import {Action, createReducer, on, StateObservable} from '@ngrx/store';
import {
  addArrayControl,
  createFormGroupState,
  FormGroupState,
  formStateReducer,
  setValue,
  updateGroup,
  removeArrayControl,
} from 'ngrx-forms';
import {UserFormsState, UserRole, UserRolesText} from '../userForms.model';
import {FindMatchForm} from './forms.findMatch.model';
import {
  addAreaBackground,
  removeAreaBackground,
  addIndustryBackground,
  removeIndustryInterest,
  addDigitalAreaInterest,
  removeDigitalAreaInterest,
  addIndustryInterest,
  removeIndustryBackground,
  setFindMoreExperienced,
  setFindInRegion,
} from './forms.findMatch.actions';
import {getUserSuccess} from 'src/app/ngrx-store/requests/user/get-user/get-user.actions';
import {User} from '../../user/user.model';
import * as _ from 'underscore';

export const initialFindMatchForm: FindMatchForm = {
  role: UserRolesText[0],
  roles: UserRolesText,
  digitalArea: '',
  digitalAreaBackgrounds: [],
  digitalAreaInterests: [],
  industry: '',
  industryBackgrounds: [],
  industryInterests: [],
  findInRegion: false,
  findMoreExperienced: false,
};

export const initialFindMatchFormState = createFormGroupState<FindMatchForm>('FIND_MATCHES_FORM', initialFindMatchForm);

const updateForm = createReducer<FormGroupState<FindMatchForm>>(
  initialFindMatchFormState,
  on(addAreaBackground, (state, action) =>
    updateGroup<FindMatchForm>(state, {
      digitalArea: setValue(''),
      digitalAreaBackgrounds: addArrayControl(action.digitalArea),
    })
  ),
  on(removeAreaBackground, (state, action) =>
    updateGroup<FindMatchForm>(state, {
      digitalAreaBackgrounds: removeArrayControl(
        _.findIndex(state.value.digitalAreaBackgrounds, (s) => s.name === action.digitalArea.name)
      ),
    })
  ),
  on(addIndustryBackground, (state, action) =>
    updateGroup<FindMatchForm>(state, {
      industry: setValue(''),
      industryBackgrounds: addArrayControl(action.industry),
    })
  ),
  on(removeIndustryBackground, (state, action) =>
    updateGroup<FindMatchForm>(state, {
      industryBackgrounds: removeArrayControl(
        _.findIndex(state.value.industryBackgrounds, (s) => s.name === action.industry.name)
      ),
    })
  ),
  on(addDigitalAreaInterest, (state, action) =>
    updateGroup<FindMatchForm>(state, {
      digitalArea: setValue(''),
      digitalAreaInterests: addArrayControl(action.digitalArea),
    })
  ),
  on(removeDigitalAreaInterest, (state, action) =>
    updateGroup<FindMatchForm>(state, {
      digitalAreaInterests: removeArrayControl(
        _.findIndex(state.value.digitalAreaInterests, (s) => s.name === action.digitalArea.name)
      ),
    })
  ),
  on(addIndustryInterest, (state, action) =>
    updateGroup<FindMatchForm>(state, {
      industry: setValue(''),
      industryInterests: addArrayControl(action.industry),
    })
  ),
  on(removeIndustryInterest, (state, action) => {
    return updateGroup<FindMatchForm>(state, {
      industryInterests: removeArrayControl(
        _.findIndex(state.value.industryInterests, (s) => s.name === action.industry.name)
      ),
    });
  }),
  on(setFindMoreExperienced, (state, action) =>
    updateGroup<FindMatchForm>(state, {
      findMoreExperienced: setValue(action.value),
    })
  ),
  on(setFindInRegion, (state, action) =>
    updateGroup<FindMatchForm>(state, {
      findInRegion: setValue(action.value),
    })
  ),
  on(getUserSuccess, (state, action) => prefillForm(state, action.user))
);

const validateFindMatchForm = updateGroup<FindMatchForm>({});

const prefillForm = (state: FormGroupState<FindMatchForm>, user?: User) => {
  if (!user) {
    return state;
  }

  return updateGroup<FindMatchForm>(state, {
    digitalAreaBackgrounds: (control) => {
      return user.userBackground?.digitalAreas
        ? setValue(user.userBackground.digitalAreas)(control)
        : setValue(control.value)(control);
    },
    industryBackgrounds: (control) => {
      return user.userBackground?.industries
        ? setValue(user.userBackground.industries)(control)
        : setValue(control.value)(control);
    },
    digitalAreaInterests: (control) => {
      return user.userInterests ? setValue(user.userInterests.digitalAreas)(control) : setValue(control.value)(control);
    },
    industryInterests: (control) => {
      return user.userInterests ? setValue(user.userInterests.industries)(control) : setValue(control.value)(control);
    },
    findInRegion: setValue(user.findInRegion),
    findMoreExperienced: setValue(user.findMoreExperienced),
    role: setValue(UserRolesText[user.role]),
  });
};

export const findMatchFormReducer = (state: UserFormsState, action: Action) => {
  const findMatchForm: FormGroupState<FindMatchForm> = state.findMatchForm;

  // use the out of the box reducer to process form actions into the store
  let form = formStateReducer(findMatchForm, action);

  // Process non-form actions
  form = updateForm(form, action);

  // form validation using the new state
  form = validateFindMatchForm(form);

  return form === findMatchForm ? findMatchForm : form;
};
