<mat-dialog-content>
  <div class="mb-4 d-flex justify-content-between">
    <h1 class="text-primary">Share RightBrains</h1>

    <button mat-icon-button mat-dialog-close color="primary">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="link">
    <div #url class="pen-url">{{ currentSite }}</div>
    <button #copyButton class="copy-link" (click)="copyLink()">Copy link</button>
  </div>
</mat-dialog-content>
