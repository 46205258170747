import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Connection } from 'src/app/ngrx-store/components/connection/connection.model';
import { User } from 'src/app/ngrx-store/components/user/user.model';

@Component({
  selector: 'app-connection-list-item',
  templateUrl: './connection-list-item.component.html',
  styleUrls: ['./connection-list-item.component.scss'],
})
export class ConnectionListItemComponent implements OnInit {
  @Input() user?: User;
  @Input() connection?: Connection;
  @Input() isSelf = false;
  @Output() onOpenProfile = new EventEmitter<void>();
  @Output() onRemoveConnection = new EventEmitter<void>();
  @Output() onAddConnection = new EventEmitter<void>();

   ngOnInit(): void {
    if (this.connection) {
      this.user = this.connection.user;
    }
  }

  public openProfile() {
    this.onOpenProfile.emit();
  }

  public removeConnection() {
    this.onRemoveConnection.emit();
  }

  public addConnection() {
    this.onAddConnection.emit();
  }
}
