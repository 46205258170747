<div id="menubar-container">
  <mat-toolbar color="primary" class="straight-edges">
    <div class="desktop-header">
      <a routerLink="/{{ appRoutes.DASHBOARD }}" id="logo-rightbrains"
        ><img class="p-2 m-0" src="assets/images/RightbrainsLogoWhite.png"
      /></a>
      <div
        id="menubar-btn-container"
        class="d-flex align-items-center justify-content-between"
      >
        <div class="menu-item" [routerLink]="appRoutes.DASHBOARD">
          <div class="menu-icon">
            <i class="fa fa-home"></i>
          </div>
          <div class="menu-item-text">Home</div>
        </div>
        <div class="menu-item" [routerLink]="appRoutes.CHATS" *ngIf="enableNonAdminFeatures">
          <div class="menu-icon">
            <i class="fa fa-comment-alt"></i>
          </div>
          <div class="menu-item-text">Forum</div>
        </div>
        <div class="menu-item" [routerLink]="appRoutes.CONNECTIONS" *ngIf="enableNonAdminFeatures">
          <div class="menu-icon">
            <i class="fa fa-users"></i>
          </div>
          <div class="menu-item-text">Connections</div>
        </div>
        <div class="menu-item" [routerLink]="appRoutes.MATCHES" *ngIf="enableNonAdminFeatures">
          <div class="menu-icon">
            <i class="fa fa-search"></i>
          </div>
          <div class="menu-item-text">Find matches</div>
        </div>
        <div class="menu-item" (click)="shareSocialLink()" *ngIf="enableNonAdminFeatures">
          <div class="menu-icon">
            <i class="fa fa-share-alt"></i>
          </div>
          <div class="menu-item-text">Share</div>
        </div>
        <div class="menu-item" [routerLink]="appRoutes.NOTIFICATIONS" *ngIf="enableNonAdminFeatures">
          <div
            *ngIf="
              userNotificationCount$ | async as notificationCount;
              else noNotifications
            "
            matBadge="{{ notificationCount }}"
            matBadgeSize="small"
            matBadgeColor="warn"
            class="menu-icon"
          >
            <i class="fas fa-bell"></i>
          </div>
          <ng-template #noNotifications>
            <div class="menu-icon">
              <i class="fas fa-bell"></i>
            </div>
          </ng-template>
          <div class="menu-item-text">Notifications</div>
        </div>
        <div class="menu-item avatar-wrapper" [matMenuTriggerFor]="profileMenu">
          <div>
            <img
              *ngIf="userAvatar$ | async as avatar; else placeholder"
              [src]="avatar! | safe : 'url'"
              id="avatar"
            />
            <ng-template #placeholder>
              <i class="fas fa-user-circle"></i>
            </ng-template>
            <span class="ps-2">
              <i class="fas fa-chevron-down"></i>
            </span>
          </div>
          <div class="menu-item-text">Account</div>
        </div>
      </div>
    </div>
    <div class="mobile-header">
      <div class="mobile-left">
        <div class="menu-item avatar-wrapper" [matMenuTriggerFor]="profileMenu">
          <img
            *ngIf="userAvatar$ | async as avatar; else placeholder"
            [src]="avatar! | safe : 'url'"
            id="avatar"
          />
          <ng-template #placeholder>
            <i class="fas fa-user-circle"></i>
          </ng-template>
        </div>
      </div>
      <div class="mobile-right" *ngIf="enableNonAdminFeatures">
        <div class="menu-item" [routerLink]="appRoutes.MATCHES">
          <div class="menu-icon">
            <i class="fa fa-search"></i>
          </div>
        </div>
        <div class="menu-item" (click)="shareSocialLink()">
          <div class="menu-icon">
            <i class="fa fa-share-alt"></i>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<mat-menu #profileMenu="matMenu">
  <div class="submenu-item">
    <a [routerLink]="appRoutes.PROFILE">
      <i class="fas fa-user"></i>
      Profile
    </a>
  </div>
  <div class="submenu-item">
    <a mat-list-item [routerLink]="appRoutes.SETTINGS">
      <i class="fa fa-cog"></i>
      Settings
    </a>
  </div>
  <div class="submenu-item">
    <a (click)="logoutClicked.emit()">
      <i class="fa fa-sign-out-alt"></i>
      Log out
    </a>
  </div>
</mat-menu>
