<div *ngIf="companyDetailsForm$ | async as form">
  <app-company-details-header
    title="About us"
    *ngIf="!isSignUp"
    [canEdit]="!company"
    [modify]="modify"
    (onModify)="toggleModify($event)"
    data-cy="company-about-us-title"
  ></app-company-details-header>

  <div *ngIf="company; else self">
    {{ company.information }}
  </div>

  <ng-template #self>
    <app-input-text-area
      *ngIf="modify || isSignUp"
      [form]="form"
      [formControl]="form.controls.information"
      [minRows]="2"
      [label]="isSignUp ? 'Information about your company' : ''"
      placeholder="Describe field of work and activities (max. 1000 characters)"
      requiredErrorMessage="Please fill in this field"
      maxLengthErrorMessage="Text should contain no more than 1000 characters"
    ></app-input-text-area>

    <div *ngIf="!modify && !isSignUp">
      {{ form.controls.information.value }}
    </div>
  </ng-template>
</div>
