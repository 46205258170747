import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/ngrx-store/app.state';
import { Company } from 'src/app/ngrx-store/components/company/company.model';
import { submitCompanyDetailsForm } from 'src/app/ngrx-store/components/companyForms/companyDetails/forms.companyDetails.actions';
import { selectCompanyDetailsForm } from 'src/app/ngrx-store/components/companyForms/companyForms.selector';
import { postDetailsCompany } from 'src/app/ngrx-store/requests/company/post-company/post-company.actions';

@Component({
  selector: 'app-company-details-base',
  templateUrl: './company-details-base.component.html',
})
export class CompanyDetailsBaseComponent {
  @Input() company?: Company;
  @Input() isSignUp = false;

  public modify = false;
  public companyDetailsForm$ = this.store.select(selectCompanyDetailsForm);

  constructor(protected readonly store: Store<AppState>) {}

  toggleModify(modify: boolean) {
    this.modify = modify;
    if (!modify) {
      this.companyDetailsForm$.pipe(take(1)).subscribe((form) => {
        this.store.dispatch(
          submitCompanyDetailsForm({ formValues: form.value })
        );
        this.store.dispatch(postDetailsCompany());
      });
    }
  }
}
