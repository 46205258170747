import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  Message,
  NewMessage,
  ReactionType,
} from '../ngrx-store/components/message/message.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class MessageService {
  constructor(protected http: HttpClient) {}

  public getMessages(): Observable<Message[]> {
    return this.http.get<Message[]>(`${environment.apiBaseUrl}message/all`);
  }

  public getMessage(messageId: string): Observable<Message> {
    return this.http.get<Message>(
      `${environment.apiBaseUrl}message/${messageId}`
    );
  }

  public deleteMessage(messageId: string): Observable<Message | undefined> {
    return this.http.delete<Message | undefined>(
      `${environment.apiBaseUrl}message/${messageId}`
    );
  }

  public postMessage(
    newMessage: NewMessage,
    parentMessageId?: string
  ): Observable<Message> {
    return this.http.post<Message>(`${environment.apiBaseUrl}message`, {
      ...newMessage,
      parentMessageId,
    });
  }

  public postReaction(
    messageId: string,
    reaction: ReactionType
  ): Observable<Message> {
    return this.http.post<Message>(
      `${environment.apiBaseUrl}message/reaction/${messageId}`,
      {
        type: reaction,
      }
    );
  }
}
