export interface EducationForm {
  educationType: EducationType;
  educationTypes: string[];
  school: string;
  study: string;
  startDate: string;
  endDate: string;
  index: number;
}

export enum EducationType {
  Current_Education = 'Current Education',
  Previous_Education = 'Previous Education'
}
