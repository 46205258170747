<div class="company-details-container">
  <div *ngIf="companyDetailsForm$ | async as form">
    <div class="dashboard-title">
      <div class="underlined-title">
        <p>{{ company ? company.name : form.controls.name.value }}</p>
        <div></div>
      </div>
    </div>

    <app-company-details-media [company]="company"></app-company-details-media>

    <app-company-details-introduction
      *ngIf="!company"
      [company]="company"
    ></app-company-details-introduction>
    <app-company-details-about-us
      [company]="company"
    ></app-company-details-about-us>
    <app-company-details-careers
      [company]="company"
    ></app-company-details-careers>
    <div class="d-flex">
      <div class="w-50">
        <app-company-details-industries
          [company]="company"
        ></app-company-details-industries>
      </div>
      <div class="w-50">
        <app-company-details-country
          [company]="company"
        ></app-company-details-country>
      </div>
    </div>
    <app-company-ambassadors [company]="company"></app-company-ambassadors>
    <app-company-events [company]="company"></app-company-events>
    <app-company-vacancies [company]="company"></app-company-vacancies>
    <div *ngIf="company" class="d-flex justify-content-center">
      <button
        class="btn-sm mx-auto mt-3"
        mat-flat-button
        color="primary"
        (click)="openMailClient()"
      >
        Open application
      </button>
    </div>
    <app-company-details-contact-person
      [company]="company"
    ></app-company-details-contact-person>
    <app-company-social-media [company]="company"></app-company-social-media>
  </div>
</div>
