import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { SettingsComponent } from './settings.component';
import { NgrxFormsModule } from 'ngrx-forms';
import { SharedFormsModule } from 'src/app/shared/forms/shared.forms.module';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, NgrxFormsModule, SharedFormsModule],
  declarations: [SettingsComponent],
})
export class SettingsModule {}
