import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ChatsComponent } from './chats.component';
import { SharedModule } from '../../shared/shared.module';
import { MessageComponent } from './message/message.component';
import { ThreadComponent } from './thread/thread.component';
import { ReactionsComponent } from './reactions/reactions.component';
import { SortMessagePipe } from './sort-message.pipe';
import { AddMessageComponent } from './add-message/add-message.component';
import { SharedFormsModule } from '../../shared/forms/shared.forms.module';

const routes: Routes = [
  {
    path: '',
    component: ChatsComponent,
  },
  {
    path: ':id',
    component: ThreadComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, SharedFormsModule],
  declarations: [
    ChatsComponent,
    MessageComponent,
    ThreadComponent,
    ReactionsComponent,
    SortMessagePipe,
    AddMessageComponent,
  ],
})
export class ChatsModule {}
