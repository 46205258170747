import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {StoryService} from '../../../services/story.service';
import {getStories, getStoriesError, getStoriesSuccess} from './story.actions';

@Injectable()
export class GetStoryEffects {
  constructor(private actions$: Actions,
              private storyService: StoryService) {
  }

  getStories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStories),
      switchMap(() => {
        return this.storyService.getStories().pipe(
          map((result) => getStoriesSuccess({stories: result})),
          catchError((error: HttpErrorResponse) => of(getStoriesError({error: error.message})))
        );
      })
    ));
}
