import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  Reaction,
  ReactionType,
} from '../../../ngrx-store/components/message/message.model';

@Component({
  selector: 'app-reactions',
  templateUrl: './reactions.component.html',
  styleUrls: ['./reactions.component.scss'],
})
export class ReactionsComponent implements OnChanges {
  @Input() reactions: Reaction[];
  @Output() addReaction = new EventEmitter<ReactionType>();

  public showMenu = false;
  public ReactionTypes = Object.values(ReactionType).filter(
    (t) => !isNaN(Number(t))
  );
  public reactionCounts: number[];

  public reactionToIcon: { [key in ReactionType]: string } = {
    0: '\u{1F44D}',
    1: '\u{1F44F}',
    2: '\u{1F49C}',
    3: '\u{1F604}',
    4: '\u{1F4A1}',
    5: '\u{1F914}',
  };

  public ngOnChanges(changes: SimpleChanges): void {
    this.reactionCounts = this.ReactionTypes.map((type) =>
      this.reactions
        ? this.reactions.filter((reaction) => reaction.type === type).length
        : 0
    );
  }

  public toReaction(reaction: any): ReactionType {
    return reaction;
  }

  public emitAddReaction(reaction: any, event: any) {
    if (event) {
      event.stopPropagation();
    }
    this.addReaction.emit(reaction as ReactionType);
    this.showMenu = false;
  }

  public toggleMenu(event: any) {
    if (event) {
      event.stopPropagation();
    }
    this.showMenu = !this.showMenu;
  }
}
