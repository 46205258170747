import { Component, Input, ViewChild } from '@angular/core';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  FormControlState,
  NgrxValueConverter,
  NgrxValueConverters,
} from 'ngrx-forms';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../utilities/date.adapter';
import { NgrxFormElementDirective } from '../ngrx-form-element/ngrx-form-element.directive';

export enum DateMode {
  Default,
  Month,
  Year,
}

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class InputDateComponent extends NgrxFormElementDirective {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() requiredErrorMessage = '';
  @Input() mode: DateMode = DateMode.Month;

  public dateModes = DateMode;

  @ViewChild('picker') datePicker: MatDatepicker<any>;

  public dateValueConverter: NgrxValueConverter<Date | null, string | null> = {
    convertViewToStateValue(value) {
      if (value === null) {
        return null;
      }

      // the value provided by the date picker is in local time but we want UTC so we recreate the date as UTC
      value = new Date(
        Date.UTC(value.getFullYear(), value.getMonth(), value.getDate())
      );
      return NgrxValueConverters.dateToISOString.convertViewToStateValue(value);
    },
    convertStateToViewValue:
      NgrxValueConverters.dateToISOString.convertStateToViewValue,
  };

  constructor() {
    super();
  }

  public closeDatePicker(event: Date) {
    const date = new Date(
      Date.UTC(event.getFullYear(), event.getMonth(), event.getDate())
    );
    this.datePicker.select(date);
    this.datePicker.close();
  }
}
