import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppRoutes } from 'src/app/app-routing.model';
import { AppState } from 'src/app/ngrx-store/app.state';
import {
  clearEducationForm,
  submitEducationForm,
} from 'src/app/ngrx-store/components/userForms/profileEditEducation/forms.editEducation.actions';
import {
  EducationForm,
  EducationType,
} from 'src/app/ngrx-store/components/userForms/profileEditEducation/forms.education.model';
import { selectEducationForm } from 'src/app/ngrx-store/components/userForms/userForms.selectors';
import { postUser } from 'src/app/ngrx-store/requests/user/post-user/post-user.actions';

@Component({
  selector: 'app-edit-education',
  templateUrl: './edit-education.component.html',
  styleUrls: ['./edit-education.component.scss'],
})
export class EditEducationComponent implements AfterViewInit {
  public educationForm$ = this.store.select(selectEducationForm);
  public EducationType = EducationType;

  constructor(private readonly store: Store<AppState>, private router: Router) {
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  public submitForm(formValues: EducationForm) {
    this.store.dispatch(submitEducationForm({ formValues }));
    this.store.dispatch(postUser());
    this.router.navigateByUrl(`${AppRoutes.PROFILE}/edit/educations`);
  }

  public cancel() {
    this.store.dispatch(clearEducationForm());
    this.router.navigateByUrl(`${AppRoutes.PROFILE}/edit/educations`);
  }
}
