import { Action, createReducer } from '@ngrx/store';
import { findMatchFormReducer, initialFindMatchFormState } from './onboardingFindMatch/forms.findMatch.reducer';
import { initialPersonalDetailsFormState, personalDetailsFormReducer } from './onboardingPersonalDetails/forms.personalDetails.reducer';
import { UserFormsState } from './userForms.model';
import { initialProfileEditFormState, profileEditFormReducer } from './profileEdit/forms.profileEdit.reducer';
import { initialEditExperienceFormState, editExperienceFormReducer } from './profileEditExperience/forms.experience.reducer';
import {
  initialEditAttributeListFormState, editAttributeListReducer
} from './profileEditAttributeList/forms.editAttributeList.reducer';
import { initialEducationFormState, educationFormReducer } from './profileEditEducation/forms.education.reducer';
import { mailPreferencesFormReducer, initialMailPreferencesFormState } from './mailPreferences/userForms.mailPreferences.reducer';

export const initialUserFormsState: UserFormsState = {
  mailPreferencesForm: initialMailPreferencesFormState,
  personalDetailsForm: initialPersonalDetailsFormState,
  findMatchForm: initialFindMatchFormState,
  profileEditForm: initialProfileEditFormState,
  experienceForm: initialEditExperienceFormState,
  educationForm: initialEducationFormState,
  attributeListForm: initialEditAttributeListFormState
};

const actionReducer = createReducer<UserFormsState>(
  initialUserFormsState
);

const joinedReducer = (state: UserFormsState | undefined, action: Action): UserFormsState => {
  const newState: UserFormsState = actionReducer(state, action);

  return {
    ...newState,
    mailPreferencesForm: mailPreferencesFormReducer(newState, action),
    findMatchForm: findMatchFormReducer(newState, action),
    personalDetailsForm: personalDetailsFormReducer(newState, action),
    profileEditForm: profileEditFormReducer(newState, action),
    experienceForm: editExperienceFormReducer(newState, action),
    educationForm: educationFormReducer(newState, action),
    attributeListForm: editAttributeListReducer(newState, action),
  };
};

export function userFormsReducer(state: UserFormsState | undefined, action: Action) {
  return joinedReducer(state, action);
}
