import { Action, createReducer } from '@ngrx/store';
import { createFormGroupState, FormGroupState, formStateReducer, updateGroup, validate } from 'ngrx-forms';
import { required, minLength } from 'ngrx-forms/validation';
import { CompanyFormsState } from '../companyForms.model';
import { CreateCompanyForm } from './forms.createCompany.model';

export const initialCreateCompanyForm: CreateCompanyForm = {
  companyCode: '',
  name: ''
};

export const initialCreateCompanyFormState = createFormGroupState<CreateCompanyForm>(
  'CREATE_COMPANY_FORM',
  initialCreateCompanyForm
);

const updateForm = createReducer<FormGroupState<CreateCompanyForm>>(
  initialCreateCompanyFormState
);

const validateForm = (form: FormGroupState<CreateCompanyForm>, state: CompanyFormsState) => {
  return updateGroup<CreateCompanyForm>({
    companyCode: validate(required, minLength(1)),
    name: validate(required, minLength(1)),
  })(form);
};

export const createCompanyFormReducer = (state: CompanyFormsState, action: Action) => {
  const createCompanyForm: FormGroupState<CreateCompanyForm> = state.createCompanyForm;

  // use the out of the box reducer to process form actions into the store
  let form = formStateReducer(createCompanyForm, action);

  // Process non-form actions
  form = updateForm(form, action);

  // form validation using the new state
  form = validateForm(form, state);

  return form === createCompanyForm ? createCompanyForm : form;
};
