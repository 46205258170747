<div #page>
  <div class="container">
    <h2 class="text-primary align-center">Forum</h2>

    <a class="back-arrow" [routerLink]="'/'">
      <span></span>
      <mat-icon>arrow_back</mat-icon>
    </a>
    <div class="bevel"></div>
    <br />
    <div class="add-message">
      <app-add-message></app-add-message>
    </div>
    <div *ngIf="user$ | async as user">
      <div *ngIf="messages$ | async as messages" class="mt-2">
        <div
          class="message-block"
          *ngFor="let message of messages | sortMessages"
        >
          <app-message
            [message]="message"
            (onOpenProfile)="openProfile($event)"
            (addReaction)="addReaction($event)"
            (deleteMessage)="deleteMessage($event)"
            [userId]="user.id"
          ></app-message>
        </div>
        <div
          *ngIf="messages.length === 0"
          class="w-100 d-flex justify-content-center mt-3"
        >
          <mat-spinner class="margin-auto" [diameter]="65"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
<img
  (click)="scrollToTop()"
  class="message-scroll-button"
  src="../../../assets/images/scroll-knop.svg"
/>
