import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/ngrx-store/app.state';
import { submitCompanyContactPersonForm } from 'src/app/ngrx-store/components/companyForms/companyContactPerson/forms.companyContactPerson.actions';
import { selectCompanyContactPersonForm } from 'src/app/ngrx-store/components/companyForms/companyForms.selector';
import { postDetailsCompany } from 'src/app/ngrx-store/requests/company/post-company/post-company.actions';

@Component({
  selector: 'app-company-contact-dialog',
  templateUrl: './company-contact-dialog.component.html',
  styleUrls: ['./company-contact-dialog.component.scss']
})
export class CompanyContactDialogComponent {
  public companyContactPersonForm$ = this.store.select(selectCompanyContactPersonForm);

  constructor(private readonly store: Store<AppState>) {
  }

  addContact() {
    this.companyContactPersonForm$.pipe(take(1)).subscribe((form) => {
      this.store.dispatch(submitCompanyContactPersonForm({ formValues: form.value }));
      this.store.dispatch(postDetailsCompany());
    });
  }
}
