<div class="user-container">
  <a [routerLink]="'/'">
    <span></span>
    <mat-icon>arrow_back</mat-icon>
  </a>
  <div *ngIf="user">
    <app-profile-overview
      [user]="user"
      [connection]="connection"
    ></app-profile-overview>
  </div>
</div>
