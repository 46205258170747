<div class="container position-relative color-background-primary">
  <ng-container *ngIf="eventState$ | async as eventState">
    <div *ngIf="eventState.events?.length > 0">
      <div class="must-read-container">
        <h2 class="align-center text-white">Events</h2>
        <a class="back-arrow color-white" [routerLink]="'/'">
          <span></span>
          <mat-icon>arrow_back</mat-icon>
        </a>
        <div class="bevel"></div>

        <div class="align-center">
          <div class="underlined-title purple-background">
            <p class="text-white">Upcoming event</p>
            <div></div>
          </div>
        </div>
        <div class="preview-must-read">
          <div class="align-center">
            <img
              *ngIf="eventState.events[0].thumbnailUrl"
              class="preview-image"
              src="{{ eventState.events[0].thumbnailUrl }}"
            />
          </div>
          <div class="preview-date">
            {{ eventState.events[0].eventDate | date : "d LLLL y" }}
          </div>
          <div
            class="event-time"
            *ngIf="
              eventState.events[0].beginTime && eventState.events[0].endTime
            "
          >
            {{ toTime(eventState.events[0].beginTime) }} -
            {{ toTime(eventState.events[0].endTime) }} CET
          </div>
          <div class="preview-title">
            {{ eventState.events[0].title }}
          </div>
          <div
            [class.preview-date]="eventState.events[0].eventUrl"
            [class.preview-content]="!eventState.events[0].eventUrl"
          >
            <div [innerHTML]="eventState.events[0].description"></div>
          </div>
          <div class="align-center">
            <a
              *ngIf="
                !eventState.events[0].companyId && eventState.events[0].eventUrl
              "
              (click)="openEvent(eventState.events[0].eventUrl)"
            >
              <button
                class="btn-smn must-read-button btn-sm"
                mat-flat-button
                color="primary"
              >
                Read & register
              </button>
            </a>
            <a
              *ngIf="
                (eventState.events[0].companyId &&
                  eventState.events[0].eventUrl) ||
                !eventState.events[0].eventUrl
              "
              [routerLink]="
                '/' + AppRoutes.EVENT + '/' + eventState.events[0].id
              "
            >
              <button
                class="btn-smn must-read-button btn-sm"
                mat-flat-button
                color="primary"
              >
                Read & register
              </button>
            </a>
          </div>
        </div>
      </div>
      <div class="stories-container stories-background-color">
        <div class="align-center">
          <p class="more-inspiring">More events</p>
        </div>
        <app-eventcards
          [viewAllButton]="false"
          [events]="eventState.events.slice(1)"
        ></app-eventcards>
      </div>
    </div>
    <div *ngIf="eventState.events?.length == 0">
      <div class="align-center">
        <mat-card appearance="outlined" class="event-card float-none">
          <mat-card-content>
            No planned events at this moment. <br />
            Please check again later.
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div
      class="stories-container stories-background-color previous-container"
      *ngIf="eventState.previousEvents?.length > 0"
    >
      <div class="align-center">
        <p class="more-inspiring">Previous events</p>
      </div>
      <app-eventcards
        [viewAllButton]="false"
        [events]="eventState.previousEvents"
      ></app-eventcards>
    </div>
  </ng-container>
</div>
