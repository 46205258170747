<mat-dialog-content *ngIf="company">
  <div class="row m-0 p-0 col-12 col-md-10 mx-auto overflow-hidden">
    <div class="col-12 mt-3 mb-4 d-flex justify-content-between">
      <button
        mat-icon-button
        mat-dialog-close
        color="primary"
        class="close-button"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <app-company-details
      [company]="company"
      class="w-100"
    ></app-company-details>
  </div>
</mat-dialog-content>
