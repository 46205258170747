<ng-container>
  <div class="step h-100">
    <div class="container step-content h-100">
      <div class="onboarding-image">
        <div class="circle"></div>
        <img class="visual" src="../../../../../assets/images/onboarding/visual_1.svg"/>
      </div>
      <div class="intro-text-div">
        <div class="main-text text-center">
          <div class="underlined-title">
            <p class="stepper-title text-primary font-weight-bold m-0">Who's your match?</p>
            <div></div>
          </div>
          <br>
          <p class="intro-text">Answer the next questions and find your best match.</p>
        </div>
        <div class="button-area w-100 onboarding-image-next">
          <button
            mat-flat-button
            color="primary"
            type="submit"
            class="btn-sm"
            (click)="submit()">
            Let's get started
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
