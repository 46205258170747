import { createAction, props } from '@ngrx/store';
import { CompanyContactPersonForm } from './forms.companyContactPerson.model';

export enum CompanyContactPersonFormActionTypes {
  ADD_PHOTO = '[CompanyForms] Add company contact person photo',
  ADD_PHOTO_SUCCESS = '[CompanyForms] Add company contact person photo success',
  DELETE_PHOTO = '[CompanyForms] Delete company contact person photo',
  SUBMIT = '[CompanyForms] Submit company contact person',
}

export const submitCompanyContactPersonForm =
  createAction(CompanyContactPersonFormActionTypes.SUBMIT, props<{ formValues: CompanyContactPersonForm }>());
export const addCompanyContactPersonPhoto =
  createAction(CompanyContactPersonFormActionTypes.ADD_PHOTO, props<{ photo: File }>());
export const addCompanyContactPersonPhotoSuccess =
  createAction(CompanyContactPersonFormActionTypes.ADD_PHOTO_SUCCESS, props<{ referenceUrl: string }>());
export const deleteCompanyContactPersonPhoto =
  createAction(CompanyContactPersonFormActionTypes.DELETE_PHOTO);
