import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import getVideoId from 'get-video-id';

@Pipe({
  name: 'safeVideo',
})
export class SafeVideoPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(url: string): SafeResourceUrl {
    if (!url) {
      return '';
    }
    let iframeLink = '';
    const videoData = getVideoId(url);
    if (videoData.service === 'youtube') {
      iframeLink = 'https://www.youtube.com/embed/' + videoData.id;
    } else if (videoData.service === 'vimeo') {
      iframeLink = 'https://player.vimeo.com/video/' + videoData.id;
    } else {
      return '';
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(iframeLink);
  }
}
