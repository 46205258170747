import {Action, createReducer, on} from '@ngrx/store';
import {createFormGroupState, FormGroupState, formStateReducer, setValue, updateGroup, validate, } from 'ngrx-forms';
import {required} from 'ngrx-forms/validation';
import {UserFormsState} from '../userForms.model';
import {clearEducationForm, prefillEducationForm, submitEducationForm} from './forms.editEducation.actions';
import {EducationForm, EducationType} from './forms.education.model';

export const initialEducationForm: EducationForm = {
  educationType: EducationType.Current_Education,
  educationTypes: Object.values(EducationType).map((x) => x),
  school: '',
  study: '',
  startDate: '',
  endDate: '',
  index: -1,
};

export const initialEducationFormState = createFormGroupState<EducationForm>('EDUCATION_FORM', initialEducationForm);

const updateForm = createReducer<FormGroupState<EducationForm>>(
  initialEducationFormState,
  on(prefillEducationForm, (state, action) =>
    updateGroup<EducationForm>(state, {
      school: setValue(action.education.name),
      study: setValue(action.education.study),
      startDate: setValue(action.education.startDate ? action.education.startDate.toString() : ''),
      endDate: setValue(action.education.endDate ? action.education.endDate.toString() : ''),
      educationType: setValue(action.education.endDate ? EducationType.Previous_Education.toString() :
        EducationType.Current_Education.toString()),
      index: setValue(action.index),
    })
  ),
  on(clearEducationForm, (state) => initialEducationFormState),
  on(submitEducationForm, (state) => initialEducationFormState)
);

const validateForm = (form: FormGroupState<EducationForm>, state: UserFormsState) => {
  const educationType = form.controls.educationType.value;

  return updateGroup<EducationForm>({
    school: validate(required),
    study: validate(required),
  })(form);
};

export const educationFormReducer = (state: UserFormsState, action: Action) => {
  const educationForm: FormGroupState<EducationForm> = state.educationForm;

  // use the out of the box reducer to process form actions into the store
  let form = formStateReducer(educationForm, action);

  // Process non-form actions
  form = updateForm(form, action);

  // form validation using the new state
  form = validateForm(form, state);

  return form === educationForm ? educationForm : form;
};
