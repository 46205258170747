import { Component, OnInit, Input } from '@angular/core';
import { UserCompany } from 'src/app/ngrx-store/components/user/user.model';

@Component({
  selector: 'app-profile-work-experience',
  templateUrl: './profile-work-experience.component.html',
  styleUrls: ['./profile-work-experience.component.scss']
})
export class ProfileWorkExperienceComponent implements OnInit {

  @Input() companies: UserCompany[];
  @Input() self = false;

  constructor() { }

  ngOnInit(): void {
  }

}
