<div *ngFor="let connection of connections">
  <app-connection-list-item
    [connection]="connection"
    (onOpenProfile)="openProfile(connection.user, connection)"
    (onRemoveConnection)="removeConnection(connection.user)"
  ></app-connection-list-item>
</div>
<div *ngFor="let user of noConnections">
  <app-connection-list-item
    [user]="user"
    [isSelf]="user.id == self?.id"
    (onOpenProfile)="openProfile(user)"
    (onAddConnection)="addConnection(user)"
  ></app-connection-list-item>
</div>
<div class="align-center">
  <ng-container *ngIf="connections?.length === page * 9 + 9">
    <button
      class="mx-auto btn-sm"
      mat-flat-button
      color="primary"
      (click)="showMore.emit()"
    >
      Show more
    </button>
  </ng-container>
</div>

<p *ngIf="!(connections?.length > 0 || noConnections.length > 0)">No results</p>
