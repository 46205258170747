import { createReducer, on } from '@ngrx/store';
import { AdminState } from './admin.model';
import { addCompany, deleteCompany, getUsersSuccess, setRights } from './admin.actions';
import { User } from '../user/user.model';
import { deleteUserId } from '../../requests/user/post-user/post-user.actions';

export const initialAdminState: AdminState = {
  allUsers: undefined,
};

export const adminReducer = createReducer<AdminState>(
  initialAdminState,
  on(getUsersSuccess, (state, action) => {
    return {
      ...state,
      allUsers: action.users,
    };
  }),
  on(deleteUserId, (state, action) => {
    if (!action.id || !state.allUsers) {
      return state;
    }

    const allUsers: User[] = JSON.parse(JSON.stringify(state.allUsers));

    return {
      ...state,
      allUsers: allUsers?.filter(u => u.id !== action.id),
    };
  }),
  on(setRights, (state, action) => {
    if (!action.id || !state.allUsers) {
      return state;
    }

    const allUsers: User[] = JSON.parse(JSON.stringify(state.allUsers));
    const userIndex = allUsers?.findIndex(u => u.id === action.id);
    allUsers[userIndex].isAdmin = action.rights.isAdmin;
    allUsers[userIndex].isPartner = action.rights.isPartner;

    return {
      ...state,
      allUsers,
    };
  }),
  on(deleteCompany, (state, action) => {
    if (!action.id || !state.allUsers) {
      return state;
    }

    const allUsers: User[] = JSON.parse(JSON.stringify(state.allUsers));
    const userIndex = allUsers?.findIndex(u => u.id === action.id);
    allUsers[userIndex].companyName = '';

    return {
      ...state,
      allUsers,
    };
  }),
  on(addCompany, (state, action) => {
    if (!action.id || !state.allUsers) {
      return state;
    }

    const allUsers: User[] = JSON.parse(JSON.stringify(state.allUsers));
    const userIndex = allUsers?.findIndex(u => u.id === action.id);
    allUsers[userIndex].companyName = action.companyName;

    return {
      ...state,
      allUsers,
    };
  })
);
