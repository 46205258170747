import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, ScannedActionsSubject } from '@ngrx/store';
import { selectAdminPartner } from 'src/app/ngrx-store/components/user/user.selectors';
import { AppState } from 'src/app/ngrx-store/app.state';
import {
  getUserExcel,
  getUserExcelSuccess,
  adminRequestError,
  setDigitalArea,
  setIndustry,
  getUsers,
  setRights,
  getUsersSuccess,
  getPartnerExcel,
} from 'src/app/ngrx-store/components/admin/admin.actions';
import { Subject, Subscription } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  getIndustries,
  getDigitalAreas,
} from 'src/app/ngrx-store/requests/attributes/get-userEntities.actions';
import {
  selectIndustries,
  selectDigitalAreas,
} from 'src/app/ngrx-store/components/userEntities/userEntities.selectors';
import { selectAllUsers } from 'src/app/ngrx-store/components/admin/admin.selectors';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  deleteUserId,
} from 'src/app/ngrx-store/requests/user/post-user/post-user.actions';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../../ngrx-store/components/user/user.model';
import {
  deleteCompany,
  addCompany,
  getPartnerExcelSuccess,
} from '../../ngrx-store/components/admin/admin.actions';
import { CompanySelectionDialogComponent } from '../../shared/components/company-selection-dialog/company-selection-dialog.component';
import { getCompanies, getCompaniesSuccess } from '../../ngrx-store/requests/company/get-company/get-company.actions';
import { selectCompanies } from '../../ngrx-store/components/company/company.selectors';
import { Company } from '../../ngrx-store/components/company/company.model';
import {
  deleteCompanyId,
} from '../../ngrx-store/requests/company/delete-company/delete-company.actions';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss'],
})
export class AdminPanelComponent implements OnInit, OnDestroy {
  admin$ = this.store.select(selectAdminPartner);

  industries$ = this.store.select(selectIndustries);
  digitalAreas$ = this.store.select(selectDigitalAreas);
  allUsers$ = this.store.select(selectAllUsers);
  allCompanies$ = this.store.select(selectCompanies);
  shownUsers = 20;
  shownCompanies = 20;

  loadingExcel = false;
  loadingPartnerExcel = false;
  loadingUsers = true;
  loadingCompanies = false;
  industriesCollapsed = true;
  digitalAreasCollapsed = true;
  userRightsCollapsed = true;
  companiesCollapsed = true;

  subscriptions = new Subscription();

  newDigitalArea = '';
  newIndustry = '';
  userFilter = '';
  companyFilter = '';
  users: User[] = [];
  filteredUsers: User[] = [];
  companies: Company[] = [];
  filteredCompanies: Company[] = [];

  userFilterUpdate = new Subject<string>();
  companiesFilterUpdate = new Subject<string>();

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private actions$: ScannedActionsSubject,
    private snackbar: MatSnackBar
  ) {
    this.subscriptions.add(this.actions$
      .pipe(ofType(getUserExcelSuccess))
      .subscribe(() => (this.loadingExcel = false)));
    this.subscriptions.add(this.actions$
      .pipe(ofType(getPartnerExcelSuccess))
      .subscribe(() => (this.loadingPartnerExcel = false)));
    this.subscriptions.add(this.actions$
      .pipe(ofType(getUsersSuccess))
      .subscribe((users) => {
        if (users.users) {
          setTimeout(() => (this.loadingUsers = false), 200);
        }
      }));
    this.subscriptions.add(this.actions$
      .pipe(ofType(getCompaniesSuccess))
      .subscribe((companies) => {
        if (companies.companies) {
          setTimeout(() => (this.loadingCompanies = false), 200);
        }
      })
    );
    this.subscriptions.add(
      this.actions$.pipe(ofType(adminRequestError)).subscribe((e) => {
        this.loadingExcel = false;
        this.snackbar.open(
          'An error occurred trying to use an admin function: ' + e.error,
          '',
          {
            duration: 5000,
            panelClass: ['failed-snackbar'],
          }
        );
      })
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.store.dispatch(getIndustries());
    this.store.dispatch(getDigitalAreas());

    this.subscriptions.add(this.allUsers$.subscribe((users) => {
      if (users) {
        this.users = users;
        this.filterUsers();
      }
    }));

    this.subscriptions.add(this.allCompanies$.subscribe((companies) => {
      if (companies) {
        this.companies = companies;
        this.filterCompanies();
      }
    }));

    this.subscriptions.add(
      this.userFilterUpdate.pipe(
        debounceTime(400),
        distinctUntilChanged())
        .subscribe(value => {
          this.userFilter = value;
          this.filterUsers();
        })
    );

    this.subscriptions.add(
      this.companiesFilterUpdate.pipe(
        debounceTime(400),
        distinctUntilChanged())
        .subscribe(value => {
          this.companyFilter = value;
          this.filterCompanies();
        })
    );
  }

  getExcel() {
    this.store.dispatch(getUserExcel());
    this.loadingExcel = true;
  }

  getPartnerExcel() {
    this.store.dispatch(getPartnerExcel());
    this.loadingPartnerExcel = true;
  }

  updateIndustry(id: number, name: string) {
    this.store.dispatch(setIndustry({ industry: { id, name } }));
  }

  deleteIndustry(id: number) {
    this.store.dispatch(setIndustry({ industry: { id, name: '' } }));
  }

  addIndustry() {
    this.store.dispatch(
      setIndustry({ industry: { id: -1, name: this.newIndustry } })
    );
  }

  updateDigitalArea(id: number, name: string) {
    this.store.dispatch(setDigitalArea({ digitalArea: { id, name } }));
  }

  deleteDigitalArea(id: number) {
    this.store.dispatch(setDigitalArea({ digitalArea: { id, name: '' } }));
  }

  addDigitalArea() {
    this.store.dispatch(
      setDigitalArea({ digitalArea: { id: -1, name: this.newDigitalArea } })
    );
  }

  setRights(
    id: string,
    rights: { isPartner: boolean; isAdmin: boolean },
    isPartnerEl: MatCheckbox,
    isAdminEl: MatCheckbox
  ) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message:
          'Are you sure you want to change the rights for this user?',
        callback: (confirmed: boolean) => {
          if (!confirmed) {
            return;
          }

          if (rights.isAdmin) {
            isPartnerEl.checked = false;
          }
          if (rights.isPartner) {
            isAdminEl.checked = false;
          }
          this.store.dispatch(setRights({ id, rights }));
        },
      },
    });

  }

  allUsers() {
    this.userRightsCollapsed = !this.userRightsCollapsed;

    if (this.loadingUsers) {
      this.store.dispatch(getUsers());
    }
  }

  deleteUser(id: string) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message:
          'Are you sure you want to permanently delete this account? It will lose all of the connections and settings.',
        callback: (confirmed: boolean) => {
          if (confirmed) {
            this.store.dispatch(deleteUserId({ id }));
          }
        },
      },
    });
  }

  removeFromCompany(id: string) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message:
          'Are you sure you want to delete this user from the company? (The company will still exist)',
        callback: (confirmed: boolean) => {
          if (confirmed) {
            this.store.dispatch(deleteCompany({ id }));
          }
        },
      },
    });
  }

  addToCompany(id: string) {
    this.dialog.open(CompanySelectionDialogComponent, {
      data: {
        callback: (companyId: string, companyName: string) => {
          if (companyId) {
            this.store.dispatch(addCompany({ id, companyId, companyName }));
          }
        },
      },
    });
  }

  filterUsers() {
    this.filteredUsers = this.users.filter((user) => {
      return (
        (`${user.firstName} ${user.lastName} ${user.email}`)
          .toLowerCase()
          .indexOf(this.userFilter.toLowerCase()) !== -1
      );
    });
    this.filteredUsers = this.filteredUsers.sort((u1, u2) =>
      !u1.companyName ? 1 : -1
    );

    this.filteredUsers = this.filteredUsers.slice(0, this.shownUsers);
  }

  public triggerRefilterUsers() {
    this.filterUsers();
  }

  public triggerRefilterCompanies() {
    this.filterCompanies();
  }

  filterCompanies() {
    this.filteredCompanies = this.companies.filter((company) => {
      return (
        company.name
          .toLowerCase()
          .indexOf(this.companyFilter.toLowerCase()) !== -1
      );
    });
  }

  allCompanies() {
    this.companiesCollapsed = !this.companiesCollapsed;
    this.loadingCompanies = true;
    this.store.dispatch(getCompanies());
  }

  deleteCompany(id: string) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message:
          'Are you sure you want to permanently delete this company? It will lose all of the connections and settings.',
        callback: (confirmed: boolean) => {
          if (confirmed) {
            this.store.dispatch(deleteCompanyId({ id }));
          }
        },
      },
    });
  }
}
