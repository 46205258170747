import { createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';
import { VacancyFormsState } from './vacancyForm.model';

const selectVacancyFormsState = (state: AppState) => state.vacancyFormsState;

export const selectCreateVacancyForm = createSelector(
  selectVacancyFormsState,
  (state: VacancyFormsState) => state.createVacancyForm
);

export const selectVacancyFilterForm = createSelector(
  selectVacancyFormsState,
  (state: VacancyFormsState) => state.vacancyFilterForm
);
