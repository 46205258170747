<mat-label
  ><b>{{ label }}</b></mat-label
>
<input
  class="d-none"
  type="file"
  onclick="this.value=null;"
  (change)="onFileSelected($event)"
  #fileInput
/>

<div
  class="my-3 text-primary font-weight-bold d-flex justify-content-between"
  *ngIf="!icon"
>
  <a
    *ngIf="uploadedReference"
    class="pointer"
    [href]="safeReference"
    target="_blank"
    ><u>{{ fileName }}</u></a
  >
  <div *ngIf="!uploadedReference" class="pointer" (click)="fileInput.click()">
    <u>{{ uploadButtonText }}</u>
  </div>
  <mat-icon
    *ngIf="uploadedReference && canDelete"
    class="pointer"
    (click)="onFileDeleted()"
    >delete</mat-icon
  >
</div>

<div class="upload-image" *ngIf="icon" (click)="fileInput.click()">
  <i class="fas fa-image"></i>
  <span>Photo</span>
</div>

<mat-error>
  {{ helpText }}
</mat-error>
