import {createAction, props} from '@ngrx/store';
import {User} from '../../../components/user/user.model';
export enum DismissMatchActionTypes {
  POST = '[Match] Post dismiss match',
  SUCCESS = '[Match] Post dismiss match success',
  ERROR = '[Match] Post dismiss match error',
}

export const postDismissMatch = createAction(DismissMatchActionTypes.POST, props<{
  matchId: string
}>());
export const postDismissMatchSuccess = createAction(DismissMatchActionTypes.SUCCESS);
export const postDismissMatchError = createAction(DismissMatchActionTypes.ERROR, props<{ error: string }>());
