import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { FormControlState, createFormControlState, setValue } from 'ngrx-forms';
import { AppState } from 'src/app/ngrx-store/app.state';
import { UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject, ReplaySubject } from 'rxjs';
import { NgrxFormElementDirective } from '../ngrx-form-element/ngrx-form-element.directive';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-input-country',
  templateUrl: './input-country.component.html',
})
export class InputCountryComponent extends NgrxFormElementDirective
  implements OnInit {
  @Input() type = 'text';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() emptyOption = '';

  @Input() requiredErrorMessage = '';
  @Input() patternErrorMessage = '';
  @Input() emailErrorMessage = '';

  @Output() selectionChange: EventEmitter<MatSelectChange> = new EventEmitter();

  countries: { name: string; code: string }[] = [];
  public filteredCountries: { name: string; code: string }[] = [];

  public filter: UntypedFormControl = new UntypedFormControl();
  protected onDestroy = new Subject<void>();

  constructor(private readonly store: Store<AppState>) {
    super();
  }
  ngOnInit(): void {
    i18nIsoCountries.registerLocale(
      require('i18n-iso-countries/langs/en.json')
    );
    const isoCountries = i18nIsoCountries.getNames('en');
    Object.keys(isoCountries).map((key, index) => {
      this.countries.push({ name: isoCountries[key].toString(), code: key });
    });

    this.countries.sort((n1, n2) => n1.name.localeCompare(n2.name));
    this.filteredCountries = this.countries;

    // listen for search field value changes
    this.filter.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.filterCountries();
    });

    if (this.emptyOption) {
      this.formControl = setValue(this.formControl, this.emptyOption);
    }
  }

  private filterCountries() {
    if (!this.countries) {
      return;
    }

    // get the search keyword
    let search = this.filter.value;
    if (!search) {
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCountries = this.countries.filter(
      (country) => country.name.toLowerCase().indexOf(search) > -1
    );
  }
}
