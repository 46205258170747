import { Action, createReducer, on } from '@ngrx/store';
import { addArrayControl, box, createFormGroupState, FormGroupState, formStateReducer, removeArrayControl, setValue, updateGroup, validate } from 'ngrx-forms';
import { CompanyDetailsForm } from './forms.companyDetails.model';
import { CompanyFormsState } from '../companyForms.model';
import { getCompanySuccess } from '../../../requests/company/get-company/get-company.actions';
import { Company } from '../../company/company.model';
import { addAmbassador, deleteAmbassador, addCompanyLogoSuccess, addCompanyPhotoSuccess, deleteCompanyPhoto } from './forms.companyDetails.actions';
import _ = require('underscore');
import { postNewCompanySuccess } from 'src/app/ngrx-store/requests/company/post-company/post-company.actions';
import { maxLength, pattern, required, requiredTrue } from 'ngrx-forms/validation';

const urlRegEx = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;


export const initialCompanyDetailsForm: CompanyDetailsForm = {
  name: '',
  logo: '',
  introduction: '',
  information: '',
  careers: '',
  industry: undefined,
  country: '',
  videoUrl: '',
  websiteUrl: '',
  linkedIn: '',
  instagram: '',
  facebook: '',
  twitter: '',
  photos: [],
  ambassadors: []
};

export const initialCompanyDetailsFormState = createFormGroupState<CompanyDetailsForm>(
  'COMPANY_DETAILS_FORM',
  initialCompanyDetailsForm
);

const updateForm = createReducer<FormGroupState<CompanyDetailsForm>>(
  initialCompanyDetailsFormState,
  on(postNewCompanySuccess, (state, action) => prefillForm(state, action.company)),
  on(getCompanySuccess, (state, action) => prefillForm(state, action.company)),
  on(addCompanyLogoSuccess, (state, action) => updateGroup<CompanyDetailsForm>(state, {
    logo: setValue(action.reference)
    })
  ),
  on(addCompanyPhotoSuccess, (state, action) => updateGroup<CompanyDetailsForm>(state, {
    photos: addArrayControl(action.companyPhoto)
    })
  ),
  on(deleteCompanyPhoto, (state, action) => updateGroup<CompanyDetailsForm>(state, {
    photos: removeArrayControl(
      _.findIndex(state.value.photos, (p) => p.photoUrl === action.companyPhoto.photoUrl)),
    })
  ),
  on(addAmbassador, (state, action) => updateGroup<CompanyDetailsForm>(state, {
    ambassadors: addArrayControl(action.ambassador)
    })
  ),
  on(deleteAmbassador, (state, action) => updateGroup<CompanyDetailsForm>(state, {
    ambassadors: removeArrayControl(
      _.findIndex(state.value.ambassadors, (s) => s.user.id === action.ambassador.user.id)),
    })
  )
);

const prefillForm = (state: FormGroupState<CompanyDetailsForm>, company: Company) => {
  if (!company) {
    return state;
  }

  return updateGroup<CompanyDetailsForm>(state, {
    name: setValue(company.name),
    logo: setValue(company.logo),
    introduction: setValue(company.introduction),
    information: setValue(company.information),
    careers: setValue(company.careers),
    industry: setValue(company.industry ? box(company.industry) : undefined),
    country: setValue(company.country),
    websiteUrl: setValue(company.websiteUrl),
    videoUrl: setValue(company.videoUrl),
    ambassadors: setValue(company.ambassadors),
    linkedIn: setValue(company.socialMedia?.linkedinSocialMedia ?? ''),
    instagram: setValue(company.socialMedia?.instagramSocialMedia ?? ''),
    facebook: setValue(company.socialMedia?.facebookSocialMedia ?? ''),
    twitter: setValue(company.socialMedia?.twitterSocialMedia ?? ''),
    photos: setValue(company.photos)
  });
};

const validateForm = (form: FormGroupState<CompanyDetailsForm>, state: CompanyFormsState) => {
  return updateGroup<CompanyDetailsForm>({
    name: validate(required),
    introduction: validate(required, maxLength(200)),
    information: validate(required, maxLength(1000)),
    careers: validate(required, maxLength(1000)),
    industry: validate(required),
    country: validate(required),
    websiteUrl: validate(pattern(urlRegEx)),
    videoUrl: validate(pattern(/youtube|vimeo/)),
    linkedIn: validate(pattern(/linkedin/)),
    facebook: validate(pattern(/facebook/)),
    instagram: validate(pattern(/instagram/)),
    twitter: validate(pattern(/twitter/)),
  })(form);
};

export const companyDetailsFormReducer = (state: CompanyFormsState, action: Action) => {
  const companyDetailsForm: FormGroupState<CompanyDetailsForm> = state.companyDetailsForm;

  // use the out of the box reducer to process form actions into the store
  let form = formStateReducer(companyDetailsForm, action);

  // Process non-form actions
  form = updateForm(form, action);

  // form validation using the new state
  form = validateForm(form, state);

  return form === companyDetailsForm ? companyDetailsForm : form;
};
