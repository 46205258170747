<div *ngIf="onboardingModel$ | async as onboardingModel">
  <div class="">
    <p class="font-weight-bold">Question {{ onboardingModel.activeQuestionIndex + 1 }}:<br></p>
    <table style="width: 100%">
      <tr>
    <td *ngFor="let question of onboardingModel.questions; let index = index">
      <ng-container *ngIf="onboardingModel.activeQuestionIndex < index; else completed">
        <div class="notDone" style="height: 6px"></div>
      </ng-container>
      <ng-template #completed>
        <div class="completed" style="height: 6px"></div>
      </ng-template>
    </td>
    </table>
  </div>
</div>
