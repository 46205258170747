import { Action, createReducer, on } from '@ngrx/store';
import { createFormGroupState, FormGroupState, formStateReducer, updateGroup, validate, reset } from 'ngrx-forms';
import { getUserSuccess } from 'src/app/ngrx-store/requests/user/get-user/get-user.actions';
import { User } from '../../user/user.model';
import { UserFormsState } from '../userForms.model';
import { MailPreferencesForm } from './userForms.mailPreferences.model';
import { submitMailPreferencesForm } from './userForms.mailPreferences.actions';

export const initialMailPreferences: MailPreferencesForm = {
  newsletter: true,
  platform: true,
  shareData: false,
};

export const initialMailPreferencesFormState = createFormGroupState<MailPreferencesForm>(
  'MAIL_PREFERENCES_FORM',
  initialMailPreferences
);

const updateForm = createReducer<FormGroupState<MailPreferencesForm>>(
  initialMailPreferencesFormState,
  on(getUserSuccess, (state, action) => prefillForm(state, action.user)),
  on(submitMailPreferencesForm, (state) => ({
    ...reset(state)
  })),
);

const validateForm = (form: FormGroupState<MailPreferencesForm>, state: UserFormsState) => {
  return updateGroup<MailPreferencesForm>({
  })(form);
};

const prefillForm = (state: FormGroupState<MailPreferencesForm>, user: User) => {
  if (!user) {
    return state;
  }

  return updateGroup<MailPreferencesForm>(state, {
  });
};

export const mailPreferencesFormReducer = (state: UserFormsState, action: Action) => {
  const mailPreferencesForm: FormGroupState<MailPreferencesForm> = state.mailPreferencesForm;

  // use the out of the box reducer to process form actions into the store
  let form = formStateReducer(mailPreferencesForm, action);

  // Process non-form actions
  form = updateForm(form, action);

  // form validation using the new state
  form = validateForm(form, state);

  return form === mailPreferencesForm ? mailPreferencesForm : form;
};
