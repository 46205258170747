import { Component, Input } from '@angular/core';
import { AppRoutes } from 'src/app/app-routing.model';
import { Vacancy } from 'src/app/ngrx-store/components/vacancy/vacancy.model';
import { Company } from '../../../ngrx-store/components/company/company.model';

@Component({
  selector: 'app-vacancycards',
  templateUrl: './vacancycards.component.html',
  styleUrls: ['./vacancycards.component.scss'],
})
export class VacancycardsComponent {
  public AppRoutes = AppRoutes;
  @Input() vacancies: Vacancy[];
  @Input() companies: Company[];
  @Input() viewAllButton = true;

  public getCompanyForVacancy(vacancy: Vacancy) {
    return this.companies.find((company) => company.id === vacancy.companyId);
  }
}
