import { Component, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../ngrx-store/app.state';
import { selectOnboardingModel } from '../../ngrx-store/components/onboarding/onboarding.selectors';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingComponent {
  public onboardingModel$ = this.store.pipe(select(selectOnboardingModel));

  constructor(private store: Store<AppState>) {}
}
