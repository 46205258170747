import { CompanyEventForm } from './forms.companyEvent.model';
import { createFormGroupState, FormGroupState, formStateReducer, setValue, updateGroup, validate } from 'ngrx-forms';
import { Action, createReducer, on } from '@ngrx/store';
import { CompanyFormsState } from '../companyForms.model';
import { required, pattern, maxLength } from 'ngrx-forms/validation';
import { deleteCompanyEventPhoto, addCompanyEventPhotoSuccess, loadCompanyEvent, clearCompanyEventForm, submitCompanyEventForm } from './forms.companyEvent.actions';
import { Guid } from 'guid-typescript';

const urlRegEx = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export const initialCompanyEventForm: CompanyEventForm = {
  id: Guid.create().toString(),
  name: '',
  eventDate: '',
  beginTime: '',
  endTime: '',
  description: '',
  imageUrl: '',
  link: '',
};

export const initialCompanyEventFormState = createFormGroupState<CompanyEventForm>(
  'CREATE_COMPANY_FORM',
  initialCompanyEventForm
);

const updateForm = createReducer<FormGroupState<CompanyEventForm>>(
  initialCompanyEventFormState,
  on(clearCompanyEventForm, (state) => initialCompanyEventFormState),
  on(submitCompanyEventForm, (state) => initialCompanyEventFormState),
  on(addCompanyEventPhotoSuccess, (state, action) => updateGroup<CompanyEventForm>(state, {
    imageUrl: setValue(action.referenceUrl)
    })
  ),
  on(deleteCompanyEventPhoto, (state, action) => updateGroup<CompanyEventForm>(state, {
    imageUrl: setValue('')
    })
  ),
  on(loadCompanyEvent, (state, action) => updateGroup<CompanyEventForm>(state, {
    id: setValue(action.event.id),
    name: setValue(action.event.title),
    eventDate: setValue([
      ('0' + (new Date(action.event.eventDate).getDate())).slice(-2),
      ('0' + ((new Date(action.event.eventDate).getMonth() + 1))).slice(-2),
      (new Date(action.event.eventDate)).getFullYear()
    ].join('-')),
    beginTime: setValue(action.event.beginTime),
    endTime: setValue(action.event.endTime),
    description: setValue(action.event.content),
    imageUrl: setValue(action.event.imageUrl),
    link: setValue(action.event.originalUrl),
    })
  ),
);

const validateForm = (form: FormGroupState<CompanyEventForm>, state: CompanyFormsState) => {
  return updateGroup<CompanyEventForm>({
    name: validate(required),
    description: validate(required, maxLength(1000)),
    eventDate: validate(required, pattern(/\d\d\-\d\d\-\d\d\d\d/)),
    beginTime: validate(required, pattern(/\d\d:\d\d/)),
    endTime: validate(required, pattern(/\d\d:\d\d/)),
    link: validate(required, pattern(urlRegEx)),
  })(form);
};

export const companyEventFormReducer = (state: CompanyFormsState, action: Action) => {
  const companyEventForm: FormGroupState<CompanyEventForm> = state.companyEventForm;

  // use the out of the box reducer to process form actions into the store
  let form = formStateReducer(companyEventForm, action);

  // Process non-form actions
  form = updateForm(form, action);

  // form validation using the new state
  form = validateForm(form, state);

  return form === companyEventForm ? companyEventForm : form;
};
