<ng-container *ngIf="userModel$ | async as userModel">
  <ng-container *ngIf="userModel.user as user">
    <div class="container">
      <div class="header-container">
        <p class="align-center color-primary">Edit profile picture</p>
        <a class="back-arrow color-primary" [routerLink]="'../edit'">
          <span></span>
          <mat-icon>arrow_back</mat-icon>
        </a>
        <div class="bevel"></div>
      </div>
      <div class="align-center">
        <mat-icon
          *ngIf="!user?.avatarRef"
          class="avatar h-100 w-100 d-flex justify-content-center align-items-center"
          >account_circle
        </mat-icon>
        <img
          *ngIf="user?.avatarRef"
          [src]="user.avatarRef | safe: 'url'"
          class="profile-picture"
        />

        <br />
        <input
          class="display-none"
          type="file"
          (change)="onFileSelected($event)"
          #fileInput
        />
        <button
          #uploadButton
          class="btn-sm mt-3 mb-3"
          mat-flat-button
          color="primary"
          (click)="fileInput.click()"
        >
          Upload
        </button>

        <p class="error-text" #helpText></p>
      </div>
    </div>
  </ng-container>
</ng-container>
