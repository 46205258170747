import { createAction, props } from '@ngrx/store';
import { PersonalDetailsForm } from './forms.personalDetails.model';

export enum PersonalDetailsFormActionTypes {
  RESET_FIELDS = '[UserForms] Reset background fields',
  ADD_COMPANY = '[UserForms] Add to company background list',
  SET_COMPANY = '[UserForms] Set company background list',
  ADD_EDUCATION = '[UserForms] Add to education background list',
  SET_EDUCATION = '[UserForms] Set education background list',
  SUBMIT = '[UserForms] Submit personal details',
}

export const addCompanyBackground = createAction(PersonalDetailsFormActionTypes.ADD_COMPANY,
  props<{ name: string, position: string, experience: string, startDate: string }>());
export const setCompanyBackground = createAction(PersonalDetailsFormActionTypes.SET_COMPANY,
  props<{ companies: [{ name: string, position: string, yearsExperience: string, startDate: string }]; }>());
export const addEducationBackground = createAction(PersonalDetailsFormActionTypes.ADD_EDUCATION,
  props<{ name: string, study: string, startDate: string }>());
export const setEducationBackground = createAction(PersonalDetailsFormActionTypes.SET_EDUCATION,
  props<{ educations: [{ name: string, study: string, startDate: string, index: number }] }>());

export const submitPersonalDetailsForm = createAction(PersonalDetailsFormActionTypes.SUBMIT, props<{ formValues: PersonalDetailsForm }>());
