import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from 'src/app/ngrx-store/components/user/user.model';
import {
  Message,
  ReactionType,
} from '../../../ngrx-store/components/message/message.model';
import { AppRoutes } from '../../../app-routing.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
  readonly AppRoutes = AppRoutes;
  @Input() userId: string;
  @Input() message: Message;
  @Input() expandedReplies = false;
  @Input() hideReplies = false;
  @Output() onOpenProfile = new EventEmitter<User>();
  @Output() deleteMessage = new EventEmitter<string>();
  @Output() addReaction = new EventEmitter<{
    messageId: string;
    reaction: ReactionType;
  }>();

  constructor(private router: Router) {}

  public openProfile(user: User) {
    this.onOpenProfile.emit(user);
  }

  public emitAddReaction(messageId: string, reaction: ReactionType) {
    this.addReaction.emit({ messageId, reaction });
  }

  public goToThread(messageId: string) {
    this.router.navigateByUrl(`/${AppRoutes.CHATS}/${messageId}`);
  }

  public emitDeleteMessage(messageId: string, event?: any) {
    if (event) {
      event.stopPropagation();
    }
    this.deleteMessage.emit(messageId);
  }
}
