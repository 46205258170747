<div class="profile-item" *ngIf="user">
  <img
    *ngIf="user.avatarRef; else placeholder"
    class="avatar"
    [src]="user.avatarRef | safe : 'url'"
    style="width: 50px; height: 50px"
    (click)="openProfile()"
  />
  <ng-template #placeholder>
    <mat-icon class="avatar" width="50" height="50">account_circle</mat-icon>
  </ng-template>

  <div class="profile-text w-50" (click)="openProfile()">
    <div>{{ user.firstName }} {{ user.lastName }}</div>
    <div>
      {{ user.functionLabel || "Unknown position" }}
    </div>
    <div>{{ (user?.role | userRole) || "Unknown role" }}</div>
  </div>
  <div class="flex-grow-1"></div>
  <div class="connect-btn-wrapper" *ngIf="!connection?.isPending && !isSelf">
    <a
      *ngIf="connection && user.email"
      [href]="'mailto:' + user.email"
      target="_blank"
    >
      <img src="../../../../assets/images/email.svg" class="me-1" />
    </a>
    <img
      *ngIf="connection"
      (click)="removeConnection()"
      src="../../../../assets/images/menu/close.svg"
    />
    <img
      *ngIf="!connection"
      (click)="addConnection()"
      src="../../../../assets/images/add-button.svg"
    />
  </div>
  <span *ngIf="connection?.isPending && !isSelf">Pending</span>
  <div class="break"></div>
</div>
