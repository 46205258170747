<form [ngrxFormState]="form">
  <ng-container class="row">
    <div [class.invalid]="formControl.isTouched && formControl.isInvalid">
      <mat-form-field class="col-12 p-0">
        <mat-label>{{ label }}</mat-label>
        <mat-select
          [ngrxFormControlState]="formControl"
          (selectionChange)="selectionChange.emit($event)"
        >
          <mat-option *ngIf="emptyOption" [value]="emptyOption">
            {{ emptyOption }}
          </mat-option>
          <ng-container *ngIf="valueList">
            <mat-option
              *ngFor="let item of selectionList; let i = index"
              [value]="valueList[i]"
            >
              <div *ngIf="key">
                {{ item[key] }}
              </div>
              <div *ngIf="!key">
                {{ item }}
              </div>
            </mat-option>
          </ng-container>

          <ng-container *ngIf="!valueList">
            <mat-option *ngFor="let item of selectionList" [value]="item">
              <div *ngIf="key">
                {{ item[key] }}
              </div>
              <div *ngIf="!key">
                {{ item }}
              </div>
            </mat-option>
          </ng-container>
        </mat-select>
        <mat-error *ngIf="formControl.errors?.required">
          {{ requiredErrorMessage }}
        </mat-error>
      </mat-form-field>
    </div>
  </ng-container>
</form>
