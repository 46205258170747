<div *ngIf="companyDetailsForm$ | async as form">
  <app-company-details-header
    *ngIf="
      !isSignUp && (!company || (company && company.contactPersons.length > 0))
    "
    title="Our recruitment contacts"
    editText="Add contact"
    [canEdit]="!company"
    [modify]="modify"
    (onModify)="toggleModify($event)"
    data-cy="company-our-recruitment-contacts-title"
  ></app-company-details-header>

  <div *ngIf="company; else self">
    <div
      class="d-flex py-3"
      *ngFor="let contactPerson of company.contactPersons"
    >
      <img
        class="avatar"
        *ngIf="contactPerson.profilePhotoUrl"
        [src]="contactPerson.profilePhotoUrl"
      />
      <mat-icon
        class="avatar"
        *ngIf="!contactPerson.profilePhotoUrl"
        color="primary"
        >account_circle</mat-icon
      >
      <div class="ps-2">
        <div>{{ contactPerson.firstName }} {{ contactPerson.lastName }}</div>
        <div>{{ contactPerson.role }}</div>
      </div>
    </div>
  </div>

  <ng-template #self>
    <div *ngIf="company$ | async as ownCompany">
      <div
        class="d-flex justify-content-between align-items-center py-3"
        *ngFor="let contactPerson of ownCompany.contactPersons"
      >
        <div class="d-flex">
          <img
            class="avatar"
            *ngIf="contactPerson.profilePhotoUrl"
            [src]="contactPerson.profilePhotoUrl"
          />
          <mat-icon
            class="avatar"
            *ngIf="!contactPerson.profilePhotoUrl"
            color="primary"
            >account_circle</mat-icon
          >
          <div class="ps-2">
            <div>
              {{ contactPerson.firstName }} {{ contactPerson.lastName }}
            </div>
            <div>{{ contactPerson.role }}</div>
            <div>{{ contactPerson.email }}</div>
          </div>
        </div>
        <mat-icon
          (click)="removeContact(contactPerson)"
          color="primary"
          class="pointer"
          >delete</mat-icon
        >
      </div>
    </div>
  </ng-template>
</div>
