import { Component, OnInit, Input } from '@angular/core';
import { FormControlState } from 'ngrx-forms';
import { NgrxFormElementDirective } from '../ngrx-form-element/ngrx-form-element.directive';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
})
export class InputFieldComponent extends NgrxFormElementDirective{

  @Input() type = 'text';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() value = '';
  @Input() tooltipText = '';

  @Input() requiredErrorMessage = '';
  @Input() patternErrorMessage = '';
  @Input() emailErrorMessage = '';

  constructor() {
    super();
  }
}
