import { createAction, props } from '@ngrx/store';

export enum DeleteCompanyActionTypes {
  DELETE = '[Company] Delete company',
  SUCCESS = '[Company] Delete company success',
  ERROR = '[Company] Delete company error',
}

export const deleteCompanyId = createAction(DeleteCompanyActionTypes.DELETE, props<{ id: string }>());
export const deleteCompanyIdSuccess = createAction(DeleteCompanyActionTypes.SUCCESS);
export const deleteCompanyIdError = createAction(DeleteCompanyActionTypes.ERROR, props<{ error: string }>());
