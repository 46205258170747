import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectMailPreferencesForm } from '../../../../ngrx-store/components/userForms/userForms.selectors';
import { submitMailPreferencesForm } from '../../../../ngrx-store/components/userForms/mailPreferences/userForms.mailPreferences.actions';
import { finishOnboarding } from '../../../../ngrx-store/components/onboarding/onboarding.actions';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routing.model';

@Component({
  selector: 'app-onboarding-step4',
  templateUrl: './onboarding-step4.component.html',
  styleUrls: ['./onboarding-step4.component.scss'],
})
export class OnboardingStep4Component {
  public mailPreferencesForm$ = this.store.select(selectMailPreferencesForm);

  constructor(private router: Router, private store: Store<AppState>) {
  }

  public changeCheckBox() {
    this.mailPreferencesForm$.pipe(take(1)).subscribe((form) => {
      this.store.dispatch(finishOnboarding({ finished: true, ...form.value }));
    });
  }

  public submitFormAndPost() {
    this.mailPreferencesForm$.pipe(take(1)).subscribe((form) => {
      this.store.dispatch(submitMailPreferencesForm({ formValues: form.value }));
      this.store.dispatch(finishOnboarding({ finished: true, ...form.value }));

      this.router.navigate([AppRoutes.DASHBOARD], { replaceUrl: true });
    });
  }
}
