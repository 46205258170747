<mat-card appearance="outlined" class="story-card h-100">
  <img
    *ngIf="company?.logo"
    (click)="openDialog()"
    [src]="company.logo"
    class="company-picture pointer"
  />
  <div *ngIf="!company?.logo" class="company-picture"></div>
  <mat-card-content class="d-flex flex-column justify-content-between">
    <div class="card-content mb-4">
      {{ company.introduction }}
    </div>

    <a (click)="openDialog()">
      <button mat-flat-button color="primary" class="btn-sm d-block">
        Contact us
      </button>
    </a>
  </mat-card-content>
</mat-card>
