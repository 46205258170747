import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/ngrx-store/components/user/user.model';

export enum PostUserActionTypes {
  POST = '[User] Post entire user data',
  SUCCESS = '[User] Post success',
  ERROR = '[User] Post error',
  CLEAR = '[User] Post clear',
  DELETE = '[User] Delete user',
  DELETE_SUCCESS = '[User] Delete user success',
  DELETE_ID = '[User] Delete user by id',
  DELETE_ID_SUCCESS = '[User] Delete user by id success',
  TRACK_CLICK = '[User] Track click event',
  TRACK_CLICK_SUCCES = '[User] Track click event success',
  TRACK_CLICK_ERROR = '[User] Track click event error',
  UPLOAD_AVATAR = '[User] Upload avatar',
  UPLOAD_AVATAR_SUCCESS = '[User] Upload avatar success',
  UPLOAD_AVATAR_ERROR = '[User] Upload avatar error',
}

export const postUser = createAction(PostUserActionTypes.POST);

export const postUserSuccess = createAction(PostUserActionTypes.SUCCESS, props<{ user: User }>());
export const postUserError = createAction(PostUserActionTypes.ERROR, props<{ error: string }>());
export const postUserClear = createAction(PostUserActionTypes.CLEAR);

export const deleteUser = createAction(PostUserActionTypes.DELETE);
export const deleteUserSuccess = createAction(PostUserActionTypes.DELETE_SUCCESS);

export const deleteUserId = createAction(PostUserActionTypes.DELETE_ID, props<{ id: string }>());
export const deleteUserIdSuccess = createAction(PostUserActionTypes.DELETE_ID_SUCCESS);

export const trackClick = createAction(PostUserActionTypes.TRACK_CLICK, props<{ eventType: string, companyId?: string }>());
export const trackClickSuccess = createAction(PostUserActionTypes.TRACK_CLICK_SUCCES);
export const trackClickError = createAction(PostUserActionTypes.TRACK_CLICK_ERROR, props<{ error: string }>());

export const uploadAvatar = createAction(PostUserActionTypes.UPLOAD_AVATAR, props<{ file: File }>());
export const uploadAvatarSuccess = createAction(PostUserActionTypes.UPLOAD_AVATAR_SUCCESS, props<{ urlRef: string }>());
export const uploadAvatarError = createAction(PostUserActionTypes.UPLOAD_AVATAR_ERROR, props<{ error: string }>());
