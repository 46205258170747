import { createAction, props } from '@ngrx/store';
import { Company } from '../../../components/company/company.model';

export enum GetCompanyActionTypes {
  GET = '[Company] Get company',
  SUCCESS = '[Company] Get company success',
  ERROR = '[Company] Get company error',

  GET_ALL = '[Company] Get companies',
  SUCCESS_ALL = '[Company] Get companies success',
  ERROR_ALL = '[Company] Get companies error',

  FOLLOW = '[Company] Follow company',
  FOLLOW_SUCCESS = '[Company] Follow company success',
  FOLLOW_ERROR = '[Company] Follow company error',

  UNFOLLOW = '[Company] Unfollow company',
  UNFOLLOW_SUCCESS = '[Company] Unfollow company success',
  UNFOLLOW_ERROR = '[Company] Unfollow company error',

  FOLLOW_STATUS = '[Company] Follow status of company',
  FOLLOW_STATUS_SUCCESS = '[Company] Follow status of company success',
  FOLLOW_STATUS_ERROR = '[Company] Follow status of company error',
}

export const getCompany = createAction(GetCompanyActionTypes.GET);
export const getCompanySuccess = createAction(GetCompanyActionTypes.SUCCESS, props<{ company: Company }>());
export const getCompanyError = createAction(GetCompanyActionTypes.ERROR, props<{ error: string }>());

export const getCompanies = createAction(GetCompanyActionTypes.GET_ALL);
export const getCompaniesSuccess = createAction(GetCompanyActionTypes.SUCCESS_ALL, props<{ companies: Company[] }>());
export const getCompaniesError = createAction(GetCompanyActionTypes.ERROR_ALL, props<{ error: string }>());

export const followCompany = createAction(GetCompanyActionTypes.FOLLOW, props<{ id: string }>());
export const followCompanySuccess = createAction(GetCompanyActionTypes.FOLLOW_SUCCESS, props<{ id: string }>());
export const followCompanyError = createAction(GetCompanyActionTypes.FOLLOW_ERROR, props<{ error: string }>());

export const unfollowCompany = createAction(GetCompanyActionTypes.UNFOLLOW, props<{ id: string }>());
export const unfollowCompanySuccess = createAction(GetCompanyActionTypes.UNFOLLOW_SUCCESS, props<{ id: string }>());
export const unfollowCompanyError = createAction(GetCompanyActionTypes.UNFOLLOW_ERROR, props<{ error: string }>());

export const followCompanyStatus = createAction(GetCompanyActionTypes.FOLLOW_STATUS, props<{ id: string }>());
export const followCompanyStatusSuccess = createAction(GetCompanyActionTypes.FOLLOW_STATUS_SUCCESS, props<{ status: boolean }>());
export const followCompanyStatusError = createAction(GetCompanyActionTypes.FOLLOW_STATUS_ERROR, props<{ error: string }>());
