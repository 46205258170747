<ng-container *ngIf="onboardingModel$ | async as onboardingModel">
  <ng-container *ngIf="findMatchForm$ | async as findMatchForm">
    <div class="step">
      <div class="container p-0 step-content" style="margin-bottom: 30px">
        <app-horizontal-stepper linear #findyourmatchstepper [selectedIndex]="onboardingModel.activeQuestionIndex"
          [progressBarEnabled]="false" [backButtonEnabled]="false" [showHeader]="false">

          <app-step [completed]="true">
            <div class="step">
              <div class="container step-content">
                <div class="onboarding-image">
                  <div class="align-title">
                    <p class="match-finding-title">Let's find your match</p>
                  </div>
                  <div class="ellipse"></div>
                  <div class="visuals">
                    <img class="onboarding_stepper_image" src="../../../../../assets/images/onboarding/visual_2.svg" />
                  </div>
                </div>
                <app-onboarding-question-progress class="col-12 my-2"></app-onboarding-question-progress>
                <div class="row question-box">
                  <p class="col-12">
                    How would you like to be matched?
                  </p>
                  <app-input-select class="col-12" [form]="findMatchForm" [formControl]="findMatchForm.controls.role"
                    [selectionList]="findMatchForm.controls.roles.value"
                    [requiredErrorMessage]="'Role is required'"></app-input-select>

                  <div class="button-area w-100">
                    <app-onboarding-question-buttons class="col-12 p-0" style="position: static;"
                      [canPressNext]="findMatchForm.controls.role.value !== ''"
                      (previousStep)="previousStep()"></app-onboarding-question-buttons>
                  </div>
                </div>
              </div>
            </div>
          </app-step>
          <app-step [completed]="true">
            <div class="step">
              <div class="container step-content">
                <div class="onboarding-image">
                  <div class="align-title">
                    <p class="match-finding-title">Let's find your match</p>
                  </div>
                  <div class="ellipse"></div>
                  <div class="visuals">
                    <img class="onboarding_stepper_image" src="../../../../../assets/images/onboarding/visual_3.svg" />
                  </div>
                </div>
                <div class="row question-box">
                  <app-onboarding-question-progress class="col-12 my-2"></app-onboarding-question-progress>
                  <p class="col-12">
                    Select the
                    <strong>digital areas</strong>
                    that you are <strong>skilled</strong> in.
                  </p>

                  <app-input-list-builder class="col-12" *ngIf="digitalAreas$ | async as digitalAreas"
                    (addInput)="addDigitalAreaBackground($event)" (removeInput)="removeDigitalAreaBackground($event)"
                    [inputList]="digitalAreas"
                    [selectedItems]="findMatchForm.controls.digitalAreaBackgrounds.value | attributeToString"
                    [inputPlaceholder]="'Select one or more'"></app-input-list-builder>

                  <div class="button-area w-100">
                    <app-onboarding-question-buttons class="col-12 p-0"></app-onboarding-question-buttons>
                  </div>
                </div>
              </div>
            </div>
          </app-step>
          <app-step [completed]="true">
            <div class="step">
              <div class="container step-content">
                <div class="onboarding-image">
                  <div class="align-title">
                    <p class="match-finding-title">Let's find your match</p>
                  </div>
                  <div class="ellipse"></div>
                  <div class="visuals">
                    <img class="onboarding_stepper_image" src="../../../../../assets/images/onboarding/visual_4.svg" />
                  </div>
                </div>
                <app-onboarding-question-progress class="col-12 my-2"></app-onboarding-question-progress>
                <div class="row question-box">
                  <p class="col-12">
                    Select <strong>digital areas</strong> that you want to <strong>learn</strong> more about.
                  </p>

                  <app-input-list-builder class="col-12" *ngIf="digitalAreas$ | async as digitalAreas"
                    (addInput)="addDigitalAreaInterest($event)" (removeInput)="removeDigitalAreaInterest($event)"
                    [inputList]="digitalAreas"
                    [selectedItems]="findMatchForm.controls.digitalAreaInterests.value | attributeToString"
                    [inputPlaceholder]="'Select one or more'"></app-input-list-builder>

                  <div class="button-area w-100">
                    <app-onboarding-question-buttons class="col-12 p-0"></app-onboarding-question-buttons>
                  </div>
                </div>
              </div>
            </div>
          </app-step>
          <app-step [completed]="true">
            <div class="step">
              <div class="container step-content">
                <div class="onboarding-image">
                  <div class="align-title">
                    <p class="match-finding-title">Let's find your match</p>
                  </div>
                  <div class="ellipse"></div>
                  <div class="visuals">
                    <img class="onboarding_stepper_image" src="../../../../../assets/images/onboarding/visual_5.svg">
                  </div>
                </div>
                <app-onboarding-question-progress class="col-12 my-2"></app-onboarding-question-progress>
                <div class="row question-box">
                  <p class="col-12">
                    Select the <strong>industries</strong> you have <strong>experience</strong> in.
                  </p>
                  <app-input-list-builder class="col-12" *ngIf="industries$ | async as industries"
                    (addInput)="addIndustryBackground($event)" (removeInput)="removeIndustryBackground($event)"
                    [inputList]="industries"
                    [selectedItems]="findMatchForm.controls.industryBackgrounds.value | attributeToString"
                    [inputPlaceholder]="'Select one or more'"></app-input-list-builder>

                  <div class="button-area w-100">
                    <app-onboarding-question-buttons class="col-12 p-0"></app-onboarding-question-buttons>
                  </div>
                </div>
              </div>
            </div>
          </app-step>
          <app-step [completed]="true">
            <div class="step">
              <div class="container step-content">

                <div class="onboarding-image">
                  <div class="align-title">
                    <p class="match-finding-title">Let's find your match</p>
                  </div>
                  <div class="ellipse"></div>
                  <div class="visuals">
                    <img class="onboarding_stepper_image" src="../../../../../assets/images/onboarding/visual_6.svg">
                  </div>
                </div>
                <app-onboarding-question-progress class="col-12 my-2"></app-onboarding-question-progress>
                <div class="row question-box">
                  <p class="col-12">
                    Select the <strong>industries</strong> that you want to <strong>learn</strong> more about.
                  </p>

                  <app-input-list-builder class="col-12" *ngIf="industries$ | async as industries"
                    (addInput)="addIndustryInterest($event)" (removeInput)="removeIndustryInterest($event)"
                    [inputList]="industries"
                    [selectedItems]="findMatchForm.controls.industryInterests.value | attributeToString"
                    [inputPlaceholder]="'Select one or more'"></app-input-list-builder>

                  <div class="button-area w-100">
                    <app-onboarding-question-buttons class="col-12 p-0" [finishText]="'Final step'"
                      *ngIf="!submittingStep"
                      (finish)="submitFormAndPost(findMatchForm.value)"></app-onboarding-question-buttons>
                    <ng-container *ngIf="submittingStep">
                      <mat-spinner [diameter]="35"></mat-spinner>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </app-step>
          <app-step [completed]="true" *ngIf="onboardingModel.questions.length > 6">
            <div class="step">
              <div class="container step-content">
                <div class="onboarding-image">
                  <div class="align-title">
                    <p class="match-finding-title">Let's find your match</p>
                  </div>
                  <div class="ellipse"></div>
                  <div class="visuals">
                    <img class="onboarding_stepper_image" src="../../../../../assets/images/onboarding/visual_7.svg" />
                  </div>
                </div>
                <app-onboarding-question-progress class="col-12 my-2"></app-onboarding-question-progress>
                <div class="row question-box">
                  <p class="col-12">
                    Do you want your mentor to have more experience?
                  </p>
                  <app-button-toggle class="col-12" (clicked)="findMoreExperienced($event)"
                    [isToggleChecked]="findMatchForm.controls.findMoreExperienced.value"
                    [confirmText]="'Yes, I prefer this'" [declineText]="'No, I have no preference'"></app-button-toggle>

                  <div class="button-area w-100">
                    <app-onboarding-question-buttons *ngIf="!submittingStep" class="col-12" [finishText]="'Final step'"
                      (finish)="submitFormAndPost(findMatchForm.value)"
                      [canPressNext]="findMoreExperiencedSelected"></app-onboarding-question-buttons>
                    <ng-container *ngIf="submittingStep">
                      <mat-spinner [diameter]="35"></mat-spinner>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </app-step>
          <app-step [completed]="true" *ngIf="onboardingModel.questions.length > 5">
            <div class="step">
              <div class="container step-content">
                <div class="onboarding-image">
                  <div class="align-title">
                    <p class="match-finding-title">Let's find your match</p>
                  </div>
                  <div class="ellipse"></div>
                  <div class="visuals">
                    <img class="onboarding_stepper_image" src="../../../../../assets/images/onboarding/visual_8.svg" />
                  </div>
                </div>
                <app-onboarding-question-progress class="col-12 my-2"></app-onboarding-question-progress>
                <div class="row question-box">
                  <p class="col-12">
                    Would you like your mentor/mentee to be in your region?
                  </p>
                  <app-button-toggle class="col-12" (clicked)="findInRegion($event)"
                    [isToggleChecked]="findMatchForm.controls.findInRegion.value"
                    [confirmText]="'Yes, that would be great'" [declineText]="'No, not important'"></app-button-toggle>

                  <div class="button-area w-100">
                    <app-onboarding-question-buttons *ngIf="!submittingStep" class="col-12" [finishText]="'Final step'"
                      (finish)="submitFormAndPost(findMatchForm.value)"
                      [canPressNext]="findInRegionSelected"></app-onboarding-question-buttons>
                    <ng-container *ngIf="submittingStep">
                      <mat-spinner [diameter]="35"></mat-spinner>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </app-step>
        </app-horizontal-stepper>
      </div>
    </div>
  </ng-container>
</ng-container>