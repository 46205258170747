<mat-card appearance="outlined" class="match-card">
  <button
    *ngIf="canDismiss"
    mat-icon-button
    mat-dialog-close
    color="primary"
    class="close-button font-weight-bold"
    (click)="dismiss()"
  >
    &times;
  </button>
  <img
    (click)="openProfile(profile.shareData)"
    class="match-picture"
    src="{{ profile.avatarRef }}"
    *ngIf="profile?.avatarRef; else noPicMatch"
  />
  <ng-template #noPicMatch>
    <img
      (click)="openProfile(profile.shareData)"
      class="match-picture"
      src="../../../../assets/images/dashboard/account_circle.svg"
    />
  </ng-template>
  <mat-card-content class="profile-card-content">
    <div class="user-properties">
      <p class="user-role">
        {{ (profile?.role | userRole) || "Unknown role" }}
      </p>
      <div class="user-name" (click)="openProfile(profile.shareData)">
        {{ profile?.firstName }}&nbsp;{{ profile?.lastName }}
      </div>
      <p class="user-function">
        {{ profile?.functionLabel || "Unknown position" }}
      </p>
      <p class="user-function">{{ profile?.yearsExperience | experience }}</p>
    </div>
    <p class="user-profile-link">
      <a (click)="openProfile(profile.shareData)">
        <span *ngIf="profile?.firstName; else noFirstName">
          View {{ profile?.firstName
          }}{{ profile?.firstName.slice(-1) == "s" ? "'" : "'s" }} profile
        </span>
        <ng-template #noFirstName> View profile </ng-template>
      </a>
    </p>
    <p
      *ngIf="!hasConnection && !isSelf && !hasPendingConnection"
      class="connect-button"
    >
      <button
        class="btn-sm mx-auto"
        mat-flat-button
        id="connect_{{ profile?.id }}"
        color="primary"
        (click)="connectWith(profile, $event)"
      >
        Connect
      </button>
    </p>
    <p *ngIf="hasConnection" class="connect-button">
      <a
        *ngIf="profile?.email"
        [href]="'mailto:' + profile.email"
        target="_blank"
      >
        <button
          class="btn-sm mx-auto"
          mat-flat-button
          id="connect_{{ profile?.id }}"
          color="primary"
        >
          Contact me
        </button>
      </a>
      <button
        *ngIf="!profile?.email"
        class="btn-sm mx-auto"
        mat-flat-button
        id="connect_{{ profile?.id }}"
        color="primary"
        [disabled]="true"
      >
        Connected
      </button>
    </p>
    <p *ngIf="isSelf" class="connect-button">
      <button
        class="btn-sm mx-auto"
        mat-flat-button
        id="connect_{{ profile?.id }}"
        color="primary"
        [disabled]="true"
      >
        It's you!
      </button>
    </p>
    <p *ngIf="hasPendingConnection" class="connect-button">
      <button
        class="btn-sm mx-auto"
        mat-flat-button
        id="connect_{{ profile?.id }}"
        color="primary"
        [disabled]="true"
      >
        Request pending
      </button>
    </p>
  </mat-card-content>
</mat-card>
