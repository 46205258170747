import { createAction, props } from '@ngrx/store';
import { FindMatchForm } from './forms.findMatch.model';
import { DigitalArea, Industry } from '../../user/user.model';

export enum FindMatchFormActionTypes {
  ADD_AREA_BACKGROUND = '[UserForms] Add Area background',
  REMOVE_AREA_BACKGROUND = '[UserForms] Remove Area background',
  ADD_INDUSTRY_BACKGROUND = '[UserForms] Add Industry background',
  REMOVE_INDUSTRY_BACKGROUND = '[UserForms] Remove Industry background',
  ADD_AREA_INTEREST = '[UserForms] Add Area interest',
  REMOVE_AREA_INTEREST = '[UserForms] Remove Area interest',
  ADD_INDUSTRY_INTEREST = '[UserForms] Add Industry interest',
  REMOVE_INDUSTRY_INTEREST = '[UserForms] Remove Industry interest',
  SET_FINDMOREEXPERIENCED = '[UserForms] Answer find more experienced question',
  SET_FINDINREGION = '[UserForms] Answer find in region question',
  SUBMIT = '[UserForms] Submit find match form',
}

export const addAreaBackground = createAction(
  FindMatchFormActionTypes.ADD_AREA_BACKGROUND,
  props<{ digitalArea: DigitalArea }>()
);
export const removeAreaBackground = createAction(
  FindMatchFormActionTypes.REMOVE_AREA_BACKGROUND,
  props<{ digitalArea: DigitalArea }>()
);
export const addIndustryBackground = createAction(
  FindMatchFormActionTypes.ADD_INDUSTRY_BACKGROUND,
  props<{ industry: Industry }>()
);
export const removeIndustryBackground = createAction(
  FindMatchFormActionTypes.REMOVE_INDUSTRY_BACKGROUND,
  props<{ industry: Industry }>()
);

export const addDigitalAreaInterest = createAction(
  FindMatchFormActionTypes.ADD_AREA_INTEREST,
  props<{ digitalArea: DigitalArea }>()
);
export const removeDigitalAreaInterest = createAction(
  FindMatchFormActionTypes.REMOVE_AREA_INTEREST,
  props<{ digitalArea: DigitalArea }>()
);
export const addIndustryInterest = createAction(
  FindMatchFormActionTypes.ADD_INDUSTRY_INTEREST,
  props<{ industry: Industry }>()
);
export const removeIndustryInterest = createAction(
  FindMatchFormActionTypes.REMOVE_INDUSTRY_INTEREST,
  props<{ industry: Industry }>()
);

export const setFindMoreExperienced = createAction(
  FindMatchFormActionTypes.SET_FINDMOREEXPERIENCED,
  props<{ value: boolean }>()
);
export const setFindInRegion = createAction(FindMatchFormActionTypes.SET_FINDINREGION, props<{ value: boolean }>());

export const submitFindMatchForm = createAction(
  FindMatchFormActionTypes.SUBMIT,
  props<{ formValues: FindMatchForm }>()
);
