import { createAction, props } from '@ngrx/store';

export enum UserActionTypes {
  SET_EMAIL = '[User] Set email',
  SET_EMAIL_SUCCESS = '[User] Set email success',
  SET_EDITENABLED = '[User] Set editing enabled',
  REMOVE_COMPANY = '[User] Remove company from background',
  REMOVE_EDUCATION = '[User] Remove education from background',
  SET_EXPERIENCE = '[User] Set work experience',
  CHANGE_ROLE = '[User] Role changed',
  CHANGE_AVATAR = '[User] Avatar changed',
  SET_FINDMOREEXPERIENCED = '[User] Answer find more experienced question',
  SET_FINDINREGION = '[User] Answer find in region question',
  SET_CAREER_STARTING_YEAR = '[User] Set career starting year',
}

export const setEmail = createAction(
  UserActionTypes.SET_EMAIL,
  props<{ newEmail: string }>()
);
export const setEmailSuccess = createAction(UserActionTypes.SET_EMAIL_SUCCESS);
export const setWorkExperience = createAction(
  UserActionTypes.SET_EXPERIENCE,
  props<{ experience: string }>()
);
export const setCareerStartingYear = createAction(
  UserActionTypes.SET_CAREER_STARTING_YEAR,
  props<{ startingYear: number }>()
);
export const setEditEnabled = createAction(
  UserActionTypes.SET_EDITENABLED,
  props<{ value: boolean }>()
);
export const removeCompany = createAction(
  UserActionTypes.REMOVE_COMPANY,
  props<{ index: number }>()
);
export const removeEducation = createAction(
  UserActionTypes.REMOVE_EDUCATION,
  props<{ index: number }>()
);
export const changeRole = createAction(
  UserActionTypes.CHANGE_ROLE,
  props<{ role: string }>()
);
export const changeAvatar = createAction(
  UserActionTypes.CHANGE_AVATAR,
  props<{ avatar: string }>()
);
export const setFindMoreExperiencedUser = createAction(
  UserActionTypes.SET_FINDMOREEXPERIENCED,
  props<{ value: boolean }>()
);
export const setFindInRegionUser = createAction(
  UserActionTypes.SET_FINDINREGION,
  props<{ value: boolean }>()
);
