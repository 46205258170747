import { DigitalArea, Industry } from '../user/user.model';
import { Company } from '../company/company.model';

export interface VacancyState {
  vacancies: Vacancy[];
  interests: VacancyInterest[];
  newVacancy?: Vacancy;
  vacanciesForCompany: Record<string, Vacancy[]>;
}

export interface Vacancy {
  id: string;
  companyId: string;
  jobTitle: string;
  photoUrl: string;
  summary: string;
  idealCandidate: string;
  type: VacancyType;
  startDate: string;
  expirationDate: Date;
  careerPhase: number;
  country: string;
  city: string;
  requiredDigitalAreas: DigitalArea[];
  requiredIndustries: Industry[];
  interested?: boolean;
  company?: Company;
  matchScore?: number;
}

export interface VacancyInterest {
  id: string;
  interested: boolean;
}

export enum VacancyType {
  Internship,
  Freelance,
  Employed,
}
