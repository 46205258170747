<div *ngIf="companyDetailsForm$ | async as form">
  <app-company-details-header
    title="Meet our ambassadors"
    *ngIf="
      !isSignUp && (!company || (company && company.ambassadors.length > 0))
    "
    [canEdit]="!company"
    [modify]="modify"
    (onModify)="toggleModify($event)"
    data-cy="ambassador-section-title"
  ></app-company-details-header>

  <div *ngIf="company; else self">
    <div class="match-cards">
      <div *ngFor="let ambassador of company.ambassadors">
        <app-profilecard
          (click)="trackAmbassadorClick()"
          [hasPendingConnection]="hasPendingConnection(ambassador.user)"
          [hasConnection]="hasConnection(ambassador.user)"
          [isSelf]="isSelf(ambassador.user)"
          [profile]="ambassador.user"
        ></app-profilecard>
      </div>
    </div>
  </div>

  <ng-template #self>
    <div
      class="d-flex justify-content-between py-3"
      *ngFor="let ambassador of form.controls.ambassadors.value"
    >
      <div class="d-flex">
        <img
          class="avatar"
          *ngIf="ambassador.user.avatarRef"
          [src]="ambassador.user.avatarRef | safe : 'url'"
        />
        <mat-icon class="avatar" *ngIf="!ambassador.user.avatarRef" color="primary"
          >account_circle</mat-icon
        >
        <div class="ps-2">
          <div data-cy="ambassador-name-card">
            {{ ambassador.user.firstName }} {{ ambassador.user.lastName }}
          </div>
          <div>{{ ambassador.user.functionLabel }}</div>
          <div>{{ ambassador.user.role | userRole }}</div>
        </div>
      </div>
      <mat-icon
        *ngIf="modify || isSignUp"
        (click)="removeAmbassador(ambassador.user)"
        class="pointer"
        color="primary"
        data-cy="remove-ambassador-button"
        >delete</mat-icon
      >
    </div>

    <div
      *ngIf="(modify || isSignUp) && form.controls.ambassadors.value.length < 3"
      class="ambassador-search"
    >
      <mat-form-field floatLabel="never" class="col-12 p-0">
        <input
          matInput
          autocomplete="off"
          type="text"
          placeholder="Search by typing in first and last name"
          (input)="searchMembers($event.target.value)"
          data-cy="ambassador-search-field"
        />
      </mat-form-field>
      <mat-spinner
        *ngIf="loading"
        mode="indeterminate"
        diameter="20"
        class="loader"
      ></mat-spinner>
      <div *ngIf="searchResult" class="search-box">
        <div *ngIf="search.length > 0 && searchResult.length == 0">
          No members found with that name
        </div>
        <div
          class="d-flex justify-content-between"
          *ngFor="let member of searchResult; trackBy: trackByName"
        >
          <div class="d-flex pointer" (click)="openProfile(member.id)">
            <img
              class="avatar"
              *ngIf="member.avatarRef"
              [src]="member.avatarRef | safe : 'url'"
            />
            <mat-icon class="avatar" *ngIf="!member.avatarRef" color="primary"
              >account_circle</mat-icon
            >
            <div class="ps-2">
              <div>{{ member.firstName }} {{ member.lastName }}</div>
              <div>{{ member.functionLabel }}</div>
              <div>{{ member.role | userRole }}</div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <span class="edit-link pe-2">Add ambassador</span>
            <mat-icon
              class="pointer"
              (click)="addAmbassador(member)"
              color="primary"
              data-cy="add-ambassador-button"
              >add_circle_outline</mat-icon
            >
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
