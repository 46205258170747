import { createAction, props } from '@ngrx/store';
import { DigitalArea, Industry } from '../../user/user.model';

export enum VacancyFilterFormActions {
  CLEAR = '[VacancyFilterForm] Clear filter',
  SET_COMPANY = '[VacancyFilterForm] Set filter company',
  ADD_DIGITAL_AREA = '[VacancyFilterForm] Add digital area',
  REMOVE_DIGITAL_AREA = '[VacancyFilterForm] Remove digital area',
  ADD_INDUSTRY = '[VacancyFilterForm] Add industry',
  REMOVE_INDUSTRY = '[VacancyFilterForm] Remove industry',
}

export const clearFilter = createAction(VacancyFilterFormActions.CLEAR);
export const setFilterCompany = createAction(
  VacancyFilterFormActions.SET_COMPANY,
  props<{ company: string }>()
);
export const addDigitalAreaToFilter = createAction(
  VacancyFilterFormActions.ADD_DIGITAL_AREA,
  props<{ digitalArea: DigitalArea }>()
);
export const removeDigitalAreaFromFilter = createAction(
  VacancyFilterFormActions.REMOVE_DIGITAL_AREA,
  props<{ digitalArea: DigitalArea }>()
);
export const addIndustryToFilter = createAction(
  VacancyFilterFormActions.ADD_INDUSTRY,
  props<{ industry: Industry }>()
);
export const removeIndustryFromFilter = createAction(
  VacancyFilterFormActions.REMOVE_INDUSTRY,
  props<{ industry: Industry }>()
);
