import {AppState} from '../../app.state';
import {createSelector} from '@ngrx/store';
import {BlogItem, BlogItemState} from './blogitem.model';

const selectBlogItemState = (state: AppState) => state.blogItemState;
export const selectBlogItem = createSelector(
  selectBlogItemState, (state: BlogItemState) => state);

export const selectBlogItems = createSelector(
  selectBlogItemState, (state: BlogItemState) => state?.blogItems);
export const selectBlogItemForId = (blogItemId: string) => createSelector(
  selectBlogItems, (blogItems: BlogItem[]) => blogItems.find(item => item.id === blogItemId));
