import { Component, Input } from '@angular/core';
import { AppRoutes } from 'src/app/app-routing.model';
import {BlogItem} from '../../../ngrx-store/components/blogitem/blogitem.model';

@Component({
  selector: 'app-blogitemcards',
  templateUrl: './blogitemcards.component.html',
  styleUrls: ['./blogitemcards.component.scss']
})
export class BlogitemcardsComponent {
  public AppRoutes = AppRoutes;
  @Input() blogItems: BlogItem[];
  @Input() viewAllButton = true;

}
