<div *ngIf="connections$ | async as connections">
  <div class="background-color-matches"></div>
  <div *ngFor="let user of shownUsers" class="profile-item align-center">
    <app-profilecard [hasConnection]="hasConnection(user, connections)" [hasPendingConnection]="hasPendingConnection(user, connections)" style="margin-left: auto; margin-right: auto" [profile]="user"></app-profilecard>
  </div>

  <ng-container *ngIf="shownUsers.length < users.length">
    <div class="align-center">
      <button class="mt-3 mx-auto btn-sm" mat-flat-button color="primary" (click)="showMore()">
        Show more
      </button>
    </div>
  </ng-container>

  <p *ngIf="users?.length === 0">No results</p>
</div>
