import {createAction, props} from '@ngrx/store';
import { Education } from '../../components/education/education.model';

export enum GetEducationActionTypes {
  GET = '[Education] Get educations',
  SUCCESS = '[Education] Get educations success',
  ERROR = '[Education] Get educations error',
}

export const getEducations = createAction(GetEducationActionTypes.GET);
export const getEducationsSuccess = createAction(GetEducationActionTypes.SUCCESS,
                                                    props<{ educations: Education[] }>());
export const getEducationsError = createAction(GetEducationActionTypes.ERROR, props<{ error: string }>());
