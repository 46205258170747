import { RouterModule, Routes } from '@angular/router';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { AdminPanelComponent } from './admin-panel.component';
import { MaterialModule } from 'src/app/shared/material';
import { SharedFormsModule } from 'src/app/shared/forms/shared.forms.module';

const routes: Routes = [
  {
    path: '',
    component: AdminPanelComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, MaterialModule, SharedFormsModule],
  declarations: [AdminPanelComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class AdminPanelModule {}
