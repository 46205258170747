import { Action, createReducer, on } from '@ngrx/store';
import { createFormGroupState, FormGroupState, formStateReducer, setValue, updateGroup, validate } from 'ngrx-forms';
import { CompanyFormsState } from '../companyForms.model';
import { CompanyContactPersonForm } from './forms.companyContactPerson.model';
import { addCompanyContactPersonPhotoSuccess, deleteCompanyContactPersonPhoto } from './forms.companyContactPerson.actions';
import { email, required } from 'ngrx-forms/validation';
import _ = require('underscore');

export const initialCompanyContactPersonForm: CompanyContactPersonForm = {
  photo: '',
  firstName: '',
  lastName: '',
  role: '',
  email: ''
};

export const initialCompanyContactPersonState = createFormGroupState<CompanyContactPersonForm>(
  'COMPANY_CONTACT_PERSON_FORM',
  initialCompanyContactPersonForm
);

const updateForm = createReducer<FormGroupState<CompanyContactPersonForm>>(
  initialCompanyContactPersonState,
  on(addCompanyContactPersonPhotoSuccess, (state, action) => updateGroup<CompanyContactPersonForm>(state, {
    photo: setValue(action.referenceUrl)
    })
  ),
  on(deleteCompanyContactPersonPhoto, (state, action) => updateGroup<CompanyContactPersonForm>(state, {
    photo: setValue('')
    })
  ),
);

const validateForm = (form: FormGroupState<CompanyContactPersonForm>, state: CompanyFormsState) => {
  return updateGroup<CompanyContactPersonForm>({
    email: validate(required, email)
  })(form);
};

export const companyContactPersonFormReducer = (state: CompanyFormsState, action: Action) => {
  const companyContactPersonForm: FormGroupState<CompanyContactPersonForm> = state.companyContactPersonForm;

  // use the out of the box reducer to process form actions into the store
  let form = formStateReducer(companyContactPersonForm, action);

  // Process non-form actions
  form = updateForm(form, action);

  // form validation using the new state
  form = validateForm(form, state);

  return form === companyContactPersonForm ? companyContactPersonForm : form;
};
