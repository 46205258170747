<div class="container">
<ng-container *ngIf="educationForm$ | async as form">
  <app-input-select
    [form]="form"
    [formControl]="form.controls.educationType"
    [label]="'Education type'"
    [selectionList]="form.controls.educationTypes.value"
    [requiredErrorMessage]="'Selection is required'"
  ></app-input-select>
  <app-input-field
    [form]="form"
    [formControl]="form.controls.school"
    [label]="'Educational institution'"
    [placeholder]="'Educational Institution'"
    [requiredErrorMessage]="'Educational Institution is required'"
  ></app-input-field>
  <app-input-field
    [form]="form"
    [formControl]="form.controls.study"
    [label]="'Study'"
    [placeholder]="'Study field and level'"
    [requiredErrorMessage]="'Study is required'"
  ></app-input-field>
  <app-input-date
    [form]="form"
    [formControl]="form.controls.startDate"
    [label]="'Start date'"
    [requiredErrorMessage]="'Please enter a valid date'"
  ></app-input-date>
  <app-input-date
    *ngIf="form.controls.educationType.value === EducationType.Previous_Education"
    [form]="form"
    [formControl]="form.controls.endDate"
    [label]="'End date'"
    [requiredErrorMessage]="'Please enter a valid date'"
  ></app-input-date>
  <div class="d-flex justify-content-center">
    <button
      mat-flat-button
      color="primary"
      class="btn-sm mx-2"
      [disabled]="form.isInvalid"
      (click)="submitForm(form.value)"
    >
      Save changes
    </button>
    <button mat-stroked-button color="primary" class="btn-xs mx-2" (click)="cancel()">
      Cancel
    </button>
  </div>
</ng-container>
</div>
