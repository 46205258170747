import {createSelector} from '@ngrx/store';
import {AppState} from '../../app.state';
import {UserState, UserAttributes} from './user.model';

const selectUserState = (state: AppState) => state.userState;
export const selectUser = createSelector(selectUserState, (state: UserState) => {
  if (!state.user) {
    return state.user;
  }

  return {
    ...state.user,
    userBackground: sortUserBackground(state.user ? state.user.userBackground : undefined)
  };
});
export const selectUserAvatar = createSelector(selectUserState, (state: UserState) => state.user?.avatarRef);
export const selectCheckingForNotifications = createSelector(
  selectUserState,
  (state: UserState) => state.checkingForNotifications
);
export const selectUserSocialMediaCount = createSelector(selectUserState, (state: UserState) =>
  state.user === undefined ? 0 :
    ((state.user.twitterSocialMedia?.trim() === '' || state.user.twitterSocialMedia === null) ? 0 : 1) +
    ((state.user.facebookSocialMedia?.trim() === '' || state.user.facebookSocialMedia === null) ? 0 : 1) +
    ((state.user.linkedinSocialMedia?.trim() === '' || state.user.linkedinSocialMedia === null) ? 0 : 1) +
    ((state.user.instagramSocialMedia?.trim() === '' || state.user.instagramSocialMedia === null) ? 0 : 1)
);
export const selectUserProfileCompleteness = createSelector(selectUserState, (state: UserState) =>
  Math.round((state.user === undefined ? 0 :
    ((state.user.about === null || state.user.about.trim() === '') ? 0 : 1) +
    ((state.user.functionLabel === null || state.user.functionLabel.trim() === '') ? 0 : 1) +
    ((state.user.userBackground == null || state.user.userBackground.companies.length === 0) ? 0 : 1) +
    ((state.user.userBackground == null || state.user.userBackground.educations.length === 0) ? 0 : 1) +
    (((state.user.facebookSocialMedia == null || state.user.facebookSocialMedia === '') &&
      (state.user.twitterSocialMedia == null || state.user.twitterSocialMedia === '') &&
      (state.user.instagramSocialMedia == null || state.user.instagramSocialMedia === '') &&
      (state.user.linkedinSocialMedia == null || state.user.linkedinSocialMedia === '')) ? 0 : 1)) / 5 * 100)
);
export const selectUserEditingEnabled = createSelector(selectUserState, (state: UserState) => state.editingEnabled);
export const selectAdminPartner = createSelector(selectUserState, (state: UserState) => ({
  isAdmin: state.user?.isAdmin,
  isPartner: state.user?.isPartner,
}));
export const selectUserNotifications = createSelector(
  selectUserState,
  (state: UserState) => state.user?.userNotifications
);
export const selectUserNotificationCount = createSelector(selectUserState, (state: UserState) =>
  state.user === undefined ? 0 : state.user.userNotifications === undefined ? 0 : state.user.userNotifications.length
);

export const selectUserModel = createSelector(
  [selectUser, selectUserEditingEnabled, selectCheckingForNotifications],
  (user, userEditingEnabled, checkingForNotifications) => ({
    user,
    userEditingEnabled,
    checkingForNotifications,
  })
);

function sortUserBackground(userAttributes: UserAttributes | undefined): UserAttributes | undefined {
  if (!userAttributes) {
    return undefined;
  }

  return {
    ...userAttributes,
    companies: sortByStartDate(userAttributes.companies),
    educations: sortByStartDate(userAttributes.educations)
  };
}

function sortByStartDate<T extends { startDate?: Date }>(arr: T[]): T[] {
  return arr.slice().sort((a: T, b: T) => {
    if (!a.startDate) {
      return 1;
    }
    if (!b.startDate) {
      return -1;
    }

    const startDateA = new Date(a.startDate).getTime();
    const startDateB = new Date(b.startDate).getTime();

    return startDateB - startDateA;
  });
}
