<div class="container position-relative" #container>
  <h2 class="align-center text-primary">Matches</h2>
  <a class="back-arrow" [routerLink]="'/'">
    <span></span>
    <mat-icon>arrow_back</mat-icon>
  </a>
  <div class="bevel mb-4"></div>
  <div class="underlined-title fs-28 underlined-title-margin">
    <p class="text-primary">Search for matches</p>
    <div></div>
  </div>
  <br />
  <p class="fs-18">Search, connect and grow your network.</p>
  <br />

  <ng-container *ngIf="matchModel$ | async as matchModel">
    <app-filter-user-list
      [mentors]="matchModel.mentors"
      [mentees]="matchModel.mentees"
      [communityMembers]="matchModel.members"
      [allowFilterName]="true"
      (filterChange)="filterChange($event)"
      (dataChange)="updateList($event)"
    ></app-filter-user-list>
    <div class="search-button" *ngIf="!filterApplied">
      <button
        class="btn-sm mx-auto"
        mat-flat-button
        color="primary"
        *ngIf="!previousFilter"
        [disabled]="true"
      >
        Search
      </button>
      <button
        class="btn-sm mx-auto"
        mat-flat-button
        color="primary"
        *ngIf="previousFilter"
        (click)="applyFilter()"
      >
        Search
      </button>
    </div>
  </ng-container>

  <div *ngIf="filterApplied">
    <div class="mt-2 mb-2">
      <p class="align-center search-results" *ngIf="!filterEmpty">
        Search results
      </p>
      <p class="align-center search-results" *ngIf="filterEmpty">All matches</p>
    </div>

    <div id="loading-wrapper" *ngIf="loading; else loaded">
      <mat-spinner [diameter]="65"></mat-spinner>
    </div>

    <ng-template #loaded>
      <app-user-list [users]="filteredUsers" [maxCount]="9"></app-user-list>
    </ng-template>
  </div>
</div>
