import { CompanyEventForm } from './forms.companyEvent.model';
import { createAction, props } from '@ngrx/store';
import { RightbrainsEvent } from '../../event/event.model';

export enum CompanyEventFormActionTypes {
  ADD_PHOTO = '[CompanyForms] Add company event photo',
  ADD_PHOTO_SUCCESS = '[CompanyForms] Add company event photo success',
  DELETE_PHOTO = '[CompanyForms] Delete company event photo',
  LOAD_EVENT = '[CompanyForms] Load in company event',
  SUBMIT = '[CompanyForms] Submit company event',
  CLEAR = '[CompanyForms] Clear company event form',
}

export const submitCompanyEventForm = createAction(CompanyEventFormActionTypes.SUBMIT, props<{ formValues: CompanyEventForm }>());
export const addCompanyEventPhoto = createAction(CompanyEventFormActionTypes.ADD_PHOTO, props<{ photo: File }>());
export const addCompanyEventPhotoSuccess = createAction(CompanyEventFormActionTypes.ADD_PHOTO_SUCCESS, props<{ referenceUrl: string }>());
export const loadCompanyEvent = createAction(CompanyEventFormActionTypes.LOAD_EVENT, props<{ event: RightbrainsEvent }>());
export const deleteCompanyEventPhoto = createAction(CompanyEventFormActionTypes.DELETE_PHOTO);
export const clearCompanyEventForm = createAction(CompanyEventFormActionTypes.CLEAR);
