import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CompanyService } from '../../../../services/company.service';
import { getCompany, getCompanySuccess, getCompanyError, getCompanies, getCompaniesSuccess, getCompaniesError, followCompany, followCompanySuccess, followCompanyError, unfollowCompany, unfollowCompanyError, unfollowCompanySuccess } from './get-company.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GetCompanyEffects {
  constructor(
    private actions$: Actions,
    private companyService: CompanyService,
  ) {}

  getCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCompany),
      switchMap(() => {
        return this.companyService.getCompany().pipe(
          map((company) => getCompanySuccess({company})),
          catchError((error: HttpErrorResponse) => of(getCompanyError({error: error.message})))
        );
      })
    )
  );

  getCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCompanies),
      switchMap(() => {
        return this.companyService.getCompanies().pipe(
          map((companies) => getCompaniesSuccess({companies})),
          catchError((error: HttpErrorResponse) => of(getCompaniesError({error: error.message})))
        );
      })
    )
  );

  followCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(followCompany),
      switchMap(({id}) => {
        return this.companyService.followCompany(id).pipe(
          map((companyId) => followCompanySuccess(companyId)),
          catchError((error: HttpErrorResponse) => of(followCompanyError({error: error.message})))
        );
      })
    )
  );

  unfollowCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unfollowCompany),
      switchMap(({id}) => {
        return this.companyService.unfollowCompany(id).pipe(
          map((companyId) => unfollowCompanySuccess(companyId)),
          catchError((error: HttpErrorResponse) => of(unfollowCompanyError({error: error.message})))
        );
      })
    )
  );
}
