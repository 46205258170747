import {Component, OnInit} from '@angular/core';
import { CompanyDetailsBaseComponent } from '../company-details-base/company-details-base.component';

@Component({
  selector: 'app-company-social-media',
  templateUrl: './company-social-media.component.html',
  styleUrls: ['./company-social-media.component.scss']
})
export class CompanySocialMediaComponent extends CompanyDetailsBaseComponent implements OnInit {
  hasSocialMedia = false;

  ngOnInit(): void {
    this.hasSocialMedia = !!this.company?.socialMedia?.linkedinSocialMedia || !!this.company?.socialMedia?.facebookSocialMedia || !!this.company?.socialMedia?.instagramSocialMedia || !!this.company?.socialMedia?.twitterSocialMedia;
  }
}
