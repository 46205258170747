<h1 md-dialog-title>You can customise this invitation.</h1>
<div md-dialog-content>
  <b>You can add a note to personalise your connection request.</b>
</div>
<mat-form-field>
  <textarea
    class="text-input"
    matInput
    cdkTextareaAutosize
    #autosize="cdkTextareaAutosize"
    [cdkAutosizeMinRows]="5"
    #textInput
  ></textarea>
</mat-form-field>
<div md-dialog-actions class="mt-3">
  <button
    mat-flat-button
    color="primary"
    class="btn-xs my-1 me-3"
    mat-dialog-close
    (click)="sendRequest(textInput.value)"
  >
    Send
  </button>
  <button
    mat-stroked-button
    color="primary"
    class="btn-xs my-1"
    mat-dialog-close
  >
    Cancel
  </button>
</div>
