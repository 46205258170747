import { MatchState } from './match.model';
import { createReducer, on } from '@ngrx/store';
import { getMentorsSuccess } from '../../requests/match/get-mentor/get-mentor.actions';
import { getMenteesSuccess } from '../../requests/match/get-mentee/get-mentee.actions';
import { sendConnectionSuccess } from '../connection/connection.actions';
import { getMembersSuccess, getMembersClear } from '../../requests/match/get-members/get-members.actions';
import {postDismissMatch} from '../../requests/match/post-dismiss-match/post-dismiss-match.actions';

export const initialMatchState: MatchState = {
  mentors: undefined,
  mentees: undefined,
  members: undefined,
};

export const matchReducer = createReducer<MatchState>(
  initialMatchState,
  on(getMentorsSuccess, (state, action) => ({
    ...state,
    mentors: action.matches,
  })),
  on(getMenteesSuccess, (state, action) => ({
    ...state,
    mentees: action.matches,
  })),
  on(getMembersSuccess, (state, action) => ({
    ...state,
    members: action.matches,
  })),
  on(getMembersClear, (state, action) => ({
    ...state,
    members: undefined,
  })),
  on(postDismissMatch, (state, action) => ({
    ...state,
    members: state.members?.filter(match => match.id !== action.matchId)
  }))
);
