import { Component, Input } from '@angular/core';
import { Story } from '../../../ngrx-store/components/story/story.model';
import { AppRoutes } from 'src/app/app-routing.model';

@Component({
  selector: 'app-storycards',
  templateUrl: './storycards.component.html',
  styleUrls: ['./storycards.component.scss']
})
export class StorycardsComponent {
  public AppRoutes = AppRoutes;
  @Input() stories: Story[];
  @Input() viewAllButton = true;

}
