import { createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';
import { OnboardingState, OnboardingStep, Question } from './onboarding.model';
import { initialOnboardingState } from './onboarding.reducer';
import { selectFindMatchForm } from '../userForms/userForms.selectors';
import {UserRole, UserRolesText} from '../userForms/userForms.model';

const selectOnboardingState = (state: AppState) => state.onboardingState;

export const selectShowStepper = createSelector(selectOnboardingState, (state: OnboardingState) =>
  state.activeStep === OnboardingStep.Stepper);

export const selectStepperIndex = createSelector(selectOnboardingState, (state: OnboardingState) =>
  state.stepperIndex);

export const selectActiveQuestionIndex = createSelector(selectOnboardingState, (state: OnboardingState) => state.questionIndex);

export const selectQuestions = createSelector([selectOnboardingState, selectFindMatchForm], (state: OnboardingState, form) => {
  let role = '';
  if ( 'controls' in form) {
    role = form.controls.role.value;
  }
  return initialOnboardingState.questions.filter(q => {
    if (q === Question.Q6) {
      return role === UserRolesText[Object.values(UserRole).indexOf(UserRole.MentorAndMentee)] ||
        role === UserRolesText[Object.values(UserRole).indexOf(UserRole.Mentee)];
    } else if (q === Question.Q7) {
      return role !== UserRolesText[Object.values(UserRole).indexOf(UserRole.Member)];
    }

    return true;
  });
});

export const selectActiveQuestion = createSelector([selectQuestions, selectActiveQuestionIndex], (questions, questionIndex) => {
  if (typeof questions !== 'number' && typeof questionIndex === 'number') {
    return questions[questionIndex];
  } else if (typeof questions !== 'number') {
    return undefined;
  }
});

export const selectIsLastQuestion = createSelector([selectQuestions, selectActiveQuestionIndex], (questions, questionIndex) => {
    if (typeof questions !== 'number') {
      return (questions.length - 1 === questionIndex);
    } else {
      return questions;
    }
  }
);

export const selectOnboardingModel = createSelector(
  [selectShowStepper, selectStepperIndex,
    selectActiveQuestion, selectActiveQuestionIndex, selectQuestions,
    selectIsLastQuestion],
  (showStepper, stepperIndex, activeQuestion, activeQuestionIndex, questions, isLastQuestion) => ({
    showStepper,
    activeQuestion,
    activeQuestionIndex,
    questions,
    stepperIndex,
    isLastQuestion
  })
);
