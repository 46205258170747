import {
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ofType } from '@ngrx/effects';
import { ScannedActionsSubject, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/ngrx-store/app.state';
import {
  selectOnboardingModel,
} from 'src/app/ngrx-store/components/onboarding/onboarding.selectors';
import { selectUserModel } from 'src/app/ngrx-store/components/user/user.selectors';
import {
  selectFindMatchForm,
  selectPersonalDetailsForm,
} from 'src/app/ngrx-store/components/userForms/userForms.selectors';
import {
  getDigitalAreas,
  getIndustries,
  getDigitalAreasSuccess,
  getIndustriesSuccess,
} from 'src/app/ngrx-store/requests/attributes/get-userEntities.actions';
import {
  setStepperIndex,
} from '../../../ngrx-store/components/onboarding/onboarding.actions';
import {
  postUserError,
} from '../../../ngrx-store/requests/user/post-user/post-user.actions';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-onboarding-stepper',
  templateUrl: './onboarding-stepper.component.html',
  styleUrls: ['./onboarding-stepper.component.scss'],
})
export class OnboardingStepperComponent implements OnDestroy {
  public userModel$ = this.store.select(selectUserModel);
  public personalDetailsForm$ = this.store.select(selectPersonalDetailsForm);
  public findMatchForm$ = this.store.select(selectFindMatchForm);
  public onboardingModel$ = this.store.select(selectOnboardingModel);

  @ViewChild('stepper') stepper: MatStepper;

  subscriptions: Subscription;

  loadingCount = 0;
  submittingStep = false;

  constructor(
    private actions$: ScannedActionsSubject,
    private userService: UserService,
    private snackbar: MatSnackBar,
    private store: Store<AppState>
  ) {
    this.subscriptions = this.actions$
      .pipe(ofType(postUserError))
      .subscribe(() => this.showError());
    // this.subscriptions.add(this.actions$.pipe(ofType(postUserSuccess)).subscribe(() => this.gotoNextStep()));
    this.subscriptions.add(
      this.actions$
        .pipe(ofType(getDigitalAreasSuccess, getIndustriesSuccess))
        .subscribe(
          () => (this.loadingCount = Math.max(0, this.loadingCount - 1))
        )
    );

    this.store.dispatch(getDigitalAreas());
    this.store.dispatch(getIndustries());
    this.loadingCount = 2;
  }

  submitStep() {
    this.submittingStep = true;
    this.gotoNextStep();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onStepChange(event: any) {
    this.store.dispatch(setStepperIndex({ stepIndex: event.selectedIndex }));
  }

  public previousStepMatchFinder() {
    this.stepper.previous();

    window.scroll({
      top: 0,
      left: 0,
    });
  }

  public showError() {
    this.submittingStep = false;
    this.snackbar.open(
      'An error occured trying to save your data. Please try again later or contact us if the problem keeps appearing.',
      '',
      {
        duration: 5000,
      }
    );
  }

  public logout() {
    if (confirm('Are you sure you want to log out?')) {
      this.userService.logout();
    }
  }

  private gotoNextStep() {
    this.submittingStep = false;
    if (this.stepper.selectedIndex < this.stepper.steps.length - 1) {
      this.stepper.next();

      window.scroll({
        top: 0,
        left: 0,
      });
    }
  }
}
