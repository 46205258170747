import { BlogItemState} from './blogitem.model';
import {createReducer, on} from '@ngrx/store';
import {getBlogItems, getBlogItemsSuccess} from '../../requests/blogitem/blogitem.actions';

export const initialBlogItemState: BlogItemState = {
  blogItems: []
};

export const blogItemReducer = createReducer<BlogItemState>(
  initialBlogItemState,

  on(getBlogItems,
    (state, action) => ({
      ...state,
      blogItems: []
    })),

  on(getBlogItemsSuccess,
    (state, action) => ({
      ...state,
      blogItems: action.stories,
    })
  )
);
