import { ActionReducerMap, MetaReducer, ActionReducer } from '@ngrx/store';
import { AppState } from './app.state';
import { userReducer } from './components/user/user.reducer';
import { onboardingReducer } from './components/onboarding/onboarding.reducer';
import { matchReducer } from './components/match/match.reducer';
import { userEntitiesReducer } from './components/userEntities/userEntities.reducer';
import { connectionReducer } from './components/connection/connection.reducer';
import { userFormsReducer } from './components/userForms/userForms.reducer';
import { adminReducer } from './components/admin/admin.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';
import { eventReducer } from './components/event/event.reducer';
import { storyReducer } from './components/story/story.reducer';
import { companyFormsReducer } from './components/companyForms/companyForms.reducer';
import { companyReducer } from './components/company/company.reducer';
import { educationReducer } from './components/education/education.reducer';
import { messageReducer } from './components/message/message.reducer';
import { messageFormsReducer } from './components/messageForm/messageForm.reducer';
import { vacancyReducer } from './components/vacancy/vacancy.reducer';
import { vacancyFormsReducer } from './components/vacancyForm/vacancyForm.reducer';
import { blogItemReducer } from './components/blogitem/blogitem.reducer';

// Specify the reducers in our program and what parts of our state they apply to
export const reducers: ActionReducerMap<AppState> = {
  userState: userReducer,
  userFormsState: userFormsReducer,
  onboardingState: onboardingReducer,
  matchState: matchReducer,
  eventState: eventReducer,
  userEntitiesState: userEntitiesReducer,
  storyState: storyReducer,
  educationState: educationReducer,
  connections: connectionReducer,
  adminState: adminReducer,
  companyFormsState: companyFormsReducer,
  companyState: companyReducer,
  messageState: messageReducer,
  messageFormsState: messageFormsReducer,
  vacancyState: vacancyReducer,
  vacancyFormsState: vacancyFormsReducer,
  blogItemState: blogItemReducer,
};

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({ keys: ['onboardingState'], rehydrate: true })(
    reducer
  );
}
export const metaReducers: Array<MetaReducer<any, any>> = [];
