<ng-container *ngIf="self || industryBackgrounds.length > 0">
  <h3 class="section-title">Industries</h3>
  <p class="mt-3">Industry experience</p>
  <mat-chip-listbox class="d-block my-2">
    <mat-chip-option
      color="primary"
      selected
      style="color: white"
      *ngFor="let item of industryBackgrounds; let i = index"
    >
      {{ item.name }}
    </mat-chip-option>
    <a *ngIf="self" [routerLink]="'../edit/industries/background'" class="add-button"
      ><img src="../../../../assets/images/add-button.svg"
    /></a>
  </mat-chip-listbox>
</ng-container>
<ng-container *ngIf="self || industryInterests.length > 0">
  <p class="mt-3">Industries of interest</p>
  <mat-chip-listbox class="d-block my-2" aria-label="List items">
    <mat-chip-option
      color="primary"
      selected
      style="color: white"
      *ngFor="let item of industryInterests; let i = index"
    >
      {{ item.name }}
    </mat-chip-option>
    <a *ngIf="self" [routerLink]="'../edit/industries/interest'" class="add-button"
      ><img src="../../../../assets/images/add-button.svg"
    /></a>
  </mat-chip-listbox>
</ng-container>
