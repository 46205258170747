import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-a2hs-dialog',
  templateUrl: './a2hs-dialog.component.html',
  styleUrls: ['./a2hs-dialog.component.scss']
})
export class A2hsDialogComponent {

  public isAndroid: boolean;

  constructor(private deviceDetector: DeviceDetectorService) {
    this.isAndroid = deviceDetector.device === 'Android';
  }
}
