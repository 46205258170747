<ng-container *ngIf="userModel$ | async as userModel">
  <div class="container">
    <div
      class="col-12 p-0"
      *ngIf="personalDetailsForm$ | async as personalDetailsForm"
    >
      <div class="header-container">
        <p class="align-center color-primary">Work experience</p>
        <a class="back-arrow color-primary" [routerLink]="'../../view'">
          <span></span>
          <mat-icon>arrow_back</mat-icon>
        </a>
        <div class="bevel"></div>
      </div>
      <div *ngIf="!edit" class="experience-container">
        <div class="w-100">
          <div><b>Starting year of your career</b></div>
          <div>{{ userModel.user?.careerStartingYear }}</div>
        </div>
        <div (click)="toggleEdit(true)" class="save-button">
          Edit
          <img
            class="edit-pencil"
            src="../../../../assets/images/dashboard/pencil.svg"
          />
        </div>
      </div>
      <div *ngIf="edit" class="experience-container">
        <app-input-field
          class="w-100"
          [form]="personalDetailsForm"
          [formControl]="personalDetailsForm.controls.careerStartingYear"
          label="Starting year of your career"
          requiredErrorMessage="Please provide a valid starting year"
        ></app-input-field>
        <button
          class="save-button"
          [class.disabled]="personalDetailsForm.isInvalid"
          [disabled]="personalDetailsForm.isInvalid"
          (click)="submitFormAndPost(personalDetailsForm.value)"
        >
          <span>Save</span>
          <mat-icon class="edit-pencil">save_alt</mat-icon>
        </button>
      </div>
      <div
        class="backgroundEntry"
        *ngFor="
          let item of userModel.user?.userBackground?.companies;
          index as i
        "
      >
        <div class="backgroundInfo">
          <p class="m-0">
            {{ item.name }}
          </p>
          <p class="m-0">
            {{ item.position }}
          </p>
          <p class="m-0" *ngIf="item.startDate">
            <em
              >{{ item.startDate | shortdate }} -
              {{ item.endDate ? (item.endDate | shortdate) : "now" }}</em
            >
          </p>
          <p class="m-0">
            {{ item.description }}
          </p>
        </div>
        <div class="editButtons">
          <button
            mat-mini-fab
            color="accent"
            class="col-auto p-0 my-3 mx-2"
            (click)="goToEditWorkExperience(item, i)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            *ngIf="userModel.user?.userBackground?.companies.length > 0"
            mat-mini-fab
            color="warn"
            class="col-aut p-0 my-3 mx-2"
            (click)="removeWorkExperience(i)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>

      <button
        mat-flat-button
        color="primary"
        class="btn-sm col-auto my-3"
        (click)="goToEditWorkExperience()"
      >
        Add work experience
      </button>
    </div>
  </div>
</ng-container>
