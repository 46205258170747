<!-- Type name -->
<mat-form-field *ngIf="allowFilterName" class="w-100">
  <mat-label>Search name</mat-label>
  <input
    class="w-100 input-search-name"
    type="text"
    placeholder="Fill name"
    matInput
    value="{{nameValue}}"
    (input)="changeName($event.target.value)"
  />
</mat-form-field>

<div *ngIf="collapseOnDesktop" (click)="collapsed = !collapsed" id="expand-bar">
  <p>Filter options</p>
  <div *ngIf="collapsed"><i class="fa fa-arrow-down"></i></div>
  <div *ngIf="!collapsed"><i class="fa fa-arrow-up"></i></div>
</div>

<div
  id="filter-list-container"
  [style.transform]="collapsed ? 'scaleY(0)' : 'scaleY(1)'"
  [style.height]="collapsed ? 0 : 'auto'"
>
  <!-- Select mentor/mentee -->
  <app-input-select
    class="w-100"
    [label]="'I\'m searching for'"
    [selectionList]="userRoles"
    [emptyOption]="noPreference"
    (selectionChange)="changeRole($event.value)"
  >
  </app-input-select>

  <!-- Work experience -->
  <app-input-select
    [label]="'Work experience'"
    [selectionList]="workExperienceOptions"
    [emptyOption]="noPreference"
    (selectionChange)="changeExperience($event.value)"
  >
  </app-input-select>

  <!-- Select digital areas -->
  <div class="mt-2 w-100">
    <p class="font-weight-bold">Digital expertise</p>

    <app-input-list-builder
      *ngIf="digitalAreas$ | async as digitalAreas"
      (addInput)="addDigitalAreaExpertise($event)"
      (removeInput)="removeDigitalAreaExpertise($event)"
      [inputList]="digitalAreas"
      [selectedItems]="digitalAreaExpertiseNames"
      [inputPlaceholder]="'Select one or more'"
    ></app-input-list-builder>
  </div>

  <div class="mt-2 w-100">
    <p class="font-weight-bold">Digital areas of interest</p>

    <app-input-list-builder
      *ngIf="digitalAreas$ | async as digitalAreas"
      (addInput)="addDigitalAreaInterest($event)"
      (removeInput)="removeDigitalAreaInterest($event)"
      [inputList]="digitalAreas"
      [selectedItems]="digitalAreaInterestNames"
      [inputPlaceholder]="'Select one or more'"
    ></app-input-list-builder>
  </div>

  <!-- Select industries -->

  <div class="mt-2 w-100">
    <p class="font-weight-bold">Industry experience</p>
    <app-input-list-builder
      class="mt-2"
      *ngIf="industries$ | async as industries"
      (addInput)="addIndustryExperience($event)"
      (removeInput)="removeIndustryExperience($event)"
      [inputList]="industries"
      [selectedItems]="industryExperienceNames"
      [inputPlaceholder]="'Select one or more'"
    ></app-input-list-builder>
  </div>

  <div class="mt-2 w-100">
    <p class="font-weight-bold">Industries of interest</p>
    <app-input-list-builder
      class="mt-2"
      *ngIf="industries$ | async as industries"
      (addInput)="addIndustryInterest($event)"
      (removeInput)="removeIndustryInterest($event)"
      [inputList]="industries"
      [selectedItems]="industryInterestNames"
      [inputPlaceholder]="'Select one or more'"
    ></app-input-list-builder>
  </div>

  <!-- Types company -->
  <app-input-auto-complete
    class="pt-2"
    [label]="'Company'"
    [placeholder]="'Type a company'"
    [inputList]="companies"
    (inputChanged)="changeCompany($event.target.value)"
    (optionSelected)="changeCompany($event.option.value)"
  ></app-input-auto-complete>

  <!-- Type country -->
  <app-input-country
    [placeholder]="'Type a country'"
    [label]="'Country'"
    [emptyOption]="noPreference"
    (selectionChange)="changeCountry($event.value)"
  >
  </app-input-country>

  <!-- Type region -->
  <app-input-auto-complete
    *ngIf="country === 'Netherlands'"
    [label]="'Region'"
    [placeholder]="'Type a region'"
    [inputList]="provincies"
    (inputChanged)="changeRegion($event.target.value)"
    (optionSelected)="changeRegion($event.option.value)"
  >
  </app-input-auto-complete>
</div>
