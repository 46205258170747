import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectPersonalDetailsForm } from '../../../../ngrx-store/components/userForms/userForms.selectors';
import { AppState } from '../../../../ngrx-store/app.state';
import { selectUserModel } from '../../../../ngrx-store/components/user/user.selectors';
import {
  removeCompany,
  setCareerStartingYear,
} from '../../../../ngrx-store/components/user/user.actions';
import { postUser } from '../../../../ngrx-store/requests/user/post-user/post-user.actions';
import { UserCompany } from '../../../../ngrx-store/components/user/user.model';
import { prefillExperienceForm } from '../../../../ngrx-store/components/userForms/profileEditExperience/forms.experience.actions';
import { AppRoutes } from '../../../../app-routing.model';
import { Router } from '@angular/router';
import { PersonalDetailsForm } from 'src/app/ngrx-store/components/userForms/onboardingPersonalDetails/forms.personalDetails.model';

@Component({
  selector: 'app-edit-work-experience',
  templateUrl: './edit-work-experience.component.html',
  styleUrls: ['./edit-work-experience.component.scss'],
})
export class EditWorkExperienceComponent {
  public personalDetailsForm$ = this.store.select(selectPersonalDetailsForm);
  public userModel$ = this.store.select(selectUserModel);
  public edit = false;

  constructor(
    private readonly store: Store<AppState>,
    private router: Router
  ) {}

  public goToEditWorkExperience(company?: UserCompany, index?: number) {
    if (company && index !== undefined) {
      this.store.dispatch(prefillExperienceForm({ company, index }));
    }

    this.router.navigateByUrl(`${AppRoutes.PROFILE}/edit/experience`);
  }

  public submitFormAndPost(formValues: PersonalDetailsForm) {
    this.store.dispatch(
      setCareerStartingYear({ startingYear: Number(formValues.careerStartingYear) })
    );
    this.store.dispatch(postUser());

    this.edit = false;
  }

  public removeWorkExperience(index: number) {
    this.store.dispatch(removeCompany({ index }));
    this.store.dispatch(postUser());
  }

  toggleEdit(edit: boolean): void {
    this.edit = edit;
  }
}
