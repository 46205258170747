import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/ngrx-store/app.state';
import {onboardingAgain} from 'src/app/ngrx-store/components/onboarding/onboarding.actions';
import {addWelcomeNotifications} from 'src/app/ngrx-store/requests/user/get-user/get-user.actions';
import {AppRoutes} from 'src/app/app-routing.model';
import {User} from 'src/app/ngrx-store/components/user/user.model';
import {MatDialog} from '@angular/material/dialog';
import {ProfileDialogComponent} from 'src/app/shared/components/profile-dialog/profile-dialog.component';
import {UserService} from '../../../services/user.service';
import {Connection} from '../../../ngrx-store/components/connection/connection.model';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss'],
})
export class ProfileViewComponent implements OnInit {
  public user: User;
  public connection: Connection | undefined;

  public AppRoutes = AppRoutes;

  constructor(private readonly store: Store<AppState>,
              private dialog: MatDialog,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.userService.getUser().subscribe((user) => this.user = user);
  }

  getUser() {
    this.userService.getUser().subscribe((user) => this.user = user);
  }

  addWelcomeNotifications(): void {
    this.store.dispatch(addWelcomeNotifications());
  }

  doOnboardingAgain(): void {
    this.store.dispatch(onboardingAgain());
  }

  public openProfile(user: User) {
    this.dialog.open(ProfileDialogComponent, {
      data: {
        profile: user,
      },
      autoFocus: false,
    });
  }
}
