<div *ngIf="self$ | async as self">
  <app-profile-details
    class="w-100"
    [user]="user"
    [connection]="connection"
    [hideConnectButton]="user.id == self.id"
    [self]="false"
  ></app-profile-details>

  <div class="bevel" *ngIf="user.userBackground?.companies.length > 0"></div>

  <app-profile-work-experience
    class="w-100"
    [companies]="user.userBackground?.companies"
    *ngIf="user.userBackground?.companies.length > 0"
  >
  </app-profile-work-experience>

  <div class="bevel" *ngIf="user.userBackground?.educations.length > 0"></div>

  <app-profile-education
    class="w-100"
    [educations]="user.userBackground?.educations"
    *ngIf="user.userBackground?.educations.length > 0"
  >
  </app-profile-education>

  <div
    class="bevel"
    *ngIf="
      user?.userBackground?.digitalAreas.length +
        user?.userInterests?.digitalAreas.length >
      0
    "
  ></div>

  <app-profile-digital-areas
    class="w-100"
    [digitalAreaBackgrounds]="user?.userBackground?.digitalAreas"
    [digitalAreaInterests]="user?.userInterests?.digitalAreas"
  >
  </app-profile-digital-areas>

  <div
    class="bevel"
    *ngIf="
      user?.userBackground?.industries.length +
        user?.userInterests?.industries.length >
      0
    "
  ></div>

  <app-profile-industries
    class="w-100"
    [industryBackgrounds]="user?.userBackground?.industries"
    [industryInterests]="user?.userInterests?.industries"
  >
  </app-profile-industries>

  <ng-container *ngIf="connection && !connection.isPending">
    <div class="bevel"></div>
    <app-profile-social-media [user]="user"></app-profile-social-media>
  </ng-container>
</div>
