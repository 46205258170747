<div id="loading-wrapper" *ngIf="loadingUser; else userLoaded">
  <mat-spinner [diameter]="65"></mat-spinner>
</div>

<ng-template #userLoaded>
  <ng-container *ngIf="activePage === 'onboarding'">
    <router-outlet></router-outlet>
  </ng-container>

  <ng-container *ngIf="activePage !== 'onboarding'">
    <app-vertical-stepper #verticalstepper>
      <app-step [completed]="true">
        <app-menubar (logoutClicked)="logout()"></app-menubar>
        <div class="content" style="min-height: 100vh">
          <!-- Desktop menu -->
          <div id="left-desktop-content-wrapper">
            <div id="desktop-menu">
              <ng-container *ngIf="enableNonAdminFeatures">
              <div class="desktop-menu-item">
                <div class="menu-icon">
                  <i class="fas fa-briefcase"></i>
                </div>
                <a
                  [routerLink]="AppRoutes.VACANCIES"
                  [routerLinkActive]="['menu-item-active']"
                >
                  Job opportunities
                </a>
              </div>

              <div class="desktop-menu-item">
                <div class="menu-icon">
                  <i class="fas fa-graduation-cap"></i>
                </div>
                <a
                  [routerLink]="AppRoutes.EDUCATION"
                  [routerLinkActive]="['menu-item-active']"
                >
                  Learn and grow
                </a>
              </div>
              <div class="desktop-menu-item">
                <div class="menu-icon">
                  <i class="fas fa-calendar-day"></i>
                </div>
                <a
                  [routerLink]="AppRoutes.EVENTS"
                  [routerLinkActive]="['menu-item-active']"
                >
                  Join events
                </a>
              </div>
              <div class="desktop-menu-item">
                <div class="menu-icon">
                  <i class="fas fa-lightbulb"></i>
                </div>
                <a
                  [routerLink]="AppRoutes.STORIES"
                  [routerLinkActive]="['menu-item-active']"
                >
                  Inspiring stories
                </a>
              </div>
              <div class="desktop-menu-item">
                <div class="menu-icon">
                  <i class="fas fa-link"></i>
                </div>
                <a
                  [routerLink]="AppRoutes.BLOGITEMS"
                  [routerLinkActive]="['menu-item-active']">
                  Discover blogs </a>
              </div>
              <div class="desktop-menu-item">
                <div class="menu-icon">
                  <i class="fas fa-handshake"></i>
                </div>
                <a
                  [routerLink]="AppRoutes.COMPANIES"
                  [routerLinkActive]="['menu-item-active']"
                >
                  Our partners
                </a>
              </div>
              <br />
              </ng-container>
              <ng-container *ngIf="company$ | async">
                <div class="desktop-menu-item">
                  <div class="menu-icon">
                    <i class="fas fa-building"></i>
                  </div>
                  <a
                    [routerLink]="AppRoutes.COMPANY"
                    [routerLinkActive]="['menu-item-active']"
                    data-cy="manage-company-link"
                  >
                    Manage company
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="admin">
                <div
                  class="desktop-menu-item"
                  *ngIf="admin.isAdmin || admin.isPartner"
                >
                  <div class="menu-icon">
                    <i class="fas fa-toolbox"></i>
                  </div>
                  <a
                    [routerLink]="AppRoutes.ADMIN"
                    [routerLinkActive]="['menu-item-active']"
                    data-cy="special-user-nav-button"
                  >
                    {{ admin.isAdmin ? "Admin dashboard" : "Partner features" }}
                  </a>
                </div>
              </ng-container>
            </div>
          </div>
          <div id="main-content">
            <router-outlet></router-outlet>
          </div>
        </div>

        <!-- footer -->
        <app-footer></app-footer>

        <div class="spacer"></div>

        <!-- mobile menu -->
        <div id="mobile-menu">
          <div class="w-100 position-relative">
            <table id="menubar">
              <tr>
                <td style="text-align: center">
                  <a [routerLink]="AppRoutes.DASHBOARD">
                    <div class="mobile-menu-item h-100">
                      <i class="fa fa-home"></i>
                    </div>
                  </a>
                </td>
                <td *ngIf="enableNonAdminFeatures">
                  <a [routerLink]="AppRoutes.NOTIFICATIONS">
                    <div
                      *ngIf="
                        notificationCount$ | async as notifications;
                        else noNotifications
                      "
                    >
                      <span
                        matBadge="{{ notifications }}"
                        matBadgeSize="small"
                        matBadgeColor="warn"
                      >
                        <img
                          class="mobile-menu-item"
                          src="../assets/images/menu/notifications.svg"
                        />
                      </span>
                    </div>
                  </a>
                  <ng-template #noNotifications>
                    <img
                      class="mobile-menu-item"
                      src="../assets/images/menu/notifications.svg"
                    />
                  </ng-template>
                </td>
                <td style="text-align: center"  *ngIf="enableNonAdminFeatures">
                  <a [routerLink]="AppRoutes.CONNECTIONS">
                    <img
                      class="mobile-menu-item"
                      src="../assets/images/menu/connections.svg"
                    />
                  </a>
                </td>
                <td style="text-align: center"  *ngIf="enableNonAdminFeatures">
                  <a [routerLink]="AppRoutes.CHATS">
                    <img
                      class="mobile-menu-item"
                      src="../assets/images/menu/chat.svg"
                    />
                  </a>
                </td>
                <td style="text-align: center">
                  <img
                    (click)="openMenu()"
                    class="mobile-menu-item"
                    style="height: 35px"
                    src="../assets/images/menu/menu.svg"
                  />
                </td>
              </tr>
            </table>
            <img
              (click)="scrollToTop()"
              class="scroll-button"
              src="../assets/images/scroll-knop.svg"
            />
          </div>
        </div>

        <svg class="hidden">
          <defs>
            <symbol
              id="close"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x-square"
            >
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
              <line x1="9" y1="9" x2="15" y2="15"></line>
              <line x1="15" y1="9" x2="9" y2="15"></line>
            </symbol>
          </defs>
        </svg>
      </app-step>

      <app-step [completed]="true">
        <header class="menu-header">
          <a [routerLink]="AppRoutes.SETTINGS">
            <img
              class="header-menu-img"
              (click)="closeMenu()"
              src="../assets/images/menu/settings.svg"
            />
          </a>
          <img
            class="header-menu-img"
            (click)="closeMenu()"
            src="../assets/images/menu/close.svg"
          />
        </header>
        <div class="align-center">
          <ng-container *ngIf="enableNonAdminFeatures">
          <a
            (click)="closeMenu()"
            [routerLink]="AppRoutes.VACANCIES"
            [routerLinkActive]="['underlined-menu-item']"
            ><p class="menu-item">Job opportunities</p>
            <div></div> </a
          ><br />
          <a
            (click)="closeMenu()"
            [routerLink]="AppRoutes.EDUCATION"
            [routerLinkActive]="['underlined-menu-item']"
            ><p class="menu-item">Learn and grow</p>
            <div></div> </a
          ><br />
          <a
            (click)="closeMenu()"
            [routerLink]="AppRoutes.EVENTS"
            [routerLinkActive]="['underlined-menu-item']"
            ><p class="menu-item">Join events</p>
            <div></div> </a
          ><br />
          <a
            (click)="closeMenu()"
            [routerLink]="AppRoutes.STORIES"
            [routerLinkActive]="['underlined-menu-item']"
            ><p class="menu-item">Inspiring stories</p>
            <div></div> </a
          ><br />
          <a
            (click)="closeMenu();"
            [routerLink]="AppRoutes.BLOGITEMS"
              [routerLinkActive]="['underlined-menu-item']">
            <p class="menu-item">Discover blogs</p>
            <div></div> </a
          ><br />
          <a
            (click)="closeMenu()"
            [routerLink]="AppRoutes.COMPANIES"
            [routerLinkActive]="['underlined-menu-item']"
            ><p class="menu-item">Our partners</p>
            <div></div> </a
          ><br />
          <br />
          <br />
          </ng-container>
          <ng-container *ngIf="company$ | async">
            <a
              (click)="closeMenu()"
              [routerLink]="AppRoutes.COMPANY"
              [routerLinkActive]="['underlined-menu-item']"
              ><p class="menu-item">Manage company</p>
              <div></div> </a
            ><br />
          </ng-container>
          <ng-container *ngIf="admin.isAdmin">
            <a
              (click)="closeMenu()"
              [routerLink]="AppRoutes.ADMIN"
              [routerLinkActive]="['underlined-menu-item']"
              ><p class="menu-item">Admin panel</p>
              <div></div> </a
            ><br />
          </ng-container>
          <br />
        </div>
        <div>
          <div class="ellipse">
            <img
              class="logo-menu"
              src="../assets/images/RightbrainsLogoWhite.png"
            />
            <p class="menu-footer-text">For women with digital ambitions</p>
          </div>
        </div>
      </app-step>
    </app-vertical-stepper>
  </ng-container>
</ng-template>
