import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../ngrx-store/components/user/user.model';
import { UserEntity } from '../ngrx-store/components/userEntities/userEntities.model';

@Injectable()
export class AdminService {
  constructor(protected http: HttpClient) {}

  public getUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.apiBaseUrl}admin/allusers`);
  }

  public setRights(id: string, rights: { isAdmin: boolean; isPartner: boolean }): Observable<User> {
    return this.http.post<any>(`${environment.apiBaseUrl}admin/setrights/${id}`, rights);
  }

  public removeUserFromCompany(id: string): Observable<User> {
    return this.http.post<any>(`${environment.apiBaseUrl}admin/removefromcompany/${id}`, {});
  }

  public addUserToCompany(id: string, companyId: string): Observable<User> {
    return this.http.post<any>(`${environment.apiBaseUrl}admin/addtocompany/${id}`, {companyId});
  }

  public setIndustries(industries: UserEntity[]): Observable<UserEntity[]> {
    return this.http.post<UserEntity[]>(`${environment.apiBaseUrl}admin/industries`, industries);
  }

  public setDigitalAreas(digitalAreas: UserEntity[]): Observable<UserEntity[]> {
    return this.http.post<UserEntity[]>(`${environment.apiBaseUrl}admin/digitalareas`, digitalAreas);
  }

  public getUserExcel(): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}admin/getuserexcel`, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  public getPartnerExcel(): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}admin/getpartnerexcel`, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  public getEventExport(id: string): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}admin/eventregistrations/${id}`, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }
}
