<div *ngIf="companyEventForm$ | async as form">
  <app-input-field
    [form]="form"
    [formControl]="form.controls.name"
    label="Event title"
    requiredErrorMessage="Please fill in this field"
    data-cy="company-event-title"
  ></app-input-field>
  <app-input-field
    [form]="form"
    [formControl]="form.controls.eventDate"
    label="Date"
    placeholder="DD-MM-YYYY"
    requiredErrorMessage="Please fill in this field"
    patternErrorMessage="Please use the following format: DD-MM-YYYY (example: 21-03-2022)"
    data-cy="company-event-date"
  ></app-input-field>
  <app-input-field
    [form]="form"
    [formControl]="form.controls.beginTime"
    label="Begin time"
    placeholder="HH:MM"
    requiredErrorMessage="Please fill in this field"
    patternErrorMessage="Please use the following format: HH-MM (example: 19:30)"
    data-cy="company-event-start-time"
  ></app-input-field>
  <app-input-field
    [form]="form"
    [formControl]="form.controls.endTime"
    label="End time"
    placeholder="HH:MM"
    requiredErrorMessage="Please fill in this field"
    patternErrorMessage="Please use the following format: HH-MM (example: 19:30)"
    data-cy="company-event-end-time"
  ></app-input-field>
  <app-input-text-area
    [form]="form"
    [formControl]="form.controls.description"
    label="Description"
    requiredErrorMessage="Please fill in this field"
    maxLengthErrorMessage="Text should contain no more than 1000 characters"
    data-cy="company-event-description"
  ></app-input-text-area>
  <app-input-file
    [uploadedReference]="form.controls.imageUrl.value"
    title="Add photo (max. 2MB, PNG/JPEG)"
    fileName="Current photo"
    (onFileUpload)="addPhoto($event)"
    (onFileDelete)="deletePhoto()"
    label="Photo"
  ></app-input-file>
  <app-input-field
    [form]="form"
    [formControl]="form.controls.link"
    label="Link event"
    patternErrorMessage="Please use a valid url"
    data-cy="company-event-link"
  ></app-input-field>
</div>
