<div class="horizontal-stepper-header-container" *ngIf="showHeader">
  <div class="row p-0 w-100 text-center" >
    <div style="height: 38px;text-align: left" class="col-12">
      <button
        *ngIf="backButtonEnabled"
        [hidden]="selectedIndex == 0 || selectedIndex == 2"
        mat-button
        color="primary"
        appStepperPrevious
        class="align-self-start p-0 h-100"
      >
        <span></span><mat-icon>arrow_back</mat-icon> Back
      </button>
    </div>
    <div style="width: 100%;" *ngIf="selectedStep.title">
      <div class="underlined-title">
        <p class="stepper-title text-primary font-weight-bold m-0">
         {{ selectedStep.title }}
        </p>
        <div></div>
      </div>
    </div>
    <app-stepper-progress
      *ngIf="progressBarEnabled"
      class="col-12 mt-2 mb-4 stepper-progress"
      [stepIndex]="selectedIndex"
      [totalSteps]="steps.length"
    ></app-stepper-progress>
  </div>
</div>

<div class="horizontal-content-container mb-2" style="overflow:visible;">
  <div
    *ngFor="let step of steps; let i = index"
    [attr.tabindex]="selectedIndex === i ? 0 : null"
    class="horizontal-stepper-content"
    role="tabpanel"
    [@stepTransition]="_getAnimationDirection(i)"
    (@stepTransition.done)="animationDone.next($event)"
    [id]="_getStepContentId(i)"
    [attr.aria-labelledby]="_getStepLabelId(i)"
    [attr.aria-expanded]="selectedIndex === i"
  >
    <ng-container [ngTemplateOutlet]="step.content"></ng-container>
  </div>
</div>
