import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, withLatestFrom, exhaustMap } from 'rxjs/operators';
import { AppState } from 'src/app/ngrx-store/app.state';
import { UserService } from 'src/app/services/user.service';

import {
  finishOnboarding,
  onboardingAgain,
} from './onboarding.actions';
import { selectUser } from '../user/user.selectors';
import { AppRoutes } from 'src/app/app-routing.model';
import { postUserSuccess, postUserError } from '../../requests/user/post-user/post-user.actions';

@Injectable()
export class OnboardingEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private userService: UserService,
    private router: Router
  ) {}

  finishOnboarding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(finishOnboarding),
      withLatestFrom(this.store.pipe(select(selectUser))),
      exhaustMap(([_, user]) => {
        return this.userService.postUser(user).pipe(
          map((userResponse) => {
            return postUserSuccess({ user: userResponse });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(postUserError(error));
          })
        );
      })
    )
  );

  onboardingAgain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onboardingAgain),
      withLatestFrom(this.store.pipe(select(selectUser))),
      exhaustMap(([_, user]) => {
        return this.userService.postUser(user).pipe(
          map((userResponse) => {
            this.router.navigateByUrl(`/${AppRoutes.ONBOARDING}`);
            return postUserSuccess({ user: userResponse });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(postUserError(error));
          })
        );
      })
    )
  );
}
