export const environment = {
  production: false,
  apiBaseUrl: 'https://api.test.rightbrains.navara.cloud/api/v1/',
  signUpSignInAuthUrl: 'https://rightbrainsb2c.b2clogin.com/rightbrainsb2c.onmicrosoft.com/b2c_1_sign_in_up',
  resetPasswordAuthUrl: 'https://rightbrainsb2c.b2clogin.com/rightbrainsb2c.onmicrosoft.com/b2c_1_forgot_v3',
  editAuthUrl: 'https://rightbrainsb2c.b2clogin.com/rightbrainsb2c.onmicrosoft.com/b2c_1_edit',
  protectedScopes: ['https://rightbrainsb2c.onmicrosoft.com/backend/read'],
  msalClientId: '0f12c63a-d474-4220-924c-fae7233af509',
  authorityDomain: 'rightbrainsb2c.b2clogin.com',
  b2cRedirectUri: '/dashboard',
  featureToggles: {
    enableNonAdminFeatures: true
  }
};
