<ng-container *ngIf="self || companies.length > 0">
  <table class="w-100">
    <tr>
      <td>
        <p class="section-title">Work experience</p>
      </td>
      <td *ngIf="self">
        <div class="edit-cell">
          <a class="edit-link" [routerLink]="'../edit/workexperience'">
            Edit
            <img
              class="edit-pencil"
              src="../../../../assets/images/dashboard/pencil.svg"
            />
          </a>
        </div>
      </td>
    </tr>
  </table>
  <ng-container>
    <ng-container *ngIf="companies.length > 0; else noCompanies">
      <div class="backgroundItem my-3" *ngFor="let item of companies">
        <p class="m-0">
          {{ item.name }}<br />
          {{ item.position }}
        </p>
        <p class="m-0 font-italic experience-date" *ngIf="item.startDate">
          {{ item.startDate | shortdate }} -
          {{ item.endDate ? (item.endDate | shortdate) : "Current" }}
        </p>
        <p class="m-0 experience-description">
          {{ item.description }}
        </p>
      </div>
    </ng-container>
    <ng-template #noCompanies>
      <a *ngIf="self" [routerLink]="'../edit/workexperience'">
        <button
          mat-flat-button
          class="btn-sm mx-auto no-company-button"
          color="primary"
        >
          Add experience
        </button>
      </a>
    </ng-template>
  </ng-container>
</ng-container>
