import { BooleanInput } from '@angular/cdk/coercion';
import { CdkStepper, StepContentPositionState, CdkStep, STEPPER_GLOBAL_OPTIONS, StepperOptions } from '@angular/cdk/stepper';
import {
  AfterContentInit, Component, ContentChildren, Directive, EventEmitter, Input, Output,
  QueryList, TemplateRef, ViewChildren, ViewEncapsulation, ChangeDetectionStrategy, ContentChild, Inject, forwardRef, SkipSelf, Optional
} from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AnimationEvent } from '@angular/animations';
import { stepperAnimations } from './stepper-animations';
import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { StepLabelDirective } from './step-label.directive';
import { StepperProgressComponent } from './stepper-progress.component';
import { StepperIconDirective, StepperIconContext } from './stepper-icon.directive';

@Component({
  selector: 'app-step',
  templateUrl: 'step.html',
  providers: [
    { provide: ErrorStateMatcher, useExisting: StepComponent },
    { provide: CdkStep, useExisting: StepComponent },
  ],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'matStep',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepComponent extends CdkStep implements ErrorStateMatcher {
  /** Content for step label given by `<ng-template matStepLabel>`. */
  @ContentChild(StepLabelDirective) stepLabel: StepLabelDirective;

  @Input() title = '';

  /** @breaking-change 8.0.0 remove the `?` after `stepperOptions` */
  constructor(@Inject(forwardRef(() => StepperDirective)) stepper: StepperDirective,
              @SkipSelf() private errorStateMatcher: ErrorStateMatcher,
              @Optional() @Inject(STEPPER_GLOBAL_OPTIONS) stepperOptions?: StepperOptions) {
    super(stepper, stepperOptions);
  }

  /** Custom error state matcher that additionally checks for validity of interacted form. */
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const originalErrorState = this.errorStateMatcher.isErrorState(control, form);

    // Custom error state checks for the validity of form that is not submitted or touched
    // since user can trigger a form change by calling for another step without directly
    // interacting with the current form.
    const customErrorState = !!(control && control.invalid && this.interacted);

    return originalErrorState || customErrorState;
  }
}

@Directive({ selector: '[appStepper]', providers: [{ provide: CdkStepper, useExisting: StepperDirective }] })
export class StepperDirective extends CdkStepper implements AfterContentInit {

  get selectedStep() {
    return this.selected as StepComponent;
  }
  static ngAcceptInputTypeEditable: BooleanInput;
  static ngAcceptInputTypeOptional: BooleanInput;
  static ngAcceptInputTypeCompleted: BooleanInput;
  static ngAcceptInputTypeHasError: BooleanInput;

  /** The list of step headers of the steps in the stepper. */
  @ViewChildren(StepperProgressComponent) stepHeader: QueryList<StepperProgressComponent>;

  /** Custom icon overrides passed in by the consumer. */
  @ContentChildren(StepperIconDirective, { descendants: true }) icons: QueryList<StepperIconDirective>;

  /** Event emitted when the current step is done transitioning in. */
  @Output() readonly animationDone: EventEmitter<void> = new EventEmitter<void>();

  /** Whether ripples should be disabled for the step headers. */
  @Input() disableRipple: boolean;

  /** Consumer-specified template-refs to be used to override the header icons. */
  public iconOverrides: { [key: string]: TemplateRef<StepperIconContext> } = {};

  /** Stream of animation `done` events when the body expands/collapses. */
  isAnimationDone = new Subject<AnimationEvent>();

  ngAfterContentInit() {
    super.ngAfterContentInit();
    this.icons.forEach(({ name, templateRef }) => this.iconOverrides[name] = templateRef);

    // Mark the component for change detection whenever the content children query changes
    this.steps.changes.pipe(takeUntil(this._destroyed)).subscribe(() => {
      this._stateChanged();
    });

    this.isAnimationDone.pipe(
      // This needs a `distinctUntilChanged` in order to avoid emitting the same event twice due
      // to a bug in animations where the `.done` callback gets invoked twice on some browsers.
      // See https://github.com/angular/angular/issues/24084
      distinctUntilChanged((x, y) => x.fromState === y.fromState && x.toState === y.toState),
      takeUntil(this._destroyed)
    ).subscribe(event => {
      if ((event.toState as StepContentPositionState) === 'current') {
        this.animationDone.emit();
      }
    });
  }
}

@Component({
  selector: 'app-horizontal-stepper',
  exportAs: 'horizontalStepper',
  templateUrl: './stepper-horizontal.component.html',
  styleUrls: ['./stepper.scss'],
  animations: [stepperAnimations.horizontalStepTransition],
  providers: [
    { provide: StepperDirective, useExisting: HorizontalStepperComponent },
    { provide: CdkStepper, useExisting: HorizontalStepperComponent }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalStepperComponent extends StepperDirective {
  static ngAcceptInputTypeEditable: BooleanInput;
  static ngAcceptInputTypeOptional: BooleanInput;
  static ngAcceptInputTypeCompleted: BooleanInput;
  static ngAcceptInputTypeHasError: BooleanInput;

  @Input() progressBarEnabled = true;
  @Input() backButtonEnabled = true;
  @Input() showHeader = true;
}

@Component({
  selector: 'app-vertical-stepper',
  exportAs: 'verticalStepper',
  templateUrl: './stepper-vertical.component.html',
  styleUrls: ['./stepper.scss'],
  animations: [stepperAnimations.verticalStepTransition],
  providers: [
    { provide: StepperDirective, useExisting: VerticalStepperComponent },
    { provide: CdkStepper, useExisting: VerticalStepperComponent }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalStepperComponent extends StepperDirective {
  static ngAcceptInputTypeEditable: BooleanInput;
  static ngAcceptInputTypeOptional: BooleanInput;
  static ngAcceptInputTypeCompleted: BooleanInput;
  static ngAcceptInputTypeHasError: BooleanInput;

  @Input() showLabel = false;
  @Input() showProgress = false;
}
