import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserEntity } from '../ngrx-store/components/userEntities/userEntities.model';

@Injectable()
export class UserEntityService {
  constructor(protected http: HttpClient) { }

  public getIndustriesList(): Observable<UserEntity[]> {
    return this.http.get<UserEntity[]>(`${environment.apiBaseUrl}industries`);
  }

  public getDigitalAreasList(): Observable<UserEntity[]> {
    return this.http.get<UserEntity[]>(`${environment.apiBaseUrl}digital-areas`);
  }
}
