import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company } from '../../../ngrx-store/components/company/company.model';

@Component({
  selector: 'app-company-dialog',
  templateUrl: './company-dialog.component.html',
  styleUrls: ['./company-dialog.component.scss']
})
export class CompanyDialogComponent {
  @Input() company: Company | undefined = undefined;

  constructor(@Inject(MAT_DIALOG_DATA) data: { company: Company }) {
    this.company = data.company;
  }
}
