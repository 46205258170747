import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Company } from 'src/app/ngrx-store/components/company/company.model';
import { AppState } from 'src/app/ngrx-store/app.state';
import { followCompany, unfollowCompany } from 'src/app/ngrx-store/requests/company/get-company/get-company.actions';

@Component({
  selector: 'app-company-details-follow',
  templateUrl: './company-details-follow.component.html',
  styleUrls: ['./company-details-follow.component.scss']
})
export class CompanyDetailsFollowComponent implements OnInit {
  @Input() company?: Company;

  public isFollowing: boolean;

  constructor(private readonly store: Store<AppState>) {
  }

  ngOnInit() {
    if (this.company) {
      this.isFollowing = this.company.isFollowing;
    }
  }

  followToggle() {
    if (!this.company?.id) {
      return;
    }
    if (this.isFollowing) {
      this.store.dispatch(unfollowCompany({id: this.company.id}));
    } else {
      this.store.dispatch(followCompany({id: this.company.id}));
    }
    this.isFollowing = !this.isFollowing;
  }
}
