import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppRoutes } from 'src/app/app-routing.model';
import { Vacancy } from '../../../ngrx-store/components/vacancy/vacancy.model';
import { VacancyDialogComponent } from '../vacancy-dialog/vacancy-dialog.component';
import { Company } from '../../../ngrx-store/components/company/company.model';
import { CompanyDialogComponent } from '../company-dialog/company-dialog.component';

@Component({
  selector: 'app-vacancycard',
  templateUrl: './vacancycard.component.html',
  styleUrls: ['./vacancycard.component.scss'],
})
export class VacancycardComponent {
  public AppRoutes = AppRoutes;
  @Input() vacancy: Vacancy;
  @Input() company: Company;
  @Input() viewCompanyInfo = true;

  constructor(private dialog: MatDialog) {}

  public openDialog() {
    this.dialog.open(VacancyDialogComponent, {
      data: {
        vacancy: this.vacancy,
        company: this.company,
      },
    });
  }

  public openCompanyDialog(company: Company) {
    this.dialog.open(CompanyDialogComponent, {
      data: {
        company,
      },
    });
  }
}
