import {Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {Store, select, ScannedActionsSubject} from '@ngrx/store';
import {AppState} from 'src/app/ngrx-store/app.state';
import {getConnections, getConnectionsSuccess, getPendingConnections} from 'src/app/ngrx-store/components/connection/connection.actions';
import {selectConfirmedConnections} from 'src/app/ngrx-store/components/connection/connection.selectors';
import {Subscription} from 'rxjs';
import {ofType} from '@ngrx/effects';
import {SearchFilter} from 'src/app/ngrx-store/components/match/match.model';
import {UserRole} from 'src/app/ngrx-store/components/userForms/userForms.model';
import {Connection} from 'src/app/ngrx-store/components/connection/connection.model';
import {InputFieldComponent} from '../../shared/forms/input-field/input-field.component';
import {FilterUserListComponent} from '../../shared/components/filter-user-list/filter-user-list.component';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss'],
})
export class ConnectionsComponent implements OnInit, OnDestroy {

  constructor(private store: Store<AppState>, private cdr: ChangeDetectorRef, private actions$: ScannedActionsSubject) {
    this.callbackSubscription = this.actions$.pipe(ofType(getConnectionsSuccess)).subscribe(connections => {
      this.cdr.detectChanges();
      this.loading = false;
      if (this.filter?.page === 0) {
        this.connections = connections.connections.filter(c => !c.isPending);
      } else {
        this.connections = this.connections.concat(connections.connections.filter(c => !c.isPending));
      }
    });
  }

  connections: Connection[] = [];

  callbackSubscription: Subscription;
  filteredConnections: Connection[] | undefined = undefined;
  loading = false;
  showFilter = false;
  filter: SearchFilter | undefined = undefined;

  @ViewChild('nameField') nameField: InputFieldComponent;
  @ViewChild('filterList') filterList: FilterUserListComponent;

  private static getEmptyFilter(): SearchFilter {
    return {
      educationNames: undefined,
      industryInterestNames: undefined,
      industryExperienceNames: undefined,
      digitalAreaInterestNames: undefined,
      digitalAreaExpertiseNames: undefined,
      region: undefined,
      workExperience: undefined,
      name: undefined,
      company: undefined,
      country: undefined,
      page: 0
    };
  }

  ngOnDestroy(): void {
    this.callbackSubscription.unsubscribe();
  }

  showMore() {
    if (this.filter) {
      const filter = {...this.filter};
      if (!filter.page) {
        filter.page = 0;
      }
      filter.page += 1;
      this.filter = filter;
      this.store.dispatch(getConnections({filter: this.filter}));
    }
  }


  ngOnInit() {
    this.loading = true;
    this.store.dispatch(getConnections({filter: ConnectionsComponent.getEmptyFilter()}));
    this.store.dispatch(getPendingConnections());
    this.filter = ConnectionsComponent.getEmptyFilter();
  }

  filterChangeWrapper(newFilter: { filter: SearchFilter; selectedRole: UserRole | undefined }) {
    if (newFilter.filter.name) {
      this.nameField.value = newFilter.filter.name;
    }
    this.filterChange(newFilter.filter);
  }

  filterChange(newFilter: SearchFilter) {
    newFilter.page = 0;
    this.filter = newFilter;
    this.loading = true;
    this.store.dispatch(getConnections({filter: newFilter}));
  }

  updateList(newList: Connection[]) {
    this.filteredConnections = newList;
    this.cdr.detectChanges();
  }

  public toggleFilters() {
    this.showFilter = !this.showFilter;
  }

  public nameFieldChange(value: string) {
    this.filterList.nameValue = value;

    let filter: SearchFilter;
    if (!this.filter) {
      filter = ConnectionsComponent.getEmptyFilter();
    } else {
      filter = {...this.filter};
    }
    filter.name = value;
    this.filterChange(filter);
  }
}
