<div *ngIf="companyDetailsForm$ | async as form">
  <app-company-details-header
    title="Country"
    *ngIf="!isSignUp"
    [canEdit]="!company"
    [modify]="modify"
    (onModify)="toggleModify($event)"
    [paddingBottom]="false"
    data-cy="company-country-title"
  ></app-company-details-header>

  <div *ngIf="company; else self">
    {{ company.country }}
  </div>

  <ng-template #self>
    <app-input-country
      *ngIf="modify || isSignUp"
      [form]="form"
      [formControl]="form.controls.country"
      [label]="isSignUp ? 'Country' : ''"
      emptyOption="Select country"
      requiredErrorMessage = 'Please select a country'
    ></app-input-country>

    <div *ngIf="!modify && !isSignUp">
      {{ form.controls.country.value }}
    </div>
  </ng-template>
</div>
