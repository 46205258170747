import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Connection } from 'src/app/ngrx-store/components/connection/connection.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import {
  acceptConnection,
  denyConnection,
  sendConnectionError,
  sendConnectionSuccess,
  denyConnectionSuccess,
} from 'src/app/ngrx-store/components/connection/connection.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-connection-request',
  templateUrl: './connection-request.component.html',
  styleUrls: ['./connection-request.component.scss'],
})
export class ConnectionRequestComponent implements OnInit, OnDestroy {
  @Input() connection: Connection;

  pendingRequest = false;
  subscriptions: Subscription;

  constructor(private store: Store<AppState>, private actions$: Actions, private snackbar: MatSnackBar) {}

  ngOnInit() {
    this.subscriptions = this.actions$.pipe(ofType(sendConnectionError)).subscribe(({ error }) => {
      this.pendingRequest = false;
      this.snackbar.open(
        'An error occured trying to accept or deny this connection request. Please try again later',
        '',
        {
          duration: 5000,
          panelClass: ['failed-snackbar'],
        }
      );
    });
    this.subscriptions.add(
      this.actions$.pipe(ofType(sendConnectionSuccess, denyConnectionSuccess)).subscribe(() => {
        this.pendingRequest = false;
      })
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  accept() {
    this.pendingRequest = true;
    this.store.dispatch(acceptConnection({ userId: this.connection.user.id }));
  }

  decline() {
    this.pendingRequest = true;
    this.store.dispatch(denyConnection({ userId: this.connection.user.id }));
  }
}
