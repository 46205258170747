import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {ConnectionsComponent} from './connections.component';
import {SharedFormsModule} from '../../shared/forms/shared.forms.module';

const routes: Routes = [
  {
    path: '',
    component: ConnectionsComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    SharedFormsModule
  ],
  declarations: [ConnectionsComponent]
})
export class ConnectionsModule { }
