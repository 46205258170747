<form [ngrxFormState]="form">
  <ng-container class="row">
    <div [class.invalid]="formControl.isTouched && formControl.isInvalid">
      <mat-form-field class="col-12 p-0">
        <mat-label>{{ label }}</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [ngrxFormControlState]="formControl"
          [ngrxValueConverter]="dateValueConverter"
          [placeholder]="placeholder"
        />
        <mat-datepicker-toggle matSuffix [for]="picker" style="font-size: 30px;" ></mat-datepicker-toggle>
        <mat-datepicker
          #picker
          [startView]="'year'"
          (monthSelected)="mode === dateModes.Month && closeDatePicker($event)"
          (yearSelected)="mode == dateModes.Year && closeDatePicker($event)"
        ></mat-datepicker>
        <mat-error *ngIf="formControl.errors?.required">
          {{ requiredErrorMessage }}
        </mat-error>
      </mat-form-field>
    </div>
  </ng-container>
</form>
