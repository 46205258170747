import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Vacancy } from 'src/app/ngrx-store/components/vacancy/vacancy.model';
import {
  Company,
  CompanyAmbassador,
} from '../../../ngrx-store/components/company/company.model';
import { AppState } from '../../../ngrx-store/app.state';
import { Store } from '@ngrx/store';
import { selectConnections } from 'src/app/ngrx-store/components/connection/connection.selectors';
import { selectUser } from 'src/app/ngrx-store/components/user/user.selectors';
import { Connection } from 'src/app/ngrx-store/components/connection/connection.model';
import { getConnections } from '../../../ngrx-store/components/connection/connection.actions';

@Component({
  selector: 'app-vacancy-dialog',
  templateUrl: './vacancy-dialog.component.html',
  styleUrls: ['./vacancy-dialog.component.scss'],
})
export class VacancyDialogComponent {
  @Input() vacancy?: Vacancy;
  @Input() company?: Company;
  public connections$ = this.store.select(selectConnections);
  public self$ = this.store.select(selectUser);

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { vacancy: Vacancy; company: Company },
    private store: Store<AppState>
  ) {
    this.vacancy = data.vacancy;
    this.company = data.company;
    this.store.dispatch(getConnections({ filter: undefined }));
  }

  public openMailClient() {
    if (!this.vacancy || !this.company) {
      return;
    }

    if (this.company.contactPersons.length === 0) {
      return;
    }

    const recipients = this.company.contactPersons.map(c => c.email)?.join(',');
    const subject = `Vacancy application for "${this.vacancy.jobTitle}" from Rightbrains`;

    window.open(`mailto:${recipients}?subject=${subject}`, '_blank');
  }

  public getConnectedAmbassadors(
    connections: Connection[],
    ambassadors: CompanyAmbassador[]
  ) {
    const ambassadorsById = ambassadors.map((u) => u.user.id);
    return connections.filter((c) => ambassadorsById.includes(c.user.id));
  }

  public getUnconnectedAmbassadors(
    connections: Connection[],
    ambassadors: CompanyAmbassador[]
  ) {
    const connectionsById = connections.map((c) => c.user.id);
    return ambassadors
      .map((a) => a.user)
      .filter((u) => !connectionsById.includes(u.id));
  }
}
