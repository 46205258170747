import { createAction, props } from '@ngrx/store';
import { DigitalArea, Industry } from '../user/user.model';
import { Vacancy } from '../vacancy/vacancy.model';
import { CreateVacancyForm } from './vacancyForm.model';

export enum VacancyFormActions {
  SUBMIT = '[VacancyForm] Submit vacancy',
  CLEAR = '[VacancyForm] Clear message',
  LOAD = '[VacancyForm] Load vacancy',
  ADD_DIGITAL_AREA = '[VacancyForm] Add digital area',
  REMOVE_DIGITAL_AREA = '[VacancyForm] Remove digital area',
  ADD_INDUSTRY = '[VacancyForm] Add industry',
  REMOVE_INDUSTRY = '[VacancyForm] Remove industry',
  ADD_PHOTO = '[VacancyForm] Add photo',
  REMOVE_PHOTO = '[VacancyForm] Remove photo',
  ADD_PHOTO_SUCCESS = '[VacancyForm] Add photo success',
}

export const submitVacancy = createAction(
  VacancyFormActions.SUBMIT,
  props<{ formValues: CreateVacancyForm }>()
);
export const clearVacancy = createAction(VacancyFormActions.CLEAR);
export const loadVacancy = createAction(
  VacancyFormActions.LOAD,
  props<{ vacancy: Vacancy }>()
);
export const addDigitalAreaToVacancy = createAction(
  VacancyFormActions.ADD_DIGITAL_AREA,
  props<{ digitalArea: DigitalArea }>()
);
export const removeDigitalAreaFromVacancy = createAction(
  VacancyFormActions.REMOVE_DIGITAL_AREA,
  props<{ digitalArea: DigitalArea }>()
);
export const addIndustryToVacancy = createAction(
  VacancyFormActions.ADD_INDUSTRY,
  props<{ industry: Industry }>()
);
export const removeIndustryFromVacancy = createAction(
  VacancyFormActions.REMOVE_INDUSTRY,
  props<{ industry: Industry }>()
);
export const addPhotoToVacancy = createAction(
  VacancyFormActions.ADD_PHOTO,
  props<{ photo: File }>()
);
export const addPhotoToVacancySuccess = createAction(
  VacancyFormActions.ADD_PHOTO_SUCCESS,
  props<{ reference: string }>()
);
export const removePhotoFromVacancy = createAction(
  VacancyFormActions.REMOVE_PHOTO
);
