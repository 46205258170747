<ng-container *ngIf="userModel$ | async as userModel" style="overflow: hidden;">
  <ng-container *ngIf="profileEditForm$ | async as profileEditForm">
    <ng-container *ngIf="userModel.user as user">
      <div class="container">
        <div class="header-container">
          <p class="align-center color-primary">Edit profile</p>
          <a class='back-arrow color-primary' [routerLink]="'../view'">
            <span></span>
            <mat-icon>arrow_back</mat-icon>
          </a>
          <div class="bevel"></div>
        </div>

        <div class="align-center row col-12 profile-picture-container">
          <a
            class="avatar-hover col-auto w-100"
            [routerLink]="'../photo'"
            matTooltip="Change your photo"
          >
            <mat-icon *ngIf="!user?.avatarRef" class="avatar h-100 w-100 d-flex justify-content-center align-items-center"
            >account_circle
            </mat-icon
            >
            <img
              *ngIf="user?.avatarRef"
              [src]="user.avatarRef | safe: 'url'"
              class="profile-picture"
            />
            <button mat-mini-fab class="edit-icon" color="accent" style="left: -5px;">
              <mat-icon>photo_camera</mat-icon>
            </button>
          </a>
        </div>
        <br>
        <table class="w-100">
          <tr>
            <td>
              <h3 class="font-weight-bold">Personal settings</h3>
            </td>
            <td>
              <div class="float-end edit-link mb-3">
                {{saveText}} <img *ngIf="saveText === 'Saved'" class="edit-pencil"
                                  src="../../../../assets/images/done.svg">
              </div>
            </td>
          </tr>
        </table>

        <div *ngIf="profileEditForm as form">
          <app-input-field
            [form]="form"
            [formControl]="form.controls.firstName"
            [label]="'First name'"
            (input)="startTimer()"
          >
          </app-input-field>
          <app-input-field
            [form]="form"
            [formControl]="form.controls.lastName"
            [label]="'Last name'"
            (input)="startTimer()"
          >
          </app-input-field>
          <app-input-field
            [form]="form"
            [formControl]="profileEditForm.controls.functionLabel"
            [label]="'Job title'"
            [placeholder]="'Set a custom job title (this is how others see you).'"
            (input)="startTimer()"
          >
          </app-input-field>
        </div>
        <app-input-country
          [form]="profileEditForm"
          [label]="'Country'"
          [formControl]="profileEditForm.controls.country"
          [placeholder]="'Select your Country'"
          [requiredErrorMessage]="'Country is required'"
          (selectionChange)="startTimer()"
        ></app-input-country>
        <app-input-auto-complete
          *ngIf="profileEditForm.controls.country.value === 'Netherlands'"
          [form]="profileEditForm"
          [formControl]="profileEditForm.controls.region"
          [inputList]="provincies"
          [label]="'Region'"
          [placeholder]="'Enter your Region'"
          [requiredErrorMessage]="'Region is required'"
          (optionSelected)="startTimer()"
        ></app-input-auto-complete>

        <app-input-select
          class="col-12 p-0 my-2"
          [form]="profileEditForm"
          [formControl]="profileEditForm.controls.employmentStatus"
          [label]="'Current employment'"
          [selectionList]="profileEditForm.controls.employmentStatuses.value"
          (selectionChange)="startTimer()"
        ></app-input-select>

        <div class="bevel mb-3"></div>

        <table class="w-100">
          <tr>
            <td>
              <h3 class="font-weight-bold">Current role</h3>
            </td>
            <td>
              <div class="float-end edit-link mb-3">
                {{saveText}} <img *ngIf="saveText === 'Saved'" class="edit-pencil"
                                  src="../../../../assets/images/done.svg">
              </div>
            </td>
          </tr>
        </table>

        <mat-chip-listbox class="d-block my-2 " aria-label="List items" label="Current role">
          <mat-chip-option #member color="primary" class="role-chip" (click)="selectRole(roles.Member)">
            Community member
          </mat-chip-option>
          <mat-chip-option #mentor color="primary" class="role-chip" (click)="selectRole(roles.Mentor)">
            Mentor
          </mat-chip-option>
          <mat-chip-option #mentee color="primary" class="role-chip" (click)="selectRole(roles.Mentee)">
            Mentee
          </mat-chip-option>
          <mat-chip-option #mentormentee color="primary" class="role-chip" (click)="selectRole(roles.MentorAndMentee)">
            Mentor and Mentee
          </mat-chip-option>
        </mat-chip-listbox>

        <ng-container *ngIf="findMatchForm$ | async as findMatchForm">
          <div *ngIf="Q1">
            <p class="col-12 font-weight-bold">
              Do you want your mentor to have more experience?
            </p>
            <app-button-toggle
              (clicked)="findMoreExperienced($event)"
              [isToggleChecked]="findMatchForm.controls.findMoreExperienced.value"
              [confirmText]="'Yes, I prefer this'"
              [declineText]="'No, I have no preference'"
            ></app-button-toggle>
          </div>
          <div *ngIf="Q2">
            <p class="col-12 font-weight-bold">
              Would you like your mentor/mentee to be in your region?
            </p>
            <app-button-toggle
              (clicked)="findInRegion($event)"
              [isToggleChecked]="findMatchForm.controls.findInRegion.value"
              [confirmText]="'Yes, that would be great'"
              [declineText]="'No, not important'"
            ></app-button-toggle>
          </div>
        </ng-container>

        <div class="edit-link mb-3">
          {{saveText}} <img *ngIf="saveText === 'Saved'" class="edit-pencil" src="../../../../assets/images/done.svg">
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
