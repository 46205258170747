import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { A2hsDialogComponent } from './a2hs-dialog/a2hs-dialog.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-a2hs-button',
  templateUrl: './a2hs-button.component.html',
  styleUrls: ['./a2hs-button.component.scss'],
})
export class A2hsButtonComponent implements OnInit {
  @Input() text = 'Add RightBrains to home screen';
  @Output() clicked = new EventEmitter<any>();

  constructor(private dialog: MatDialog, private deviceDetector: DeviceDetectorService) {}

  ngOnInit(): void {}

  public addToHomescreen() {
    if (this.deviceDetector.isDesktop()) {
      return;
    }
    this.dialog.open(A2hsDialogComponent);
  }
}
