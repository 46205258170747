import { Component, OnInit, OnDestroy } from '@angular/core';
import { addAmbassador, deleteAmbassador } from 'src/app/ngrx-store/components/companyForms/companyDetails/forms.companyDetails.actions';
import { selectMembers } from 'src/app/ngrx-store/components/match/match.selectors';
import { User } from 'src/app/ngrx-store/components/user/user.model';
import { getMembers, getMembersClear } from 'src/app/ngrx-store/requests/match/get-members/get-members.actions';
import { CompanyDetailsBaseComponent } from '../company-details-base/company-details-base.component';
import { selectUser } from '../../../../ngrx-store/components/user/user.selectors';
import { CompanyAmbassador } from '../../../../ngrx-store/components/company/company.model';
import { selectConnections } from '../../../../ngrx-store/components/connection/connection.selectors';
import { getConnections } from '../../../../ngrx-store/components/connection/connection.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { ProfileDialogComponent } from '../../profile-dialog/profile-dialog.component';
import { trackClick } from 'src/app/ngrx-store/requests/user/post-user/post-user.actions';
import { Connection } from 'src/app/ngrx-store/components/connection/connection.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company-ambassadors',
  templateUrl: './company-ambassadors.component.html',
  styleUrls: ['./company-ambassadors.component.scss']
})
export class CompanyAmbassadorsComponent extends CompanyDetailsBaseComponent implements OnInit, OnDestroy {
  public loading = false;

  user$ = this.store.select(selectUser);
  members$ = this.store.select(selectMembers);
  connections$ = this.store.select(selectConnections);

  searchResult?: User[];
  ambassadors: CompanyAmbassador[] = [];
  connections?: Connection[];
  search = '';
  private user?: User;
  private members?: User[];
  private subscriptions = new Subscription();

  constructor(protected store: Store<AppState>,
              private dialog: MatDialog,
              private userService: UserService) {
    super(store);
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.members$.subscribe((result) => {
        this.loading = false;
        this.members = result;
        this.updateSearchResult();
      })
    );

    this.subscriptions.add(
      this.user$.subscribe((user) => {
        this.user = user;
      })
    );

    this.subscriptions.add(
      this.companyDetailsForm$.subscribe((form) => {
        this.ambassadors = form.value.ambassadors;
        this.updateSearchResult();
      })
    );

    this.subscriptions.add(
      this.connections$.subscribe((connections) => {
        this.connections = connections;
      })
    );
    this.store.dispatch(getMembersClear());
    this.store.dispatch(getConnections({filter: undefined}));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private userIsAMatch(user: User) {
    return (user.firstName + ' ' + user.lastName).toLowerCase().indexOf(this.search) >= 0;
  }

  private userIsInResults(user: User, result: User[]|undefined) {
    return result?.find((u) => u.id === user.id);
  }

  updateSearchResult() {
    if (this.search.length === 0) {
      this.searchResult = [];
      return;
    }

    let result = this.members ?? [];
    if (result && this.user && this.userIsAMatch(this.user) && !this.userIsInResults(this.user, result)) {
      result = result.concat([this.user]);
    }

    if (result && this.connections) {
      for (const connection of this.connections) {
        if (this.userIsAMatch(connection.user) && !this.userIsInResults(connection.user, result)) {
          result = result.concat([connection.user]);
        }
      }
    }

    this.searchResult = result?.filter(user => this.ambassadors.filter(ambassador => ambassador.user.id === user.id).length === 0);
  }

  searchMembers(name: string) {
    this.search = name.toLowerCase();
    if (!name) {
      this.store.dispatch(getMembersClear());
      return;
    }

    this.loading = true;
    this.store.dispatch(getMembers({filter: {name, profilePhoto: false}, limit: undefined, bestMatches: false}));
  }

  trackByName(index: number, item: User) {
    return item.id;
  }

  addAmbassador(user: User) {
    this.store.dispatch(addAmbassador({ ambassador: {user}}));
  }

  removeAmbassador(user: User) {
    this.store.dispatch(deleteAmbassador({ ambassador: {user}}));
  }

  hasConnection(user: User) {
    if (!this.connections) {
      return false;
    }

    return this.connections?.filter(c => c.user.id === user.id && !c.isPending).length > 0;
  }

  hasPendingConnection(user: User) {
    if (!this.connections) {
      return false;
    }

    return this.connections?.filter(c => c.user.id === user.id && c.isPending && c.isOutgoing).length > 0;
  }

  isSelf(user: User) {
    if (!this.user) {
      return false;
    }

    return this.user.id === user.id;
  }

  trackAmbassadorClick() {
    this.store.dispatch(trackClick({ eventType: 'open ambassador profile', companyId: this.company?.id }));
  }

  openProfile(id: string) {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.subscriptions.add(
      this.userService.getUserById(id)
      .subscribe(data => {
        this.loading = false;
        this.dialog.open(ProfileDialogComponent, {
          data: {
            profile: data
          },
        });
      })
    );
  }
}
