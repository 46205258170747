import { createReducer, on } from '@ngrx/store';
import { nextQuestion, nextStepperIndex, previousQuestion, setStepperIndex, showStep, onboardingAgain } from './onboarding.actions';
import { OnboardingState, OnboardingStep, Question } from './onboarding.model';
import { deleteUserSuccess } from '../../requests/user/post-user/post-user.actions';

export const initialOnboardingState: OnboardingState = {
  activeStep: OnboardingStep.Stepper,
  stepperIndex: 0,
  questionIndex: 0,
  questions: Object.values(Question).map((k) => k),
};

export const onboardingReducer = createReducer<OnboardingState>(
  initialOnboardingState,
  on(showStep, (state, action) => ({
    ...state,
    activeStep: action.step,
  })),
  on(nextStepperIndex, (state, action) => ({
    ...state,
    stepperIndex: state.stepperIndex + 1,
  })),
  on(setStepperIndex, (state, action) => ({
    ...state,
    stepperIndex: action.stepIndex,
  })),
  on(nextQuestion, (state, action) => {
    const newIndex = Math.min(state.questionIndex + 1, state.questions.length - 1);
    return {
      ...state,
      questionIndex: newIndex,
    };
  }),
  on(onboardingAgain, (state, _) => {
    return { ...initialOnboardingState, stepperIndex: 0 };
  }),
  on(previousQuestion, (state, action) => {
    const newIndex = Math.max(state.questionIndex - 1, 0);
    return {
      ...state,
      questionIndex: newIndex,
    };
  }),
  on(deleteUserSuccess, (state) => {
    return initialOnboardingState;
  })
);
