<ng-container *ngIf="admin$ | async as admin">
  <div class="container" data-cy="special-user-dashboard-container">
    <div class="underlined-title">
      <p class="text-primary">
        {{ admin.isAdmin ? "Admin dashboard" : "Partner features" }}
      </p>
      <div></div>
    </div>

    <ng-container *ngIf="admin.isPartner || admin.isAdmin">
      <h1 class="mt-3">Export users</h1>
      <mat-spinner
        *ngIf="loadingExcel"
        mode="indeterminate"
        diameter="50"
      ></mat-spinner>
      <button
        *ngIf="!loadingExcel"
        mat-flat-button
        color="primary"
        class="btn-sm d-block"
        (click)="getExcel()"
        data-cy="get-user-excel-button"
      >
        Get user Excel
      </button>
    </ng-container>

    <ng-container *ngIf="admin.isAdmin">
      <h1 class="mt-3">Export partner analysis</h1>
      <mat-spinner
        *ngIf="loadingPartnerExcel"
        mode="indeterminate"
        diameter="50"
      ></mat-spinner>
      <button
        *ngIf="!loadingPartnerExcel"
        mat-flat-button
        color="primary"
        class="btn-sm d-block"
        (click)="getPartnerExcel()"
        data-cy="get-partner-excel-button"
      >
        Get partner Excel
      </button>
    </ng-container>

    <ng-container *ngIf="admin.isAdmin">
      <div (click)="industriesCollapsed = !industriesCollapsed">
        <h1 class="mt-5 collapse-title" data-cy="set-industries">Set industries</h1>
        <mat-icon>{{
          industriesCollapsed ? "expand_more" : "expand_less"
        }}</mat-icon>
      </div>

      <ng-container *ngIf="!industriesCollapsed">
        <div *ngFor="let industry of industries$ | async">
          <mat-form-field class="w-100 p-1" data-cy="industry-card">
            <input
              class="w-100"
              matInput
              type="text"
              [value]="industry.name"
              (change)="updateIndustry(industry.id, $event.target.value)"
            />
            <mat-icon class="delete-icon" (click)="deleteIndustry(industry.id)" data-cy="delete-industry-button"
              >delete</mat-icon
            >
          </mat-form-field>
        </div>

        <mat-form-field class="w-100 p-1">
          <mat-label>Add new industry</mat-label>
          <input
            class="w-100"
            matInput
            type="text"
            placeholder="Add new industry..."
            (input)="newIndustry = $event.target.value"
            data-cy="add-new-industry"
          />
        </mat-form-field>

        <button
          mat-flat-button
          color="primary"
          class="btn-xs mt-1"
          [disabled]="newIndustry.length === 0"
          (click)="addIndustry()"
          data-cy="add-industry-button"
        >
          +
        </button>
      </ng-container>

      <div (click)="digitalAreasCollapsed = !digitalAreasCollapsed">
        <h1 class="mt-3 collapse-title" data-cy="set-digital-areas">Set digital areas</h1>
        <mat-icon>{{
          digitalAreasCollapsed ? "expand_more" : "expand_less"
        }}</mat-icon>
      </div>

      <ng-container *ngIf="!digitalAreasCollapsed">
        <div *ngFor="let digitalArea of digitalAreas$ | async" >
          <mat-form-field class="w-100 p-1" data-cy="digital-area-card">
            <input
              class="w-100"
              matInput
              type="text"
              [value]="digitalArea.name"
              (change)="updateDigitalArea(digitalArea.id, $event.target.value)"
            />
            <mat-icon
              class="delete-icon"
              (click)="deleteDigitalArea(digitalArea.id)"
              data-cy="delete-digital-area-button"
              >delete</mat-icon
            >
          </mat-form-field>
        </div>
        <mat-form-field class="w-100">
          <mat-label>Add new digital area</mat-label>
          <input
            class="w-100"
            matInput
            type="text"
            placeholder="Add new digital area..."
            (input)="newDigitalArea = $event.target.value"
            data-cy="add-new-digital-area"
          />
        </mat-form-field>
        <button
          mat-flat-button
          color="primary"
          class="btn-xs mt-1"
          [disabled]="newDigitalArea.length === 0"
          (click)="addDigitalArea()"
          data-cy="add-digital-area-button"
        >
          +
        </button>
      </ng-container>

      <div (click)="allUsers()">
        <h1 class="mt-3 collapse-title" data-cy="set-user-rights">Set user rights</h1>
        <mat-icon>{{
          userRightsCollapsed ? "expand_more" : "expand_less"
        }}</mat-icon>
      </div>

      <ng-container *ngIf="!userRightsCollapsed">
        <mat-spinner
          *ngIf="loadingUsers"
          mode="indeterminate"
          diameter="50"
        ></mat-spinner>
        <div class="userRightsContainer">
          <ng-container *ngIf="!loadingUsers">
            <div class="d-flex align-items-center justify-content-around">
              <input
                matInput
                type="text"
                placeholder="Search name or email..."
                class="mt-2 mb-2"
                (input)="this.userFilterUpdate.next($event.target.value)"
                data-cy="search-user-in-user-rights"
              />
              <mat-spinner
                *ngIf="(allUsers$ | async)?.length === 0"
                mode="indeterminate"
                diameter="50"
              ></mat-spinner>
              <p class="label">Partner | Admin</p>
              <p class="label">Company</p>
              <p class="label">Remove</p>
            </div>
            <div class="d-flex justify-content-between flex-column">
              <ng-container
                *ngFor="let user of filteredUsers; let i = index"
              >
                <div class="user-container">
                  <div class="user">
                    <mat-icon *ngIf="!user?.avatarRef" color="primary"
                      >account_circle</mat-icon
                    >
                    <img
                      class="avatar"
                      *ngIf="user?.avatarRef"
                      [src]="user.avatarRef | safe: 'url'"
                    />
                    <div class="user-text">
                      <p>
                        {{ user.firstName + " " + user.lastName }}
                      </p>
                      <p *ngIf="user.email">
                        {{ user.email }}
                      </p>
                    </div>
                  </div>

                  <div class="checkboxes">
                    <mat-checkbox
                      #isPartnerEl
                      type="checkbox"
                      color="accent"
                      [checked]="user.isPartner"
                      data-cy="partner-checkbox"
                      (change)="
                        setRights(
                          user.id,
                          {
                            isPartner: $event.checked,
                            isAdmin: $event.checked ? false : user.isAdmin
                          },
                          isPartnerEl,
                          isAdminEl
                        )
                      "
                    ></mat-checkbox>
                    <mat-checkbox
                      #isAdminEl
                      type="checkbox"
                      color="accent"
                      [checked]="user.isAdmin"
                      data-cy="admin-checkbox"
                      (change)="
                        setRights(
                          user.id,
                          {
                            isPartner: $event.checked
                              ? false
                              : user.isPartner,
                            isAdmin: $event.checked
                          },
                          isPartnerEl,
                          isAdminEl
                        )
                      "
                    ></mat-checkbox>
                  </div>

                  <div class="company">
                    <button
                      *ngIf="!user.companyName"
                      class="delete-account-btn me-3 p-0"
                      mat-flat-button
                      color="accent"
                      (click)="addToCompany(user?.id)"
                      data-cy="add-to-company-button"
                    >
                      +
                    </button>
                    <button
                      *ngIf="user.companyName"
                      class="delete-account-btn me-3 p-0"
                      mat-flat-button
                      color="warn"
                      (click)="removeFromCompany(user.id)"
                      data-cy="remove-from-company-button"
                    >
                      {{user?.companyName}}
                    </button>
                  </div>

                  <div class="remove">
                    <button
                      class="delete-account-btn me-3 p-0"
                      mat-flat-button
                      color="warn"
                      (click)="deleteUser(user.id)"
                    >
                      X
                    </button>
                  </div>
                </div>
                <button
                  class="btn-xs mt-3 ms-3"
                  *ngIf="i + 1 === shownUsers && (allUsers$ | async)?.length > i + 1"
                  mat-flat-button
                  color="primary"
                  (click)="shownUsers = shownUsers + 20;triggerRefilterUsers()"
                >
                  Show more
                </button>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <div (click)="allCompanies()">
        <h1 class="mt-3 collapse-title">Manage companies</h1>
        <mat-icon>{{
          companiesCollapsed ? "expand_more" : "expand_less"
          }}</mat-icon>
      </div>

      <ng-container *ngIf="!companiesCollapsed">
        <mat-spinner
          *ngIf="loadingCompanies"
          mode="indeterminate"
          diameter="50"
        ></mat-spinner>
        <div class="companiesContainer">
          <ng-container *ngIf="!loadingCompanies">
            <div class="d-flex align-items-center justify-content-around">
              <input
                matInput
                type="text"
                placeholder="Search name..."
                class="mt-2 mb-2"
                (input)="this.companiesFilterUpdate.next($event.target.value)"
              />
              <mat-spinner
                *ngIf="(allCompanies$ | async)?.length === 0"
                mode="indeterminate"
                diameter="50"
              ></mat-spinner>
              <p class="label">Company</p>
              <p class="label">Remove</p>
            </div>
            <div class="d-flex justify-content-between flex-column">
              <ng-container
                *ngFor="let company of filteredCompanies; let i = index"
              >
                <div class="company-container">
                  <div class="company">
                    <div class="company-text">
                      <p>{{ company.name }}</p>
                    </div>
                  </div>

                  <div class="remove">
                    <button
                      class="delete-account-btn me-3 p-0"
                      mat-flat-button
                      color="warn"
                      (click)="deleteCompany(company?.id)"
                    >
                      X
                    </button>
                  </div>
                </div>
                <button
                  class="btn-xs mt-3 ms-3"
                  *ngIf="i + 1 === shownCompanies && (allCompanies$ | async)?.length > i + 1"
                  mat-flat-button
                  color="primary"
                  (click)="shownCompanies = shownCompanies + 20;triggerRefilterCompanies()"
                >
                  Show more
                </button>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>


    </ng-container>
  </div>
</ng-container>
