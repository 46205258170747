<mat-card appearance="outlined" class="event-card">
  <img
    *ngIf="event.thumbnailUrl"
    class="event-picture"
    src="{{ event.thumbnailUrl }}"
  />
  <div *ngIf="event.companyId && showPartnerLabel" class="partner-event-label">
    Partner event
  </div>
  <div *ngIf="!event.thumbnailUrl" class="event-picture"></div>
  <mat-card-content class="profile-card-content mat-card-inner-content">
    <div class="event-date">
      {{ event.eventDate | date : "d LLLL y" }}
    </div>
    <div class="event-time">
      <span *ngIf="event.beginTime && event.endTime"
        >{{ toTime(event.beginTime) }} - {{ toTime(event.endTime) }} CET</span
      >
    </div>
    <div #eventCardContent class="card-content">
      <div #eventTitle class="event-title">
        {{ event.title }}
      </div>
    </div>
    <a
      *ngIf="!event.companyId && event.eventUrl"
      (click)="openEvent(event.eventUrl)"
    >
      <button
        (click)="closeDialog()"
        mat-flat-button
        color="primary"
        class="btn-sm d-block"
      >
        <span *ngIf="inFuture(this.event.eventDate); else prevEvent"
          >Read & register</span
        >
        <ng-template #prevEvent>Read more</ng-template>
      </button>
    </a>
    <a
      *ngIf="(event.companyId && event.eventUrl) || !event.eventUrl"
      [routerLink]="'/' + AppRoutes.EVENT + '/' + event.id"
    >
      <button
        (click)="closeDialog()"
        mat-flat-button
        color="primary"
        class="btn-sm d-block"
      >
        <span *ngIf="inFuture(this.event.eventDate); else prevEvent"
          >Read & register</span
        >
        <ng-template #prevEvent>Read more</ng-template>
      </button>
    </a>
  </mat-card-content>
</mat-card>
