import { Action, createReducer, on } from '@ngrx/store';
import {
  createFormGroupState,
  FormGroupState,
  formStateReducer,
  setErrors,
  setValue,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { UserFormsState } from '../userForms.model';
import { clearExperienceForm, prefillExperienceForm, submitExperienceForm } from './forms.experience.actions';
import { ExperienceForm, JobType } from './forms.experience.model';

export const initialEditExperienceForm: ExperienceForm = {
  jobType: JobType.Current_Job,
  jobTypes: Object.values(JobType).map((k) => k),
  position: '',
  company: '',
  startDate: '',
  endDate: '',
  description: '',
  index: -1,
};

export const initialEditExperienceFormState = createFormGroupState<ExperienceForm>(
  'EXPERIENCE_FORM',
  initialEditExperienceForm
);

const updateForm = createReducer<FormGroupState<ExperienceForm>>(
  initialEditExperienceFormState,
  on(prefillExperienceForm, (state, action) =>
    updateGroup<ExperienceForm>(state, {
      jobType: setValue<string>(action.company.endDate ? JobType.Previous_Job : JobType.Current_Job),
      company: setValue(action.company.name),
      position: setValue(action.company.position),
      startDate: setValue(action.company.startDate ? action.company.startDate.toString() : ''),
      endDate: setValue(action.company.endDate ? action.company.endDate.toString() : ''),
      description: setValue(action.company.description),
      index: setValue(action.index),
    })
),
  on(clearExperienceForm, (state) => initialEditExperienceFormState),
  on(submitExperienceForm, (state) => initialEditExperienceFormState)
);

const validateForm = (form: FormGroupState<ExperienceForm>, state: UserFormsState) => {
  const jobType = form.controls.jobType.value;

  return updateGroup<ExperienceForm>({
    company: validate(required),
    position: validate(required),
    startDate: validate(required),
  })(form);
};

export const editExperienceFormReducer = (state: UserFormsState, action: Action) => {
  const editExperienceForm: FormGroupState<ExperienceForm> = state.experienceForm;

  // use the out of the box reducer to process form actions into the store
  let form = formStateReducer(editExperienceForm, action);

  // Process non-form actions
  form = updateForm(form, action);

  // form validation using the new state
  form = validateForm(form, state);

  return form === editExperienceForm ? editExperienceForm : form;
};
