import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, filter, withLatestFrom, concatMap } from 'rxjs/operators';
import { UserService } from '../../../../services/user.service';
import {
  notificationRequestFailed,
  addWelcomeNotifications,
  notificationRequestSuccess,
  getUser,
  getUserError,
  getUserSuccess,
  clearUserNotifications,
  deleteNotification,
  checkNewNotifications,
  checkNewNotificationsSuccess,
  setCheckingForNotifications,
  getUserUnauth, getCompanyListSuccess, getCompanyList, getCompanyListFailed,
} from './get-user.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { selectUserModel } from 'src/app/ngrx-store/components/user/user.selectors';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { getCompany } from '../../company/get-company/get-company.actions';

@Injectable()
export class GetUserEffects {
  constructor(private actions$: Actions, private userService: UserService, private store: Store<AppState>) {}

  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUser),
      switchMap(() => {
        return this.userService.getUser().pipe(
          map((user) => getUserSuccess({ user })),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
              return of(getUserUnauth());
            }

            return of(getUserError({ error: error.message }));
          })
        );
      })
    )
  );

  pollForNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkNewNotifications),
      withLatestFrom(this.store.pipe(select(selectUserModel))),
      filter(([_, userModel]) => {
        if (typeof userModel.user !== 'undefined' && typeof userModel.user !== 'boolean') {
          return (!userModel.checkingForNotifications && userModel.user?.onboardingCompleted === true);
        }

        return false;
      }),
      concatMap(() => this.userService.hasNewNotifications()),
      switchMap(({ hasNew }) => {
        if (hasNew) {
          this.store.dispatch(getUser());
          this.store.dispatch(getCompany());
        }

        // queue new check in 5 seconds
        setTimeout(() => {
          this.store.dispatch(setCheckingForNotifications({ checking: false }));
          this.store.dispatch(checkNewNotifications());
        }, 120000);

        this.store.dispatch(setCheckingForNotifications({ checking: true }));
        return [checkNewNotificationsSuccess({ hasNew })];
      }),
      catchError((error: HttpErrorResponse) => {
        // try again after 5 seconds
        setTimeout(() => {
          this.store.dispatch(setCheckingForNotifications({ checking: false }));
          this.store.dispatch(checkNewNotifications());
        }, 120000);

        return of(getUserError({ error: error.message }));
      })
    )
  );

  getCompanyList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCompanyList),
      switchMap(() =>
        this.userService.getCompanyList().pipe(
          map((companyList) => getCompanyListSuccess({companies: companyList})),
          catchError((error: HttpErrorResponse) => of(getCompanyListFailed({error: error.message})))
        )
      )
    )
  );

  addWelcomeNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addWelcomeNotifications),
      switchMap(() => {
        return this.userService.addWelcomeNotifications().pipe(
          map((user) => notificationRequestSuccess()),
          catchError((error: HttpErrorResponse) => of(notificationRequestFailed()))
        );
      })
    )
  );

  clearUserNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearUserNotifications),
      switchMap(() => {
        return this.userService.clearUserNotifications().pipe(
          map((user) => notificationRequestSuccess()),
          catchError((error: HttpErrorResponse) => of(notificationRequestFailed()))
        );
      })
    )
  );

  deleteNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteNotification),
      switchMap((a) => {
        return this.userService.deleteNotification(a.id).pipe(
          map(() => notificationRequestSuccess()),
          catchError((error: HttpErrorResponse) => of(notificationRequestFailed()))
        );
      })
    )
  );
}
