<ng-container *ngIf="mailPreferencesForm$ | async as mailPreferencesForm">
  <div class="step">
    <div class="container step-content">
      <p class="intro">Please check the boxes to give permissions.</p>
      <form>
        <div class="col-12 p-0">
          <app-input-checkbox
            class="diplay-none"
            [form]="mailPreferencesForm"
            [checked]="true"
            [formControl]="mailPreferencesForm.controls.newsletter"
            (checkboxChange)="changeCheckBox()"
          >
          </app-input-checkbox>

          <app-input-checkbox
            class="diplay-none"
            [form]="mailPreferencesForm"
            [formControl]="mailPreferencesForm.controls.platform"
            [checked]="true"
            (checkboxChange)="changeCheckBox()"
          >
          </app-input-checkbox>

          <h3>Open for new job opportunities?</h3>
          <app-input-checkbox
            [form]="mailPreferencesForm"
            [formControl]="mailPreferencesForm.controls.shareData"
            (checkboxChange)="changeCheckBox()"
          >
            I allow RightBrains and their partners to connect with me for career
            opportunities.
          </app-input-checkbox>
        </div>
      </form>
      <div class="button-area w-100">
        <button
          [disabled]="mailPreferencesForm.isInvalid"
          class="btn-sm mx-auto"
          mat-flat-button
          color="primary"
          (click)="submitFormAndPost()"
        >
          Finish!
        </button>
      </div>
    </div>
  </div>
</ng-container>
