import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectUser } from 'src/app/ngrx-store/components/user/user.selectors';
import { UserService } from 'src/app/services/user.service';
import {
  postUser,
  postUserError,
  postUserSuccess,
  deleteUser,
  deleteUserSuccess,
  deleteUserId,
  deleteUserIdSuccess,
  trackClick,
  uploadAvatar,
} from './post-user.actions';
import {
  setEmail,
  setEmailSuccess,
} from 'src/app/ngrx-store/components/user/user.actions';
import { CookieService } from 'ngx-cookie-service';
import {
  trackClickSuccess,
  trackClickError,
  uploadAvatarSuccess,
  uploadAvatarError,
} from './post-user.actions';
import { StorageService } from '../../../../services/storage.service';

@Injectable()
export class PostUserEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private userService: UserService,
    private cookieService: CookieService,
    private storageService: StorageService
  ) {}

  postUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postUser, uploadAvatarSuccess),
      withLatestFrom(this.store.pipe(select(selectUser))),
      exhaustMap(([_, user]) => {
        return this.userService.postUser(user).pipe(
          map((userResponse) => postUserSuccess({ user: userResponse })),
          catchError((error: HttpErrorResponse) => {
            return of(postUserError({ error: error.message }));
          })
        );
      })
    )
  );

  uploadAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadAvatar),
      exhaustMap((a) => {
        return this.storageService.uploadImage(a.file).pipe(
          map((response) =>
            uploadAvatarSuccess({ urlRef: response.reference })
          ),
          catchError((error: HttpErrorResponse) => {
            return of(uploadAvatarError({ error: error.message }));
          })
        );
      })
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUser),
      exhaustMap(() => {
        return this.userService.deleteUser().pipe(
          map(() => {
            this.cookieService.deleteAll();
            localStorage.clear();
            window.location.replace(
              '/.auth/logout?post_logout_redirect_uri=https%3A%2F%2Frightbrains.nl'
            );
            return deleteUserSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            return of(postUserError({ error: error.message }));
          })
        );
      })
    )
  );

  setEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setEmail),
      exhaustMap((a) => {
        return this.userService.setEmail(a.newEmail).pipe(
          map(() => {
            return setEmailSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            return of(postUserError({ error: error.message }));
          })
        );
      })
    )
  );

  trackClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(trackClick),
      exhaustMap(({ eventType, companyId }) => {
        return this.userService.trackClick(eventType, companyId).pipe(
          map(() => {
            return trackClickSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            return of(trackClickError({ error: error.message }));
          })
        );
      })
    )
  );

  deleteUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUserId),
      exhaustMap((a) => {
        return this.userService.deleteUserId(a.id).pipe(
          map(() => {
            return deleteUserIdSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            return of(postUserError({ error: error.message }));
          })
        );
      })
    )
  );
}
