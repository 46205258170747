import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  withLatestFrom,
} from 'rxjs/operators';
import { AppState } from 'src/app/ngrx-store/app.state';
import { CompanyService } from '../../../../services/company.service';
import {
  postNewCompany,
  postNewCompanySuccess,
  postNewCompanyError,
  postDetailsCompany,
  postDetailsCompanyError,
  postDetailsCompanySuccess,
} from './post-company.actions';
import {
  selectCompanyCreationDetails,
  selectCompany,
} from '../../../components/company/company.selectors';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routing.model';

@Injectable()
export class PostCompanyEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private companyService: CompanyService,
    private router: Router
  ) {}

  postNewCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postNewCompany),
      withLatestFrom(this.store.pipe(select(selectCompanyCreationDetails))),
      exhaustMap(([_, newCompanyDetails]) => {
        return this.companyService
          .createNewCompany(
            newCompanyDetails.companyCode,
            newCompanyDetails.companyName
          )
          .pipe(
            map((companyResponse) =>
              postNewCompanySuccess({ company: companyResponse })
            ),
            catchError((error: HttpErrorResponse) => {
              return of(postNewCompanyError({ error: error.message }));
            })
          );
      })
    )
  );

  postDetailsCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postDetailsCompany),
      withLatestFrom(this.store.pipe(select(selectCompany))),
      exhaustMap(([_, companyDetails]) => {
        return this.companyService.postCompany(companyDetails).pipe(
          map((companyResponse) => {
            if (this.router.url !== `/${AppRoutes.COMPANY}/view`) {
              this.router.navigateByUrl(`/${AppRoutes.COMPANY}`);
            }
            return postDetailsCompanySuccess({ company: companyResponse });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(postDetailsCompanyError({ error: error.message }));
          })
        );
      })
    )
  );
}
