<div *ngIf="createMessageForm$ | async as form" class="add-message-container">
  <div class="d-flex">
    <div class="w-100 h-100 message-box">
      <app-input-text-area
        [form]="form"
        [formControl]="form.controls.message"
        [minRows]="2"
        [maxRows]="6"
        placeholder="Add a message"
      ></app-input-text-area>
    </div>
    <div class="send-container">
      <div
        class="purple-circle"
        (click)="submitForm(form.value)"
        [class.disabled]="form.isInvalid"
      >
        <i class="fas fa-location-arrow"></i>
      </div>
    </div>
  </div>
  <div class="footer-padding"></div>
  <div class="image-container">
    <div class="d-flex">
      <app-input-file
        fileName="Upload photo"
        (onFileUpload)="addPhoto($event)"
        [canDelete]="false"
        [icon]="true"
      ></app-input-file>
      <div class="mx-4 upload-video" (click)="toggleVideo()">
        <i class="fas fa-video"></i>
        <span>Video</span>
      </div>
    </div>
    <app-input-field
      class="video-input"
      *ngIf="showVideo && !(messageImage$ | async) "
      [form]="form"
      [formControl]="form.controls.video"
      placeholder="youtube/vimeo link"
      patternErrorMessage="Please provide a youtube or vimeo link"
    ></app-input-field>
    <div class="image" *ngIf="messageImage$ | async as image">
      <img [src]="image | safe : 'url'" />
      <div (click)="deletePhoto()" class="ms-2 pointer">
        <i class="fas fa-trash"></i>
      </div>
    </div>
  </div>
</div>
