import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';
import { Inject, LOCALE_ID, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  constructor(
    @Inject(LOCALE_ID) protected locale: string,
    protected platform: Platform
  ) {
    super(locale);
  }

  format(date: Date, displayFormat: any): string {
    if (displayFormat === 'input') {
      return new DatePipe(this.locale).transform(date) || date.toString();
    }
    return date.toDateString();
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: {
      year: 'numeric',
      month: 'numeric',
    },
    dateA11yLabel: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};
