import {createAction, props} from '@ngrx/store';
import {RightbrainsEvent} from '../../components/event/event.model';

export enum GetEventActionTypes {
  GET = '[Event] Get Event',
  SUCCESS = '[Event] Get Event success',
  ERROR = '[Event] Get Event error',
  PREVIOUSGET = '[Event] Get Previous Event',
  PREVIOUSSUCCESS = '[Event] Get Previous Event success',
  PREVIOUSERROR = '[Event] Get Previous Event error',
  REGISTER = '[Event] Register for event',
  REGISTERSUCCESS = '[Event] Registration successful',
  REGISTERERROR = '[Event] Registration failed',
  SIGNOUT = '[Event] Sign out for event',
  SIGNOUTSUCCESS = '[Event] Sign out successful',
  SIGNOUTERROR = '[Event] Sign out failed',
  GETREGISTRATION = '[Event] Get registration',
  GETREGISTRATIONSUCCESS = '[Event] Get registration successful',
  GETREGISTRATIONERROR = '[Event] Get registration failed',
}

export const getEvents = createAction(GetEventActionTypes.GET);
export const getEventsSuccess = createAction(GetEventActionTypes.SUCCESS,
  props<{ events: RightbrainsEvent[] }>());
export const getEventsError = createAction(GetEventActionTypes.ERROR, props<{ error: string }>());
export const getPreviousEvents = createAction(GetEventActionTypes.PREVIOUSGET);
export const getPreviousEventsSuccess = createAction(GetEventActionTypes.PREVIOUSSUCCESS,
  props<{ previousEvents: RightbrainsEvent[] }>());
export const getPreviousEventsError = createAction(GetEventActionTypes.PREVIOUSERROR, props<{ error: string }>());
export const registerEvent = createAction(GetEventActionTypes.REGISTER, props<{ eventId: string }>());
export const registerSuccess = createAction(GetEventActionTypes.REGISTERSUCCESS,
  props<{ status: string }>());
export const registerError = createAction(GetEventActionTypes.REGISTERERROR, props<{ error: string }>());
export const signOutEvent = createAction(GetEventActionTypes.SIGNOUT, props<{ eventId: string }>());
export const signOutSuccess = createAction(GetEventActionTypes.SIGNOUTSUCCESS,
  props<{ status: string }>());
export const signOutError = createAction(GetEventActionTypes.SIGNOUTERROR, props<{ error: string }>());
export const getRegistration = createAction(GetEventActionTypes.GETREGISTRATION, props<{ eventId: string }>());
export const getRegistrationSuccess = createAction(GetEventActionTypes.GETREGISTRATIONSUCCESS,
  props<{ status: string }>());
export const getRegistrationError = createAction(GetEventActionTypes.GETREGISTRATIONERROR, props<{ error: string }>());
