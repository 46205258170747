import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppRoutes } from 'src/app/app-routing.model';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectCompany } from 'src/app/ngrx-store/components/company/company.selectors';
import {getVacancies, getVacanciesForCompany} from 'src/app/ngrx-store/components/vacancy/vacancy.actions';
import { Vacancy } from 'src/app/ngrx-store/components/vacancy/vacancy.model';
import {
  selectVacanciesForCompanyId,
  selectVacanciesForCompanyIdAsAdmin,
} from 'src/app/ngrx-store/components/vacancy/vacancy.selectors';
import { CompanyDetailsBaseComponent } from '../company-details-base/company-details-base.component';
import { CreateVacancyDialogComponent } from '../../create-vacancy-dialog/create-vacancy-dialog.component';
import {
  deleteVacancy,
  getVacanciesInterests,
} from 'src/app/ngrx-store/components/vacancy/vacancy.actions';
import {
  loadVacancy,
} from 'src/app/ngrx-store/components/vacancyForm/vacancyForm.actions';
import { VacancyDialogComponent } from '../../vacancy-dialog/vacancy-dialog.component';
import { Company } from 'src/app/ngrx-store/components/company/company.model';
import { setFilterCompany } from 'src/app/ngrx-store/components/vacancyForm/vacancyFilter/forms.vacancyFilter.actions';

@Component({
  selector: 'app-company-vacancies',
  templateUrl: './company-vacancies.component.html',
  styleUrls: ['./company-vacancies.component.scss'],
})
export class CompanyVacanciesComponent extends CompanyDetailsBaseComponent implements OnInit, OnDestroy {
  company$ = this.store.select(selectCompany);
  public AppRoutes = AppRoutes;
  public vacancies$: Observable<Vacancy[]>;

  private subscriptions = new Subscription();

  constructor(
    protected readonly store: Store<AppState>,
    private dialog: MatDialog
  ) {
    super(store);
  }

  ngOnInit() {
    this.store.dispatch(getVacancies());
    this.store.dispatch(getVacanciesInterests());

    if (this.company) {
      this.vacancies$ = this.store.select(
        selectVacanciesForCompanyId(this.company.id)
      );
      return;
    }

    // force an empty result while the subscription is being handled
    this.vacancies$ = this.store.select(
      selectVacanciesForCompanyIdAsAdmin('non-matching-id')
    );

    const sub = this.company$.subscribe((company) => {
      if (company) {
        this.store.dispatch(getVacanciesForCompany({companyId: company.id}));
        this.vacancies$ = this.store.select(
          selectVacanciesForCompanyIdAsAdmin(company.id)
        );
      }
    });

    this.subscriptions.add(sub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  toggleModify(modify: boolean): void {
    if (modify) {
      this.dialog.open(CreateVacancyDialogComponent, {
        data: { isEdit: true },
      });
    }
  }

  openVacancyDialog(vacancy: Vacancy, company: Company) {
    this.dialog.open(VacancyDialogComponent, {
      data: {
        vacancy,
        company,
      },
    });
  }

  removeVacancy(vacancy: Vacancy) {
    this.store.dispatch(deleteVacancy({ vacancyId: vacancy.id }));
  }

  editVacancy(vacancy: Vacancy) {
    this.store.dispatch(loadVacancy({ vacancy }));
    this.toggleModify(true);
  }

  createVacancy(modify: boolean) {
    this.toggleModify(modify);
  }

  setVacancyFilter(company: string) {
    this.dialog.closeAll();
    this.store.dispatch(setFilterCompany({ company }));
  }
}
