import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MatchService } from 'src/app/services/match.service';
import { getMentors, getMentorsError, getMentorsSuccess } from './get-mentor.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { NoFilter } from 'src/app/ngrx-store/components/match/match.model';

@Injectable()
export class GetMentorsEffects {
  constructor(private actions$: Actions, private matchService: MatchService) {}

  getMatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMentors),
      switchMap((a) => {
        return this.matchService.getMentors(a.filter || NoFilter, a.limit, a.bestMatches).pipe(
          map((matches) => getMentorsSuccess({ matches })),
          catchError((error: HttpErrorResponse) => of(getMentorsError({ error: error.message })))
        );
      })
    )
  );
}
