import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CreateVacancyForm } from '../ngrx-store/components/vacancyForm/vacancyForm.model';
import { VacancyInterest } from '../ngrx-store/components/vacancy/vacancy.model';
import {
  Vacancy,
} from '../ngrx-store/components/vacancy/vacancy.model';

@Injectable()
export class VacancyService {
  constructor(protected http: HttpClient) {}

  public postVacancy(vacancy: CreateVacancyForm): Observable<Vacancy> {
    return this.http.post<Vacancy>(`${environment.apiBaseUrl}vacancy`, vacancy);
  }

  public deleteVacancy(vacancyId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiBaseUrl}vacancy/${vacancyId}`
    );
  }

  public getVacancies(): Observable<Vacancy[]> {
    return this.http.get<Vacancy[]>(`${environment.apiBaseUrl}vacancy/all`);
  }

  public getVacanciesForCompany(companyId: string): Observable<Vacancy[]> {
    return this.http.get<Vacancy[]>(`${environment.apiBaseUrl}company/vacancies/${companyId}`);
  }

  public getVacancyInterests(): Observable<VacancyInterest[]> {
    return this.http.get<VacancyInterest[]>(
      `${environment.apiBaseUrl}vacancy/interests`
    );
  }

  public postVacancyInterest(
    vacancyId: string,
    interested: boolean
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.apiBaseUrl}vacancy/${vacancyId}/interest`,
      interested
    );
  }
}
