import { NgModule } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { NgrxFormsModule } from 'ngrx-forms';
// tslint:disable-next-line: max-line-length
import { OnboardingQuestionButtonsComponent } from 'src/app/shared/components/onboarding/onboarding-question-buttons/onboarding-question-buttons.component';
// tslint:disable-next-line: max-line-length
import { OnboardingQuestionProgressComponent } from 'src/app/shared/components/onboarding/onboarding-question-progress/onboarding-question-progress.component';
import { StepperModule } from 'src/app/shared/stepper/stepper.module';
import { SharedFormsModule } from '../../shared/forms/shared.forms.module';
import { SharedModule } from '../../shared/shared.module';
import { OnboardingStep1Component } from './onboarding-stepper/onboarding-step1/onboarding-step1.component';
import { OnboardingStep2Component } from './onboarding-stepper/onboarding-step2/onboarding-step2.component';
import { OnboardingStep3Component } from './onboarding-stepper/onboarding-step3/onboarding-step3.component';
import { OnboardingStep4Component } from './onboarding-stepper/onboarding-step4/onboarding-step4.component';
import { OnboardingStepperComponent } from './onboarding-stepper/onboarding-stepper.component';
import { OnboardingComponent } from './onboarding.component';

const routes: Routes = [
  {
    path: '',
    component: OnboardingComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    SharedFormsModule,
    StepperModule,
    NgrxFormsModule,
    MatSnackBarModule,
    MatRadioModule,
    MatTooltipModule,
  ],
  declarations: [
    OnboardingComponent,
    OnboardingStepperComponent,
    OnboardingStep1Component,
    OnboardingStep2Component,
    OnboardingStep3Component,
    OnboardingStep4Component,
    OnboardingQuestionButtonsComponent,
    OnboardingQuestionProgressComponent,
  ],
})
export class OnboardingModule {}
