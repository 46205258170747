<mat-card appearance="outlined" class="story-card h-100">
  <img
    *ngIf="viewCompanyInfo"
    (click)="openDialog()"
    [src]="company?.logo"
    class="company-picture pointer"
  />
  <div *ngIf="!company?.logo && viewCompanyInfo" class="company-picture"></div>
  <mat-card-content class="d-flex flex-column justify-content-between">
    <div class="job-title">
      {{ vacancy.jobTitle }}
    </div>
    <div class="card-content mb-2">
      <div class="points">
        <div class="experience-point mb-2">
          {{ vacancy.careerPhase | experience }}
        </div>
      </div>
      <div class="points">
        <div class="location-point">
          {{ vacancy.city ? vacancy.city + ", " : "" }}{{ vacancy.country }}
        </div>
      </div>
    </div>
    <button
      (click)="openDialog()"
      mat-flat-button
      color="primary"
      class="btn-sm d-block mb-1"
    >
      Job details
    </button>
    <div
      *ngIf="viewCompanyInfo"
      class="meet-company"
      (click)="openCompanyDialog(company)"
    >
      <span>Meet {{ company.name }}</span>
    </div>
  </mat-card-content>
</mat-card>
