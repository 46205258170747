import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectCompanyContactPersonForm } from '../../../ngrx-store/components/companyForms/companyForms.selector';
import { addCompanyContactPersonPhoto, deleteCompanyContactPersonPhoto } from '../../../ngrx-store/components/companyForms/companyContactPerson/forms.companyContactPerson.actions';

@Component({
  selector: 'app-company-contact-person-form',
  templateUrl: './company-contact-person-form.component.html',
  styleUrls: ['./company-contact-person-form.component.scss']
})
export class CompanyContactPersonFormComponent{
  public companyContactPersonForm$ = this.store.pipe(select(selectCompanyContactPersonForm));

  constructor(private store: Store<AppState>) {
  }

  public addPhoto(photo: File) {
    this.store.dispatch(addCompanyContactPersonPhoto({photo}));
  }

  public deletePhoto(photoUrl: string) {
    this.store.dispatch(deleteCompanyContactPersonPhoto());
  }
}
