import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectCompanyEventForm } from '../../../ngrx-store/components/companyForms/companyForms.selector';
import { addCompanyEventPhotoSuccess, addCompanyEventPhoto } from '../../../ngrx-store/components/companyForms/companyEvent/forms.companyEvent.actions';

@Component({
  selector: 'app-company-event-form',
  templateUrl: './company-event-form.component.html',
  styleUrls: ['./company-event-form.component.scss']
})
export class CompanyEventFormComponent {
  public companyEventForm$ = this.store.select(selectCompanyEventForm);

  constructor(private store: Store<AppState>) {
  }

  public addPhoto(photo: File) {
    this.store.dispatch(addCompanyEventPhoto({photo}));
  }

  public deletePhoto() {
    this.store.dispatch(addCompanyEventPhotoSuccess({referenceUrl: ''}));
  }
}
