import { Component, Input } from '@angular/core';
import { AppRoutes } from 'src/app/app-routing.model';
import { Education } from '../../../ngrx-store/components/education/education.model';

@Component({
  selector: 'app-educationcards',
  templateUrl: './educationcards.component.html',
  styleUrls: ['./educationcards.component.scss']
})
export class EducationcardsComponent {
  public AppRoutes = AppRoutes;
  @Input() educations: Education[];
  @Input() viewAllButton = true;
}
