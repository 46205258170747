import { Component, Output, EventEmitter, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
})
export class InputFileComponent {
  private maxFileSize = 2000000; // 2 MB
  private isUploading = false;
  public helpText = '';

  @Input() uploadedReference?: string;
  @Input() label = '';
  @Input() fileName = '';
  @Input() type: 'image' | 'pdf' = 'image';
  @Input() canDelete = true;
  @Input() icon = false;
  @Output() onFileUpload = new EventEmitter<File>();
  @Output() onFileDelete = new EventEmitter<string>();

  constructor(private sanitizer: DomSanitizer) {}

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      if (file.size > this.maxFileSize) {
        this.helpText =
          'File size too large! The size can not exceed ' +
          this.maxFileSize / 1000000 +
          ' MB.';
      } else if (this.isValidImage(file) || this.isValidPdf(file)) {
        this.isUploading = true;
        this.helpText = '';
        this.onFileUpload.emit(file);
        this.isUploading = false;
      } else {
        this.helpText =
          'Unsupported file type! Please upload a ' +
          (this.type === 'image' ? '.png or .jpeg' : '.pdf') +
          ' file.';
      }
    }
  }

  get uploadButtonText() {
    return this.isUploading ? 'Uploading' : 'Upload';
  }

  get safeReference() {
    return this.uploadedReference
      ? this.sanitizer.bypassSecurityTrustUrl(this.uploadedReference)
      : '';
  }

  onFileDeleted() {
    this.onFileDelete.emit(this.uploadedReference);
  }

  private isValidImage(file: File): boolean {
    return (
      this.type === 'image' &&
      (file.type === 'image/png' || file.type === 'image/jpeg')
    );
  }

  private isValidPdf(file: File): boolean {
    return this.type === 'pdf' && file.type === 'application/pdf';
  }
}
