import { Pipe, PipeTransform } from '@angular/core';
import { Message } from 'src/app/ngrx-store/components/message/message.model';
@Pipe({
  name: 'sortMessages',
})
export class SortMessagePipe implements PipeTransform {
  transform(
    messages: Message[],
    order: 'ascending' | 'descending' = 'descending'
  ): Message[] {
    if (messages.length === 0) {
      return messages;
    }
    return [...messages].sort((m1: Message, m2: Message) => {
      if (order === 'ascending') {
        return new Date(m1.date).getTime() - new Date(m2.date).getTime();
      } else {
        return new Date(m2.date).getTime() - new Date(m1.date).getTime();
      }
    });
  }
}
