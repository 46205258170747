import { createAction, props } from '@ngrx/store';
import { Vacancy, VacancyInterest } from './vacancy.model';
import { CreateVacancyForm } from '../vacancyForm/vacancyForm.model';

export enum VacancyActionTypes {
  GET = '[Vacancy] Get vacancies',
  GET_SUCCESS = '[Vacancy] Get vacancies success',
  GET_ERROR = '[Vacancy] Get vacancies success',
  GET_INTERESTS = '[Vacancy] Get vacancies interests',
  GET_INTERESTS_SUCCESS = '[Vacancy] Get vacancies interests success',
  GET_INTERESTS_ERROR = '[Vacancy] Get vacancies interests success',
  POST = '[Vacancy] Post vacancy',
  POST_SUCCESS = '[Vacancy] Post vacancy success',
  POST_ERROR = '[Vacancy] Post vacancy error',
  DELETE = '[Vacancy] Delete vacancy',
  DELETE_SUCCESS = '[Vacancy] Delete vacancy success',
  DELETE_ERROR = '[Vacancy] Delete vacancy error',
  POST_INTEREST = '[Vacancy] Post vacancy interest',
  POST_INTEREST_SUCCESS = '[Vacancy] Post vacancy interest success',
  POST_INTEREST_ERROR = '[Vacancy] Post vacancy interest error',
  GET_FOR_COMPANY = '[Vacancy] Get vacancies for company',
  GET_FOR_COMPANY_SUCCESS = '[Vacancy] Get vacancies for company success',
  GET_FOR_COMPANY_ERROR = '[Vacancy] Get vacancies for company success',
}

export const getVacancies = createAction(VacancyActionTypes.GET);
export const getVacanciesSuccess = createAction(
  VacancyActionTypes.GET_SUCCESS,
  props<{ vacancies: Vacancy[] }>()
);
export const getVacanciesError = createAction(
  VacancyActionTypes.GET_ERROR,
  props<{ error: string }>()
);

export const getVacanciesForCompany = createAction(
  VacancyActionTypes.GET_FOR_COMPANY,
  props<{ companyId: string }>()
);
export const getVacanciesForCompanySuccess = createAction(
  VacancyActionTypes.GET_FOR_COMPANY_SUCCESS,
  props<{ companyId: string, vacancies: Vacancy[] }>()
);
export const getVacanciesForCompanyError = createAction(
  VacancyActionTypes.GET_FOR_COMPANY_ERROR,
  props<{ error: string }>()
);

export const postVacancy = createAction(
  VacancyActionTypes.POST,
  props<{ vacancy: CreateVacancyForm }>()
);
export const postVacancySuccess = createAction(
  VacancyActionTypes.POST_SUCCESS,
  props<{ vacancy: Vacancy }>()
);
export const postVacancyError = createAction(
  VacancyActionTypes.POST_ERROR,
  props<{ error: string }>()
);

export const deleteVacancy = createAction(
  VacancyActionTypes.DELETE,
  props<{ vacancyId: string }>()
);
export const deleteVacancySuccess = createAction(
  VacancyActionTypes.DELETE_SUCCESS,
  props<{ vacancyId: string }>()
);
export const deleteVacancyError = createAction(
  VacancyActionTypes.DELETE_ERROR,
  props<{ error: string }>()
);

export const getVacanciesInterests = createAction(
  VacancyActionTypes.GET_INTERESTS
);
export const getVacanciesInterestsSuccess = createAction(
  VacancyActionTypes.GET_INTERESTS_SUCCESS,
  props<{ interests: VacancyInterest[] }>()
);
export const getVacanciesInterestsError = createAction(
  VacancyActionTypes.GET_INTERESTS_ERROR,
  props<{ error: string }>()
);

export const postVacancyInterest = createAction(
  VacancyActionTypes.POST_INTEREST,
  props<{ vacancyId: string; interested: boolean }>()
);
export const postVacancyInterestSuccess = createAction(
  VacancyActionTypes.POST_INTEREST_SUCCESS
);
export const postVacancyInterestError = createAction(
  VacancyActionTypes.POST_INTEREST_ERROR,
  props<{ error: string }>()
);
