import { HttpClient } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../ngrx-store/components/user/user.model';
import {Connection} from '../ngrx-store/components/connection/connection.model';
import {PublicClientApplication} from '@azure/msal-browser';
import {msalConfig} from '../auth-config';

@Injectable()
export class UserService {
  constructor(
    protected cookieService: CookieService,
    protected http: HttpClient,
  ) {}

  public getUser(): Observable<User> {
    return this.http.get<User>(`${environment.apiBaseUrl}user`, {
      headers: { 'Referrer-Policy': 'no-referrer-when-downgrade' },
    });
  }

  public getUserById(id: string): Observable<User> {
    return this.http.get<User>(`${environment.apiBaseUrl}user/${id}`);
  }

  public postUser(user: User | undefined): Observable<User> {
    return this.http.post<User>(`${environment.apiBaseUrl}user`, user);
  }

  public getConnection(withUser: User): Observable<Connection> {
    return this.http.get<Connection>(`${environment.apiBaseUrl}connection/${withUser.id}`);
  }

  public addWelcomeNotifications(): Observable<object> {
    return this.http.get(`${environment.apiBaseUrl}notifications/welcome`);
  }

  public getCompanyList(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiBaseUrl}companylist`);
  }

  public clearUserNotifications(): Observable<object> {
    return this.http.get(`${environment.apiBaseUrl}notifications/clear`);
  }

  public deleteNotification(id: string): Observable<object> {
    return this.http.delete(`${environment.apiBaseUrl}notifications/delete/${id}`);
  }

  public hasNewNotifications(): Observable<{ hasNew: boolean }> {
    return this.http.get<{ hasNew: boolean }>(`${environment.apiBaseUrl}notifications/new`);
  }

  public setEmail(email: string): Observable<object> {
    return this.http.post<object>(`${environment.apiBaseUrl}change-email-request`, { email });
  }

  public trackClick(eventType: string, companyId?: string): Observable<object> {
    return this.http.post<object>(`${environment.apiBaseUrl}clickevent`, { eventType, companyId });
  }

  public deleteUser(): Observable<object> {
    return this.http.delete<object>(`${environment.apiBaseUrl}user`);
  }

  public deleteUserId(id: string): Observable<object> {
    return this.http.delete<object>(`${environment.apiBaseUrl}admin/deleteaccount/${id}`);
  }

  public logout(): void {
    this.clear('/.auth/logout?post_logout_redirect_uri=https%3A%2F%2Frightbrains.nl').then();
  }

  public async clear(redirectTo: string) {
    this.cookieService.deleteAll();
    localStorage.clear();

    const names = await caches.keys();
    for (const name of names) {
      await caches.delete(name);
    }

    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    await msalInstance.logoutRedirect();
    await msalInstance.logoutPopup();
  }
}
