import {Component, EventEmitter, Output} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import {postUser} from '../../../../ngrx-store/requests/user/post-user/post-user.actions';

@Component({
  selector: 'app-onboarding-step2',
  templateUrl: './onboarding-step2.component.html',
  styleUrls: ['./onboarding-step2.component.scss'],
})
export class OnboardingStep2Component {
  @Output() submitStep = new EventEmitter();

  constructor(private store: Store<AppState>) {}

  public submit() {
    this.store.dispatch(postUser());
    this.submitStep.emit();
  }
}
