import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { UserService } from './user.service';
import { AppRoutes } from '../app-routing.model';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../ngrx-store/app.state';
import { getUserError, getUserSuccess } from '../ngrx-store/requests/user/get-user/get-user.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { getCompany } from '../ngrx-store/requests/company/get-company/get-company.actions';

@Injectable()
export class OnboardingGuardService  {
  constructor(private router: Router, private user: UserService, private store: Store<AppState>) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.user.getUser().pipe(
      map((user) => {
        const onboardingFinished = user.onboardingCompleted;
        // User tries to go to onboarding
        if (state.url === `/${AppRoutes.ONBOARDING}`) {
          if (onboardingFinished) {
            this.router.navigate([AppRoutes.DASHBOARD], { replaceUrl: true }).then(() => {
              this.store.dispatch(getUserSuccess({ user }));
            });
          } else {
            this.store.dispatch(getUserSuccess({ user }));
          }
          return !onboardingFinished;
        } else {
          // User tries to go to something other than onboarding
          if (!onboardingFinished) {
            this.router.navigate([AppRoutes.ONBOARDING], { replaceUrl: true }).then(() => {
              this.store.dispatch(getUserSuccess({ user }));
            });
          } else {
            this.store.dispatch(getUserSuccess({ user }));
            this.store.dispatch(getCompany());
          }
          return onboardingFinished || false;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.dispatch(getUserError({ error: error.message }));
        return of(false);
      })
    );
  }
}
