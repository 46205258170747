import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx-store/app.state';
import {
  selectUserNotificationCount,
  selectUserAvatar,
} from '../../../ngrx-store/components/user/user.selectors';
import { AppRoutes } from 'src/app/app-routing.model';
import { MatDialog } from '@angular/material/dialog';
import { ShareDialogComponent } from '../share-dialog/share-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss'],
})
export class MenubarComponent {
  @Output() logoutClicked = new EventEmitter<void>();

  userNotificationCount$ = this.store.select(selectUserNotificationCount);
  userAvatar$ = this.store.select(selectUserAvatar);

  public appRoutes = AppRoutes;
  public enableNonAdminFeatures = environment.featureToggles.enableNonAdminFeatures;

  constructor(private store: Store<AppState>, private dialog: MatDialog) {}

  shareSocialLink() {
    if (navigator.share) {
      navigator
        .share({
          title: 'Share RightBrains',
          text: 'Join RightBrains!',
          url: window.location.origin,
        })
        .catch(console.error);
      return;
    }

    this.dialog.open(ShareDialogComponent);
  }
}
