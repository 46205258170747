import { createAction, props } from '@ngrx/store';
import { User } from '../user/user.model';
import { UserEntity } from '../userEntities/userEntities.model';

export enum AdminActionTypes {
  GET_USERS = '[Admin] Get all users',
  GET_USERS_SUCCES = '[Admin] Get all users',
  GET_USER_EXCEL = '[Admin] Get user excel export',
  GET_USER_EXCEL_SUCCESS = '[Admin] Get user excel export',
  GET_PARTNER_EXCEL = '[Admin] Get partner excel export',
  GET_PARTNER_EXCEL_SUCCESS = '[Admin] Get partner excel export',
  GET_EVENT_REGISTRATIONS = '[Admin] Get registrations for event',
  GET_EVENT_REGISTRATIONS_SUCCESS = '[Admin] Get registrations for event success',
  SET_INDUSTRIES = '[Admin] Set industries',
  SET_INDUSTRY = '[Admin] Set industry',
  SET_INDUSTRIES_SUCCESS = '[Admin] Set industries sucess',
  SET_DIGITAL_AREAS = '[Admin] Set digital areas',
  SET_DIGITAL_AREA = '[Admin] Set digital area',
  SET_DIGITAL_AREAS_SUCCESS = '[Admin] Set digital areas success',
  SET_RIGHTS = '[Admin] Set user rights',
  SET_RIGHTS_SUCCESS = '[Admin] Set user rights success',
  ADMIN_REQUEST_ERROR = '[Admin] Admin request error',
  DELETE_COMPANY = '[User] Delete user from company',
  DELETE_COMPANY_SUCCESS = '[User] Delete user from company',
  ADD_COMPANY = '[User] Add user to company',
  ADD_COMPANY_SUCCESS = '[User] Add user to company',
}

export const getUsers = createAction(AdminActionTypes.GET_USERS);
export const getUsersSuccess = createAction(AdminActionTypes.GET_USERS_SUCCES, props<{ users: User[] }>());

export const getEventRegistrations = createAction(AdminActionTypes.GET_EVENT_REGISTRATIONS, props<{ eventId: string }>());
export const getEventRegistrationsSuccess = createAction(AdminActionTypes.GET_EVENT_REGISTRATIONS_SUCCESS);

export const getUserExcel = createAction(AdminActionTypes.GET_USER_EXCEL);
export const getUserExcelSuccess = createAction(AdminActionTypes.GET_USER_EXCEL_SUCCESS);

export const getPartnerExcel = createAction(AdminActionTypes.GET_PARTNER_EXCEL);
export const getPartnerExcelSuccess = createAction(AdminActionTypes.GET_PARTNER_EXCEL_SUCCESS);

export const setIndustries = createAction(AdminActionTypes.SET_INDUSTRIES, props<{ industries: UserEntity[] }>());
export const setIndustry = createAction(AdminActionTypes.SET_INDUSTRY, props<{ industry: UserEntity }>());
export const setIndustriesSuccess = createAction(
  AdminActionTypes.SET_INDUSTRIES_SUCCESS,
  props<{ industries: UserEntity[] }>()
);

export const setDigitalAreas = createAction(
  AdminActionTypes.SET_DIGITAL_AREAS,
  props<{ digitalAreas: UserEntity[] }>()
);
export const setDigitalArea = createAction(AdminActionTypes.SET_DIGITAL_AREA, props<{ digitalArea: UserEntity }>());
export const setDigitalAreasSuccess = createAction(
  AdminActionTypes.SET_DIGITAL_AREAS_SUCCESS,
  props<{ digitalAreas: UserEntity[] }>()
);

export const setRights = createAction(
  AdminActionTypes.SET_RIGHTS,
  props<{ id: string; rights: { isAdmin: boolean; isPartner: boolean } }>()
);
export const setRightsSuccess = createAction(AdminActionTypes.SET_RIGHTS_SUCCESS, props<{ id: string }>());

export const adminRequestError = createAction(AdminActionTypes.ADMIN_REQUEST_ERROR, props<{ error: string }>());

export const deleteCompany = createAction(AdminActionTypes.DELETE_COMPANY, props<{ id: string }>());
export const deleteCompanySuccess = createAction(AdminActionTypes.DELETE_COMPANY_SUCCESS);

export const addCompany = createAction(AdminActionTypes.ADD_COMPANY, props<{ id: string, companyId: string, companyName: string }>());
export const addCompanySuccess = createAction(AdminActionTypes.ADD_COMPANY_SUCCESS);
