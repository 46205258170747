<ng-container *ngIf="user" style="overflow: hidden">
  <div class="container">
    <div class="header-container">
      <p class="align-center color-primary">Profile</p>
      <a class="back-arrow color-primary" [routerLink]="'/'">
        <span></span>
        <mat-icon>arrow_back</mat-icon>
      </a>
      <div class="bevel"></div>
    </div>

    <app-profile-details
      [user]="user"
      [self]="true"
      [connection]="connection"
      [connectionsRetrieved]=""
      (refreshUser)="getUser()"
    >
    </app-profile-details>

    <ng-container *ngIf="user.isAdmin">
      <div class="bevel"></div>
      <button
        class="btn-sm mt-3"
        mat-flat-button
        color="primary"
        (click)="openProfile(user)"
      >
        Open Profile
      </button>
      <button
        class="btn-sm mt-3"
        mat-flat-button
        color="primary"
        (click)="addWelcomeNotifications()"
      >
        Add Welcome notifications
      </button>
      <br />
      <button
        class="btn-sm mt-3"
        mat-flat-button
        color="primary"
        (click)="doOnboardingAgain()"
      >
        Do onboarding again
      </button>
    </ng-container>

    <div class="bevel"></div>

    <app-profile-work-experience
      [companies]="user?.userBackground?.companies"
      [self]="true"
    ></app-profile-work-experience>

    <div class="bevel"></div>

    <app-profile-education
      [educations]="user?.userBackground?.educations"
      [self]="true"
    >
    </app-profile-education>

    <div class="bevel"></div>

    <app-profile-digital-areas
      [digitalAreaBackgrounds]="user?.userBackground?.digitalAreas"
      [digitalAreaInterests]="user?.userInterests?.digitalAreas"
      [self]="true"
    ></app-profile-digital-areas>

    <div class="bevel"></div>

    <app-profile-industries
      [industryBackgrounds]="user?.userBackground?.industries"
      [industryInterests]="user?.userInterests?.industries"
      [self]="true"
    ></app-profile-industries>

    <div class="bevel"></div>

    <app-profile-social-media
      [user]="user"
      [self]="true"
      (refreshUser)="getUser()"
    ></app-profile-social-media>
  </div>
</ng-container>
