import { Component } from '@angular/core';
import { CompanyDetailsBaseComponent } from '../company-details-base/company-details-base.component';
import { EventDialogComponent } from '../../event-dialog/event-dialog.component';
import { selectCompany } from 'src/app/ngrx-store/components/company/company.selectors';
import { AppState } from 'src/app/ngrx-store/app.state';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { postDetailsCompany } from 'src/app/ngrx-store/requests/company/post-company/post-company.actions';
import { RightbrainsEvent } from '../../../../ngrx-store/components/event/event.model';
import { deleteEvent } from '../../../../ngrx-store/components/company/company.actions';
import { AppRoutes } from 'src/app/app-routing.model';
import {
  loadCompanyEvent,
  clearCompanyEventForm,
} from '../../../../ngrx-store/components/companyForms/companyEvent/forms.companyEvent.actions';

@Component({
  selector: 'app-company-events',
  templateUrl: './company-events.component.html',
  styleUrls: ['./company-events.component.scss'],
})
export class CompanyEventsComponent extends CompanyDetailsBaseComponent {
  company$ = this.store.select(selectCompany);
  public AppRoutes = AppRoutes;

  constructor(
    protected readonly store: Store<AppState>,
    private dialog: MatDialog
  ) {
    super(store);
  }

  toggleModify(modify: boolean): void {
    if (modify) {
      this.store.dispatch(clearCompanyEventForm());
      this.dialog.open(EventDialogComponent);
    }
  }

  removeEvent(event: RightbrainsEvent) {
    this.store.dispatch(deleteEvent({ event }));
    this.store.dispatch(postDetailsCompany());
  }

  editEvent(event: RightbrainsEvent) {
    this.store.dispatch(loadCompanyEvent({ event }));
    this.dialog.open(EventDialogComponent, { data: { isEdit: true } });
  }

  filterActiveEvents(events: RightbrainsEvent[]): RightbrainsEvent[] {
    return events.filter(
      (event) => new Date(event.eventDate).getTime() >= Date.now()
    );
  }
}
