<div
  class="dashboard-title"
  *ngIf="title"
  [class.pointer]="collapsable"
  (click)="isCollapsed = !isCollapsed"
>
  <div class="collapse-button" *ngIf="!isCollapsed">
    <i class="fas fa-chevron-up"></i>
  </div>
  <div class="collapse-button" *ngIf="isCollapsed">
    <i class="fas fa-chevron-down"></i>
  </div>
  <div class="underlined-title story-title">
    <p><span class="text-nowrap">Your best</span> matches</p>
    <div></div>
  </div>
  <div class="collapse-button invisible"></div>
</div>
<div [ngbCollapse]="isCollapsed">
  <div *ngIf="!loading; else loadingSpinner">
    <div *ngIf="matchModel$ | async as matches">
      <div
        style="text-align: center; margin-bottom: 10px"
        *ngIf="userModel$ | async as userModel"
      >
        <button
          #mentorsButton
          class="filter-matches-button filter-matches-button-inactive"
          mat-flat-button
          color="primary"
          *ngIf="userModel.role == 2 || userModel.role == 3"
          (click)="filterMatches('mentors')"
        >
          Mentor matches
        </button>
        <button
          #menteesButton
          style="margin-left: 20px"
          class="filter-matches-button filter-matches-button-inactive"
          mat-flat-button
          color="primary"
          *ngIf="userModel.role == 1 || userModel.role == 3"
          (click)="filterMatches('mentees')"
        >
          Mentee matches
        </button>
      </div>
      <div
        *ngIf="
          matches &&
          matches[currentTypeMatch] &&
          matches[currentTypeMatch].length == 0
        "
      >
        <div class="align-center">
          <mat-card appearance="outlined" class="match-card float-none no-matches">
            <mat-card-content class="profile-card-content">
              Not a lot of great matches were found... <br />
              Try to check again later.
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div *ngIf="matches" class="match-cards">
        <div *ngFor="let match of matches[currentTypeMatch] | slice : 0 : 3">
          <app-profilecard
            [profile]="match"
            (onDismiss)="dismissMatch(match)"
            [canDismiss]="true"
          ></app-profilecard>
        </div>
      </div>
    </div>
  </div>
  <ng-template #loadingSpinner>
    <div class="w-100">
      <mat-spinner
        *ngIf="!spinnerColorSecondary"
        class="margin-auto"
        [diameter]="65"
      ></mat-spinner>
      <mat-spinner
        *ngIf="spinnerColorSecondary"
        color="accent"
        class="margin-auto"
        [diameter]="65"
      ></mat-spinner>
    </div>
  </ng-template>
  <br />
  <div class="all-matches-button" *ngIf="viewAllButton">
    <a [routerLink]="'/' + AppRoutes.MATCHES">
      <button class="btn-sm mx-auto" mat-flat-button color="primary">
        Search more matches
      </button>
    </a>
  </div>
</div>
