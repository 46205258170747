import { Component, Input } from '@angular/core';
import { AppState } from 'src/app/ngrx-store/app.state';
import { Store } from '@ngrx/store';
import { selectPendingConnections } from 'src/app/ngrx-store/components/connection/connection.selectors';

@Component({
  selector: 'app-pending-connections',
  templateUrl: './pending-connections.component.html',
})
export class PendingConnectionsComponent {
  pendingConnections$ = this.store.select(selectPendingConnections);

  @Input() displayLimit = 10;
  showMoreCount = 0;

  constructor(private store: Store<AppState>) {}
}
