<div
  class="message-container"
  [class.clickable]="!expandedReplies && !hideReplies"
>
  <div class="avatar-wrapper">
    <img
      *ngIf="message.user.avatarRef; else placeholder"
      class="medium-avatar"
      [src]="message.user.avatarRef | safe : 'url'"
      (click)="openProfile(message.user)"
    />
    <ng-template #placeholder>
      <mat-icon class="medium-avatar" width="40" height="40"
        >account_circle</mat-icon
      >
    </ng-template>
  </div>
  <div class="content">
    <span
      ><b>{{ message.user.firstName + " " + message.user.lastName }}</b
      >&nbsp; <i>{{ message.date | date }}</i
      >&nbsp;
      <span
        (click)="emitDeleteMessage(message.id, $event)"
        *ngIf="userId === message.user.id"
      >
        <i class="fas fa-trash"></i>
      </span>
    </span>
    <div class="message">
      {{
        !expandedReplies && !hideReplies
          ? message.content.slice(0, 200)
          : message.content
      }}
      <span
        *ngIf="
          message.content.length >= 200 && !expandedReplies && !hideReplies
        "
      >
        ...
        <span (click)="goToThread(message.id)" class="link">(read more)</span>
      </span>
    </div>
    <div class="mt-2" *ngIf="message.photo">
      <img class="photo" [src]="message.photo | safe : 'url'" />
    </div>
    <div class="mt-2" *ngIf="message.video">
      <iframe
        class="video"
        [src]="message.video | safeVideo"
        frameborder="0"
        width="300px"
        height="300px"
        allowfullscreen
      ></iframe>
    </div>
    <div class="reactions-container">
      <app-reactions
        [reactions]="message.reactions"
        (addReaction)="emitAddReaction(message.id, $event)"
      ></app-reactions>
    </div>
    <div *ngIf="!hideReplies">
      <div *ngIf="message.replies">
        <div
          *ngIf="!expandedReplies"
          class="reply-container"
          (click)="goToThread(message.id)"
        >
          <div
            class="small-avatar-wrapper"
            *ngFor="let reply of message.replies.slice(0, 2)"
          >
            <img
              *ngIf="reply.user.avatarRef; else placeholder"
              class="small-avatar"
              [src]="reply.user.avatarRef | safe : 'url'"
            />
            <ng-template #placeholder>
              <mat-icon class="small-avatar" width="20" height="20"
                >account_circle</mat-icon
              >
            </ng-template>
          </div>
          <div class="link">
            <b>
              {{ message.replies.length }}
              {{ message.replies.length == 1 ? "reply" : "replies" }}</b
            >
          </div>
          <div class="mx-2 view-in-thread">
            <div>View thread</div>
            <div>></div>
          </div>
        </div>

        <div *ngIf="expandedReplies" class="replies">
          <app-message
            *ngFor="let reply of message.replies | sortMessages : 'ascending'"
            [message]="reply"
            [hideReplies]="true"
            (addReaction)="emitAddReaction($event.messageId, $event.reaction)"
            [userId]="userId"
            (deleteMessage)="emitDeleteMessage(reply.id)"
          ></app-message>
        </div>
      </div>
    </div>
  </div>
</div>
