import { Component, Input } from '@angular/core';
import { DigitalArea } from 'src/app/ngrx-store/components/user/user.model';

@Component({
  selector: 'app-profile-digital-areas',
  templateUrl: './profile-digital-areas.component.html',
  styleUrls: ['./profile-digital-areas.component.scss']
})
export class ProfileDigitalAreasComponent {

  @Input() digitalAreaBackgrounds: DigitalArea[];
  @Input() digitalAreaInterests: DigitalArea[];
  @Input() self = false;

  constructor() { }

}
