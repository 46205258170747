import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MatchService } from 'src/app/services/match.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NoFilter } from 'src/app/ngrx-store/components/match/match.model';
import { getMembers, getMembersSuccess, getMembersError } from './get-members.actions';

@Injectable()
export class GetMembersEffects {
  constructor(private actions$: Actions, private matchService: MatchService) {}

  getMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMembers),
      switchMap((a) => {
        return this.matchService.getCommunityMembers(a.filter || NoFilter, a.limit, a.bestMatches).pipe(
          map((matches) => getMembersSuccess({ matches })),
          catchError((error: HttpErrorResponse) => of(getMembersError({ error: error.message })))
        );
      })
    )
  );
}
