import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { catchError, exhaustMap, map, concatMap, withLatestFrom, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatchService } from '../../../../services/match.service';
import {
  sendConnectionRequest,
  sendConnectionSuccess,
  sendConnectionError,
  acceptConnection,
  denyConnection,
  denyConnectionSuccess,
  removeConnection,
  removeConnectionSuccess,
  initiateConnectionRequest,
} from 'src/app/ngrx-store/components/connection/connection.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { selectUser } from 'src/app/ngrx-store/components/user/user.selectors';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionRequestDialogComponent } from 'src/app/shared/components/connection-request-dialog/connection-request-dialog.component';

@Injectable()
export class PostConnectionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private matchService: MatchService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  initiateConnectionRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initiateConnectionRequest),
        tap((action) => {
          this.dialog.open(ConnectionRequestDialogComponent, {
            data: {
              connectionId: action.id,
            },
          });
        })
      ),
    { dispatch: false }
  );

  postConnectionRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendConnectionRequest),
      withLatestFrom(this.store.pipe(select(selectUser))),
      concatMap(([action, user]) => {
        return this.matchService.sendConnectionRequest(action.id, action.message).pipe(
          map((connectionResponse) => {
            if (user?.onboardingCompleted) {
              this.snackbar.open(`You sent a connection request to ${connectionResponse.user.firstName}`, undefined, {
                duration: 5000,
                panelClass: ['success-snackbar'],
              });
            }
            return sendConnectionSuccess({ connection: connectionResponse });
          }),
          catchError((error: Error) => {
            return of(sendConnectionError({ error: error.message }));
          })
        );
      })
    )
  );

  acceptConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptConnection),
      exhaustMap((action) => {
        return this.matchService.acceptConnectionRequest(action.userId).pipe(
          map((connectionResponse) => {
            this.snackbar.open('You and ' + connectionResponse.user.firstName + ' are now connected!', undefined, {
              duration: 5000,
              panelClass: ['success-snackbar'],
            });
            return sendConnectionSuccess({ connection: connectionResponse });
          }),
          catchError((error: Error) => {
            return of(sendConnectionError({ error: error.message }));
          })
        );
      })
    )
  );

  denyConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(denyConnection),
      exhaustMap((action) => {
        return this.matchService.denyConnectionRequest(action.userId).pipe(
          map(() => {
            return denyConnectionSuccess({ userId: action.userId });
          }),
          catchError((error: Error) => {
            return of(sendConnectionError({ error: error.message }));
          })
        );
      })
    )
  );

  removeConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeConnection),
      exhaustMap((action) => {
        return this.matchService.removeConnection(action.userId).pipe(
          map(() => {
            return removeConnectionSuccess({ userId: action.userId });
          }),
          catchError((error: Error) => {
            return of(sendConnectionError({ error: error.message }));
          })
        );
      })
    )
  );
}
