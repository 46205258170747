import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Company } from '../ngrx-store/components/company/company.model';

@Injectable()
export class CompanyService {
  constructor(protected http: HttpClient) {}

  public createNewCompany(
    companyCode: string,
    companyName: string
  ): Observable<Company> {
    return this.http.post<Company>(`${environment.apiBaseUrl}new-company`, {
      companyCode,
      companyName,
    });
  }

  public postCompany(company?: Company) {
    return this.http.post<Company>(`${environment.apiBaseUrl}company`, company);
  }

  public getCompany() {
    return this.http.get<Company>(`${environment.apiBaseUrl}company`);
  }

  public getCompanies() {
    return this.http.get<Company[]>(`${environment.apiBaseUrl}companies`);
  }

  followCompany(id: string): Observable<{ id: string }> {
    return this.http.get<{ id: string }>(
      `${environment.apiBaseUrl}company/follow/${id}`
    );
  }

  unfollowCompany(id: string): Observable<{ id: string }> {
    return this.http.get<{ id: string }>(
      `${environment.apiBaseUrl}company/unfollow/${id}`
    );
  }

  deleteCompany(id: string): Observable<{ id: string }> {
    return this.http.delete<{ id: string }>(
      `${environment.apiBaseUrl}company/${id}`
    );
  }
}
