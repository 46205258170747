import { createReducer, on } from '@ngrx/store';
import { VacancyState } from './vacancy.model';
import {
  postVacancyInterest,
  getVacanciesInterestsSuccess, getVacanciesForCompanySuccess,
} from './vacancy.actions';
import {
  getVacanciesSuccess,
  postVacancySuccess,
  deleteVacancySuccess,
} from './vacancy.actions';

export const initialVacancyState: VacancyState = {
  vacancies: [],
  interests: [],
  vacanciesForCompany: {}
};

export const vacancyReducer = createReducer<VacancyState>(
  initialVacancyState,
  on(getVacanciesSuccess, (state, action) => {
    return { ...state, vacancies: action.vacancies };
  }),
  on(postVacancySuccess, (state, action) => {
    return {
      ...state,
      vacancies: state.vacancies
        .filter((v) => v.id !== action.vacancy.id)
        .concat([action.vacancy]),
    };
  }),
  on(deleteVacancySuccess, (state, action) => {
    return {
      ...state,
      vacancies: state.vacancies.filter((v) => v.id !== action.vacancyId),
    };
  }),
  on(postVacancyInterest, (state, action) => {
    return {
      ...state,
      interests: state.interests
        .filter((i) => i.id !== action.vacancyId)
        .concat([{ id: action.vacancyId, interested: action.interested }]),
    };
  }),
  on(getVacanciesInterestsSuccess, (state, action) => {
    return {
      ...state,
      interests: action.interests,
    };
  }),
  on(getVacanciesForCompanySuccess, (state, action) => {
    return { ...state, vacanciesForCompany: {
      ...state.vacanciesForCompany,
        [action.companyId]: action.vacancies
      }
    };
  }),
);
