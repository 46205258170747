import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectProfileEditForm } from 'src/app/ngrx-store/components/userForms/userForms.selectors';
import { AppState } from 'src/app/ngrx-store/app.state';
import { postUser, deleteUser } from 'src/app/ngrx-store/requests/user/post-user/post-user.actions';
import { submitProfileEditForm } from 'src/app/ngrx-store/components/userForms/profileEdit/forms.profileEdit.actions';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { selectUser } from 'src/app/ngrx-store/components/user/user.selectors';
import { setEmail } from 'src/app/ngrx-store/components/user/user.actions';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  profileEditForm$ = this.store.select(selectProfileEditForm);
  user$ = this.store.select(selectUser);

  constructor(
    private store: Store<AppState>,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
  ) {}

  saveProfile() {
    this.profileEditForm$.pipe(take(1)).subscribe((form) => {
      this.store.dispatch(submitProfileEditForm({ formValues: form.value }));
      this.store.dispatch(postUser());
    });
  }

  changeEmail(value: string) {
    // tslint:disable-next-line: max-line-length
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(String(value).toLowerCase())) {
      this.snackbar.open('Invalid email entered', '', { duration: 5000, panelClass: ['failed-snackbar'] });
      return;
    }

    this.store.dispatch(setEmail({ newEmail: value }));
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        noCancel: true,
        message:
          // tslint:disable-next-line: max-line-length
          'Please click the activation link in the email we sent to this new address.',
        callback: () => {},
      },
    });
  }

  deleteAccount() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message:
          'Are you sure you want to permanently delete your account? You will lose all of your connections and settings.',
        callback: (confirmed: boolean) => {
          if (!confirmed) {
            return;
          }

          this.store.dispatch(deleteUser());
        },
      },
    });
  }
}
