<div id="footer-container">
  <div id="footer-content-wrapper">
    <img
      id="footer-logo"
      src="../../../../assets/images/RightbrainsLogoBlack.png"
    />
    <div>
      <strong>Website</strong><br />
      <a
        href="https://rightbrains.nl/"
        class="color-social-icon"
        target="_blank"
        >RightBrains.nl</a
      >
    </div>
    <div class="ms-0 d-lg-none d-flex justify-content-center">
      <app-a2hs-button></app-a2hs-button>
    </div>

    <div>
      <strong>Address</strong><br />
      <div>Prins Hendriklaan 6</div>
      <div>3701 CM Zeist</div>
      <div>The Netherlands</div>
    </div>

    <div>
      <strong>Email</strong><br />
      <div>
        <a href="mailto:info@rightbrains.nl" class="color-social-icon"
          >info&#64;rightbrains.nl</a
        >
      </div>
      <br />
      <a
        href="https://www.linkedin.com/company/rightbrains"
        target="_blank"
        class="color-social-icon"
      >
        <img
          src="../../../../assets/images/brands/linkedin.svg"
          class="social-media-icon"
        />
      </a>
      <a
        href="https://www.instagram.com/right.brains"
        target="_blank"
        class="color-social-icon"
      >
        <img
          src="../../../../assets/images/brands/instagram.svg"
          class="social-media-icon"
        />
      </a>
      <a
        href="https://www.facebook.com/RightBrains.nl"
        target="_blank"
        class="color-social-icon"
      >
        <img
          src="../../../../assets/images/brands/facebook.svg"
          class="social-media-icon"
        />
      </a>
      <a
        href="https://twitter.com/rightbrains_nl"
        target="_blank"
        class="color-social-icon"
      >
        <img
          src="../../../../assets/images/brands/twitter.svg"
          class="social-media-icon"
        />
      </a>
    </div>
  </div>
  <div id="footer-bottom">
    <p>
      Copyright © {{ year }} RightBrains <br />
      <a href="https://rightbrains.nl/terms-and-conditions.html"
        >Terms and Conditions</a
      >
      <br />
      <a href="https://rightbrains.nl/privacy-notice.html">Privacy Notice</a>
    </p>
  </div>
</div>
