import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {BlogItemService} from '../../../services/blogitem.service';
import {getBlogItems, getBlogItemsError, getBlogItemsSuccess} from './blogitem.actions';

@Injectable()
export class GetBlogItemEffects {
  constructor(private actions$: Actions,
              private blogItemService: BlogItemService) {
  }

  getBlogItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBlogItems),
      switchMap(() => {
        return this.blogItemService.getBlogItems().pipe(
          map((result) => getBlogItemsSuccess({stories: result})),
          catchError((error: HttpErrorResponse) => of(getBlogItemsError({error: error.message})))
        );
      })
    ));
}
