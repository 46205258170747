<div *ngIf="stories.length == 0; else storycards">
  <div class="align-center">
    <mat-card appearance="outlined" class="match-card float-none">
      <mat-card-content class="profile-card-content">
        No stories were found... <br>
        Try to check again later.
      </mat-card-content>
    </mat-card>
  </div>
</div>
<ng-template #storycards>
  <div class="match-cards">
    <div *ngFor="let story of stories"> <!-- do not slice here, please pass sliced lists to this component -->
      <app-storycard [story]="story"></app-storycard>
    </div>
  </div>
  <br>
  <div class="all-matches-button" *ngIf="viewAllButton">
    <a [routerLink]="'/' + AppRoutes.STORIES">
      <button class="btn-sm mx-auto" mat-flat-button color="primary">View all stories</button>
    </a>
  </div>
</ng-template>

