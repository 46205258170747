import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/ngrx-store/components/user/user.model';

export enum GetUserActionTypes {
  GET = '[User] Get',
  SUCCESS = '[User] Get success',
  ERROR = '[User] Get error',
  UNAUTH = '[User] Get user unauthorized',
  CLEAR = '[User] Get clear',
  GET_COMPANY_LIST = '[User] Get Company List',
  GET_COMPANY_LIST_SUCCESS = '[User] Get Company List Success',
  GET_COMPANY_LIST_FAILED = '[User] Get Company List Failed',
  ADD_WELCOME_NOTIFICATIONS = '[User] Add welcome notifications to server',
  ADD_WELCOME_SUCCESS = '[User] Add welcome notifications success',
  ADD_WELCOME_ERROR = '[User] Add welcome notifications error',
  CLEAR_NOTIFICATIONS = '[User] Clear user notifications',
  DELETE_NOTIFICATION = '[User] Delete notification',
  CHECK_NEW_NOTIFICATIONS = '[User] Check for new notifications',
  CHECK_NEW_NOTIFICATIONS_SUCCESS = '[User] Check for new notifications success',
  NOTIFICATION_CHECKING = '[User] Set checking for notifications',
}

export const getUser = createAction(GetUserActionTypes.GET);
export const getUserSuccess = createAction(GetUserActionTypes.SUCCESS, props<{ user: User }>());
export const getUserError = createAction(GetUserActionTypes.ERROR, props<{ error: string }>());
export const getUserUnauth = createAction(GetUserActionTypes.UNAUTH);
export const getUserClear = createAction(GetUserActionTypes.CLEAR);

export const getCompanyList = createAction(GetUserActionTypes.GET_COMPANY_LIST);
export const getCompanyListSuccess = createAction(GetUserActionTypes.GET_COMPANY_LIST_SUCCESS, props<{ companies: string[] }>());
export const getCompanyListFailed = createAction(GetUserActionTypes.GET_COMPANY_LIST_FAILED, props<{ error: string }>());

export const addWelcomeNotifications = createAction(GetUserActionTypes.ADD_WELCOME_NOTIFICATIONS);
export const clearUserNotifications = createAction(GetUserActionTypes.CLEAR_NOTIFICATIONS);
export const deleteNotification = createAction(GetUserActionTypes.DELETE_NOTIFICATION, props<{ id: string }>());
export const notificationRequestSuccess = createAction(GetUserActionTypes.ADD_WELCOME_SUCCESS);
export const notificationRequestFailed = createAction(GetUserActionTypes.ADD_WELCOME_ERROR);
export const checkNewNotifications = createAction(GetUserActionTypes.CHECK_NEW_NOTIFICATIONS);
export const setCheckingForNotifications = createAction(
  GetUserActionTypes.NOTIFICATION_CHECKING,
  props<{ checking: boolean }>()
);
export const checkNewNotificationsSuccess = createAction(
  GetUserActionTypes.CHECK_NEW_NOTIFICATIONS_SUCCESS,
  props<{ hasNew: boolean }>()
);
