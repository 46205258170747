<div *ngIf="companyDetailsForm$ | async as form">
  <app-company-details-header
    title="Industry"
    *ngIf="!isSignUp"
    [canEdit]="!company"
    [modify]="modify"
    (onModify)="toggleModify($event)"
    [paddingBottom]="false"
    data-cy="company-industry-title"
  ></app-company-details-header>

  <div class="pe-4" *ngIf="company; else self">
    {{ company.industry?.name }}
  </div>

  <ng-template #self>
    <div *ngIf="industries$ | async as industries">
      <app-input-select
        *ngIf="modify || isSignUp"
        [form]="form"
        [formControl]="form.controls.industry"
        [selectionList]="industries"
        [label]="isSignUp ? 'Industry' : ''"
        key="name"
        emptyOption="Select core industry"
        requiredErrorMessage = 'Please select a core industry'
      ></app-input-select>
    </div>

    <div *ngIf="!modify && !isSignUp">
      {{ form.controls.industry.value?.value?.name }}
    </div>
  </ng-template>
</div>
