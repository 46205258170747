<mat-dialog-content *ngIf="user">
  <div class="row m-0 p-0 col-12 col-md-10 mx-auto">
    <div class="col-12 mt-3 mb-4 d-flex justify-content-between">
      <div class="underlined-title">
        <p class="text-primary">Profile</p>
        <div></div>
      </div>

      <button
        mat-icon-button
        mat-dialog-close
        color="primary"
        class="close-button"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <app-profile-overview
      [user]="user"
      [connection]="connection"
    ></app-profile-overview>
  </div>
</mat-dialog-content>
