import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatchService } from '../../../../services/match.service';
import {
  getConnections,
  getConnectionsSuccess,
  getConnectionsError, getPendingConnections, getPendingConnectionsSuccess, getPendingConnectionsError,
} from 'src/app/ngrx-store/components/connection/connection.actions';
import { NoFilter } from 'src/app/ngrx-store/components/match/match.model';

@Injectable()
export class GetConnectionEffects {
  constructor(private actions$: Actions, private store: Store<AppState>, private matchService: MatchService) {}

  getConnectionsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConnections),
      switchMap((a) => {
        return this.matchService.getConnections(a.filter || NoFilter).pipe(
          map((connections) => {
            return getConnectionsSuccess({ connections });
          }),
          catchError((error: Error) => {
            return of(getConnectionsError({ error: error.message }));
          })
        );
      })
    )
  );

  getPendingConnectionsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPendingConnections),
      switchMap(() => {
        return this.matchService.getPendingConnections().pipe(
          map((connections) => {
            return getPendingConnectionsSuccess({ connections });
          }),
          catchError((error: Error) => {
            return of(getPendingConnectionsError({ error: error.message }));
          })
        );
      })
    )
  );
}
