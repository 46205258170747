import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectPersonalDetailsForm } from 'src/app/ngrx-store/components/userForms/userForms.selectors';
import {
  postUser,
  uploadAvatar,
} from 'src/app/ngrx-store/requests/user/post-user/post-user.actions';
import { submitPersonalDetailsForm } from '../../../../ngrx-store/components/userForms/onboardingPersonalDetails/forms.personalDetails.actions';
import { PersonalDetailsForm } from '../../../../ngrx-store/components/userForms/onboardingPersonalDetails/forms.personalDetails.model';
import { PROVINCIES_LIST } from '../../../../shared/utilities/data/provincies.data';
import { EmploymentStatus, ReferenceType } from '../../../../ngrx-store/components/userForms/userForms.model';
import { Actions, ofType } from '@ngrx/effects';
import {
  getCompanyList,
  getCompanyListSuccess,
} from '../../../../ngrx-store/requests/user/get-user/get-user.actions';
import { selectUserAvatar } from 'src/app/ngrx-store/components/user/user.selectors';

@Component({
  selector: 'app-onboarding-step1',
  templateUrl: './onboarding-step1.component.html',
  styleUrls: ['./onboarding-step1.component.scss'],
})
export class OnboardingStep1Component implements OnInit {
  public companies: string[] = [];
  public provincies = PROVINCIES_LIST;

  public employmentStatus = EmploymentStatus;
  public referenceType = ReferenceType;
  public basicReferenceTypes = [
    this.referenceType.Social_Media,
    this.referenceType.Google_Search,
  ];

  @Input() submittingStep = false;
  @Output() submitStep = new EventEmitter();

  public personalDetailsForm$ = this.store.select(selectPersonalDetailsForm);
  public selectUserAvatar$ = this.store.select(selectUserAvatar);

  constructor(private store: Store<AppState>, private actions$: Actions) {
    this.actions$
      .pipe(ofType(getCompanyListSuccess))
      .subscribe((companyList) => (this.companies = companyList.companies));
  }

  public submitFormAndPost(formValues: PersonalDetailsForm) {
    this.store.dispatch(submitPersonalDetailsForm({ formValues }));
    this.store.dispatch(postUser());
    this.submitStep.emit();
  }

  ngOnInit(): void {
    this.store.dispatch(getCompanyList());
  }

  addPhoto(file: File): void {
    this.store.dispatch(uploadAvatar({ file }));
  }
}
