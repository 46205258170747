import { createAction, props } from '@ngrx/store';
import { ExperienceForm } from './forms.experience.model';
import { UserCompany } from '../../user/user.model';

export enum ExperienceActionTypes {
  PREFILL = '[UserForms] Prefill experience form',
  CLEAR = '[UserForms] Clear experience form',
  SUBMIT = '[UserForms] Submit experience form',
}

export const prefillExperienceForm = createAction(ExperienceActionTypes.PREFILL, props<{ company: UserCompany, index: number }>());
export const submitExperienceForm = createAction(ExperienceActionTypes.SUBMIT, props<{ formValues: ExperienceForm }>());
export const clearExperienceForm = createAction(ExperienceActionTypes.CLEAR);
