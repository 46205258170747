import { Component, OnInit } from '@angular/core';
import { Store} from '@ngrx/store';
import { AppRoutes } from 'src/app/app-routing.model';
import { AppState } from '../../ngrx-store/app.state';
import { getEvents } from '../../ngrx-store/requests/event/get-event.actions';
import { selectEvent } from '../../ngrx-store/components/event/event.selectors';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  public eventState$ = this.store.select(selectEvent);
  public AppRoutes = AppRoutes;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.store.dispatch(getEvents());
  }

  toTime(timeString: string) {
    const timeTokens = timeString.split(':');
    const date = new Date(1970, 0, 1, Number(timeTokens[0]), Number(timeTokens[1]), 0);
    return date.toLocaleTimeString('en', {hour: 'numeric', minute: 'numeric'}).toLowerCase();
  }

  openEvent(url: string) {
    window.open(url, '_blank');
  }
}
