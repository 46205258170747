import { Component, Input, OnInit } from '@angular/core';
import { Company } from '../../../ngrx-store/components/company/company.model';

@Component({
  selector: 'app-companycards',
  templateUrl: './companycards.component.html',
  styleUrls: ['./companycards.component.scss']
})
export class CompanycardsComponent implements OnInit {
  @Input() companies: Company[];
  @Input() viewAllButton = true;

  public followedCompanies: Company[];
  public preferedCompanies: Company[];
  public nonPreferedCompanies: Company[];

  ngOnInit() {
    this.followedCompanies = this.companies.filter(company => company.isFollowing)
      .sort(() => Math.random() - 0.5);
    this.preferedCompanies = this.companies
      .filter(company => company.name !== 'RightBrains' && !company.isFollowing)
      .sort(() => Math.random() - 0.5);
    this.nonPreferedCompanies = this.companies
      .filter(company => company.name === 'RightBrains' && !company.isFollowing);
  }
}
