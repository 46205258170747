import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'shortdate' })
export class ShortDatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  transform(date: Date | string, format: string = 'MMM yyyy'): string | null {
    date = new Date(date); // if orginal type was a string
    let result: string | null = '';

    try {
      result = new DatePipe(this.locale).transform(date, format);
    } catch {}

    return result;
  }
}
