import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { RightbrainsEvent } from '../../../ngrx-store/components/event/event.model';
import { AppRoutes } from 'src/app/app-routing.model';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-eventcard',
  templateUrl: './eventcard.component.html',
  styleUrls: ['./eventcard.component.scss']
})
export class EventcardComponent {
  public AppRoutes = AppRoutes;
  @Input() event: RightbrainsEvent;
  @Input() showPartnerLabel = true;

  @ViewChild('eventTitle') eventTitle: ElementRef;
  @ViewChild('eventCardContent') eventCardContent: ElementRef;

  constructor(private dialog: MatDialog) {
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  toTime(timeString: string) {
    const timeTokens = timeString.split(':');
    const date = new Date(1970, 0, 1, Number(timeTokens[0]), Number(timeTokens[1]), 0);
    return date.toLocaleTimeString('en', {hour: 'numeric', minute: 'numeric'}).toLowerCase();
  }

  inFuture(eveDate: Date) {
    return new Date(eveDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
  }

  openEvent(url: string) {
    window.open(url, '_blank');
  }
}
