import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User} from '../../../ngrx-store/components/user/user.model';
import {initiateConnectionRequest} from '../../../ngrx-store/components/connection/connection.actions';
import {AppState} from '../../../ngrx-store/app.state';
import {Store} from '@ngrx/store';
import {AppRoutes} from 'src/app/app-routing.model';
import {ProfileDialogComponent} from '../profile-dialog/profile-dialog.component';
import {UserService} from '../../../services/user.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-profilecard',
  templateUrl: './profilecard.component.html',
  styleUrls: ['./profilecard.component.scss']
})
export class ProfilecardComponent {
  public AppRoutes = AppRoutes;

  loading = false;

  @Input() profile: any;

  @Input() canDismiss = false;

  @Input() hasConnection = false;

  @Input() hasPendingConnection = false;

  @Input() isSelf = false;

  @Output() onDismiss: EventEmitter<void> = new EventEmitter<void>();

  constructor(private store: Store<AppState>,
              private dialog: MatDialog,
              private userService: UserService) {
  }

  openProfile(requestSent: any) {
    if (!this.loading) {
      this.loading = true;
      this.userService.getUserById(this.profile.id)
        .subscribe(data => {
          this.loading = false;
          this.dialog.open(ProfileDialogComponent, {
            data: {
              profile: data,
              requestSent,
            },
          });
        });
    }
  }

  connectWith(user: User, event: Event) {
    this.store.dispatch(initiateConnectionRequest({ id: user.id }));
    // @ts-ignore
    const button = document.getElementById(event.currentTarget.id);
    if (button) {
      button.classList.add('connect-clicked');
      button.setAttribute('disabled', 'disabled');
      button.innerHTML = 'Request sent!';
    }
  }

  dismiss() {
    this.onDismiss.emit();
  }
}
