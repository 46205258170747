import { createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';
import { MessageFormsState } from './messageForm.model';

const selectMessageFormsState = (state: AppState) => state.messageFormsState;

export const selectCreateMessageForm = createSelector(
  selectMessageFormsState,
  (state: MessageFormsState) => state.createMessageForm
);
