import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Story} from '../../../ngrx-store/components/story/story.model';
import { AppRoutes } from 'src/app/app-routing.model';

@Component({
  selector: 'app-storycard',
  templateUrl: './storycard.component.html',
  styleUrls: ['./storycard.component.scss']
})
export class StorycardComponent implements AfterViewInit {
  public AppRoutes = AppRoutes;
  @Input() story: Story;

  @ViewChild('storyTitle') storyTitle: ElementRef;
  @ViewChild('storyCardContent') storyCardContent: ElementRef;

  ngAfterViewInit(): void {
    this.storyTitle.nativeElement.innerHTML = this.story.title;
    const wordArray = this.story.title.split(' ');
    while (this.storyCardContent.nativeElement.scrollHeight > this.storyCardContent.nativeElement.offsetHeight && wordArray.length > 0) {
      wordArray.pop();
      if (wordArray.length > 0) {
        this.storyTitle.nativeElement.innerHTML = wordArray.join(' ') + '...';
      } else {
        this.storyTitle.nativeElement.innerHTML = '';
      }
    }
  }

}
