<div class="container">
  <h2 class="text-primary align-center">Settings</h2>

  <a class="back-arrow" [routerLink]="'/'">
    <span></span>
    <mat-icon>arrow_back</mat-icon>
  </a>
  <div class="bevel"></div>

  <div class="col-12 p-0" *ngIf="profileEditForm$ | async as profileEditForm">
    <!-- MAIL PREFERENCES -->
    <div class="underlined-title">
      <p class="text-primary">Mail preferences</p>
      <div></div>
    </div>

    <p class="section-title">Newsletter</p>
    <app-input-checkbox
      [form]="profileEditForm"
      [formControl]="profileEditForm.controls.newsletter"
      (checkboxChange)="saveProfile()"
    >
      Stay up-to-date with the latest news from RightBrains.
    </app-input-checkbox>

    <p class="section-title section-title-2">Platform</p>
    <app-input-checkbox
      [form]="profileEditForm"
      [formControl]="profileEditForm.controls.platform"
      (checkboxChange)="saveProfile()"
    >
      Receive emails related to the platform.
    </app-input-checkbox>

    <!-- OTHER SETTINGS -->
    <div class="underlined-title">
      <p class="text-primary">Other settings</p>
      <div></div>
    </div>

    <p class="section-title">Open for new job opportunities</p>
    <app-input-checkbox
      [form]="profileEditForm"
      [formControl]="profileEditForm.controls.shareData"
      (checkboxChange)="saveProfile()"
    >
      I allow RightBrains and their partners to connect with me for career
      opportunities.
    </app-input-checkbox>
    <br />

    <!-- CHANGE MAIL -->
    <div *ngIf="user$ | async as user">
      <div class="bevel"></div>
      <div class="underlined-title">
        <p class="text-primary">Change email</p>
        <div></div>
      </div>
      <p class="email-text">
        Fill in your new email address below and click on the change button. You
        will receive an email to verify your new address. <br /><br />
        <b
          >NOTE: You need to change your email on Slack as well, to receive
          emails.</b
        >
      </p>
      <div class="d-flex">
        <mat-form-field style="flex: 1">
          <mat-label>New email</mat-label>
          <input
            #email
            matInput
            id="email_id"
            type="email"
            class="p-1"
            style="height: 40px"
            placeholder="Fill in your new email"
            [value]="user.email"
          />
        </mat-form-field>
      </div>
      <div class="align-center primary-color">
        <button
          mat-raised-button
          color="primary"
          class="btn-sm button email-button"
          (click)="changeEmail(email.value)"
        >
          Change
        </button>
      </div>
    </div>
    <div class="bevel"></div>
    <div class="underlined-title">
      <p class="text-primary">Delete account</p>
      <div></div>
    </div>
    <p class="delete-account-text">
      This will permanently delete your account.
    </p>
    <div class="align-center extra-buttons">
      <button
        mat-raised-button
        color="warn"
        class="btn-sm button"
        (click)="deleteAccount()"
      >
        Delete account
      </button>
    </div>
  </div>
</div>
