import { Component, OnInit } from '@angular/core';
import { Store} from '@ngrx/store';
import {selectUserModel} from '../../../../ngrx-store/components/user/user.selectors';
import {AppState} from '../../../../ngrx-store/app.state';
import {Education} from '../../../../ngrx-store/components/user/user.model';
import {prefillEducationForm} from '../../../../ngrx-store/components/userForms/profileEditEducation/forms.editEducation.actions';
import {AppRoutes} from '../../../../app-routing.model';
import {postUser} from '../../../../ngrx-store/requests/user/post-user/post-user.actions';
import {Router} from '@angular/router';
import { removeEducation } from 'src/app/ngrx-store/components/user/user.actions';

@Component({
  selector: 'app-edit-education-list',
  templateUrl: './edit-education-list.component.html',
  styleUrls: ['./edit-education-list.component.scss']
})
export class EditEducationListComponent implements OnInit {

  public userModel$ = this.store.select(selectUserModel);

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
  }

  public goToEditEducation(education?: Education, index?: number) {
    if (education && index !== undefined) {
      this.store.dispatch(prefillEducationForm({ education, index }));
    }

    this.router.navigateByUrl(`${AppRoutes.PROFILE}/edit/education`);
  }

  public removeEducation(index: number) {
    this.store.dispatch(removeEducation({ index }));
    this.store.dispatch(postUser());
  }

}
