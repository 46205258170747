import { Pipe, PipeTransform } from '@angular/core';
import { ExperienceOption } from 'src/app/ngrx-store/components/userForms/userForms.model';

@Pipe({ name: 'experience' })
export class ExperienceToStringPipe implements PipeTransform {
  transform(roleIndex: number, short = false): string {
    const value = Object.values(ExperienceOption)[roleIndex];
    return short ? value.replace(/ \(.*\)/, '') : value;
  }
}
