import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectOnboardingModel } from 'src/app/ngrx-store/components/onboarding/onboarding.selectors';

@Component({
  selector: 'app-onboarding-question-progress',
  templateUrl: './onboarding-question-progress.component.html',
  styleUrls: ['./onboarding-question-progress.component.scss']
})
export class OnboardingQuestionProgressComponent implements OnInit {
  public onboardingModel$ = this.store.pipe(select(selectOnboardingModel));

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
  }

}
