import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { User } from 'src/app/ngrx-store/components/user/user.model';
import { SearchFilter } from 'src/app/ngrx-store/components/match/match.model';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { getIndustries, getDigitalAreas } from 'src/app/ngrx-store/requests/attributes/get-userEntities.actions';
import {
  selectIndustries, selectDigitalAreas } from 'src/app/ngrx-store/components/userEntities/userEntities.selectors';
import * as _ from 'underscore';
import { PROVINCIES_LIST } from '../../utilities/data/provincies.data';
import { UserRole, ExperienceOption } from 'src/app/ngrx-store/components/userForms/userForms.model';
import { Connection } from 'src/app/ngrx-store/components/connection/connection.model';
import {Actions, ofType} from '@ngrx/effects';
import {getCompanyList, getCompanyListSuccess} from '../../../ngrx-store/requests/user/get-user/get-user.actions';

@Component({
  selector: 'app-filter-user-list',
  templateUrl: './filter-user-list.component.html',
  styleUrls: ['./filter-user-list.component.scss'],
})
export class FilterUserListComponent implements OnInit, OnChanges {
  @Input() mentors: User[] | undefined = undefined;
  @Input() mentees: User[] | undefined = undefined;
  @Input() communityMembers: User[] | undefined = undefined;
  @Input() connections: Connection[] | undefined = undefined;

  @Input() collapseOnDesktop = false;
  @Input() allowFilterName = false;
  @Input() nameValue = '';

  @Output() dataChange = new EventEmitter<(User | Connection)[]>();
  @Output() filterChange = new EventEmitter<{ filter: SearchFilter; selectedRole: UserRole }>();

  industries$ = this.store.pipe(select(selectIndustries));
  digitalAreas$ = this.store.pipe(select(selectDigitalAreas));

  public provincies = PROVINCIES_LIST;
  public companies: string[] = [];
  public workExperienceOptions: string[] = Object.values(ExperienceOption);
  // Explicitly remove "Mentor and Mentee" and "Community member" options from possible selected values without changing UserRole Object
  public userRoles: string[] = Object.values(UserRole).filter(element => element !== 'Mentor and Mentee' && element !== 'Community member');
  public noPreference = 'No Preference';

  collapsed = false;

  // current filters
  selectedRole = UserRole.Member;
  educationNames: string[] = [];

  industryInterestNames: string[] = [];
  industryExperienceNames: string[] = [];

  digitalAreaInterestNames: string[] = [];
  digitalAreaExpertiseNames: string[] = [];

  region: string | undefined = undefined;
  country: string | undefined = undefined;
  workExperience: number | undefined = undefined;
  company: string | undefined = undefined;
  name: string | undefined = undefined;
  noRolePreference = false;

  constructor(private store: Store<AppState>,
              private actions$: Actions) {
    this.actions$.pipe(ofType(getCompanyListSuccess)).subscribe((companyList) => this.companies = companyList.companies);
  }

  ngOnChanges(): void {
    let filtered: (User | Connection)[] = [];

    switch (this.selectedRole) {
      case UserRole.Member: {
        if (this.communityMembers) {
          filtered = this.communityMembers;
        } else if (this.connections) {
          filtered = this.connections;
        }
        break;
      }
      case UserRole.Mentor: {
        if (this.mentors) {
           filtered = this.mentors;
        } else if (this.connections) {
          filtered = this.connections
            .filter(element => element.user.role === Object.values(UserRole).findIndex((r) => r === UserRole.Mentor) ||
                               element.user.role === Object.values(UserRole).findIndex((r) => r === UserRole.MentorAndMentee));
        }
        break;
      }
      case UserRole.Mentee: {
        if (this.mentees) {
          filtered = this.mentees;
        } else if (this.connections) {
          filtered = this.connections
            .filter(element => element.user.role === Object.values(UserRole).findIndex((r) => r === UserRole.Mentee) ||
                               element.user.role === Object.values(UserRole).findIndex((r) => r === UserRole.MentorAndMentee));
          break;
        }
      }
    }

    if (this.connections) {
      this.dataChange.emit(filtered);
    } else {
      this.dataChange.emit(_.uniq(filtered, 'id'));
    }
  }

  ngOnInit(): void {
    this.store.dispatch(getIndustries());
    this.store.dispatch(getDigitalAreas());
    this.store.dispatch(getCompanyList());
    this.collapsed = this.collapseOnDesktop;
  }

  emitChangeFilter() {
    this.filterChange.emit({
      filter: {
        educationNames: this.educationNames,
        industryInterestNames: this.industryInterestNames,
        industryExperienceNames: this.industryExperienceNames,
        digitalAreaInterestNames: this.digitalAreaInterestNames,
        digitalAreaExpertiseNames: this.digitalAreaExpertiseNames,
        region: this.region,
        workExperience: this.workExperience,
        name: this.name,
        company: this.company,
        country: this.country,
        page: undefined
      },
      selectedRole: this.selectedRole,
    });
  }

  changeRole(newRole: string) {
    this.noRolePreference = (newRole === this.noPreference);
    if (!this.noRolePreference) {
      this.selectedRole = Object.values(UserRole)[Object.values(UserRole).findIndex((u) => u === newRole)];
    } else {
      this.selectedRole = Object.values(UserRole)[Object.values(UserRole).findIndex((u) => u === 'Community member')];
    }
    this.ngOnChanges();
    this.emitChangeFilter();
  }

  changeName(newName: string) {
    this.name = newName || undefined;
    if (this.name) {
      this.name = this.name.toLowerCase();
    }
    this.emitChangeFilter();
  }

  changeExperience(experience: string) {
    this.workExperience =
      experience === this.noPreference ? undefined : Object.values(ExperienceOption).findIndex((u) => u === experience);

    this.emitChangeFilter();
  }

  changeCompany(company: string) {
    this.company = company || undefined;
    this.emitChangeFilter();
  }

  changeRegion(region: string) {
    this.region = region.length > 0 ? region : undefined;
    this.emitChangeFilter();
  }

  changeCountry(country: string) {
    this.country = country.length > 0 && country !== this.noPreference ? country : undefined;
    this.emitChangeFilter();
  }

  addIndustryInterest(toAdd: string) {
    this.industryInterestNames = this.industryInterestNames.concat([toAdd]);
    this.emitChangeFilter();
  }

  removeIndustryInterest(toRemove: string) {
    this.industryInterestNames = _.without(this.industryInterestNames, toRemove);
    this.emitChangeFilter();
  }

  addIndustryExperience(toAdd: string) {
    this.industryExperienceNames = this.industryExperienceNames.concat([toAdd]);
    this.emitChangeFilter();
  }

  removeIndustryExperience(toRemove: string) {
    this.industryExperienceNames = _.without(this.industryExperienceNames, toRemove);
    this.emitChangeFilter();
  }

  addDigitalAreaInterest(toAdd: string) {
    this.digitalAreaInterestNames = this.digitalAreaInterestNames.concat([toAdd]);
    this.emitChangeFilter();
  }
  removeDigitalAreaInterest(toRemove: string) {
    this.digitalAreaInterestNames = _.without(this.digitalAreaInterestNames, toRemove);
    this.emitChangeFilter();
  }

  addDigitalAreaExpertise(toAdd: string) {
    this.digitalAreaExpertiseNames = this.digitalAreaExpertiseNames.concat([toAdd]);
    this.emitChangeFilter();
  }
  removeDigitalAreaExpertise(toRemove: string) {
    this.digitalAreaExpertiseNames = _.without(this.digitalAreaExpertiseNames, toRemove);
    this.emitChangeFilter();
  }

}
