import { Action, createReducer, on } from '@ngrx/store';
import {
  createFormGroupState,
  FormGroupState,
  formStateReducer,
  setValue,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { CreateMessageForm, MessageFormsState } from './messageForm.model';
import { pattern, required } from 'ngrx-forms/validation';
import { clearMessage } from './messageForm.actions';

export const initialCreateMessageForm: CreateMessageForm = {
  message: '',
  video: '',
};

export const initialCreateMessageFormState =
  createFormGroupState<CreateMessageForm>(
    'CREATE_MESSAGE_FORM',
    initialCreateMessageForm
  );

const updateForm = createReducer<FormGroupState<CreateMessageForm>>(
  initialCreateMessageFormState,
  on(clearMessage, (_) => initialCreateMessageFormState)
);

const validateForm = (
  form: FormGroupState<CreateMessageForm>,
  state: MessageFormsState
) => {
  return updateGroup<CreateMessageForm>({
    message: validate(required),
    video: validate(pattern(/youtube|vimeo/)),
  })(form);
};

export const createMessageFormReducer = (
  state: MessageFormsState,
  action: Action
) => {
  const createMessageForm: FormGroupState<CreateMessageForm> =
    state.createMessageForm;

  // use the out of the box reducer to process form actions into the store
  let form = formStateReducer(createMessageForm, action);

  // Process non-form actions
  form = updateForm(form, action);

  // form validation using the new state
  form = validateForm(form, state);

  return form === createMessageForm ? createMessageForm : form;
};

export const initialMessageFormsState: MessageFormsState = {
  createMessageForm: initialCreateMessageFormState,
};

const actionReducer = createReducer<MessageFormsState>(
  initialMessageFormsState
);

const joinedReducer = (
  state: MessageFormsState | undefined,
  action: Action
): MessageFormsState => {
  const newState: MessageFormsState = actionReducer(state, action);

  return {
    ...newState,
    createMessageForm: createMessageFormReducer(newState, action),
  };
};

export function messageFormsReducer(
  state: MessageFormsState | undefined,
  action: Action
) {
  return joinedReducer(state, action);
}
