<div class="container color-background-primary">
  <h2 class="text-primary align-center">Job opportunities</h2>

  <a class="back-arrow" [routerLink]="'/'">
    <span></span>
    <mat-icon>arrow_back</mat-icon>
  </a>
  <div class="bevel mb-4"></div>
  <app-vacancy-filter *ngIf="searchMode"></app-vacancy-filter>
  <ng-container *ngIf="vacancies$ | async as vacancies; else loadingSpinner">
    <ng-container *ngIf="companies$ | async as companies; else loadingSpinner">
      <ng-container *ngIf="filter$ | async as filter; else loadingSpinner">
        <div
          class="title"
          *ngIf="sortVacancies(vacancies, filter.value).length > 0"
        >
          <div class="underlined-title">
            <p>{{ searchMode ? "Search results" : "Current vacancies" }}</p>
            <div></div>
          </div>
        </div>
        <div
          class="vacancy-container"
          *ngFor="let vacancy of sortVacancies(vacancies, filter.value)"
        >
          <div class="job-title">
            {{ vacancy.jobTitle }}
          </div>
          <div class="company-name">
            {{ vacancy.company.name }}
          </div>
          <div class="extra-info">
            {{ vacancy.careerPhase | experience : true }}
          </div>
          <div class="extra-info">
            <span *ngIf="vacancy.city">{{ vacancy.city }}, </span>
            {{ vacancy.country }}
          </div>
          <div class="button-container">
            <button
              mat-stroked-button
              color="primary"
              (click)="openDialog(vacancy)"
            >
              Job details
            </button>
            <button
              *ngIf="!searchMode"
              class="ms-2"
              mat-stroked-button
              color="primary"
              (click)="postInterest(vacancy)"
            >
              {{
                vacancy.interested === false ? "Interested" : "Not interested"
              }}
            </button>
          </div>
        </div>
        <div
          *ngIf="!searchMode && vacancies.length > 10"
          class="d-flex justify-content-center pt-3"
        >
          <button (click)="enableSearch()" mat-flat-button color="primary">
            Search more vacancies
          </button>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #loadingSpinner>
    <div class="w-100 d-flex justify-content-center">
      <mat-spinner class="margin-auto" [diameter]="65"></mat-spinner>
    </div>
  </ng-template>
</div>
