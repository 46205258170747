<ng-container *ngIf="story">
  <div class="container">
    <div class="story-image-container">
      <img class="story-image" src="{{story.imageUrl}}"/>
      <a [routerLink]="'/' + AppRoutes.STORIES">
        <img class="story-close-button" src="../../../assets/images/close-story.svg"/>
      </a>
    </div>
    <div class="story-date">
      {{story.publicationDate | date:'d LLLL y' }}
    </div>
    <div class="story-title">
      {{story.title}}
    </div>
    <div class="story-content" [innerHTML]="story.content">
    </div>
  </div>
</ng-container>
