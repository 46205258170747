import { Component, Input, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-stepper-progress',
  templateUrl: 'stepper-progress.html',
  styleUrls: ['stepper-progress.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StepperProgressComponent {
  /** Index of the given step. */
  @Input() stepIndex = 0;

  /** Total number of steps. */
  @Input() totalSteps = 1;

  constructor() { }

  get progress(): number {
    return ((this.stepIndex + 1) / this.totalSteps) * 100;
  }
}
