<div *ngIf="companyDetailsForm$ | async as form">
  <ng-container *ngIf="vacancies$ | async as vacancies">
    <div *ngIf="!company || (company && vacancies.length > 0)">
      <app-company-details-header
        *ngIf="!isSignUp && company"
        title="Looking for a job or internship?"
        editText="Add vacancy"
        [canEdit]="false"
        [modify]="modify"
        (onModify)="createVacancy($event)"
      ></app-company-details-header>

      <app-company-details-header
        *ngIf="!isSignUp && !company"
        title="Publish a job"
        editText="Add vacancy"
        [canEdit]="true"
        [modify]="modify"
        (onModify)="createVacancy($event)"
        data-cy="company-publish-a-job-title"
      ></app-company-details-header>

      <div *ngIf="company; else self">
        <div *ngIf="company.contactPersons.length > 0">
          <app-vacancycards
            [vacancies]="vacancies.slice(0, 3)"
            [companies]="[company]"
            [viewAllButton]="false"
          ></app-vacancycards>
          <a
            *ngIf="vacancies.length > 3"
            [routerLink]="'/' + AppRoutes.VACANCIES"
            class="d-flex justify-content-center"
          >
            <button
              (click)="setVacancyFilter(company.name)"
              class="btn-sm mx-auto"
              mat-flat-button
              color="primary"
            >
              View all vacancies
            </button>
          </a>
        </div>
      </div>

      <ng-template #self>
        <div *ngIf="company$ | async as ownCompany">
          <div *ngIf="vacancies.length == 0">No job opportunities created</div>
          <div
            class="d-flex justify-content-between align-items-center pt-3"
            *ngFor="let vacancy of vacancies"
          >
            <span
              class="vacancy-row"
              (click)="openVacancyDialog(vacancy, ownCompany)"
              >{{ vacancy.jobTitle }}
              {{ vacancy.city ? " - " + vacancy.city : "" }} [expires
              {{ vacancy.expirationDate | date }}]</span
            >

            <div class="d-flex align-items-end">
              <img
              data-cy="edit-vacancy-button"
                (click)="editVacancy(vacancy)"
                class="pe-2 edit-pencil"
                src="../../../../assets/images/dashboard/pencil.svg"
              />
              <mat-icon
              data-cy="remove-vacancy-button"
                (click)="removeVacancy(vacancy)"
                color="primary"
                class="pointer"
                >delete</mat-icon
              >
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-container>
</div>
