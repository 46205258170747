import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {selectUserNotifications} from '../../ngrx-store/components/user/user.selectors';
import {AppState} from '../../ngrx-store/app.state';
import {Router} from '@angular/router';
import {Notification} from '../../ngrx-store/components/user/user.model';
import {checkNewNotifications, clearUserNotifications, deleteNotification} from '../../ngrx-store/requests/user/get-user/get-user.actions';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications$ = this.store.select(selectUserNotifications);

  constructor(private store: Store<AppState>, private router: Router) {}

  isMobile = false;

  ngOnInit() {
    this.store.dispatch(checkNewNotifications());
  }

  openNotification(notification: Notification) {
    if (notification.link?.length > 0 && notification.isRouterLink) {
      this.router.navigateByUrl(notification.link);
    } else if (notification.link?.length > 0 && !notification.isRouterLink) {
      window.open(notification.link, '_blank');
    }

    this.store.dispatch(deleteNotification({ id: notification.id }));
  }

  clearNotifications() {
    this.store.dispatch(clearUserNotifications());
  }
}
