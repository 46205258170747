<mat-dialog-content *ngIf="companyEventForm$ | async as form">
  <div class="d-flex justify-content-between">
    <div class="section-title text-primary mb-4">Add event</div>
    <button mat-icon-button mat-dialog-close color="primary" class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <app-company-event-form></app-company-event-form>
  <button data-cy="modal-update-event-button" [disabled]="form.isInvalid" mat-flat-button color="primary" class="btn btn-sm my-1 me-3" mat-dialog-close (click)="addEvent(form.value)">{{isEdit ? "Save changes" : "Add event"}}</button>
  <button
    mat-stroked-button
    color="primary"
    class="btn btn-sm my-1"
    mat-dialog-close
  >
    Cancel
  </button>
</mat-dialog-content>
