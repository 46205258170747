import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectCompanyDetailsForm } from '../../../ngrx-store/components/companyForms/companyForms.selector';
import { Company } from '../../../ngrx-store/components/company/company.model';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent {
  @Input() company?: Company;
  public companyDetailsForm$ = this.store.select(selectCompanyDetailsForm);

  constructor(
    private readonly store: Store<AppState>,
  ) {}

  openMailClient() {
    if (!this.company) {
      return;
    }

    if (this.company.contactPersons.length === 0) {
      return;
    }

    const recipients = this.company.contactPersons.map(c => c.email)?.join(',');
    const subject = `Open application from Rightbrains`;

    window.open(`mailto:${recipients}?subject=${subject}`, '_blank');
  }
}
