import { VacancyFilterForm } from './forms.vacancyFilter.model';
import { Action, createReducer, on } from '@ngrx/store';
import {
  addArrayControl,
  createFormGroupState,
  FormGroupState,
  formStateReducer,
  removeArrayControl,
  setValue,
  updateGroup,
} from 'ngrx-forms';
import { VacancyFormsState } from '../vacancyForm.model';
import {
  clearFilter,
  setFilterCompany,
  addDigitalAreaToFilter,
  removeIndustryFromFilter,
  removeDigitalAreaFromFilter,
  addIndustryToFilter,
} from './forms.vacancyFilter.actions';
import _ = require('underscore');

export const initialVacancyFilterForm: VacancyFilterForm = {
  jobTitle: '',
  company: '',
  country: '',
  careerPhase: undefined,
  requiredDigitalAreas: [],
  requiredIndustries: [],
};

export const initialVacancyFilterFormState =
  createFormGroupState<VacancyFilterForm>(
    'VACANCY_FILTER_FORM',
    initialVacancyFilterForm
  );

const updateForm = createReducer<FormGroupState<VacancyFilterForm>>(
  initialVacancyFilterFormState,
  on(clearFilter, () => initialVacancyFilterFormState),
  on(setFilterCompany, (state, action) =>
    updateGroup<VacancyFilterForm>(state, {
      company: setValue(action.company),
    })
  ),
  on(addDigitalAreaToFilter, (state, action) =>
    updateGroup<VacancyFilterForm>(state, {
      requiredDigitalAreas: addArrayControl(action.digitalArea),
    })
  ),
  on(removeDigitalAreaFromFilter, (state, action) =>
    updateGroup<VacancyFilterForm>(state, {
      requiredDigitalAreas: removeArrayControl(
        _.findIndex(
          state.value.requiredDigitalAreas,
          (s) => s.name === action.digitalArea.name
        )
      ),
    })
  ),
  on(addIndustryToFilter, (state, action) =>
    updateGroup<VacancyFilterForm>(state, {
      requiredIndustries: addArrayControl(action.industry),
    })
  ),
  on(removeIndustryFromFilter, (state, action) =>
    updateGroup<VacancyFilterForm>(state, {
      requiredIndustries: removeArrayControl(
        _.findIndex(
          state.value.requiredIndustries,
          (s) => s.name === action.industry.name
        )
      ),
    })
  )
);

export const vacancyFilterFormReducer = (
  state: VacancyFormsState,
  action: Action
) => {
  const vacancyFilterForm: FormGroupState<VacancyFilterForm> =
    state.vacancyFilterForm;

  // use the out of the box reducer to process form actions into the store
  let form = formStateReducer(vacancyFilterForm, action);

  // Process non-form actions
  form = updateForm(form, action);

  return form === vacancyFilterForm ? vacancyFilterForm : form;
};
