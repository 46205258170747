<div *ngIf="vacancies.length == 0; else vacancycards">
  <div class="align-center">
    <mat-card appearance="outlined" class="match-card float-none">
      <mat-card-content class="profile-card-content">
        No vacancies were found... <br />
        Try to check again later.
      </mat-card-content>
    </mat-card>
  </div>
</div>
<ng-template #vacancycards>
  <div class="match-cards">
    <div *ngFor="let vacancy of vacancies">
      <app-vacancycard
        *ngIf="getCompanyForVacancy(vacancy)"
        [viewCompanyInfo]="viewAllButton"
        [vacancy]="vacancy"
        [company]="getCompanyForVacancy(vacancy)"
      ></app-vacancycard>
    </div>
  </div>
  <br />
  <div class="all-matches-button" *ngIf="viewAllButton">
    <a [routerLink]="'/' + AppRoutes.VACANCIES">
      <button class="btn-sm mx-auto" mat-flat-button color="primary">
        View all vacancies
      </button>
    </a>
  </div>
</ng-template>
