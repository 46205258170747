<div class="container position-relative color-background-primary">
  <ng-container *ngIf="blogItemState$ | async as blogItemState">
    <div *ngIf="blogItemState.blogItems.length > 0">
      <div class="must-read-container">
        <h2 class="align-center text-white">Blogs</h2>
        <a class='back-arrow color-white' [routerLink]="'/'">
          <span></span>
          <mat-icon>arrow_back</mat-icon>
        </a>
        <div class="bevel"></div>

        <div class="align-center">
          <div class="underlined-title purple-background">
            <p class="text-white">Must-read</p>
            <div></div>
          </div>
        </div>
        <div class="preview-must-read">
          <div class="align-center">
            <img class="preview-image" src="{{blogItemState.blogItems[0].headerImageUrl}}"/>
          </div>
          <div class="preview-date">
            {{blogItemState.blogItems[0].publicationDate | date:'d LLLL y' }}
          </div>
          <div class="preview-title">
            {{blogItemState.blogItems[0].title }}
          </div>
          <div class="preview-content">
            <div [innerHTML]="blogItemState.blogItems[0].previewDescription"></div>
          </div>
          <div class="align-center">
            <a [routerLink]="'/' + AppRoutes.BLOGITEM + '/' + blogItemState.blogItems[0].id">
              <button
                class="btn-smn must-read-button btn-sm"
                mat-flat-button
                color="primary">
                Read blog
              </button>
            </a>
          </div>
        </div>
      </div>
      <div class="stories-container stories-background-color">
        <div class="align-center">
          <p class="more-inspiring">
            More blogs
          </p>
        </div>
        <app-blogitemcards [viewAllButton]="false" [blogItems]="blogItemState.blogItems.slice(1)"></app-blogitemcards>
      </div>
    </div>
  </ng-container>
</div>

