import { createReducer, on, Action } from '@ngrx/store';
import { CompanyState, CompanyContactPerson } from './company.model';
import { submitCreateCompanyForm } from '../companyForms/createCompany/forms.createCompany.actions';
import { postDetailsCompanySuccess, postNewCompany, postNewCompanySuccess, postNewCompanyError } from '../../requests/company/post-company/post-company.actions';
import { submitCompanyDetailsForm } from '../companyForms/companyDetails/forms.companyDetails.actions';
import { getCompanySuccess, getCompaniesSuccess, followCompanySuccess, unfollowCompanySuccess } from '../../requests/company/get-company/get-company.actions';
import { unbox } from 'ngrx-forms';
import { submitCompanyContactPersonForm } from '../companyForms/companyContactPerson/forms.companyContactPerson.actions';
import { deleteContact, setOnboardingComplete, deleteEvent } from './company.actions';
import { submitCompanyEventForm } from '../companyForms/companyEvent/forms.companyEvent.actions';
import { RightbrainsEvent } from '../event/event.model';

export const initialCompanyState: CompanyState = {
  companyCode: '',
  companyName: '',
};

const actionReducer = createReducer<CompanyState>(
  initialCompanyState,
  on(postNewCompanySuccess, (state, action) => {
    return {
      ...state,
      company: action.company
    };
  }),
  on(postNewCompanyError, (state, action) => {
    return {
      ...state,
      companyError: action.error
    };
  }),
  on(postDetailsCompanySuccess, (state, action) => {
    return {
      ...state,
      company: action.company
    };
  }),
  on(getCompanySuccess, (state, action) => {
    return {
      ...state,
      company: action.company
    };
  }),
  on(getCompaniesSuccess, (state, action) => {
    return {
      ...state,
      companies: action.companies
    };
  }),
  on(followCompanySuccess, (state, action) => {
    const newCompanies = state.companies?.filter(c => c.id !== action.id);
    const changedCompany = state.companies?.find(c => c.id === action.id);
    if (!changedCompany) {
      return state;
    }

    return {
      ...state,
      companies: newCompanies ? [
        ...newCompanies,
        {
          ...changedCompany,
          isFollowing: true
        }
      ] : []
    };
  }),
  on(unfollowCompanySuccess, (state, action) => {
    const newCompanies = state.companies?.filter(c => c.id !== action.id);
    const changedCompany = state.companies?.find(c => c.id === action.id);
    if (!changedCompany) {
      return state;
    }

    return {
      ...state,
      companies: newCompanies ? [
        ...newCompanies,
        {
          ...changedCompany,
          isFollowing: false
        }
      ] : []
    };
  }),
  on(deleteContact, (state, action) => {
    const newContactPersons = !!state.company?.contactPersons ? state.company.contactPersons.filter(c => c !== action.contact) : [];
    return {
      ...state,
      company: state.company ? {
        ...state.company,
        contactPersons: newContactPersons
      } : state.company
    };
  }),
  on(deleteEvent, (state, action) => {
    const newEvents = !!state.company?.events ? state.company.events.filter(c => c !== action.event) : [];
    return {
      ...state,
      company: state.company ? {
        ...state.company,
        events: newEvents
      } : state.company
    };
  }),
  on(setOnboardingComplete, (state, action) => {
    return {
      ...state,
      company: state.company ? {
        ...state.company,
        onboardingCompleted: true,
      } : state.company
    };
  })
);

const consumeFormsReducer = createReducer<CompanyState>(
  initialCompanyState,
  on(submitCreateCompanyForm, (state, action) => {
    return {
      ...state,
      companyCode: action.formValues.companyCode,
      companyName: action.formValues.name
    };
  }),
  on(submitCompanyDetailsForm, (state, action) => {
    return {
      ...state,
      company: state.company ? {
        ...state.company,
        name: action.formValues.name,
        logo: action.formValues.logo,
        introduction: action.formValues.introduction,
        information: action.formValues.information,
        careers: action.formValues.careers,
        industry: action.formValues.industry ? unbox(action.formValues.industry) : undefined,
        country: action.formValues.country,
        videoUrl: action.formValues.videoUrl,
        photos: action.formValues.photos,
        websiteUrl: action.formValues.websiteUrl,
        ambassadors: action.formValues.ambassadors,
        socialMedia: {
          linkedinSocialMedia: action.formValues.linkedIn,
          instagramSocialMedia: action.formValues.instagram,
          facebookSocialMedia: action.formValues.facebook,
          twitterSocialMedia: action.formValues.twitter
        }
      } : state.company
    };
  }),
  on(submitCompanyContactPersonForm, (state, action) => {
    const newContact: CompanyContactPerson = {
      profilePhotoUrl: action.formValues.photo,
      firstName: action.formValues.firstName,
      lastName: action.formValues.lastName,
      role: action.formValues.role,
      email: action.formValues.email,
    };
    const newContactPersons = !!state.company?.contactPersons ? state.company.contactPersons.concat(newContact) : [];
    return {
      ...state,
      company: state.company ? {
        ...state.company,
        contactPersons: newContactPersons
      } : state.company
    };
  }),
  on(submitCompanyEventForm, (state, action) => {
    const dateParts = action.formValues.eventDate.split('-');
    const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], 10);

    const newEvent: RightbrainsEvent = {
      id: action.formValues.id,
      title: action.formValues.name,
      eventDate: date,
      beginTime: action.formValues.beginTime,
      endTime: action.formValues.endTime,
      description: 'Event at ' + state.company?.name,
      content: action.formValues.description,
      imageUrl: action.formValues.imageUrl,
      thumbnailUrl: action.formValues.imageUrl,
      originalUrl: action.formValues.link,
      eventUrl: action.formValues.link,
    };
    const newEvents = !!state.company?.events
    ? state.company.events.filter(e => e.id !== newEvent.id).concat(newEvent) :
    [];
    return {
      ...state,
      company: state.company ? {
        ...state.company,
        events: newEvents
      } : state.company
    };
  }),
);


const joinedReducer = (state: CompanyState | undefined, action: Action) => {
  let newState: CompanyState = actionReducer(state, action);
  newState = consumeFormsReducer(newState, action);

  return {
    ...newState,
  };
};

export function companyReducer(state: CompanyState | undefined, action: Action) {
  return joinedReducer(state, action);
}
