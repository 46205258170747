<mat-dialog-content *ngIf="vacancy && company" class="">
  <div class="row m-0 p-0 col-12 col-md-10 mx-auto overflow-hidden">
    <div class="col-12 mt-3 mb-4 d-flex justify-content-between">
      <button
        mat-icon-button
        mat-dialog-close
        color="primary"
        class="close-button"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="content w-100 vacancy-dialog-row">
      <img
        *ngIf="company?.logo"
        [src]="company.logo"
        class="company-logo my-4"
      />
      <div class="job-title">{{ vacancy.jobTitle }}</div>
      <div class="section">
        <div class="section-title">What to expect</div>
        <div class="rich-content">{{ vacancy.summary }}</div>
      </div>
      <div class="section">
        <div class="section-title">Ideal candidate</div>
        <div class="rich-content">{{ vacancy.idealCandidate }}</div>
      </div>
      <div class="section">
        <div class="section-title">Vacancy type</div>
        {{ vacancy.type | vacancyType }}
      </div>
      <div class="section">
        <div class="section-title">Start date</div>
        {{ vacancy.startDate }}
      </div>
      <div class="section">
        <div class="section-title">Career phase</div>
        {{ vacancy.careerPhase | experience }}
      </div>
      <div class="section">
        <div class="section-title">Location</div>
        <span *ngIf="vacancy.city">{{ vacancy.city }}, </span>
        {{ vacancy.country }}
      </div>
      <div class="section" *ngIf="vacancy.requiredDigitalAreas.length > 0">
        <div class="section-title">Required digital knowledge</div>
        <mat-chip-listbox class="d-block my-2">
          <mat-chip-option
            color="primary"
            selected
            style="color: white"
            *ngFor="let item of vacancy.requiredDigitalAreas; let i = index"
          >
            {{ item.name }}
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <div class="section" *ngIf="vacancy.requiredIndustries.length > 0">
        <div class="section-title">Required industry expertise</div>
        <mat-chip-listbox class="d-block my-2">
          <mat-chip-option
            color="primary"
            selected
            style="color: white"
            *ngFor="let item of vacancy.requiredIndustries; let i = index"
          >
            {{ item.name }}
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <ng-container *ngIf="connections$ | async as connections">
        <ng-container *ngIf="self$ | async as self">
          <div class="section">
            <div class="section-title">Meet our ambassadors</div>
            <app-connection-list
              [connections]="
                getConnectedAmbassadors(connections, company.ambassadors)
              "
              [noConnections]="
                getUnconnectedAmbassadors(connections, company.ambassadors)
              "
              [self]="self"
            ></app-connection-list></div></ng-container
      ></ng-container>
    </div>
  </div>
  <div
    md-dialog-actions
    class="mt-3 d-flex flex-column justify-content-center align-items-center"
  >
    <button
      mat-flat-button
      color="primary"
      class="btn-sm my-1"
      (click)="openMailClient()"
    >
      Apply now
    </button>
    <button
      mat-stroked-button
      color="primary"
      class="btn-sm my-1"
      mat-dialog-close
    >
      Cancel
    </button>
  </div>
</mat-dialog-content>
