import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { setOnboardingComplete } from 'src/app/ngrx-store/components/company/company.actions';
import { selectCompany } from 'src/app/ngrx-store/components/company/company.selectors';
import { selectCompanyDetailsForm } from 'src/app/ngrx-store/components/companyForms/companyForms.selector';
import { postDetailsCompany } from 'src/app/ngrx-store/requests/company/post-company/post-company.actions';

@Component({
  selector: 'app-company-owned',
  templateUrl: './company-owned.component.html',
  styleUrls: ['./company-owned.component.scss']
})
export class CompanyOwnedComponent {
  public companyDetailsForm$ = this.store.select(selectCompanyDetailsForm);
  public company$ = this.store.select(selectCompany);

  constructor(private readonly store: Store<AppState>) {
  }

  publishCompany() {
    this.store.dispatch(setOnboardingComplete());
    this.store.dispatch(postDetailsCompany());
  }
}
