<ng-container *ngIf="self || educations.length > 0">
  <table class="w-100">
    <tr>
      <td>
        <p class="section-title">Education</p>
      </td>
      <td *ngIf="self">
        <div class="edit-cell">
          <a class="edit-link" [routerLink]="'../edit/educations'">
            Edit
            <img
              class="edit-pencil"
              src="../../../../assets/images/dashboard/pencil.svg"
            />
          </a>
        </div>
      </td>
    </tr>
  </table>
  <ng-container>
    <ng-container *ngIf="educations.length > 0; else noEducations">
      <div class="backgroundItem my-3" *ngFor="let item of educations">
        <p class="m-0">
          {{ item.name }}<br />
          {{ item.study }}
        </p>
        <p class="m-0 font-italic experience-date" *ngIf="item.startDate">
          {{ item.startDate | shortdate }} -
          {{ item.endDate ? (item.endDate | shortdate) : "now" }}
        </p>
      </div>
    </ng-container>
    <ng-template #noEducations>
      <a *ngIf="self" class="no-education" [routerLink]="'../edit/educations'">
        <button
          mat-flat-button
          class="btn-sm mx-auto no-education-button"
          color="primary"
        >
          Add education
        </button>
      </a>
    </ng-template>
  </ng-container>
</ng-container>
