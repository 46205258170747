import {Component, EventEmitter, Input, Output} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NgrxFormElementDirective } from '../ngrx-form-element/ngrx-form-element.directive';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
})
export class InputCheckboxComponent extends NgrxFormElementDirective {
  @Output() checkboxChange: EventEmitter<
    MatCheckboxChange
  > = new EventEmitter();
  @Input() checked = false;

  constructor() {
    super();
  }
}
