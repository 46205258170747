import { Component, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { User } from 'src/app/ngrx-store/components/user/user.model';
import { Store } from '@ngrx/store';
import { selectProfileEditForm } from '../../../ngrx-store/components/userForms/userForms.selectors';
import { AppState } from '../../../ngrx-store/app.state';
import { take } from 'rxjs/operators';
import { submitProfileEditForm } from '../../../ngrx-store/components/userForms/profileEdit/forms.profileEdit.actions';
import {
  postUser,
  postUserSuccess,
} from '../../../ngrx-store/requests/user/post-user/post-user.actions';
import { Connection } from '../../../ngrx-store/components/connection/connection.model';
import {
  acceptConnection,
  denyConnection,
  initiateConnectionRequest,
} from '../../../ngrx-store/components/connection/connection.actions';
import { selectAdminPartner, selectUserProfileCompleteness } from '../../../ngrx-store/components/user/user.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { Clipboard } from '@angular/cdk/clipboard';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
})
export class ProfileDetailsComponent implements OnDestroy {
  public profileEditForm$ = this.store.select(selectProfileEditForm);
  public adminPartner$ = this.store.select(selectAdminPartner);
  public modifyAboutMe = false;
  public showCopiedText = false;

  @Input() user: User;
  @Input() self = false;
  @Input() connection: Connection;
  @Input() connectionsRetrieved = false;
  @Input() hideConnectButton = false;
  @Output() refreshUser = new EventEmitter();

  public profileCompleteness = 0;
  private subscriptions = new Subscription();

  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private clipboard: Clipboard
  ) {
    this.subscriptions.add(this.store
      .select(selectUserProfileCompleteness)
      .subscribe((percentage) => (
        this.profileCompleteness = percentage ?? 0))
    );
    this.subscriptions.add(this.actions
      .pipe(ofType(postUserSuccess))
      .subscribe(() => this.refreshUser.emit()));
  }

  public saveProfile() {
    this.profileEditForm$.pipe(take(1)).subscribe((form) => {
      this.store.dispatch(submitProfileEditForm({ formValues: form.value }));
      this.store.dispatch(postUser());
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  connectWith(event: Event) {
    this.store.dispatch(initiateConnectionRequest({ id: this.user.id }));
    // @ts-ignore
    const button = document.getElementById(event.currentTarget.id);
    if (button) {
      button.classList.add('connect-clicked');
      button.setAttribute('disabled', 'disabled');
      button.innerHTML = 'Request sent!';
    }
  }

  acceptRequest() {
    this.store.dispatch(acceptConnection({ userId: this.connection.user.id }));
  }

  denyRequest() {
    this.store.dispatch(denyConnection({ userId: this.connection.user.id }));
  }

  copyProfileUrlToClipboard() {
    this.showCopiedText = true;
    this.clipboard.copy(`${window.location.host}/user/${this.user.id}`);
  }
}
