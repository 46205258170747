import { createAction, props } from '@ngrx/store';
import { CreateMessageForm } from './messageForm.model';

export enum MessageFormActionTypes {
  SUBMIT = '[MessageForms] Submit message',
  CLEAR = '[MessageForms] Clear message',
}

export const submitMessage = createAction(
  MessageFormActionTypes.SUBMIT,
  props<{ formValues: CreateMessageForm }>()
);
export const clearMessage = createAction(MessageFormActionTypes.CLEAR);
