import { Pipe, PipeTransform } from '@angular/core';
import { UserRole } from 'src/app/ngrx-store/components/userForms/userForms.model';

@Pipe({ name: 'userRole' })
export class UserRoleToStringPipe implements PipeTransform {
  transform(roleIndex: number): string {
    switch (roleIndex) {
      case 0:
        return 'Community member';
      case 3:
        return 'Mentor & mentee';
      default:
        return Object.keys(UserRole)[roleIndex];
    }
  }
}
