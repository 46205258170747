import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppRoutes} from '../../app-routing.model';
import {BlogItem} from '../../ngrx-store/components/blogitem/blogitem.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../ngrx-store/app.state';
import {selectBlogItemForId} from '../../ngrx-store/components/blogitem/blogitem.selectors';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-blogitem',
  templateUrl: './blogitem.component.html',
  styleUrls: ['./blogitem.component.scss']
})
export class BlogitemComponent implements OnInit, OnDestroy {
  public blogItem: BlogItem | undefined = undefined;

  public AppRoutes = AppRoutes;
  private subscriptions = new Subscription();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private store: Store<AppState>) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.subscriptions.add(
        this.store.select(selectBlogItemForId(id)).subscribe((blogItem) => {
          if (!blogItem) {
            this.router.navigateByUrl(`/${AppRoutes.BLOGITEMS}`);
            return;
          }

          this.blogItem = blogItem;
        }
      ));
      return;
    }

    this.router.navigateByUrl(`/${AppRoutes.BLOGITEMS}`);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
