<div class="dashboard-container">
  <div class="dashboard-header">
  </div>
  <div *ngIf="userModel$ | async as userModel" class="profile-card-section">
    <mat-card appearance="outlined" class="profile-card">
      <a [routerLink]="'/' + AppRoutes.PROFILE">
        <img
          class="profile-picture"
          src="{{ userModel.avatarRef }}"
          *ngIf="userModel.avatarRef; else noPic"
        />
      </a>
      <ng-template #noPic>
        <img
          class="profile-picture"
          src="../../../assets/images/dashboard/account_circle.svg"
        />
      </ng-template>
      <mat-card-content class="profile-card-content">
        <div class="spacer"></div>
        <a class="user-profile" [routerLink]="'/' + AppRoutes.PROFILE">
          <p class="user-name">
            {{ userModel.firstName }} {{ userModel.lastName }}
          </p>
        </a>
        <p *ngIf="userModel.employmentStatus === 3; else working">
          Student {{ userModel.userBackground.educations[0]?.study }} at
          {{ userModel.userBackground.educations[0]?.name }}
        </p>
        <p>{{ userModel.yearsExperience | experience }}</p>
        <ng-template #working>
          <p *ngIf="userModel.functionLabel">{{ userModel.functionLabel }}</p>
          <p *ngIf="!userModel.functionLabel && userModel.userBackground">
            {{ userModel.userBackground.companies[0]?.position }} at
            {{ userModel.userBackground.companies[0]?.name }}
          </p>
        </ng-template>
        <p>{{ (userModel.role | userRole) || "Unknown role" }}</p>
        <a [routerLink]="'/' + AppRoutes.PROFILE" style="text-decoration:none">
          <div style="color: #31333e; opacity: 0.5">
            Edit <img src="../../../assets/images/dashboard/pencil.svg" />
          </div> </a
        ><br />
        <mat-progress-bar
          class="progress-bar"
          mode="determinate"
          value="{{ profileCompleteness }}"
        ></mat-progress-bar>
        <ng-container *ngIf="profileCompleteness < 100; else profileComplete"
          >Profile complete for {{ profileCompleteness }}%
        </ng-container>
        <ng-template #profileComplete
          >Profile complete for {{ profileCompleteness }}%, good
          job!</ng-template
        >
      </mat-card-content>
    </mat-card>
  </div>
  <ng-container *ngIf="enableNonAdminFeatures">
  <app-bestmatches [collapsable]="true"></app-bestmatches>

  <div *ngIf="vacancyState$ | async as vacancyState">
    <div *ngIf="companiesState$ | async as companiesState">
      <div
        *ngIf="vacancyState && vacancyState.length > 0"
        class="dashboard-title"
        (click)="isVacanciesCollapsed = !isVacanciesCollapsed"
      >
        <div class="collapse-button" *ngIf="!isVacanciesCollapsed">
          <i class="fas fa-chevron-up"></i>
        </div>
        <div class="collapse-button" *ngIf="isVacanciesCollapsed">
          <i class="fas fa-chevron-down"></i>
        </div>
        <div class="underlined-title story-title story-title-minimal">
          <p><span class="text-nowrap">Job</span> opportunities</p>
          <div></div>
        </div>
        <div class="collapse-button invisible"></div>
      </div>
      <div
        *ngIf="vacancyState && vacancyState.length > 0"
        class="story-cards"
        [ngbCollapse]="isVacanciesCollapsed">
        <app-vacancycards
          [vacancies]="vacancyState.slice(0, 3)"
          [companies]="companiesState"
        ></app-vacancycards>
      </div>
    </div>
  </div>

  <div *ngIf="eventState$ | async as eventState">
    <div
      class="dashboard-title"
      (click)="isEventsCollapsed = !isEventsCollapsed"
    >
      <div class="collapse-button" *ngIf="!isEventsCollapsed">
        <i class="fas fa-chevron-up"></i>
      </div>
      <div class="collapse-button" *ngIf="isEventsCollapsed">
        <i class="fas fa-chevron-down"></i>
      </div>
      <div class="underlined-title story-title">
        <p>Join events</p>
        <div></div>
      </div>
      <div class="collapse-button invisible"></div>
    </div>
    <div class="story-cards" [ngbCollapse]="isEventsCollapsed">
      <app-eventcards [events]="eventState.events.slice(0, 3)"></app-eventcards>
    </div>
  </div>
  </ng-container>
</div>
