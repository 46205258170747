<div *ngIf="filter$ | async as form">
  <div class="title">
    <div class="underlined-title">
      <p>Search for vacancies</p>
      <div></div>
    </div>
  </div>
  <app-input-field
    [form]="form"
    [formControl]="form.controls.jobTitle"
    label="Job title"
  ></app-input-field>
  <app-input-select
    [form]="form"
    [formControl]="form.controls.careerPhase"
    [selectionList]="careerPhases"
    [valueList]="careerPhaseValues"
    label="Career phase"
    requiredErrorMessage="Please fill in this field"
    emptyOption="Empty"
  ></app-input-select>
  <app-input-list-builder
    class="col-12"
    *ngIf="digitalAreas$ | async as digitalAreas"
    (addInput)="addDigitalArea($event)"
    (removeInput)="removeDigitalArea($event)"
    [inputList]="digitalAreas"
    [selectedItems]="
      form.controls.requiredDigitalAreas.value | attributeToString
    "
    [inputPlaceholder]="'Select one or more'"
    label="Required digital knowledge (max. 5)"
  ></app-input-list-builder>
  <app-input-list-builder
    class="col-12"
    *ngIf="industries$ | async as industries"
    (addInput)="addIndustry($event)"
    (removeInput)="removeIndustry($event)"
    [inputList]="industries"
    [selectedItems]="form.controls.requiredIndustries.value | attributeToString"
    [inputPlaceholder]="'Select one or more'"
    label="Required industry experience (max. 5)"
  ></app-input-list-builder>
  <app-input-field
    [form]="form"
    [formControl]="form.controls.company"
    label="Company"
  ></app-input-field>
  <app-input-country
    [form]="form"
    [formControl]="form.controls.country"
    label="Country"
    emptyOption="Empty"
  ></app-input-country>
  <div class="d-flex justify-content-center mb-4">
    <button
      mat-flat-button
      color="primary"
      class="btn-sm my-1"
      (click)="clearFilter()"
    >
      Clear filter
    </button>
  </div>
</div>
