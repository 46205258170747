<ng-container *ngIf="userModel$ | async as userModel">
  <div class="container">

    <div class="header-container">
      <p class="align-center color-primary">Education</p>
      <a class='back-arrow color-primary' [routerLink]="'../../view'">
        <span></span>
        <mat-icon>arrow_back</mat-icon>
      </a>
      <div class="bevel"></div>
    </div>

    <div class="col-12 p-0">
      <h3 class="font-weight-bold">Education</h3>
      <div
        class="backgroundEntry"
        *ngFor="let item of userModel.user?.userBackground?.educations; index as i"
      >
        <div class="backgroundInfo">
          <p class="m-0">
            {{ item.name }}
          </p>
          <p class="m-0">
            {{ item.study }}
          </p>
          <p class="m-0" *ngIf="item.startDate">
            <em>{{ item.startDate | shortdate }} - {{ item.endDate ? (item.endDate | shortdate) : 'now' }}</em>
          </p>
        </div>
        <div class="editButtons">
          <button mat-mini-fab color="accent" class="col-auto p-0 my-3 mx-2" (click)="goToEditEducation(item, i)">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="userModel.user?.userBackground?.educations.length > 0" mat-mini-fab color="warn"
                  class="col-auto p-0 my-3 mx-2" (click)="removeEducation(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <button mat-flat-button color="primary" class="btn-sm my-3" (click)="goToEditEducation()">
        Add education
      </button>
    </div>
  </div>
</ng-container>
