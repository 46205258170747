import { createSelector } from '@ngrx/store';
import { CompanyFormsState } from './companyForms.model';
import { AppState } from '../../app.state';

const selectCompanyFormsState = (state: AppState) => state.companyFormsState;

export const selectCreateCompanyForm = createSelector(selectCompanyFormsState, (state: CompanyFormsState) => state.createCompanyForm);
export const selectCompanyDetailsForm = createSelector(selectCompanyFormsState, (state: CompanyFormsState) => state.companyDetailsForm);
export const selectCompanyContactPersonForm = createSelector(selectCompanyFormsState, (state: CompanyFormsState) => state.companyContactPersonForm);
export const selectCompanyEventForm = createSelector(selectCompanyFormsState, (state: CompanyFormsState) => state.companyEventForm);
