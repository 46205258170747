import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { UserComponent } from './user.component';
import { ProfileModule } from '../profile/profile.module';
import { SharedModule } from 'src/app/shared/shared.module';

const routes: Routes = [
  {
    path: ':id',
    component: UserComponent,
  },
  {
    path: '',
    redirectTo: '/dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, ProfileModule],
  declarations: [UserComponent],
})
export class UserModule {}
