import { LayoutModule } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgrxFormsModule } from 'ngrx-forms';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { effects } from './ngrx-store/app.effects';
import { metaReducers, reducers } from './ngrx-store/app.reducers';
import { AdminService } from './services/admin.service';
import { MatchService } from './services/match.service';
import { OnboardingGuardService } from './services/onboarding.guard.service';
import { UserService } from './services/user.service';
import { UserEntityService } from './services/userEntity.service';
import { FooterComponent } from './shared/components/footer/footer.component';
import { MaterialModule } from './shared/material';
import { SharedModule } from './shared/shared.module';
import { StepperModule } from './shared/stepper/stepper.module';
import { EventService } from './services/event.service';
import { StoryService } from './services/story.service';
import { CompanyService } from './services/company.service';
import { StorageService } from './services/storage.service';
import { EducationService } from './services/education.service';
import { MessageService } from './services/message.service';
import { VacancyService } from './services/vacancy.service';
import { BlogItemService } from './services/blogitem.service';
import { MatIconModule } from '@angular/material/icon';
import { msalConfig, protectedResources } from './auth-config';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SharedFormsModule } from './shared/forms/shared.forms.module';
import { ToastModule } from 'primeng/toast';
import { MatProgressBarModule } from '@angular/material/progress-bar';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: protectedResources.backendApi.scopes
    }
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(protectedResources.backendApi.endpoint, protectedResources.backendApi.scopes);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

@NgModule({
  declarations: [AppComponent, FooterComponent, DashboardComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
      },
    }),
    EffectsModule.forRoot(effects),
    NgrxFormsModule,
    !environment.production
      ? StoreDevtoolsModule.instrument({ maxAge: 25, connectInZone: true })
      : [],
    BrowserAnimationsModule,
    NgbModule,
    MaterialModule,
    MatIconModule,
    StepperModule,
    SharedModule,
    LayoutModule,
    MatSnackBarModule,
    MsalModule,
    SharedFormsModule,
    ToastModule,
    MatProgressBarModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-GB' },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    UserService,
    EventService,
    MatchService,
    UserEntityService,
    OnboardingGuardService,
    AdminService,
    StoryService,
    EducationService,
    CompanyService,
    StorageService,
    CookieService,
    MessageService,
    VacancyService,
    BlogItemService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
