import {HttpErrorResponse} from '@angular/common/http';
import {createAction, props} from '@ngrx/store';
import {User} from 'src/app/ngrx-store/components/user/user.model';
import {SearchFilter} from 'src/app/ngrx-store/components/match/match.model';

export enum GetMentorActionTypes {
  GET = '[Match] Get mentors',
  SUCCESS = '[Match] Get mentors success',
  ERROR = '[Match] Get mentors error',
}

export const getMentors = createAction(GetMentorActionTypes.GET, props<{
  filter: SearchFilter | undefined,
  limit: number | undefined,
  bestMatches: boolean
}>());
export const getMentorsSuccess = createAction(GetMentorActionTypes.SUCCESS, props<{ matches: User[] }>());
export const getMentorsError = createAction(GetMentorActionTypes.ERROR, props<{ error: string }>());
