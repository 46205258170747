import {Component, Input, EventEmitter, Output, OnDestroy} from '@angular/core';
import {User} from '../../../ngrx-store/components/user/user.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx-store/app.state';
import {selectUserSocialMediaCount} from '../../../ngrx-store/components/user/user.selectors';
import {take} from 'rxjs/operators';
import {submitProfileEditForm} from '../../../ngrx-store/components/userForms/profileEdit/forms.profileEdit.actions';
import {postUser, postUserSuccess} from '../../../ngrx-store/requests/user/post-user/post-user.actions';
import {selectProfileEditForm} from '../../../ngrx-store/components/userForms/userForms.selectors';
import {Actions, ofType} from '@ngrx/effects';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-profile-social-media',
  templateUrl: './profile-social-media.component.html',
  styleUrls: ['./profile-social-media.component.scss']
})
export class ProfileSocialMediaComponent implements OnDestroy {
  public profileEditForm$ = this.store.select(selectProfileEditForm);

  @Input() user: User | null;
  @Input() self = false;
  @Output() refreshUser = new EventEmitter();

  modifySocialMedia = false;

  count = 0;
  private subscriptions = new Subscription();

  constructor(private store: Store<AppState>, private actions: Actions) {
    this.subscriptions.add(this.store.select(selectUserSocialMediaCount).subscribe((count) => this.count = count));
    this.subscriptions.add(this.actions.pipe(ofType(postUserSuccess)).subscribe(() => this.refreshUser.emit()));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public saveProfile() {
    this.profileEditForm$.pipe(take(1)).subscribe((form) => {
      this.store.dispatch(submitProfileEditForm({ formValues: form.value }));
      this.store.dispatch(postUser());
    });
  }
}
