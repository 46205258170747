import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {RightbrainsEvent} from '../ngrx-store/components/event/event.model';

@Injectable()
export class EventService {
  constructor(
    protected http: HttpClient
  ) {}

  public getEvents(): Observable<RightbrainsEvent[]> {
    return this.http.get<RightbrainsEvent[]>(`${environment.apiBaseUrl}events`);
  }

  public getPreviousEvents(): Observable<RightbrainsEvent[]> {
    return this.http.get<RightbrainsEvent[]>(`${environment.apiBaseUrl}events/previous`);
  }

  getEventById(id: string) {
    return this.http.get<RightbrainsEvent>(`${environment.apiBaseUrl}event/${id}`);
  }

  registerForEvent(id: string): Observable<string> {
    return this.http.get<string>(`${environment.apiBaseUrl}event/register/${id}`);
  }

  signOutForEvent(id: string): Observable<string> {
    return this.http.get<string>(`${environment.apiBaseUrl}event/signout/${id}`);
  }

  getRegistration(id: string): Observable<string> {
    return this.http.get<string>(`${environment.apiBaseUrl}event/registration/${id}`);
  }
}
