<div class="container">
  <h2 class="text-primary align-center">Thread</h2>

  <a class="back-arrow" [routerLink]="'/' + AppRoutes.CHATS">
    <span></span>
    <mat-icon>arrow_back</mat-icon>
  </a>
  <div *ngIf="user$ | async as user">
    <div *ngIf="message$ | async as message">
      <app-message
        [message]="message"
        [expandedReplies]="true"
        (addReaction)="addReaction($event)"
        (deleteMessage)="deleteMessage($event)"
        [userId]="user.id"
      ></app-message>
      <div class="add-message">
        <app-add-message [threadId]="message.id"></app-add-message>
      </div>
    </div>
  </div>
</div>
