<div *ngIf="educations?.length == 0; else educationcards">
  <div class="align-center">
    <mat-card appearance="outlined" class="event-card float-none">
      <mat-card-content>
        No planned programmes at this moment. <br/>
        Please check again later.
      </mat-card-content>
    </mat-card>
  </div>
</div>
<ng-template #educationcards>
  <div class="event-cards">
    <div *ngFor="let e of educations">
      <app-educationcard [education]="e"></app-educationcard>
    </div>
  </div>
  <br/>
  <div class="all-matches-button" *ngIf="viewAllButton">
    <a [routerLink]="'/' + AppRoutes.EDUCATION">
      <button class="btn-sm mx-auto" mat-flat-button color="primary">View all programmes</button>
    </a>
  </div>
</ng-template>
