<ng-container
  *ngIf="self || user?.linkedinSocialMedia || user?.facebookSocialMedia || user?.instagramSocialMedia || user?.twitterSocialMedia">
  <table class="w-100">
    <tr>
      <td>
        <p class="section-title">Social media</p>
      </td>
      <td *ngIf="self">
        <div *ngIf="!modifySocialMedia" (click)="modifySocialMedia = true" class="edit-cell edit-link">
          Edit <img class="edit-pencil" src="../../../../assets/images/dashboard/pencil.svg">
        </div>
        <div *ngIf="modifySocialMedia" (click)="modifySocialMedia = false; saveProfile()" class="edit-cell edit-link">
          Save
        </div>
      </td>
    </tr>
  </table>
  <div *ngIf="profileEditForm$ | async as profileEditForm" class="mb-1">
    <ng-container *ngIf="!modifySocialMedia; else modify">
      <table>
        <tr>
          <td *ngIf="user?.linkedinSocialMedia?.trim()">
            <a [href]="user?.linkedinSocialMedia?.trim().toLowerCase().startsWith('linkedin') ? 'https://' + user?.linkedinSocialMedia.trim() : user?.linkedinSocialMedia?.trim()" target="_blank">
              <img src="../../../../assets/images/brands/linkedin.svg" class=" social-media-icon" />
            </a>
          </td>
          <td *ngIf="user?.instagramSocialMedia?.trim()">
            <a [href]="user?.instagramSocialMedia?.trim().toLowerCase().startsWith('instagram') ? 'https://' + user?.instagramSocialMedia.trim() : user?.instagramSocialMedia?.trim()" target="_blank">
              <img src="../../../../assets/images/brands/instagram.svg" class=" social-media-icon" />
            </a>
          </td>
          <td *ngIf="user?.facebookSocialMedia?.trim()">
            <a [href]="user?.facebookSocialMedia?.trim().toLowerCase().startsWith('facebook') ? 'https://' + user?.facebookSocialMedia.trim() : user?.facebookSocialMedia?.trim()" target="_blank">
              <img src="../../../../assets/images/brands/facebook.svg" class=" social-media-icon" />
            </a>
          </td>
          <td *ngIf="user?.twitterSocialMedia?.trim()">
            <a [href]="user?.twitterSocialMedia?.trim().toLowerCase().startsWith('twitter') ? 'https://' + user?.twitterSocialMedia.trim() : user?.twitterSocialMedia?.trim()" target="_blank">
              <img src="../../../../assets/images/brands/twitter.svg" class=" social-media-icon" />
            </a>
          </td>
          <td *ngIf="count == 0 && self">
            <div (click)="modifySocialMedia = true"><img src="../../../../assets/images/add-button.svg"/></div>
          </td>
        </tr>
      </table>
    </ng-container>
    <ng-template #modify>
      <app-input-field
        [form]="profileEditForm"
        [formControl]="profileEditForm.controls.linkedinLink"
        [label]="'Linkedin'"
        [placeholder]="'Link to Linkedin page'"
      >
      </app-input-field>
      <app-input-field
        [form]="profileEditForm"
        [formControl]="profileEditForm.controls.facebookLink"
        [label]="'Facebook'"
        [placeholder]="'Link to Facebook page'"
      >
      </app-input-field>
      <app-input-field
        [form]="profileEditForm"
        [formControl]="profileEditForm.controls.instagramLink"
        [label]="'Instagram'"
        [placeholder]="'Link to Instagram page'"
      >
      </app-input-field>
      <app-input-field
        [form]="profileEditForm"
        [formControl]="profileEditForm.controls.twitterLink"
        [label]="'Twitter'"
        [placeholder]="'Link to Twitter page'"
      >
      </app-input-field>
    </ng-template>
  </div>
</ng-container>
