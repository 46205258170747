import { Action, createReducer, on } from '@ngrx/store';
import { RequestStatus } from '../../request-status.model';
import {
  clearUserNotifications,
  getUser,
  getUserClear,
  getUserError,
  getUserSuccess,
  deleteNotification,
  setCheckingForNotifications,
} from '../../requests/user/get-user/get-user.actions';
import {
  postUser,
  postUserError,
  postUserSuccess,
  uploadAvatarSuccess,
} from '../../requests/user/post-user/post-user.actions';
import {
  finishOnboarding,
  onboardingAgain,
} from '../onboarding/onboarding.actions';
import { submitFindMatchForm } from '../userForms/onboardingFindMatch/forms.findMatch.actions';
import { submitPersonalDetailsForm } from '../userForms/onboardingPersonalDetails/forms.personalDetails.actions';
import { submitProfileEditForm } from '../userForms/profileEdit/forms.profileEdit.actions';
import {
  setEditEnabled,
  removeCompany,
  removeEducation,
  setWorkExperience,
  changeRole,
  setFindMoreExperiencedUser,
  setFindInRegionUser,
  setCareerStartingYear,
} from './user.actions';
import {
  UserAttributes,
  UserState,
  UserCompany,
  Education,
} from './user.model';
import { submitEditAttributeListForm } from '../userForms/profileEditAttributeList/forms.editAttributeList.actions';
import { submitExperienceForm } from '../userForms/profileEditExperience/forms.experience.actions';
import { submitEducationForm } from '../userForms/profileEditEducation/forms.editEducation.actions';
import { JobType } from '../userForms/profileEditExperience/forms.experience.model';
import { EducationType } from '../userForms/profileEditEducation/forms.education.model';
import {
  UserRole,
  ExperienceOption,
  EmploymentStatus,
  UserRolesText,
  ReferenceType,
} from '../userForms/userForms.model';

export const initialUserState: UserState = {
  editingEnabled: false,
  checkingForNotifications: false,
  requestStatus: RequestStatus.IDLE,
};

export const initialAtributes: UserAttributes = {
  educations: [],
  companies: [],
  industries: [],
  digitalAreas: [],
};

const actionReducer = createReducer<UserState>(
  initialUserState,
  on(setEditEnabled, (state, action) => ({
    ...state,
    editingEnabled: action.value,
  })),
  on(getUser, (state) => ({
    ...state,
    requestStatus: RequestStatus.PENDING,
  })),
  on(postUser, (state) => ({
    ...state,
    requestStatus: RequestStatus.PENDING,
  })),
  on(getUserSuccess, (state, { user }) => ({
    ...state,
    user: {
      ...user,
      yearsExperience: generateYearsExperience(
        user.yearsExperience,
        user.careerStartingYear
      ),
      careerStartingYear: generateCareerStartingYear(
        user.yearsExperience,
        user.careerStartingYear
      ),
    },
    requestStatus: RequestStatus.SUCCESS,
  })),
  on(postUserSuccess, (state, { user }) => ({
    ...state,
    user: {
      ...user,
      yearsExperience: generateYearsExperience(
        user.yearsExperience,
        user.careerStartingYear
      ),
      careerStartingYear: generateCareerStartingYear(
        user.yearsExperience,
        user.careerStartingYear
      ),
    },
    requestStatus: RequestStatus.SUCCESS,
  })),
  on(postUserError, (state, { error }) => ({
    ...state,
    error,
    requestStatus: RequestStatus.ERROR,
  })),
  on(setCheckingForNotifications, (state, { checking }) => ({
    ...state,
    checkingForNotifications: checking,
  })),
  on(getUserError, (state, { error }) => ({
    ...state,
    error,
    requestStatus: RequestStatus.ERROR,
  })),
  on(setWorkExperience, (state, { experience }) => {
    let experienceIndex = 0;
    Object.values(ExperienceOption).forEach((el, i) => {
      if (el.toString() === experience) {
        experienceIndex = i;
      }
    });
    const newState = Object.assign({}, state);
    if (newState.user) {
      newState.user = {
        ...newState.user,
        yearsExperience: experienceIndex,
      };
    }
    return newState;
  }),
  on(setCareerStartingYear, (state, { startingYear }) => {
    const newState = Object.assign({}, state);
    if (newState.user) {
      newState.user = {
        ...newState.user,
        careerStartingYear: startingYear,
      };
    }
    return newState;
  }),
  on(getUserClear, () => initialUserState),
  on(clearUserNotifications, (state, action) => {
    const newState = Object.assign({}, state);
    if (newState.user) {
      newState.user = { ...newState.user, userNotifications: [] };
    }
    return newState;
  }),
  on(deleteNotification, (state, action) => {
    const newState = Object.assign({}, state);
    if (newState.user) {
      newState.user = {
        ...newState.user,
        userNotifications: newState.user.userNotifications?.filter(
          (n) => n.id !== action.id
        ),
      };
    }
    return newState;
  }),
  on(finishOnboarding, (state, action) => ({
    ...state,
    user: state.user
      ? {
          ...state.user,
          onboardingCompleted: action.finished,
          receiveNewsLetter: action.newsletter,
          receivePlatformMails: action.platform,
          shareData: action.shareData,
        }
      : state.user,
  })),
  on(onboardingAgain, (state) => ({
    ...state,
    user: state.user
      ? {
          ...state.user,
          onboardingCompleted: false,
        }
      : state.user,
  })),
  on(removeCompany, (state, action) => {
    const userBackground = state.user?.userBackground
      ? state.user.userBackground
      : initialAtributes;

    return {
      ...state,
      user: state.user
        ? {
            ...state.user,
            userBackground: {
              ...userBackground,
              companies: [
                ...userBackground.companies.slice(0, action.index),
                ...userBackground.companies.slice(action.index + 1),
              ],
            },
          }
        : state.user,
    };
  }),
  on(changeRole, (state, action) => ({
    ...state,
    user: state.user
      ? {
          ...state.user,
          role: Object.values(UserRole).findIndex((u) => u === action.role),
        }
      : state.user,
  })),
  on(uploadAvatarSuccess, (state, action) => ({
    ...state,
    user: state.user
      ? {
          ...state.user,
          avatarRef: action.urlRef,
        }
      : state.user,
  })),
  on(setFindMoreExperiencedUser, (state, action) => ({
    ...state,
    user: state.user
      ? {
          ...state.user,
          findMoreExperienced: action.value,
        }
      : state.user,
  })),
  on(setFindInRegionUser, (state, action) => ({
    ...state,
    user: state.user
      ? {
          ...state.user,
          findInRegion: action.value,
        }
      : state.user,
  })),
  on(removeEducation, (state, action) => {
    const userBackground = state.user?.userBackground
      ? state.user.userBackground
      : initialAtributes;

    return {
      ...state,
      user: state.user
        ? {
            ...state.user,
            userBackground: {
              ...userBackground,
              educations: [
                ...userBackground.educations.slice(0, action.index),
                ...userBackground.educations.slice(action.index + 1),
              ],
            },
          }
        : state.user,
    };
  })
);

const consumeFormsReducer = createReducer<UserState>(
  initialUserState,
  on(submitPersonalDetailsForm, (state, action) => {
    const userBackground = state.user?.userBackground
      ? state.user.userBackground
      : initialAtributes;
    const isStudent = action.formValues.employmentStatus === 'Student';

    return {
      ...state,
      user: state.user
        ? {
            ...state.user,
            employmentStatus: Object.values(EmploymentStatus).findIndex(
              (u) => u === action.formValues.employmentStatus
            ),
            country: action.formValues.country,
            region: action.formValues.region,
            yearsExperience: generateYearsExperience(
              Object.values(ExperienceOption).findIndex(
                (u) => u === action.formValues.yearsExperience
              ),
              +action.formValues.careerStartingYear
            ),
            careerStartingYear: +action.formValues.careerStartingYear,
            reference: generateReference(
              action.formValues.reference,
              action.formValues.referenceType
            ),
            userBackground: {
              ...userBackground,
              educations: isStudent
                ? [
                    {
                      name: action.formValues.education,
                      study: action.formValues.study,
                      startDate: new Date(action.formValues.startDate),
                      endDate: undefined,
                    },
                    ...userBackground.educations.slice(1),
                  ]
                : [],
              companies: !isStudent
                ? [
                    {
                      name: action.formValues.company,
                      position: action.formValues.companyPosition,
                      startDate: new Date(action.formValues.startDate),
                      endDate: undefined,
                      description: '',
                    },
                    ...userBackground.companies.slice(1),
                  ]
                : [],
            },
          }
        : state.user,
    };
  }),
  on(submitFindMatchForm, (state, action) => {
    const userBackground = state.user?.userBackground
      ? state.user.userBackground
      : initialAtributes;
    const userInterests = state.user?.userInterests
      ? state.user.userInterests
      : initialAtributes;

    return {
      ...state,
      user: state.user
        ? {
            ...state.user,
            role: Object.values(UserRolesText).findIndex(
              (u) => u === action.formValues.role
            ),
            findInRegion: action.formValues.findInRegion,
            findMoreExperienced: action.formValues.findMoreExperienced,
            userBackground: {
              ...userBackground,
              digitalAreas: action.formValues.digitalAreaBackgrounds,
              industries: action.formValues.industryBackgrounds,
            },
            userInterests: {
              ...userInterests,
              digitalAreas: action.formValues.digitalAreaInterests,
              industries: action.formValues.industryInterests,
            },
          }
        : state.user,
    };
  }),
  on(submitProfileEditForm, (state, action) => {
    return {
      ...state,
      user: state.user
        ? {
            ...state.user,
            firstName: action.formValues.firstName,
            lastName: action.formValues.lastName,
            about: action.formValues.about,
            country: action.formValues.country,
            region: action.formValues.region,
            role: Object.values(UserRole).findIndex(
              (u) => u === action.formValues.role
            ),
            employmentStatus: Object.values(EmploymentStatus).findIndex(
              (u) => u === action.formValues.employmentStatus
            ),
            receiveNewsLetter: action.formValues.newsletter,
            receivePlatformMails: action.formValues.platform,
            shareData: action.formValues.shareData,
            functionLabel: action.formValues.functionLabel,
            linkedinSocialMedia: action.formValues.linkedinLink?.trim(),
            facebookSocialMedia: action.formValues.facebookLink?.trim(),
            instagramSocialMedia: action.formValues.instagramLink?.trim(),
            twitterSocialMedia: action.formValues.twitterLink?.trim(),
          }
        : state.user,
    };
  }),
  on(submitExperienceForm, (state, action) => {
    const userBackground = state.user?.userBackground
      ? state.user.userBackground
      : initialAtributes;
    const isCurrentJob = action.formValues.jobType === JobType.Current_Job;

    const newCompany: UserCompany = {
      name: action.formValues.company,
      position: action.formValues.position,
      startDate: new Date(action.formValues.startDate),
      endDate:
        action.formValues.endDate && !isCurrentJob
          ? new Date(action.formValues.endDate)
          : undefined,
      description: action.formValues.description,
    };

    return {
      ...state,
      user: state.user
        ? {
            ...state.user,
            userBackground: {
              ...userBackground,
              companies:
                action.formValues.index >= 0
                  ? [
                      ...userBackground.companies.slice(
                        0,
                        action.formValues.index
                      ),
                      newCompany,
                      ...userBackground.companies.slice(
                        action.formValues.index + 1
                      ),
                    ]
                  : [...userBackground.companies, newCompany],
            },
          }
        : state.user,
    };
  }),
  on(submitEducationForm, (state, action) => {
    const userBackground = state.user?.userBackground
      ? state.user.userBackground
      : initialAtributes;
    const isCurrentEducation =
      action.formValues.educationType === EducationType.Current_Education;

    const newEducation: Education = {
      name: action.formValues.school,
      study: action.formValues.study,
      startDate: new Date(action.formValues.startDate),
      endDate:
        action.formValues.endDate && !isCurrentEducation
          ? new Date(action.formValues.endDate)
          : undefined,
    };

    return {
      ...state,
      user: state.user
        ? {
            ...state.user,
            userBackground: {
              ...userBackground,
              educations:
                action.formValues.index >= 0
                  ? [
                      ...userBackground.educations.slice(
                        0,
                        action.formValues.index
                      ),
                      newEducation,
                      ...userBackground.educations.slice(
                        action.formValues.index + 1
                      ),
                    ]
                  : [...userBackground.educations, newEducation],
            },
          }
        : state.user,
    };
  }),
  on(submitEditAttributeListForm, (state, action) => {
    const userBackground = state.user?.userBackground
      ? state.user.userBackground
      : initialAtributes;
    const userInterests = state.user?.userInterests
      ? state.user.userInterests
      : initialAtributes;

    return {
      ...state,
      user: state.user
        ? {
            ...state.user,
            userBackground: {
              ...userBackground,
              digitalAreas: action.formValues.digitalAreaBackgrounds,
              industries: action.formValues.industryBackgrounds,
            },
            userInterests: {
              ...userInterests,
              digitalAreas: action.formValues.digitalAreaInterests,
              industries: action.formValues.industryInterests,
            },
          }
        : state.user,
    };
  })
);

const joinedReducer = (state: UserState | undefined, action: Action) => {
  let newState: UserState = actionReducer(state, action);
  newState = consumeFormsReducer(newState, action);

  return {
    ...newState,
  };
};

export function userReducer(state: UserState | undefined, action: Action) {
  return joinedReducer(state, action);
}

function generateReference(
  reference: string,
  referenceType?: ReferenceType
): string {
  if (referenceType === ReferenceType.Relationship) {
    return 'Relationship: ' + reference;
  }
  if (referenceType === ReferenceType.Other) {
    return 'Other: ' + reference;
  }
  if (!referenceType || referenceType === ReferenceType.None) {
    return reference;
  }
  return referenceType;
}

function generateYearsExperience(
  yearsExperience: number,
  startingYear?: number
): number {
  if (!startingYear) {
    return yearsExperience;
  }
  const currentYear = new Date().getFullYear();
  if (currentYear - startingYear <= 2) {
    return 0;
  }
  if (currentYear - startingYear <= 9) {
    return 1;
  }
  if (currentYear - startingYear <= 25) {
    return 2;
  }
  return 3;
}

function generateCareerStartingYear(
  yearsExperience: number,
  startingYear?: number
): number {
  if (startingYear) {
    return startingYear;
  }
  const currentYear = new Date().getFullYear();
  if (yearsExperience === 1) {
    return currentYear - 3;
  }
  if (yearsExperience === 2) {
    return currentYear - 10;
  }
  if (yearsExperience === 3) {
    return currentYear - 25;
  }
  return currentYear;
}
