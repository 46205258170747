import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { VacancyService } from '../../../services/vacancy.service';
import { submitVacancy } from '../vacancyForm/vacancyForm.actions';
import {
  postVacancyInterestSuccess,
  postVacancyInterestError, getVacanciesForCompany, getVacanciesForCompanyError, getVacanciesForCompanySuccess,
} from './vacancy.actions';
import {
  deleteVacancyError,
  getVacanciesInterests,
  getVacanciesInterestsSuccess,
  getVacanciesInterestsError,
  postVacancyInterest,
} from './vacancy.actions';
import {
  postVacancySuccess,
  postVacancyError,
  deleteVacancy,
  deleteVacancySuccess,
} from './vacancy.actions';
import {
  getVacancies,
  getVacanciesSuccess,
  getVacanciesError,
} from './vacancy.actions';

@Injectable()
export class VacancyEffects {
  constructor(
    private actions$: Actions,
    private vacancyService: VacancyService
  ) {}

  getVacancies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getVacancies),
      switchMap(() => {
        return this.vacancyService.getVacancies().pipe(
          map((vacancies) => getVacanciesSuccess({ vacancies })),
          catchError((error: HttpErrorResponse) => {
            return of(getVacanciesError({ error: error.message }));
          })
        );
      })
    )
  );

  postVacancy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitVacancy),
      switchMap(({ formValues }) => {
        return this.vacancyService.postVacancy(formValues).pipe(
          map((vacancy) => postVacancySuccess({ vacancy })),
          catchError((error: HttpErrorResponse) => {
            return of(postVacancyError({ error: error.message }));
          })
        );
      })
    )
  );

  deleteVacancy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteVacancy),
      switchMap(({ vacancyId }) => {
        return this.vacancyService.deleteVacancy(vacancyId).pipe(
          map(() => deleteVacancySuccess({ vacancyId })),
          catchError((error: HttpErrorResponse) => {
            return of(deleteVacancyError({ error: error.message }));
          })
        );
      })
    )
  );

  getVacanciesInterests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getVacanciesInterests),
      switchMap(() => {
        return this.vacancyService.getVacancyInterests().pipe(
          map((interests) => getVacanciesInterestsSuccess({ interests })),
          catchError((error: HttpErrorResponse) => {
            return of(getVacanciesInterestsError({ error: error.message }));
          })
        );
      })
    )
  );

  postVacancyInterest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postVacancyInterest),
      switchMap(({ vacancyId, interested }) => {
        return this.vacancyService
          .postVacancyInterest(vacancyId, interested)
          .pipe(
            map(() => postVacancyInterestSuccess()),
            catchError((error: HttpErrorResponse) => {
              return of(postVacancyInterestError({ error: error.message }));
            })
          );
      })
    )
  );

  getVacanciesForCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getVacanciesForCompany),
      switchMap(({ companyId }) => {
        return this.vacancyService.getVacanciesForCompany(companyId).pipe(
          map((vacancies) => getVacanciesForCompanySuccess({ companyId, vacancies })),
          catchError((error: HttpErrorResponse) => {
            return of(getVacanciesForCompanyError({ error: error.message }));
          })
        );
      })
    )
  );

  refetchOnPostVacancySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postVacancySuccess),
      map(({vacancy}) => getVacanciesForCompany({ companyId: vacancy.companyId }))
    )
  );

}
