<ng-container *ngIf="attributeListForm$ | async as form">
  <div class="row col-12">
    <p class="col mb-3 font-weight-bold">{{ isBackground ? 'Industry experience' : 'Industries of interest' }}</p>
    <app-input-list-builder
      class="col-12 mt-3 mb-3"
      *ngIf="industries$ | async as industries"
      (addInput)="addIndustry($event)"
      (removeInput)="removeIndustry($event)"
      [inputList]="industries"
      [selectedItems]="
        isBackground
          ? (form.controls.industryBackgrounds.value | attributeToString)
          : (form.controls.industryInterests.value | attributeToString)
      "
      [inputPlaceholder]="'Select one or more'"
      data-cy="profile-industry-select"
    ></app-input-list-builder>
  </div>
  <div class="row col-12 justify-content-center">
    <button class="btn-xs mx-2" mat-flat-button color="primary" (click)="submitForm(form.value)">
      Done
    </button>
    <button class="btn-xs mx-2" mat-stroked-button color="primary" (click)="cancel()">
      Cancel
    </button>
  </div>
</ng-container>
