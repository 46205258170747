<div class="container p-0 col-12">
  <div class="align-center">
    <div class="dashboard-title pt-5">
      <div class="underlined-title">
        <p>Create business account</p>
        <div></div>
      </div>
    </div>
      <p class="intro-text pb-5">Ready to tap into the RightBrains platform?</p>
      <div class="main-page-image">
          <img
              src="https://rightbrainsproductionv2.blob.core.windows.net/easyauth/assets/images/initial_image.svg"
              class="svg-image-main-page"
          />
          <div class="circle-main-page"></div>
      </div>
  </div>
  <div id='loginFrame' class="col-12 color-white">
      <div class="align-center">
          <img
            class="logo"
            src="https://rightbrainsproductionv2.blob.core.windows.net/easyauth/assets/images/RightBrainsLogo.png"
          >
      </div>
      <div *ngIf="createCompanyForm$ | async as form">
        <app-input-field
          [form]="form"
          [formControl]="form.controls.name"
          label="Company name"
          placeholder="Enter your company name"
        >
        </app-input-field>
        <app-input-field
          [form]="form"
          [formControl]="form.controls.companyCode"
          label="Access code"
          placeholder="Fill in code"
        >
        </app-input-field>
        <div class="d-flex justify-content-center">
          <button
            class="btn-sm mx-auto"
            mat-flat-button
            color="primary"
            (click)="submitForm(form.value)">
            <div class="d-flex justify-content-center align-items-center">
              <span class="pe-3">{{ loading ? "Creating account" : "Create account"}}</span>
              <mat-spinner
                *ngIf="loading"
                mode="indeterminate"
                diameter="20"
              ></mat-spinner>
            </div>
        </button>
        </div>
        <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
      </div>
      <div class=pt-4>
        <a href="mailto:info@rightbrains.nl">Forgot your access code or your code does not work? Please contact RightBrains.</a>
      </div>
  </div>
</div>

