import { Action, createReducer, on } from '@ngrx/store';
import {
  addArrayControl,
  createFormGroupState,
  FormGroupState,
  formStateReducer,
  removeArrayControl,
  setValue,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { maxLength, required } from 'ngrx-forms/validation';
import { VacancyType } from '../vacancy/vacancy.model';
import {
  clearVacancy,
  addDigitalAreaToVacancy,
  removeDigitalAreaFromVacancy,
} from './vacancyForm.actions';
import { CreateVacancyForm, VacancyFormsState } from './vacancyForm.model';
import { loadVacancy } from './vacancyForm.actions';
import {
  initialVacancyFilterFormState,
  vacancyFilterFormReducer,
} from './vacancyFilter/forms.vacancyFilter.reducer';
import {
  addPhotoToVacancySuccess,
  removePhotoFromVacancy,
} from './vacancyForm.actions';
import {
  addIndustryToVacancy,
  removeIndustryFromVacancy,
} from './vacancyForm.actions';
import _ = require('underscore');

export const initialCreateVacancyForm: CreateVacancyForm = {
  id: '',
  jobTitle: '',
  photoUrl: '',
  summary: '',
  idealCandidate: '',
  type: VacancyType.Employed,
  startDate: '',
  expirationDate: '',
  careerPhase: 0,
  country: '',
  city: '',
  requiredDigitalAreas: [],
  requiredIndustries: [],
};

export const initialCreateVacancyFormState =
  createFormGroupState<CreateVacancyForm>(
    'CREATE_VACANCY_FORM',
    initialCreateVacancyForm
  );

const updateForm = createReducer<FormGroupState<CreateVacancyForm>>(
  initialCreateVacancyFormState,
  on(clearVacancy, () => initialCreateVacancyFormState),
  on(addDigitalAreaToVacancy, (state, action) =>
    updateGroup<CreateVacancyForm>(state, {
      requiredDigitalAreas: addArrayControl(action.digitalArea),
    })
  ),
  on(removeDigitalAreaFromVacancy, (state, action) =>
    updateGroup<CreateVacancyForm>(state, {
      requiredDigitalAreas: removeArrayControl(
        _.findIndex(
          state.value.requiredDigitalAreas,
          (s) => s.name === action.digitalArea.name
        )
      ),
    })
  ),
  on(addIndustryToVacancy, (state, action) =>
    updateGroup<CreateVacancyForm>(state, {
      requiredIndustries: addArrayControl(action.industry),
    })
  ),
  on(removeIndustryFromVacancy, (state, action) =>
    updateGroup<CreateVacancyForm>(state, {
      requiredIndustries: removeArrayControl(
        _.findIndex(
          state.value.requiredIndustries,
          (s) => s.name === action.industry.name
        )
      ),
    })
  ),
  on(addPhotoToVacancySuccess, (state, action) =>
    updateGroup<CreateVacancyForm>(state, {
      photoUrl: setValue(action.reference),
    })
  ),
  on(removePhotoFromVacancy, (state, action) =>
    updateGroup<CreateVacancyForm>(state, {
      photoUrl: setValue(''),
    })
  ),
  on(loadVacancy, (state, action) =>
    updateGroup<CreateVacancyForm>(state, {
      id: setValue(action.vacancy.id),
      jobTitle: setValue(action.vacancy.jobTitle),
      photoUrl: setValue(action.vacancy.photoUrl),
      summary: setValue(action.vacancy.summary),
      idealCandidate: setValue(action.vacancy.idealCandidate),
      type: setValue(action.vacancy.type),
      startDate: setValue(action.vacancy.startDate),
      expirationDate: setValue(action.vacancy.expirationDate.toString()),
      careerPhase: setValue(action.vacancy.careerPhase),
      country: setValue(action.vacancy.country),
      city: setValue(action.vacancy.city),
      requiredDigitalAreas: setValue(action.vacancy.requiredDigitalAreas),
      requiredIndustries: setValue(action.vacancy.requiredIndustries),
    })
  )
);

const validateForm = (
  form: FormGroupState<CreateVacancyForm>,
  state: VacancyFormsState
) => {
  return updateGroup<CreateVacancyForm>({
    jobTitle: validate(required),
    summary: validate(required, maxLength(1000)),
    idealCandidate: validate(required, maxLength(1000)),
    type: validate(required),
    startDate: validate(required),
    expirationDate: validate(required),
    careerPhase: validate(required),
    country: validate(required),
    requiredDigitalAreas: validate(maxLength(5)),
    requiredIndustries: validate(maxLength(5)),
  })(form);
};

export const createVacancyFormReducer = (
  state: VacancyFormsState,
  action: Action
) => {
  const createVacancyForm: FormGroupState<CreateVacancyForm> =
    state.createVacancyForm;

  // use the out of the box reducer to process form actions into the store
  let form = formStateReducer(createVacancyForm, action);

  // Process non-form actions
  form = updateForm(form, action);

  // form validation using the new state
  form = validateForm(form, state);

  return form === createVacancyForm ? createVacancyForm : form;
};

export const initialVacancyFormsState: VacancyFormsState = {
  createVacancyForm: initialCreateVacancyFormState,
  vacancyFilterForm: initialVacancyFilterFormState,
};

const actionReducer = createReducer<VacancyFormsState>(
  initialVacancyFormsState
);

const joinedReducer = (
  state: VacancyFormsState | undefined,
  action: Action
): VacancyFormsState => {
  const newState: VacancyFormsState = actionReducer(state, action);

  return {
    ...newState,
    createVacancyForm: createVacancyFormReducer(newState, action),
    vacancyFilterForm: vacancyFilterFormReducer(newState, action),
  };
};

export function vacancyFormsReducer(
  state: VacancyFormsState | undefined,
  action: Action
) {
  return joinedReducer(state, action);
}
