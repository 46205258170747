import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { sendConnectionRequest } from 'src/app/ngrx-store/components/connection/connection.actions';

@Component({
  selector: 'app-connection-request-dialog',
  templateUrl: './connection-request-dialog.component.html',
  styleUrls: ['./connection-request-dialog.component.scss'],
})
export class ConnectionRequestDialogComponent {
  public connectionId: string;

  constructor(
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) data: { connectionId: string }
  ) {
    this.connectionId = data.connectionId;
  }

  public sendRequest(message: string): void {
    this.store.dispatch(
      sendConnectionRequest({ id: this.connectionId, message })
    );
  }
}
