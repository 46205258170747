import {Component, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectVacancies } from '../../ngrx-store/components/vacancy/vacancy.selectors';
import { Vacancy } from '../../ngrx-store/components/vacancy/vacancy.model';
import { VacancyDialogComponent } from '../../shared/components/vacancy-dialog/vacancy-dialog.component';
import { selectCompanies } from '../../ngrx-store/components/company/company.selectors';
import { getCompanies } from '../../ngrx-store/requests/company/get-company/get-company.actions';
import {
  getVacancies,
  getVacanciesInterests,
} from 'src/app/ngrx-store/components/vacancy/vacancy.actions';
import { postVacancyInterest } from '../../ngrx-store/components/vacancy/vacancy.actions';
import { selectVacancyFilterForm } from '../../ngrx-store/components/vacancyForm/vacancyForm.selectors';
import { VacancyFilterForm } from '../../ngrx-store/components/vacancyForm/vacancyFilter/forms.vacancyFilter.model';
import { initialVacancyFilterForm } from '../../ngrx-store/components/vacancyForm/vacancyFilter/forms.vacancyFilter.reducer';
import {
  getIndustries,
  getDigitalAreas,
} from '../../ngrx-store/requests/attributes/get-userEntities.actions';
import { ActivatedRoute } from '@angular/router';
import { setFilterCompany } from '../../ngrx-store/components/vacancyForm/vacancyFilter/forms.vacancyFilter.actions';

@Component({
  selector: 'app-vacancies',
  templateUrl: './vacancies.component.html',
  styleUrls: ['./vacancies.component.scss'],
})
export class VacanciesComponent implements OnInit {
  public vacancies$ = this.store.select(selectVacancies);
  public companies$ = this.store.select(selectCompanies);
  public filter$ = this.store.select(selectVacancyFilterForm);
  public searchMode = false;

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private route: ActivatedRoute) {}

  ngOnInit() {
    const company = this.route.snapshot.queryParams.company;
    this.store.dispatch(setFilterCompany({ company: company ?? '' }));

    this.store.dispatch(getCompanies());
    this.store.dispatch(getVacancies());
    this.store.dispatch(getVacanciesInterests());
    this.store.dispatch(getIndustries());
    this.store.dispatch(getDigitalAreas());
  }

  public openDialog(vacancy: Vacancy) {
    this.dialog.open(VacancyDialogComponent, {
      data: {
        vacancy,
        company: vacancy.company,
      },
    });
  }

  public postInterest(vacancy: Vacancy) {
    this.store.dispatch(
      postVacancyInterest({
        vacancyId: vacancy.id,
        interested: false,
      })
    );
  }

  public enableSearch() {
    this.searchMode = true;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  public sortVacancies(vacancies: Vacancy[], filter: VacancyFilterForm) {
    if (this.isFilterEmpty(filter) && this.searchMode) {
      return [];
    }
    const filteredVacancies = vacancies.filter(
      (v: Vacancy) =>
        v.jobTitle.toLowerCase().includes(filter.jobTitle.toLowerCase()) &&
        v.company?.name.toLowerCase().includes(filter.company.toLowerCase()) &&
        (filter.careerPhase === undefined ||
          filter.careerPhase === 'Empty' ||
          v.careerPhase === filter.careerPhase) &&
        (filter.country === 'Empty' ||
          v.country.toLowerCase().includes(filter.country.toLowerCase())) &&
        filter.requiredDigitalAreas.every(
          (daFilter) =>
            v.requiredDigitalAreas.filter(
              (daVacancy) => daFilter.name === daVacancy.name
            ).length > 0
        ) &&
        filter.requiredIndustries.every(
          (iFilter) =>
            v.requiredIndustries.filter(
              (iVacancy) => iFilter.name === iVacancy.name
            ).length > 0
        )
    );
    return this.searchMode
      ? filteredVacancies.sort((v1, v2) =>
          v1.interested === v2.interested ? 0 : v1.interested ? -1 : 1
        )
      : filteredVacancies.filter((v) => v.interested !== false);
  }

  private isFilterEmpty(filter: VacancyFilterForm) {
    return filter === initialVacancyFilterForm;
  }
}
