<form [ngrxFormState]="form">
  <ng-container class="row">
    <div [class.invalid]="formControl.isTouched && formControl.isInvalid">
      <mat-form-field class="col-12 p-0">
        <mat-label>{{ label }}</mat-label>
        <input
          #inputList
          type="text"
          placeholder="{{ placeholder }}"
          matInput
          (input)="filterList($event.target.value)"
          (focus)="filterList($event.target.value)"
          [ngrxFormControlState]="formControl"
          [matAutocomplete]="autoComplete"
          (input)="inputChanged.emit($event)"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #autoComplete="matAutocomplete"
          (optionSelected)="filterList($event.option.value)"
          (optionSelected)="optionSelected.emit($event)"
        >
          <mat-option *ngFor="let option of options" [value]="option">
            {{ option }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="formControl.errors?.required">
          {{ requiredErrorMessage }}
        </mat-error>
        <mat-error *ngIf="formControl.errors?.pattern">
          {{ patternErrorMessage }}
        </mat-error>
        <mat-error *ngIf="formControl.errors?.email">
          {{ emailErrorMessage }}
        </mat-error>
      </mat-form-field>
    </div>
  </ng-container>
</form>
