<div *ngIf="onboardingModel$ | async as onboardingModel">

<div class="col-12 d-flex w-100">
  <button
    mat-button
    color="primary"
    (click)="previousQuestion(onboardingModel.activeQuestionIndex)"
    class="align-self-start p-0 h-100"
  >
    <span></span>
    <mat-icon>arrow_back</mat-icon>
    Back
  </button>
  <button
    #nextQuestionBtn
    *ngIf="!onboardingModel.isLastQuestion; else final"
    class="btn-sm next-question"
    mat-flat-button
    [disabled]="!canPressNext"
    color="primary"
    (click)="nextQuestion()"
  >
    Next question
  </button>
  <ng-template #final>
    <button
      class="btn-sm next-question"
      [disabled]="!canPressNext"
      mat-flat-button
      color="primary"
      (click)="finish.emit($event)"
    >
      {{ finishText }}
    </button>
  </ng-template>
</div></div>
