import { Action, createReducer, on } from '@ngrx/store';
import {
  createFormGroupState,
  FormGroupState,
  formStateReducer,
  setErrors,
  setValue,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { notEqualTo, number, pattern, required } from 'ngrx-forms/validation';
import { getUserSuccess } from 'src/app/ngrx-store/requests/user/get-user/get-user.actions';
import { User } from '../../user/user.model';
import {
  UserFormsState,
  ExperienceOption,
  EmploymentStatus,
  ReferenceType,
} from '../userForms.model';
import { PersonalDetailsForm } from './forms.personalDetails.model';
import { isEmpty } from 'rxjs/operators';

export const initialPersonalDetailsForm: PersonalDetailsForm = {
  education: '',
  study: '',
  company: '',
  companyPosition: '',
  yearsExperience: ExperienceOption.Entry_Level,
  careerStartingYear: '',
  experienceOptions: Object.values(ExperienceOption).map((x) => x),
  startDate: '',
  employmentStatuses: Object.values(EmploymentStatus).map((x) => x),
  employmentStatus: EmploymentStatus.Employee,
  country: '',
  region: '',
  reference: '',
  referenceType: ReferenceType.None,
};

export const initialPersonalDetailsFormState =
  createFormGroupState<PersonalDetailsForm>(
    'PERSONAL_DETAILS_FORM',
    initialPersonalDetailsForm
  );

const updateForm = createReducer<FormGroupState<PersonalDetailsForm>>(
  initialPersonalDetailsFormState,
  on(getUserSuccess, (state, action) => prefillForm(state, action.user))
);

const validateForm = (
  form: FormGroupState<PersonalDetailsForm>,
  state: UserFormsState
) => {
  const userTitle = form.controls.employmentStatus.value;
  const country = form.controls.country.value;
  const referenceType = form.controls.referenceType.value;
  const reference = form.controls.reference.value;

  return updateGroup<PersonalDetailsForm>({
    employmentStatus: validate(required),
    country: validate(required),
    region: (field) => {
      if (country === 'Netherlands') {
        return validate(field, required);
      } else {
        return validate(field, isEmpty);
      }
    },
    education: (field) => {
      if (userTitle === EmploymentStatus.Student) {
        return validate(field, required);
      } else {
        return setErrors(field, {});
      }
    },
    study: (field) => {
      if (userTitle === EmploymentStatus.Student) {
        return validate(field, required);
      } else {
        return setErrors(field, {});
      }
    },
    startDate: (control) => {
      if (userTitle === EmploymentStatus.Student) {
        return validate(control, required);
      } else {
        return setErrors(control, {});
      }
    },
    company: (field) => {
      if (userTitle !== EmploymentStatus.Student) {
        return validate(field, required);
      } else {
        return setErrors(field, {});
      }
    },
    companyPosition: (field) => {
      if (userTitle !== EmploymentStatus.Student) {
        return validate(field, required);
      } else {
        return setErrors(field, {});
      }
    },
    yearsExperience: (field) => {
      if (userTitle !== EmploymentStatus.Student) {
        return validate(field, required);
      } else {
        return setErrors(field, {});
      }
    },
    careerStartingYear: (field) => {
      if (userTitle !== EmploymentStatus.Student) {
        return validate(field, required, pattern(/\d{4}/));
      } else {
        return setErrors(field, {});
      }
    },
  })(form);
};

const prefillForm = (
  state: FormGroupState<PersonalDetailsForm>,
  user: User
) => {
  if (!user) {
    return state;
  }

  return updateGroup<PersonalDetailsForm>(state, {
    employmentStatus: setValue(
      Object.values(EmploymentStatus)[user.employmentStatus].toString()
    ),
    region: setValue(user.region),
    country: setValue(user.country),
    careerStartingYear: setValue(
      user.careerStartingYear ? user.careerStartingYear.toString() : ''
    ),
    reference: setValue(user.reference),
    company: setValue(
      user.userBackground?.companies && user.userBackground?.companies.length > 0
        ? user.userBackground.companies[0].name
        : ''
    ),
    companyPosition: setValue(
      user.userBackground?.companies && user.userBackground?.companies.length > 0
        ? user.userBackground.companies[0].position
        : ''
    ),
    yearsExperience: setValue(
      Object.values(ExperienceOption)[user.yearsExperience].toString()
    ),
    education: setValue(
      user.userBackground?.educations && user.userBackground?.educations.length > 0
        ? user.userBackground.educations[0].name
        : ''
    ),
    study: setValue(
      user.userBackground?.educations && user.userBackground?.educations.length > 0
        ? user.userBackground.educations[0].study
        : ''
    ),
    startDate: setValue(
      user.userBackground?.educations && user.userBackground?.educations.length > 0
        ? user.userBackground.educations[0].startDate?.toString()
        : ''
    ),
  });
};

export const personalDetailsFormReducer = (
  state: UserFormsState,
  action: Action
) => {
  const personalDetailsForm: FormGroupState<PersonalDetailsForm> =
    state.personalDetailsForm;

  // use the out of the box reducer to process form actions into the store
  let form = formStateReducer(personalDetailsForm, action);

  // Process non-form actions
  form = updateForm(form, action);

  // form validation using the new state
  form = validateForm(form, state);

  return form === personalDetailsForm ? personalDetailsForm : form;
};
