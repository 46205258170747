import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  public confirmMessage: string;
  noCancel = false;
  hideTitle = false;
  callback: (confirm: boolean) => void;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: {
      noCancel?: boolean;
      message: string;
      callback: (confirm: boolean) => void;
      hideTitle?: boolean;
    }
  ) {
    this.noCancel = data.noCancel || false;
    this.confirmMessage = data.message;
    this.hideTitle = data.hideTitle || false;
    this.callback = data.callback;
  }

  ngOnInit(): void {}
}
