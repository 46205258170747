import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppRoutes } from 'src/app/app-routing.model';
import { Company } from 'src/app/ngrx-store/components/company/company.model';
import { CompanyDialogComponent } from '../company-dialog/company-dialog.component';
import { trackClick } from '../../../ngrx-store/requests/user/post-user/post-user.actions';
import { AppState } from 'src/app/ngrx-store/app.state';

@Component({
  selector: 'app-companycard',
  templateUrl: './companycard.component.html',
  styleUrls: ['./companycard.component.scss']
})
export class CompanycardComponent {
  public AppRoutes = AppRoutes;
  @Input() company: Company;

  constructor(private dialog: MatDialog, private store: Store<AppState>) {}

  public openDialog() {
    this.store.dispatch(trackClick({eventType: 'open company profile', companyId: this.company.id}));
    this.dialog.open(CompanyDialogComponent, {
      data: {
        company: this.company
      },
    });
  }
}
