export interface ExperienceForm {
  jobType: JobType;
  jobTypes: string[];
  position: string;
  company: string;
  startDate: string;
  endDate: string;
  description: string;
  index: number;
}

export enum JobType {
  Previous_Job = 'Previous Job',
  Current_Job = 'Current Job',
}
