import { createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';
import { MatchState } from './match.model';

const selectMatchState = (state: AppState) => state.matchState;

export const selectMentors = createSelector(selectMatchState, (state: MatchState) => state.mentors);
export const selectMentees = createSelector(selectMatchState, (state: MatchState) => state.mentees);
export const selectMembers = createSelector(selectMatchState, (state: MatchState) => state.members);

export const selectMatchModel = createSelector(
  [selectMentors, selectMentees, selectMembers],
  (mentors, mentees, members) => ({
    mentors,
    mentees,
    members,
  })
);
