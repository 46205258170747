<div *ngIf="connection" class="connection-wrapper">
  <img *ngIf="connection.user.avatarRef" class="avatar" [src]="connection.user.avatarRef | safe: 'url'" />
  <div *ngIf="!connection.user.avatarRef" class="avatar" style="width: 50px; height: 50px;">
    <mat-icon width="50" height="50">account_circle</mat-icon>
  </div>

  <div class="ms-3 w-50" style="overflow: hidden;">
    <div class="col-12 p-0">
      <div>{{ connection.user.firstName }} {{ connection.user.lastName }}</div>
      <p class="p-0 m-0">
        {{ connection.user.functionLabel || 'Unknown position' }}
      </p>
      <div>{{ (connection.user.role | userRole) || 'Unknown role' }}</div>
    </div>
  </div>

  <ng-container *ngIf="!connection.isOutgoing">
    <div class="col-12 p-0">
      <button class="btn-xs height-button" disabled="{{ pendingRequest }}" mat-stroked-button color="primary" (click)="accept()">
        Accept
      </button>

      <button class="btn-xs height-button" disabled="{{ pendingRequest }}" mat-stroked-button color="primary" (click)="decline()">
        Decline
      </button>
    </div>
  </ng-container>

  <p *ngIf="connection.isOutgoing" class="p-2">Connection pending...</p>
</div>
