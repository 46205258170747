<form [ngrxFormState]="form">
  <div *ngIf="checked; else notChecked">
  <mat-checkbox
    color="primary"
    [ngrxFormControlState]="formControl"
    [labelPosition]="'after'"
    (change)="checkboxChange.emit($event)"
    checked
  >
    <ng-content></ng-content>
  </mat-checkbox>
  </div>
  <ng-template #notChecked>
    <mat-checkbox
      color="primary"
      [ngrxFormControlState]="formControl"
      [labelPosition]="'after'"
      (change)="checkboxChange.emit($event)"
    >
      <ng-content></ng-content>
    </mat-checkbox>
  </ng-template>
</form>
