import { User } from '../user/user.model';

export interface MessageState {
  messages: Message[];
  newMessage: NewMessage;
}

export interface NewMessage {
  content: string;
  photo?: string;
  video?: string;
}

export interface Message {
  id: string;
  user: User;
  content: string;
  date: Date;
  replies?: Message[];
  reactions?: Reaction[];
  photo?: string;
  video?: string;
}

export interface Reaction {
  user: User;
  type: ReactionType;
}

export enum ReactionType {
  LIKE,
  CLAP,
  HEART,
  FUNNY,
  IDEA,
  CURIOUS,
}
