<mat-dialog-content>
  <div class="d-flex justify-content-between">
    <div class="section-title text-primary mb-4">Add contact</div>
    <button mat-icon-button mat-dialog-close color="primary" class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <app-company-contact-person-form></app-company-contact-person-form>
  <button mat-flat-button color="primary" class="btn btn-sm my-1 me-3" mat-dialog-close (click)="addContact()" data-cy="modal-recruitment-add-contact-button">Add contact</button>
  <button
    mat-stroked-button
    color="primary"
    class="btn btn-sm my-1"
    mat-dialog-close
  >
    Cancel
  </button>
</mat-dialog-content>
