import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgrxFormsModule } from 'ngrx-forms';
import { ProfileDialogComponent } from 'src/app/shared/components/profile-dialog/profile-dialog.component';
import { SharedFormsModule } from 'src/app/shared/forms/shared.forms.module';
import { SharedModule } from '../../shared/shared.module';
import { EditExperienceComponent } from './profile-edit/edit-experience/edit-experience.component';
import { EditIndustriesComponent } from './profile-edit/edit-industries/edit-industries.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { ProfileViewComponent } from './profile-view/profile-view.component';
import { EditDigitalAreasComponent } from './profile-edit/edit-digital-areas/edit-digital-areas.component';
import { EditEducationComponent } from './profile-edit/edit-education/edit-education.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProfileSocialMediaComponent } from './profile-social-media/profile-social-media.component';
import { EditWorkExperienceComponent } from './profile-edit/edit-work-experience/edit-work-experience.component';
import { EditEducationListComponent } from './profile-edit/edit-education-list/edit-education-list.component';
import { EditAvatarComponent } from './profile-edit/edit-avatar/edit-avatar.component';
import { ProfileOverviewComponent } from './profile-overview/profile-overview.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'view',
    pathMatch: 'full',
  },
  {
    path: 'view',
    component: ProfileViewComponent,
  },
  {
    path: 'edit',
    component: ProfileEditComponent,
  },
  {
    path: 'photo',
    component: EditAvatarComponent,
  },
  {
    path: 'edit/experience',
    component: EditExperienceComponent,
  },
  {
    path: 'edit/workexperience',
    component: EditWorkExperienceComponent,
  },
  {
    path: 'edit/education',
    component: EditEducationComponent,
  },
  {
    path: 'edit/educations',
    component: EditEducationListComponent,
  },
  {
    path: 'edit/industries/background',
    component: EditIndustriesComponent,
    data: { isBackground: true },
  },
  {
    path: 'edit/industries/interest',
    component: EditIndustriesComponent,
    data: { isBackground: false },
  },
  {
    path: 'edit/digitalAreas/background',
    component: EditDigitalAreasComponent,
    data: { isBackground: true },
  },
  {
    path: 'edit/digitalAreas/interest',
    component: EditDigitalAreasComponent,
    data: { isBackground: false },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    NgrxFormsModule,
    SharedModule,
    SharedFormsModule,
    MatTooltipModule,
  ],
  declarations: [
    ProfileViewComponent,
    ProfileEditComponent,
    ProfileDialogComponent,
    EditExperienceComponent,
    EditIndustriesComponent,
    EditDigitalAreasComponent,
    EditEducationComponent,
    ProfileSocialMediaComponent,
    EditWorkExperienceComponent,
    EditEducationListComponent,
    EditAvatarComponent,
    ProfileOverviewComponent,
  ],
  exports: [ProfileOverviewComponent, EditAvatarComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class ProfileModule {}
