import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {User} from 'src/app/ngrx-store/components/user/user.model';
import {AppRoutes} from '../../../app-routing.model';
import {Connection} from '../../../ngrx-store/components/connection/connection.model';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss'],
})
export class ProfileDialogComponent {
  @Input() user: User | undefined = undefined;

  public connection: Connection | undefined = undefined;
  public appRoutes = AppRoutes;

  constructor(@Inject(MAT_DIALOG_DATA) data: { profile: User, connection: Connection | undefined, requestSent: boolean | undefined },
              private userService: UserService) {
    this.user = data.profile;
    this.connection = data.connection;
    if (!this.connection && this.user) {
      this.userService.getConnection(this.user).subscribe(conn => this.connection = conn);
    }
  }
}
