import { ConnectionState } from './connection.model';
import { createReducer, on } from '@ngrx/store';
import {
  sendConnectionSuccess,
  getConnectionsSuccess,
  denyConnectionSuccess,
  removeConnectionSuccess,
  getPendingConnectionsSuccess,
} from './connection.actions';

export const initialConnectionState: ConnectionState = {
  connections: undefined,
  pendingConnections: [],
};

export const connectionReducer = createReducer<ConnectionState>(
  initialConnectionState,
  on(sendConnectionSuccess, (state, action) => {
    return {
      ...state,
      connections: state.connections?.filter((c) => c.user.id !== action.connection.user.id).concat(action.connection),
      pendingConnections: state.pendingConnections?.filter((c) => c.user.id !== action.connection.user.id),
    };
  }),
  on(getConnectionsSuccess, (state, action) => {
    return { ...state, connections: action.connections };
  }),
  on(getPendingConnectionsSuccess, (state, action) => {
    return {...state, pendingConnections: action.connections};
  }),
  on(denyConnectionSuccess, (state, action) => {
    return {
      ...state,
      connections: state.connections?.filter((c) => c.user.id !== action.userId),
      pendingConnections: state.pendingConnections?.filter((c) => c.user.id !== action.userId),
    };
  }),
  on(removeConnectionSuccess, (state, action) => {
    return {
      ...state,
      connections: state.connections?.filter((c) => c.user.id !== action.userId),
    };
  })
);
