import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectIndustries } from 'src/app/ngrx-store/components/userEntities/userEntities.selectors';
import {
  addIndustryBackground,
  addIndustryInterest,
  removeIndustryBackground,
  removeIndustryInterest,
} from 'src/app/ngrx-store/components/userForms/onboardingFindMatch/forms.findMatch.actions';
import {
  submitEditAttributeListForm,
} from 'src/app/ngrx-store/components/userForms/profileEditAttributeList/forms.editAttributeList.actions';
import { AttributeListForm } from 'src/app/ngrx-store/components/userForms/profileEditAttributeList/forms.editAttributeList.model';
import { selectEditAttributeListForm } from 'src/app/ngrx-store/components/userForms/userForms.selectors';
import { getIndustries } from 'src/app/ngrx-store/requests/attributes/get-userEntities.actions';
import { AppRoutes } from 'src/app/app-routing.model';
import { postUser } from 'src/app/ngrx-store/requests/user/post-user/post-user.actions';

@Component({
  selector: 'app-edit-industries',
  templateUrl: './edit-industries.component.html',
})
export class EditIndustriesComponent implements OnInit, AfterViewInit {
  public isBackground = true;

  public attributeListForm$ = this.store.select(selectEditAttributeListForm);
  public industries$ = this.store.select(selectIndustries);

  constructor(private readonly store: Store<AppState>, private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.data.subscribe((data) => {
      this.isBackground = data.isBackground;
    });
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.store.dispatch(getIndustries());
  }

  public submitForm(formValues: AttributeListForm) {
    this.store.dispatch(submitEditAttributeListForm({ formValues }));
    this.store.dispatch(postUser());
    this.router.navigateByUrl(`${AppRoutes.PROFILE}/view`);
  }

  public cancel() {
    this.router.navigateByUrl(`${AppRoutes.PROFILE}/view`);
  }

  public addIndustry(industry: string) {
    if (this.isBackground) {
      this.addIndustryBackground(industry);
    } else {
      this.addIndustryInterest(industry);
    }
  }

  public removeIndustry(industry: string) {
    if (this.isBackground) {
      this.removeIndustryBackground(industry);
    } else {
      this.removeIndustryInterest(industry);
    }
  }

  private addIndustryBackground(industry: string) {
    this.store.dispatch(addIndustryBackground({ industry: { name: industry } }));
  }

  private removeIndustryBackground(industry: string) {
    this.store.dispatch(removeIndustryBackground({ industry: { name: industry } }));
  }

  private addIndustryInterest(industry: string) {
    this.store.dispatch(addIndustryInterest({ industry: { name: industry } }));
  }

  private removeIndustryInterest(industry: string) {
    this.store.dispatch(removeIndustryInterest({ industry: { name: industry } }));
  }
}
