import { createAction, props } from '@ngrx/store';
import { Connection } from './connection.model';
import { SearchFilter } from '../match/match.model';

export enum ConnectionActionTypes {
  INITIATE_CONNECTION_REQUEST = '[Connection] Initiate connection request',
  SEND_CONNECTION_REQUEST = '[Connection] Send connection request',
  SEND_CONNECTION_SUCCESS = '[Connection] Send connection succes',
  SEND_CONNECTION_REQUEST_ERROR = '[Connection] Send connection request error',
  CONNECTION_ACCEPT = '[Connection] Accept connection request',
  CONNECTION_DENY = '[Connection] Deny connection request',
  CONNECTION_DENY_SUCCESS = '[Connection] Deny connection request succes',
  GET_CONNECTIONS = '[Connection] Get all accepted connections',
  GET_CONNECTIONS_SUCCESS = '[Connection] Get connections success',
  GET_CONNECTIONS_ERROR = '[Connection] Get connections error',
  GET_PENDING_CONNECTIONS = '[Connection] Get pending connections',
  GET_PENDING_CONNECTIONS_SUCCESS = '[Connection] Get pending connections success',
  GET_PENDING_CONNECTIONS_ERROR = '[Connection] Get pending connections error',
  REMOVE_CONNECTION = '[Connection] Remove connection',
  REMOVE_CONNECTION_SUCCESS = '[Connection] Remove connection success',
}

export const initiateConnectionRequest = createAction(
  ConnectionActionTypes.INITIATE_CONNECTION_REQUEST,
  props<{ id: string }>()
);
export const sendConnectionRequest = createAction(
  ConnectionActionTypes.SEND_CONNECTION_REQUEST,
  props<{ id: string; message: string }>()
);
export const acceptConnection = createAction(ConnectionActionTypes.CONNECTION_ACCEPT, props<{ userId: string }>());
export const denyConnection = createAction(ConnectionActionTypes.CONNECTION_DENY, props<{ userId: string }>());
export const denyConnectionSuccess = createAction(
  ConnectionActionTypes.CONNECTION_DENY_SUCCESS,
  props<{ userId: string }>()
);
export const removeConnection = createAction(ConnectionActionTypes.REMOVE_CONNECTION, props<{ userId: string }>());
export const removeConnectionSuccess = createAction(
  ConnectionActionTypes.REMOVE_CONNECTION_SUCCESS,
  props<{ userId: string }>()
);

export const sendConnectionSuccess = createAction(
  ConnectionActionTypes.SEND_CONNECTION_SUCCESS,
  props<{ connection: Connection }>()
);
export const sendConnectionError = createAction(
  ConnectionActionTypes.SEND_CONNECTION_REQUEST_ERROR,
  props<{ error: string }>()
);

export const getConnections = createAction(
  ConnectionActionTypes.GET_CONNECTIONS,
  props<{ filter: SearchFilter | undefined }>()
);
export const getConnectionsSuccess = createAction(
  ConnectionActionTypes.GET_CONNECTIONS_SUCCESS,
  props<{ connections: Connection[] }>()
);
export const getConnectionsError = createAction(
  ConnectionActionTypes.GET_CONNECTIONS_ERROR,
  props<{ error: string }>()
);

export const getPendingConnections = createAction(
  ConnectionActionTypes.GET_PENDING_CONNECTIONS
);
export const getPendingConnectionsSuccess = createAction(
  ConnectionActionTypes.GET_PENDING_CONNECTIONS_SUCCESS,
  props<{ connections: Connection[] }>()
);
export const getPendingConnectionsError = createAction(
  ConnectionActionTypes.GET_PENDING_CONNECTIONS_ERROR,
  props<{ error: string }>()
);
