<div *ngIf="company" class="d-flex justify-content-center mb-2">
  <div class="follow-info-button"></div>
  <button
    *ngIf="!isFollowing"
    (click)="followToggle()"
    class="btn-sm mt-3"
    mat-flat-button
    color="primary"
  ><div class="d-flex justify-content-center align-items-center">
    <span>{{"Follow"}}</span>
  </div>
  </button>
  <button
  *ngIf="isFollowing"
  (click)="followToggle()"
  class="btn-sm mt-3"
  mat-stroked-button
  color="primary"
  ><div class="d-flex justify-content-center align-items-center">
    <span>{{"Unfollow"}}</span>
  </div>
</button>
<div class="follow-info-button d-flex align-items-center justify-content-center mx-2 mt-3">
  <mat-icon color="primary" matTooltip="You will receive a notification for new events if you follow us." #tooltip="matTooltip" (click)="tooltip.toggle()">info_outline</mat-icon>
</div>
</div>
