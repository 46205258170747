import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CompanyService } from '../../../../services/company.service';
import { deleteCompanyId, deleteCompanyIdError, deleteCompanyIdSuccess } from '../delete-company/delete-company.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { getCompanies } from '../get-company/get-company.actions';

@Injectable()
export class DeleteCompanyEffects {
  constructor(
    private actions$: Actions,
    private companyService: CompanyService,
  ) {
  }

  deleteCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCompanyId),
      switchMap(({id}) => {
        return this.companyService.deleteCompany(id).pipe(
          map(() => deleteCompanyIdSuccess()),
          catchError((error: HttpErrorResponse) => of(deleteCompanyIdError({error: error.message})))
        );
      })
    )
  );

  afterCompanyIsDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCompanyIdSuccess),
      map(() => getCompanies())
    ));
}
