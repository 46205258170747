import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StorageService } from '../../../services/storage.service';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { uploadImageError } from './storage.actions';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  addCompanyLogo,
  addCompanyLogoSuccess,
  addCompanyPhoto,
  addCompanyPhotoSuccess,
} from '../../components/companyForms/companyDetails/forms.companyDetails.actions';
import {
  addCompanyContactPersonPhoto,
  addCompanyContactPersonPhotoSuccess,
} from '../../components/companyForms/companyContactPerson/forms.companyContactPerson.actions';
import {
  addCompanyEventPhoto,
  addCompanyEventPhotoSuccess,
} from '../../components/companyForms/companyEvent/forms.companyEvent.actions';
import {
  addPhotoToVacancy,
  addPhotoToVacancySuccess,
} from '../../components/vacancyForm/vacancyForm.actions';

@Injectable()
export class StorageEffects {
  constructor(
    private actions$: Actions,
    private storageService: StorageService
  ) {}

  addCompanyLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCompanyLogo),
      switchMap((action) => {
        return this.storageService.uploadImage(action.logo).pipe(
          map((referenceUrl) => {
            return addCompanyLogoSuccess({ reference: referenceUrl.reference });
          }),
          catchError((error: HttpErrorResponse) =>
            of(uploadImageError({ error: error.message }))
          )
        );
      })
    )
  );

  addCompanyPhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCompanyPhoto),
      switchMap((action) => {
        return this.storageService.uploadImage(action.photo).pipe(
          map((referenceUrl) => {
            return addCompanyPhotoSuccess({
              companyPhoto: { photoUrl: referenceUrl.reference },
            });
          }),
          catchError((error: HttpErrorResponse) =>
            of(uploadImageError({ error: error.message }))
          )
        );
      })
    )
  );

  addCompanyContactPersonPhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCompanyContactPersonPhoto),
      switchMap((action) => {
        return this.storageService.uploadImage(action.photo).pipe(
          map((referenceUrl) => {
            return addCompanyContactPersonPhotoSuccess({
              referenceUrl: referenceUrl.reference,
            });
          }),
          catchError((error: HttpErrorResponse) =>
            of(uploadImageError({ error: error.message }))
          )
        );
      })
    )
  );

  addCompanyEventPhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCompanyEventPhoto),
      switchMap((action) => {
        return this.storageService.uploadImage(action.photo).pipe(
          map((referenceUrl) => {
            return addCompanyEventPhotoSuccess({
              referenceUrl: referenceUrl.reference,
            });
          }),
          catchError((error: HttpErrorResponse) =>
            of(uploadImageError({ error: error.message }))
          )
        );
      })
    )
  );

  uploadAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addPhotoToVacancy),
      exhaustMap(({ photo }) => {
        return this.storageService.uploadImage(photo).pipe(
          map(({ reference }) => addPhotoToVacancySuccess({ reference })),
          catchError((error: HttpErrorResponse) => {
            return of(uploadImageError({ error: error.message }));
          })
        );
      })
    )
  );
}
