<mat-dialog-content>
  <div class="mb-4 d-flex justify-content-between">
    <h1 class="text-primary">Add RightBrains to home screen</h1>

    <button mat-icon-button mat-dialog-close color="primary">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <p>
    To make the most out of the RightBrains platform, add RightBrains to your
    home screen following the instructions below.
  </p>

  <ng-container *ngIf="isAndroid; else ios">
    <img src="../../../../../assets/images/a2hs_android.png" class="col-12" />
  </ng-container>
  <ng-template #ios>
    <img src="../../../../../assets/images/a2hs_ios.png" class="col-12" />
  </ng-template>
</mat-dialog-content>
