import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { CompanyContactDialogComponent } from 'src/app/shared/company-contact-dialog/company-contact-dialog.component';
import { CompanyDetailsBaseComponent } from '../company-details-base/company-details-base.component';
import { CompanyContactPerson } from 'src/app/ngrx-store/components/company/company.model';
import { deleteContact } from 'src/app/ngrx-store/components/company/company.actions';
import { postDetailsCompany } from 'src/app/ngrx-store/requests/company/post-company/post-company.actions';
import { selectCompany } from '../../../../ngrx-store/components/company/company.selectors';

@Component({
  selector: 'app-company-details-contact-person',
  templateUrl: './company-details-contact-person.component.html',
})
export class CompanyDetailsContactPersonComponent extends CompanyDetailsBaseComponent {
  company$ = this.store.select(selectCompany);

  constructor(
    protected readonly store: Store<AppState>,
    private dialog: MatDialog
  ) {
    super(store);
  }

  toggleModify(modify: boolean): void {
    if (modify) {
      this.dialog.open(CompanyContactDialogComponent);
    }
  }

  removeContact(contact: CompanyContactPerson) {
    this.store.dispatch(deleteContact({ contact }));
    this.store.dispatch(postDetailsCompany());
  }
}
