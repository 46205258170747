<mat-dialog-content *ngIf="createVacancyForm$ | async as form" class="dialog">
  <div class="d-flex justify-content-between">
    <div class="section-title text-primary mb-4">Add vacancy</div>
    <button
      mat-icon-button
      mat-dialog-close
      color="primary"
      class="close-button"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <app-input-field
    [form]="form"
    [formControl]="form.controls.jobTitle"
    label="Job title"
    requiredErrorMessage="Please fill in this field"
    data-cy="vacancy-job-title"
  ></app-input-field>
  <app-input-text-area
    [form]="form"
    [formControl]="form.controls.summary"
    label="What to expect (1000 characters)"
    requiredErrorMessage="Please fill in this field"
    data-cy="vacancy-summary"
  ></app-input-text-area>
  <app-input-text-area
    [form]="form"
    [formControl]="form.controls.idealCandidate"
    label="Ideal candidate (1000 characters)"
    requiredErrorMessage="Please fill in this field"
    data-cy="vacancy-ideal-candidate"
  ></app-input-text-area>
  <app-input-select
    [form]="form"
    [formControl]="form.controls.type"
    [selectionList]="vacancyTypes"
    [valueList]="vacancyTypeValues"
    label="Vacancy type"
    requiredErrorMessage="Please fill in this field"
    data-cy="vacancy-role-type"
  ></app-input-select>
  <app-input-field
    [form]="form"
    [formControl]="form.controls.startDate"
    label="Start date job"
    placeholder="I.e. as soon a possible"
    requiredErrorMessage="Please fill in this field"
    data-cy="vacancy-start-date"
  ></app-input-field>
  <app-input-date
    [form]="form"
    [formControl]="form.controls.expirationDate"
    [mode]="0"
    label="Expiration vacancy (Deleted from list)"
    requiredErrorMessage="Please fill in this field"
    data-cy="vacancy-expiration-date"
  ></app-input-date>
  <app-input-select
    [form]="form"
    [formControl]="form.controls.careerPhase"
    [selectionList]="careerPhases"
    [valueList]="careerPhaseValues"
    label="Career phase"
    requiredErrorMessage="Please fill in this field"
    data-cy="vacancy-career-phase"
  ></app-input-select>
  <app-input-country
    [form]="form"
    [formControl]="form.controls.country"
    label="Country"
    requiredErrorMessage="Please fill in this field"
    data-cy="vacancy-country"
  ></app-input-country>
  <app-input-field
    [form]="form"
    [formControl]="form.controls.city"
    label="Location (city)"
    requiredErrorMessage="Please fill in this field"
    data-cy="vacancy-city"
  ></app-input-field>
  <app-input-list-builder
    class="col-12"
    *ngIf="digitalAreas$ | async as digitalAreas"
    (addInput)="addDigitalArea($event)"
    (removeInput)="removeDigitalArea($event)"
    [inputList]="digitalAreas"
    [selectedItems]="
      form.controls.requiredDigitalAreas.value | attributeToString
    "
    [inputPlaceholder]="'Select one or more'"
    label="Required digital knowledge (max. 5)"
    data-cy="vacancy-required-digital-areas"
  ></app-input-list-builder>
  <app-input-list-builder
    class="col-12"
    *ngIf="industries$ | async as industries"
    (addInput)="addIndustry($event)"
    (removeInput)="removeIndustry($event)"
    [inputList]="industries"
    [selectedItems]="form.controls.requiredIndustries.value | attributeToString"
    [inputPlaceholder]="'Select one or more'"
    label="Required industry experience (max. 5)"
    data-cy="vacancy-required-industries"
  ></app-input-list-builder>
  <div class="d-flex justify-content-center">
    <button
      [disabled]="form.isInvalid"
      mat-flat-button
      color="primary"
      class="btn btn-sm my-3 me-3"
      mat-dialog-close
      (click)="addVacancy(form.value)"
      data-cy="modal-update-vacancy-button"
    >
      {{ isEdit ? "Save changes" : "Add vacancy" }}
    </button>
    <button
      (click)="clearVacancyForm()"
      mat-stroked-button
      color="primary"
      class="btn btn-sm my-3"
      mat-dialog-close
    >
      Cancel
    </button>
  </div>
</mat-dialog-content>
