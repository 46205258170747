export interface OnboardingState {
  activeStep: OnboardingStep;
  stepperIndex: number;
  questionIndex: number;
  questions: Question[];
}

export enum OnboardingStep {
  Stepper,
  HomeScreen,
}

export enum Question {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
  Q5 = 'Q5',
  Q6 = 'Q6',
  Q7 = 'Q7',
}

export const onboardingStepperIndexMap: string[] = [];
onboardingStepperIndexMap[1] = 'detail';
onboardingStepperIndexMap[2] = 'background-interests';
onboardingStepperIndexMap[3] = 'matching';
onboardingStepperIndexMap[4] = 'slackconnect';
