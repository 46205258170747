import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/ngrx-store/components/user/user.model';
import { AppRoutes } from 'src/app/app-routing.model';
import { Connection } from 'src/app/ngrx-store/components/connection/connection.model';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  public user: User;
  public connection: Connection;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.userService.getUserById(id).subscribe((user) => {
        if (user) {
          this.user = user;
          this.userService
            .getConnection(this.user)
            .subscribe((connection) => (this.connection = connection));
        } else {
          this.router.navigateByUrl(`/${AppRoutes.DASHBOARD}`);
        }
      });
    } else {
      this.router.navigateByUrl(`/${AppRoutes.DASHBOARD}`);
    }
  }
}
