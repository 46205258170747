import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import {
  selectDigitalAreas,
  selectIndustries,
} from 'src/app/ngrx-store/components/userEntities/userEntities.selectors';
import { ExperienceOption } from 'src/app/ngrx-store/components/userForms/userForms.model';
import { selectVacancyFilterForm } from 'src/app/ngrx-store/components/vacancyForm/vacancyForm.selectors';
import { clearFilter } from '../../../ngrx-store/components/vacancyForm/vacancyFilter/forms.vacancyFilter.actions';
import {
  addDigitalAreaToFilter,
  removeDigitalAreaFromFilter,
  addIndustryToFilter,
  removeIndustryFromFilter,
} from '../../../ngrx-store/components/vacancyForm/vacancyFilter/forms.vacancyFilter.actions';

@Component({
  selector: 'app-vacancy-filter',
  templateUrl: './vacancy-filter.component.html',
  styleUrls: ['./vacancy-filter.component.scss'],
})
export class VacancyFilterComponent {
  public filter$ = this.store.select(selectVacancyFilterForm);
  public digitalAreas$ = this.store.select(selectDigitalAreas);
  public industries$ = this.store.select(selectIndustries);
  public careerPhases = Object.values(ExperienceOption);
  public careerPhaseValues = [...Array(this.careerPhases.length).keys()];

  constructor(private store: Store<AppState>) {}

  public addDigitalArea(digitalArea: string) {
    this.store.dispatch(
      addDigitalAreaToFilter({ digitalArea: { name: digitalArea } })
    );
  }

  public removeDigitalArea(digitalArea: string) {
    this.store.dispatch(
      removeDigitalAreaFromFilter({ digitalArea: { name: digitalArea } })
    );
  }

  public addIndustry(industry: string) {
    this.store.dispatch(addIndustryToFilter({ industry: { name: industry } }));
  }

  public removeIndustry(industry: string) {
    this.store.dispatch(
      removeIndustryFromFilter({ industry: { name: industry } })
    );
  }

  public clearFilter() {
    this.store.dispatch(clearFilter());
  }
}
