import { Component, Input } from '@angular/core';
import { Education } from 'src/app/ngrx-store/components/user/user.model';

@Component({
  selector: 'app-profile-education',
  templateUrl: './profile-education.component.html',
  styleUrls: ['./profile-education.component.scss']
})
export class ProfileEducationComponent {

  @Input() educations: Education[];
  @Input() self = false;

  constructor() { }

}
