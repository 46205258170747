<div class="container position-relative color-background-primary">
  <ng-container *ngIf="storyState$ | async as storyState">
    <div *ngIf="storyState.stories.length > 0">
      <div class="must-read-container">
        <h2 class="align-center text-white">Stories</h2>
        <a class='back-arrow color-white' [routerLink]="'/'">
          <span></span>
          <mat-icon>arrow_back</mat-icon>
        </a>
        <div class="bevel"></div>

        <div class="align-center">
          <div class="underlined-title purple-background">
            <p class="text-white">Must-read</p>
            <div></div>
          </div>
        </div>
        <div class="preview-must-read">
          <div class="align-center">
            <img class="preview-image" src="{{storyState.stories[0].headerImageUrl}}"/>
          </div>
          <div class="preview-date">
            {{storyState.stories[0].publicationDate | date:'d LLLL y' }}
          </div>
          <div class="preview-title">
            {{storyState.stories[0].title }}
          </div>
          <div class="preview-content">
            <div [innerHTML]="storyState.stories[0].previewDescription"></div>
          </div>
          <div class="align-center">
            <a [routerLink]="'/' + AppRoutes.STORY + '/' + storyState.stories[0].id">
              <button
                class="btn-smn must-read-button btn-sm"
                mat-flat-button
                color="primary">
                Read story
              </button>
            </a>
          </div>
        </div>
      </div>
      <div class="stories-container stories-background-color">
        <div class="align-center">
          <p class="more-inspiring">
            More inspiring stories
          </p>
        </div>
        <app-storycards [viewAllButton]="false" [stories]="storyState.stories.slice(1)"></app-storycards>
      </div>
    </div>
  </ng-container>
</div>

