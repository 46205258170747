import {createReducer, on} from '@ngrx/store';
import {EventState} from './event.model';
import {getEventsSuccess, getPreviousEventsSuccess} from '../../requests/event/get-event.actions';

export const initialEventState: EventState = {
  events: [],
  previousEvents: []
};

export const eventReducer = createReducer<EventState>(
  initialEventState,

  on(getEventsSuccess,
    (state, action) => ({
      ...state,
      events: action.events
    })
  ),

  on(getPreviousEventsSuccess,
    (state, action) => ({
      ...state,
      previousEvents: action.previousEvents
    })
  )
);
