import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MatchService } from 'src/app/services/match.service';
import { getMentees, getMenteesError, getMenteesSuccess } from './get-mentee.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { NoFilter } from 'src/app/ngrx-store/components/match/match.model';

@Injectable()
export class GetMenteesEffects {
  constructor(private actions$: Actions, private matchService: MatchService) {}

  getMentees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMentees),
      switchMap((a) => {
        return this.matchService.getMentees(a.filter || NoFilter, a.limit, a.bestMatches).pipe(
          map((matches) => getMenteesSuccess({ matches })),
          catchError((error: HttpErrorResponse) => of(getMenteesError({ error: error.message })))
        );
      })
    )
  );
}
