import {Component, ElementRef, ViewChild} from '@angular/core';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent {
  currentSite = window.location.origin;

  @ViewChild('url') url: ElementRef;
  @ViewChild('copyButton') copyButton: ElementRef;

  copyLink() {
    navigator.clipboard.writeText(this.currentSite).then(() => this.copyButton.nativeElement.innerHTML = 'Copied')
      .catch(e => console.error(e));
  }

}
