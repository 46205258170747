import { NgModule } from '@angular/core';
import { CompanySignupComponent } from './company-onboarding/company-signup/company-signup.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgrxFormsModule } from 'ngrx-forms';
import { SharedFormsModule } from 'src/app/shared/forms/shared.forms.module';
import { CompanyOnboardingComponent } from './company-onboarding/company-onboarding.component';
import { CompanyCreateComponent } from './company-onboarding/company-create/company-create.component';
import { CompanyOwnedComponent } from './company-owned/company-owned.component';
import { ToastModule } from 'primeng/toast';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'view',
    pathMatch: 'full',
  },
  {
    path: 'view',
    component: CompanyOwnedComponent,
  },
  {
    path: 'signup',
    component: CompanyOnboardingComponent,
  },
];

@NgModule({
  declarations: [CompanyCreateComponent, CompanySignupComponent, CompanyOnboardingComponent, CompanyOwnedComponent],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgrxFormsModule,
    SharedFormsModule,
    ToastModule
  ]
})
export class CompanyModule { }
