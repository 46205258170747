import { Component, Input } from '@angular/core';
import { Industry } from 'src/app/ngrx-store/components/user/user.model';

@Component({
  selector: 'app-profile-industries',
  templateUrl: './profile-industries.component.html',
  styleUrls: ['./profile-industries.component.scss']
})
export class ProfileIndustriesComponent{

  @Input() industryBackgrounds: Industry[];
  @Input() industryInterests: Industry[];
  @Input() self = false;

  constructor() { }

}
