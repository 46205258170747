<form [ngrxFormState]="form" autocomplete="off">
  <ng-container class="row">
    <div [class.invalid]="formControl.isTouched && formControl.isInvalid">
      <mat-form-field class="col-12 p-0" floatLabel="always">
        <mat-label>{{ label }}</mat-label>
        <div class="input-container">
          <input
            matInput
            placeholder="{{ placeholder }}"
            type="{{ type }}"
            value="{{ value }}"
            [ngrxFormControlState]="formControl"
            autocomplete="off"
          />
          <mat-icon
            *ngIf="tooltipText"
            color="primary"
            [matTooltip]="tooltipText"
            #tooltip="matTooltip"
            (click)="tooltip.toggle()"
            >info_outline</mat-icon
          >
        </div>

        <mat-error *ngIf="!!formControl.errors?.required">
          {{ requiredErrorMessage }}
        </mat-error>
        <mat-error *ngIf="!!formControl.errors?.pattern">
          {{ patternErrorMessage }}
        </mat-error>
        <mat-error *ngIf="formControl.errors?.email">
          {{ emailErrorMessage }}
        </mat-error>
      </mat-form-field>
    </div>
  </ng-container>
</form>
