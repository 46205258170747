import { Component, Input, OnInit } from '@angular/core';
import { NgrxFormElementDirective } from '../ngrx-form-element/ngrx-form-element.directive';

@Component({
  selector: 'app-input-text-area',
  templateUrl: './input-text-area.component.html',
})
export class InputTextAreaComponent
  extends NgrxFormElementDirective
  implements OnInit
{
  @Input() type = 'text';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() minRows = 5;
  @Input() maxRows = 10;

  @Input() requiredErrorMessage = '';
  @Input() maxLengthErrorMessage = '';
  @Input() emailErrorMessage = '';

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
