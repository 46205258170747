import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectCreateCompanyForm } from 'src/app/ngrx-store/components/companyForms/companyForms.selector';
import { submitCreateCompanyForm } from 'src/app/ngrx-store/components/companyForms/createCompany/forms.createCompany.actions';
import { CreateCompanyForm } from 'src/app/ngrx-store/components/companyForms/createCompany/forms.createCompany.model';
import { postNewCompany } from 'src/app/ngrx-store/requests/company/post-company/post-company.actions';
import { selectCompanyCreationDetails } from 'src/app/ngrx-store/components/company/company.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.scss']
})
export class CompanyCreateComponent implements OnDestroy {
  public loading = false;
  public errorMessage?: string;

  public createCompanyForm$ = this.store.select(selectCreateCompanyForm);
  public companyCreationDetails$ = this.store.select(selectCompanyCreationDetails);
  private subscriptions = new Subscription();

  constructor(private readonly store: Store<AppState>) {
    const sub = this.companyCreationDetails$.subscribe((company) => {
      if ( company.companyError !== undefined && company.companyError.length > 0) {
        this.loading = false;
        this.errorMessage = 'Access code does not exist or is already used';
      }
    });
    this.subscriptions.add(sub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public submitForm(formValues: CreateCompanyForm) {
    this.loading = true;
    this.store.dispatch(submitCreateCompanyForm({ formValues }));
    this.store.dispatch(postNewCompany());
  }
}

