import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import { Education } from '../ngrx-store/components/education/education.model';

@Injectable()
export class EducationService {
  constructor(
    protected http: HttpClient
  ) {}

  public getEducations(): Observable<Education[]> {
    return this.http.get<Education[]>(`${environment.apiBaseUrl}educations`);
  }
}
