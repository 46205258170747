import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { NgrxFormsModule } from 'ngrx-forms';

import { CareerOpportunitiesComponent } from './career-opportunities.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

const routes: Routes = [
  {
    path: '',
    component: CareerOpportunitiesComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, NgrxFormsModule, MatProgressBarModule],
  declarations: [CareerOpportunitiesComponent],
})
export class CareerOpportunitiesModule {}
