<ng-container *ngIf="attributeListForm$ | async as form">
  <div class="row col-12">
    <p class="col mb-3 font-weight-bold">{{ isBackground ? 'Digital expertise' : 'Digital areas of interest' }}</p>
    <app-input-list-builder
      class="col-12 mb-3"
      *ngIf="digitalAreas$ | async as digitalAreas"
      (addInput)="addDigitalArea($event)"
      (removeInput)="removeDigitalArea($event)"
      [selectedItems]="
        isBackground
          ? (form.controls.digitalAreaBackgrounds.value | attributeToString)
          : (form.controls.digitalAreaInterests.value | attributeToString)
      "
      [inputPlaceholder]="'Select one or more'"
      [inputList]="digitalAreas"
      data-cy="profile-digital-area-select"
    ></app-input-list-builder>
  </div>
  <div class="row col-12 justify-content-center">
    <button class="btn-xs mx-2" mat-flat-button color="primary" (click)="submitForm(form.value)">
      Done
    </button>
    <button class="btn-xsmx-2" mat-stroked-button color="primary" (click)="cancel()">
      Cancel
    </button>
  </div>
</ng-container>
