<div *ngIf="companyDetailsForm$ | async as form">
  <app-company-details-header
    *ngIf="!isSignUp"
    title="Introduction"
    [canEdit]="!company"
    [modify]="modify"
    (onModify)="toggleModify($event)"
    data-cy="company-introduction-title"
  ></app-company-details-header>

  <div *ngIf="company; else self">
    {{ company.introduction }}
  </div>

  <ng-template #self>
    <app-input-text-area
      *ngIf="modify || isSignUp"
      [form]="form"
      [formControl]="form.controls.introduction"
      [minRows]="2"
      [label]="isSignUp ? 'Short introduction to company' : ''"
      placeholder="This brief text appears on the user's dashboard. (max. 200 characters)"
      requiredErrorMessage="Please fill in this field"
      maxLengthErrorMessage="Text should contain no more than 200 characters"
    ></app-input-text-area>

    <div *ngIf="!modify && !isSignUp">
      {{ form.controls.introduction.value }}
    </div>
  </ng-template>
</div>
