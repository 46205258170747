<div *ngIf="companyDetailsForm$ | async as form">
  <div *ngIf="showLine" class="bevel"></div>
  <table class="w-100">
    <tr>
      <td>
        <p class="section-title pt-4" [class.mb-0]="!paddingBottom">{{ title }}</p>
      </td>
      <td *ngIf="canEdit">
        <div *ngIf="!modify" (click)="toggleModify()" class="pt-4 float-end edit-link" data-cy="company-edit-pencil-button">
          {{ editText }} <img class="edit-pencil" src="../../../../assets/images/dashboard/pencil.svg">
        </div>
        <div *ngIf="modify && form.isValid" (click)="toggleModify()" class="pt-4 float-end edit-link d-flex align-items-end" data-cy="company-save-button">
          <span class="pe-1">Save</span> <mat-icon class="edit-pencil">save_alt</mat-icon>
        </div>
      </td>
    </tr>
  </table>
</div>
