import { AppRoutes } from './app-routing.model';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { EducationModule } from './pages/education/education.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { OnboardingGuardService } from './services/onboarding.guard.service';

const routes: Routes = [
  {
    path: '',
    canActivate: [],
    children: [
      {
        path: AppRoutes.ONBOARDING,
        loadChildren: () =>
          import('./pages/onboarding/onboarding.module').then(
            (m) => m.OnboardingModule
          ),
        canActivate: [MsalGuard]
      },
      {
        path: AppRoutes.STORIES,
        loadChildren: () =>
          import('./pages/stories/stories.module').then((m) => m.StoriesModule),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.STORY,
        loadChildren: () =>
          import('./pages/story/story.module').then((m) => m.StoryModule),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.EVENTS,
        loadChildren: () =>
          import('./pages/events/events.module').then((m) => m.EventsModule),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.EDUCATION,
        component: EducationModule,
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.EVENT,
        loadChildren: () =>
          import('./pages/event/event.module').then((m) => m.EventModule),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.NOTIFICATIONS,
        loadChildren: () =>
          import('./pages/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.PROFILE,
        loadChildren: () =>
          import('./pages/profile/profile.module').then((m) => m.ProfileModule),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.CHATS,
        loadChildren: () =>
          import('./pages/chats/chats.module').then((m) => m.ChatsModule),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.MATCHES,
        loadChildren: () =>
          import('./pages/matches/matches.module').then((m) => m.MatchesModule),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.CONNECTIONS,
        loadChildren: () =>
          import('./pages/connections/connections.module').then(
            (m) => m.ConnectionsModule
          ),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.SETTINGS,
        loadChildren: () =>
          import('./pages/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.ADMIN,
        loadChildren: () =>
          import('./pages/admin-panel/admin-panel.module').then(
            (m) => m.AdminPanelModule
          ),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.DASHBOARD,
        component: DashboardComponent,
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.COMPANY,
        loadChildren: () =>
          import('./pages/company/company.module').then((m) => m.CompanyModule),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.COMPANIES,
        loadChildren: () =>
          import(
            './pages/career-opportunities/career-opportunities.module'
          ).then((m) => m.CareerOpportunitiesModule),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.USER,
        loadChildren: () =>
          import('./pages/user/user.module').then((m) => m.UserModule),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.VACANCIES,
        loadChildren: () =>
          import('./pages/vacancies/vacancies.module').then(
            (m) => m.VacanciesModule
          ),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.BLOGITEMS,
        loadChildren: () =>
          import('./pages/blogitems/blogitems.module').then((m) => m.BlogitemsModule),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: AppRoutes.BLOGITEM,
        loadChildren: () =>
          import('./pages/blogitem/blogitem.module').then((m) => m.BlogitemModule),
        canActivate: [OnboardingGuardService, MsalGuard]
      },
      {
        path: 'auth',
        component: MsalRedirectComponent
      },
      {
        path: '**',
        redirectTo: AppRoutes.DASHBOARD,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
