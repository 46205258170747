import {HttpErrorResponse} from '@angular/common/http';
import {createAction, props} from '@ngrx/store';
import {User} from 'src/app/ngrx-store/components/user/user.model';
import {SearchFilter} from 'src/app/ngrx-store/components/match/match.model';

export enum GetMenteeActionTypes {
  GET = '[Match] Get mentees',
  SUCCESS = '[Match] Get mentees success',
  ERROR = '[Match] Get mentees error',
}

export const getMentees = createAction(GetMenteeActionTypes.GET, props<{
  filter: SearchFilter | undefined,
  limit: number | undefined,
  bestMatches: boolean
}>());
export const getMenteesSuccess = createAction(GetMenteeActionTypes.SUCCESS, props<{ matches: User[] }>());
export const getMenteesError = createAction(GetMenteeActionTypes.ERROR, props<{ error: string }>());
