import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss']
})
export class ButtonToggleComponent {
  @Output() clicked: EventEmitter<boolean> = new EventEmitter();
  @Input() isToggleChecked = false;
  @Input() confirmText = 'Yes';
  @Input() declineText = 'No';

  public clickedButton = false;

  constructor() { }

  public onClick(value: boolean) {
    this.clickedButton = true;
    this.clicked.emit(value);
  }
}
