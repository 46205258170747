import { Component, OnInit } from '@angular/core';
import {AppState} from '../../ngrx-store/app.state';
import {Store} from '@ngrx/store';
import { AppRoutes } from 'src/app/app-routing.model';
import {getBlogItems} from '../../ngrx-store/requests/blogitem/blogitem.actions';
import {selectBlogItem} from '../../ngrx-store/components/blogitem/blogitem.selectors';

@Component({
  selector: 'app-blogitems',
  templateUrl: './blogitems.component.html',
  styleUrls: ['./blogitems.component.scss']
})
export class BlogitemsComponent implements OnInit {
  public blogItemState$ = this.store.select(selectBlogItem);
  public AppRoutes = AppRoutes;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.store.dispatch(getBlogItems());
  }
}
