import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { NgrxFormsModule } from 'ngrx-forms';
import { SharedFormsModule } from 'src/app/shared/forms/shared.forms.module';
import { ToastModule } from 'primeng/toast';
import {BlogitemsComponent} from './blogitems.component';

const routes: Routes = [
  {
    path: '',
    component: BlogitemsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, NgrxFormsModule, SharedFormsModule, ToastModule],
  declarations: [BlogitemsComponent],
})
export class BlogitemsModule {}
