import {HttpErrorResponse} from '@angular/common/http';
import {createAction, props} from '@ngrx/store';
import {User} from 'src/app/ngrx-store/components/user/user.model';
import {SearchFilter} from 'src/app/ngrx-store/components/match/match.model';

export enum GetMembersActionTypes {
  GET = '[Match] Get members',
  SUCCESS = '[Match] Get members success',
  ERROR = '[Match] Get members error',
  CLEAR = '[Match] Get members clear'
}

export const getMembers = createAction(GetMembersActionTypes.GET, props<{
  filter: SearchFilter | undefined,
  limit: number | undefined,
  bestMatches: boolean
}>());
export const getMembersSuccess = createAction(GetMembersActionTypes.SUCCESS, props<{ matches: User[] }>());
export const getMembersError = createAction(GetMembersActionTypes.ERROR, props<{ error: string }>());
export const getMembersClear = createAction(GetMembersActionTypes.CLEAR);
