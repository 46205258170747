import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControlState, setValue } from 'ngrx-forms';
import { MatSelectChange } from '@angular/material/select';
import { NgrxFormElementDirective } from '../ngrx-form-element/ngrx-form-element.directive';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
})
export class InputSelectComponent
  extends NgrxFormElementDirective
  implements OnInit
{
  @Output() selectionChange: EventEmitter<MatSelectChange> = new EventEmitter();

  @Input() label = '';
  @Input() selectionList: any[] = [];
  @Input() valueList?: any[];
  @Input() emptyOption: string;
  @Input() requiredErrorMessage = '';
  @Input() key?: string;

  constructor() {
    super();
  }
  ngOnInit(): void {
    if (this.emptyOption) {
      this.formControl = setValue(this.formControl, this.emptyOption);
    }
  }
}
