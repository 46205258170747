import { NgModule } from '@angular/core';
import { ButtonToggleComponent } from './button-toggle/button-toggle.component';
import { MaterialModule } from '../material';
import { CommonModule } from '@angular/common';
import { InputListBuilderComponent } from './input-list-builder/input-list-builder.component';
import { NgrxFormsModule } from 'ngrx-forms';
import { InputFieldComponent } from './input-field/input-field.component';
import { InputSelectComponent } from './input-select/input-select.component';
import { InputDateComponent } from './input-date/input-date.component';
import { InputTextAreaComponent } from './input-text-area/input-text-area.component';
import { InputCountryComponent } from './input-country/input-country.component';
import { InputAutoCompleteComponent } from 'src/app/shared/forms/input-auto-complete/input-auto-complete.component';
import { InputCheckboxComponent } from './input-checkbox/input-checkbox.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InputFileComponent } from './input-file/input-file.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { A11yModule } from '@angular/cdk/a11y';
import { MatSelectModule} from '@angular/material/select';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    MatTooltipModule,
    NgrxFormsModule,
    NgxMatSelectSearchModule,
    NgMultiSelectDropDownModule.forRoot(),
    A11yModule,
    MatSelectModule,
  ],
  exports: [
    ButtonToggleComponent,
    InputListBuilderComponent,
    InputFieldComponent,
    InputTextAreaComponent,
    InputSelectComponent,
    InputDateComponent,
    InputCountryComponent,
    InputAutoCompleteComponent,
    InputCheckboxComponent,
    InputFileComponent,
  ],
  declarations: [
    ButtonToggleComponent,
    InputListBuilderComponent,
    InputFieldComponent,
    InputTextAreaComponent,
    InputSelectComponent,
    InputDateComponent,
    InputCountryComponent,
    InputAutoCompleteComponent,
    InputCheckboxComponent,
    InputFileComponent,
  ],
})
export class SharedFormsModule {}
