import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectCreateMessageForm } from '../../../ngrx-store/components/messageForm/messageForms.selector';
import { CreateMessageForm } from '../../../ngrx-store/components/messageForm/messageForm.model';
import { submitMessage } from '../../../ngrx-store/components/messageForm/messageForm.actions';
import { postMessage } from 'src/app/ngrx-store/requests/message/message.actions';
import { selectMessageImage } from '../../../ngrx-store/components/message/message.selectors';
import {
  addMessagePhoto,
  deleteMessagePhoto,
} from '../../../ngrx-store/requests/message/message.actions';

@Component({
  selector: 'app-add-message',
  templateUrl: './add-message.component.html',
  styleUrls: ['./add-message.component.scss'],
})
export class AddMessageComponent {
  @Input() threadId?: string;

  public showVideo = false;
  public createMessageForm$ = this.store.select(selectCreateMessageForm);
  public messageImage$ = this.store.select(selectMessageImage);

  constructor(private readonly store: Store<AppState>) {}

  public submitForm(formValues: CreateMessageForm) {
    this.store.dispatch(submitMessage({ formValues }));
    this.store.dispatch(postMessage({ parentMessageId: this.threadId }));
  }

  public addPhoto(photo: File) {
    this.showVideo = false;
    this.store.dispatch(addMessagePhoto({ photo }));
  }

  public deletePhoto() {
    this.store.dispatch(deleteMessagePhoto());
  }

  public toggleVideo() {
    this.showVideo = !this.showVideo;
  }
}
