import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import {
  clearExperienceForm,
  submitExperienceForm,
} from 'src/app/ngrx-store/components/userForms/profileEditExperience/forms.experience.actions';
import {
  ExperienceForm,
  JobType,
} from 'src/app/ngrx-store/components/userForms/profileEditExperience/forms.experience.model';
import { selectExperienceForm } from 'src/app/ngrx-store/components/userForms/userForms.selectors';
import { postUser } from 'src/app/ngrx-store/requests/user/post-user/post-user.actions';
import { AppRoutes } from 'src/app/app-routing.model';
import {Actions, ofType} from '@ngrx/effects';
import {getCompanyList, getCompanyListSuccess} from '../../../../ngrx-store/requests/user/get-user/get-user.actions';

@Component({
  selector: 'app-edit-experience',
  templateUrl: './edit-experience.component.html',
  styleUrls: ['./edit-experience.component.scss'],
})
export class EditExperienceComponent implements OnInit, AfterViewInit {
  public companies: string[] = [];
  public editExperienceForm$ = this.store.select(selectExperienceForm);
  public jobType = JobType;

  constructor(private readonly store: Store<AppState>, private router: Router, private actions$: Actions) {
    this.actions$.pipe(ofType(getCompanyListSuccess)).subscribe((companyList) => this.companies = companyList.companies);
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.store.dispatch(getCompanyList());
  }

  public submitForm(formValues: ExperienceForm) {
    this.store.dispatch(submitExperienceForm({ formValues }));
    this.store.dispatch(postUser());
    this.router.navigateByUrl(`${AppRoutes.PROFILE}/edit/workexperience`);
  }

  public cancel() {
    this.store.dispatch(clearExperienceForm());
    this.router.navigateByUrl(`${AppRoutes.PROFILE}/edit/workexperience`);
  }
}
