import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectCompanyDetailsForm } from 'src/app/ngrx-store/components/companyForms/companyForms.selector';

@Component({
  selector: 'app-company-details-header',
  templateUrl: './company-details-header.component.html',
})
export class CompanyDetailsHeaderComponent {
  @Input() title = '';
  @Input() editText = 'Edit';
  @Input() canEdit = false;
  @Input() modify = false;
  @Input() showLine = true;
  @Input() paddingBottom = true;
  @Output() onModify: EventEmitter<boolean> = new EventEmitter();

  public companyDetailsForm$ = this.store.select(selectCompanyDetailsForm);

  constructor(protected readonly store: Store<AppState>) {}

  public toggleModify() {
    this.onModify.emit(!this.modify);
  }
}
