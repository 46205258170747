import {createAction, props} from '@ngrx/store';
import {BlogItem} from '../../components/blogitem/blogitem.model';

export enum GetBlogItemActionTypes {
  GET = '[BlogItem] Get BlogItem',
  SUCCESS = '[BlogItem] Get BlogItem success',
  ERROR = '[BlogItem] Get BlogItem error',
}

export const getBlogItems = createAction(GetBlogItemActionTypes.GET);
export const getBlogItemsSuccess = createAction(GetBlogItemActionTypes.SUCCESS,
                                                    props<{ stories: BlogItem[] }>());
export const getBlogItemsError = createAction(GetBlogItemActionTypes.ERROR, props<{ error: string }>());
