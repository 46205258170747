import { Component, Input } from '@angular/core';
import { RightbrainsEvent } from '../../../ngrx-store/components/event/event.model';
import { AppRoutes } from 'src/app/app-routing.model';

@Component({
  selector: 'app-eventcards',
  templateUrl: './eventcards.component.html',
  styleUrls: ['./eventcards.component.scss']
})
export class EventcardsComponent {
  public AppRoutes = AppRoutes;
  @Input() events: RightbrainsEvent[];
  @Input() viewAllButton = true;
  @Input() showPartnerLabel = true;
}
