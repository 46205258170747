import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { ProfileDetailsComponent } from '../pages/profile/profile-details/profile-details.component';
import { ProfileDigitalAreasComponent } from '../pages/profile/profile-digital-areas/profile-digital-areas.component';
import { ProfileEducationComponent } from '../pages/profile/profile-education/profile-education.component';
import { ProfileIndustriesComponent } from '../pages/profile/profile-industries/profile-industries.component';
import { ProfileWorkExperienceComponent } from '../pages/profile/profile-work-experience/profile-work-experience.component';
import { ConnectionListComponent } from './components/connection-list/connection-list.component';
import { ConnectionRequestComponent } from './components/connection-request/connection-request.component';
import { FilterUserListComponent } from './components/filter-user-list/filter-user-list.component';
import { PendingConnectionsComponent } from './components/pending-connections/pending-connections.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { MaterialModule } from './material';
import { StepperModule } from './stepper/stepper.module';
import { AttributeToStringPipe } from './utilities/pipes/attributeString.pipe';
import { ExperienceToStringPipe } from './utilities/pipes/experience.pipe';
import { SafePipe } from './utilities/pipes/safe.pipe';
import { UserRoleToStringPipe } from './utilities/pipes/userRoles.pipe';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { SharedFormsModule } from './forms/shared.forms.module';
import { A2hsButtonComponent } from './components/a2hs-button/a2hs-button.component';
import { A2hsDialogComponent } from './components/a2hs-button/a2hs-dialog/a2hs-dialog.component';
import { ShortDatePipe } from './utilities/pipes/shortDate.pipe';
import { BestmatchesComponent } from './components/bestmatches/bestmatches.component';
import { ProfilecardComponent } from './components/profilecard/profilecard.component';
import { ShareDialogComponent } from './components/share-dialog/share-dialog.component';
import { EventcardsComponent } from './components/eventcards/eventcards.component';
import { EventcardComponent } from './components/eventcard/eventcard.component';
import { StorycardsComponent } from './components/storycards/storycards.component';
import { StorycardComponent } from './components/storycard/storycard.component';
import { BlogitemcardComponent } from './components/blogitemcard/blogitemcard.component';
import { BlogitemcardsComponent } from './components/blogitemcards/blogitemcards.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MenubarComponent } from './components/menubar/menubar.component';
import { CompanySocialMediaComponent } from './components/company-details/company-social-media/company-social-media.component';
import { CompanycardsComponent } from './components/companycards/companycards.component';
import { CompanycardComponent } from './components/companycard/companycard.component';
import { CompanyContactPersonFormComponent } from './components/company-contact-person-form/company-contact-person-form.component';
import { CompanyDialogComponent } from './components/company-dialog/company-dialog.component';
import { CompanyDetailsComponent } from './components/company-details/company-details.component';
import { CompanyContactDialogComponent } from './company-contact-dialog/company-contact-dialog.component';
import {
  NgbCarouselModule,
  NgbCollapseModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CompanyDetailsNameComponent } from './components/company-details/company-details-name/company-details-name.component';
import { CompanyDetailsIntroductionComponent } from './components/company-details/company-details-introduction/company-details-introduction.component';
import { CompanyDetailsAboutUsComponent } from './components/company-details/company-details-about-us/company-details-about-us.component';
import { CompanyDetailsCareersComponent } from './components/company-details/company-details-careers/company-details-careers.component';
import { CompanyDetailsHeaderComponent } from './components/company-details/company-details-header/company-details-header.component';
import { CompanyDetailsBaseComponent } from './components/company-details/company-details-base/company-details-base.component';
import { CompanyDetailsIndustriesComponent } from './components/company-details/company-details-industries/company-details-industries.component';
import { CompanyDetailsCountryComponent } from './components/company-details/company-details-country/company-details-country.component';
import { CompanyDetailsContactPersonComponent } from './components/company-details/company-details-contact-person/company-details-contact-person.component';
import { CompanyDetailsMediaComponent } from './components/company-details/company-details-media/company-details-media.component';
import { CompanyAmbassadorsComponent } from './components/company-details/company-ambassadors/company-ambassadors.component';
import { CompanyEventFormComponent } from './components/company-event-form/company-event-form.component';
import { EventDialogComponent } from './components/event-dialog/event-dialog.component';
import { CompanyEventsComponent } from './components/company-details/company-events/company-events.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CompanyDetailsFollowComponent } from './components/company-details/company-details-follow/company-details-follow.component';
import { CompanySelectionDialogComponent } from './components/company-selection-dialog/company-selection-dialog.component';
import { EducationcardsComponent } from './components/educationcards/educationcards.component';
import { EducationcardComponent } from './components/educationcard/educationcard.component';
import { ConnectionListItemComponent } from './components/connection-list/connection-list-item/connection-list-item.component';
import { SafeVideoPipe } from './utilities/pipes/safe-video.pipe';
import { VacancyDialogComponent } from './components/vacancy-dialog/vacancy-dialog.component';
import { VacancycardsComponent } from './components/vacancycards/vacancycards.component';
import { VacancycardComponent } from './components/vacancycard/vacancycard.component';
import { VacancyFilterComponent } from './components/vacancy-filter/vacancy-filter.component';
import { CompanyVacanciesComponent } from './components/company-details/company-vacancies/company-vacancies.component';
import { CreateVacancyDialogComponent } from './components/create-vacancy-dialog/create-vacancy-dialog.component';
import { VacancyTypeToStringPipe } from './utilities/pipes/vacancy-type.pipe';
import { ConnectionRequestDialogComponent } from './components/connection-request-dialog/connection-request-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatListModule,
    MaterialModule,
    StepperModule,
    SharedFormsModule,
    MatProgressBarModule,
    NgbCarouselModule,
    MatTooltipModule,
    NgbCollapseModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    UserListComponent,
    SafePipe,
    SafeVideoPipe,
    ConnectionRequestComponent,
    PendingConnectionsComponent,
    FilterUserListComponent,
    AttributeToStringPipe,
    UserRoleToStringPipe,
    ExperienceToStringPipe,
    ShortDatePipe,
    VacancyTypeToStringPipe,
    ConnectionListComponent,
    ProfileDigitalAreasComponent,
    ProfileWorkExperienceComponent,
    ProfileEducationComponent,
    ProfileIndustriesComponent,
    ProfileDetailsComponent,
    A2hsButtonComponent,
    A2hsDialogComponent,
    MenubarComponent,
    BestmatchesComponent,
    ProfilecardComponent,
    EventcardsComponent,
    StorycardsComponent,
    BlogitemcardsComponent,
    EventcardsComponent,
    CompanycardComponent,
    CompanycardsComponent,
    CompanyAmbassadorsComponent,
    CompanySocialMediaComponent,
    CompanyContactPersonFormComponent,
    CompanyDialogComponent,
    CompanyDetailsComponent,
    CompanyDetailsNameComponent,
    CompanyDetailsIntroductionComponent,
    CompanyDetailsAboutUsComponent,
    CompanyDetailsCareersComponent,
    CompanyDetailsHeaderComponent,
    CompanyDetailsIndustriesComponent,
    CompanyDetailsCountryComponent,
    CompanyDetailsContactPersonComponent,
    CompanyDetailsMediaComponent,
    CompanyEventsComponent,
    CompanySelectionDialogComponent,
    EducationcardsComponent,
    VacancycardsComponent,
    VacancyFilterComponent,
  ],
  declarations: [
    UserListComponent,
    SafePipe,
    SafeVideoPipe,
    ConnectionRequestComponent,
    PendingConnectionsComponent,
    FilterUserListComponent,
    AttributeToStringPipe,
    UserRoleToStringPipe,
    ExperienceToStringPipe,
    ShortDatePipe,
    VacancyTypeToStringPipe,
    ConnectionListComponent,
    ProfileDigitalAreasComponent,
    ProfileWorkExperienceComponent,
    ProfileEducationComponent,
    ProfileIndustriesComponent,
    MenubarComponent,
    ProfileDetailsComponent,
    ConfirmDialogComponent,
    A2hsButtonComponent,
    A2hsDialogComponent,
    BestmatchesComponent,
    ProfilecardComponent,
    ShareDialogComponent,
    EventcardsComponent,
    EventcardComponent,
    StorycardsComponent,
    StorycardComponent,
    BlogitemcardsComponent,
    BlogitemcardComponent,
    EventcardsComponent,
    EventcardComponent,
    CompanyAmbassadorsComponent,
    CompanySocialMediaComponent,
    CompanycardsComponent,
    CompanycardComponent,
    CompanyContactPersonFormComponent,
    CompanyDialogComponent,
    CompanyDetailsComponent,
    CompanyContactDialogComponent,
    CompanyDetailsNameComponent,
    CompanyDetailsIntroductionComponent,
    CompanyDetailsAboutUsComponent,
    CompanyDetailsCareersComponent,
    CompanyDetailsHeaderComponent,
    CompanyDetailsBaseComponent,
    CompanyDetailsIndustriesComponent,
    CompanyDetailsCountryComponent,
    CompanyDetailsContactPersonComponent,
    CompanyDetailsMediaComponent,
    CompanyEventFormComponent,
    EventDialogComponent,
    CompanyEventsComponent,
    CompanyDetailsFollowComponent,
    CompanySelectionDialogComponent,
    EducationcardsComponent,
    EducationcardComponent,
    ConnectionListItemComponent,
    VacancyDialogComponent,
    VacancycardsComponent,
    VacancycardComponent,
    CompanyVacanciesComponent,
    CreateVacancyDialogComponent,
    VacancyFilterComponent,
    ConnectionRequestDialogComponent,
  ],
})
export class SharedModule {}
