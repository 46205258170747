import { Component, OnInit } from '@angular/core';
import {AppState} from '../../ngrx-store/app.state';
import {Store} from '@ngrx/store';
import {selectStory} from '../../ngrx-store/components/story/story.selectors';
import {getStories} from '../../ngrx-store/requests/story/story.actions';
import { AppRoutes } from 'src/app/app-routing.model';

@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss']
})
export class StoriesComponent implements OnInit {
  public storyState$ = this.store.select(selectStory);
  public AppRoutes = AppRoutes;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.store.dispatch(getStories());
  }
}
