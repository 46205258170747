import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { selectCompany } from 'src/app/ngrx-store/components/company/company.selectors';
import {
  addCompanyLogo,
  addCompanyLogoSuccess,
  addCompanyPhoto,
  deleteCompanyPhoto,
} from 'src/app/ngrx-store/components/companyForms/companyDetails/forms.companyDetails.actions';
import { CompanyDetailsBaseComponent } from '../company-details-base/company-details-base.component';

@Component({
  selector: 'app-company-details-media',
  templateUrl: './company-details-media.component.html',
  styleUrls: ['./company-details-media.component.scss'],
})
export class CompanyDetailsMediaComponent extends CompanyDetailsBaseComponent {
  company$ = this.store.select(selectCompany);

  constructor(protected readonly store: Store<AppState>) {
    super(store);
  }

  public addPhoto(photo: File) {
    this.store.dispatch(addCompanyPhoto({ photo }));
  }

  public deletePhoto(photoUrl: string) {
    this.store.dispatch(deleteCompanyPhoto({ companyPhoto: { photoUrl } }));
  }

  public addLogo(logo: File) {
    this.store.dispatch(addCompanyLogo({ logo }));
  }

  public deleteLogo() {
    this.store.dispatch(addCompanyLogoSuccess({ reference: '' }));
  }

  get safeWebsite() {
    return this.company?.websiteUrl.replace(/(^\w+:|^)\/\//, '');
  }
}
