<div class="container">
<ng-container *ngIf="editExperienceForm$ | async as form">
  <app-input-select
    [form]="form"
    [formControl]="form.controls.jobType"
    [label]="'Job status'"
    [selectionList]="form.controls.jobTypes.value"
    [requiredErrorMessage]="'Selection is required'"
  ></app-input-select>
  <app-input-field
    [form]="form"
    [formControl]="form.controls.position"
    [label]="'Job title'"
    [placeholder]="'Job title'"
    [requiredErrorMessage]="'Job title is required'"
  ></app-input-field>

  <app-input-auto-complete
    [form]="form"
    [formControl]="form.controls.company"
    [inputList]="companies"
    [label]="'Company'"
    [placeholder]="'Enter company name'"
    [requiredErrorMessage]="'Company name is required'"
  ></app-input-auto-complete>
  <app-input-date
    [form]="form"
    [formControl]="form.controls.startDate"
    [label]="'Start date'"
    [requiredErrorMessage]="'Please enter a valid date'"
  ></app-input-date>
  <app-input-date
    *ngIf="form.controls.jobType.value === jobType.Previous_Job"
    [form]="form"
    [formControl]="form.controls.endDate"
    [label]="'End date'"
    [requiredErrorMessage]="'Please enter a valid date'"
  ></app-input-date>
  <app-input-text-area
    [form]="form"
    [formControl]="form.controls.description"
    [label]="'Description'"
  ></app-input-text-area>
  <div class="d-flex justify-content-center">
    <button
      mat-flat-button
      color="primary"
      class="btn-sm mx-2"
      [disabled]="form.isInvalid"
      (click)="submitForm(form.value)"
    >
      Save changes
    </button>
    <button
      mat-stroked-button
      color="primary"
      class="btn-xs"
      (click)="cancel()"
    >
      Cancel
    </button>
  </div>
</ng-container>
</div>
