<div class="position-relative container">
  <h2 class="text-primary align-center">Notifications</h2>

  <a class='back-arrow' [routerLink]="'/'">
    <span></span>
    <mat-icon>arrow_back</mat-icon>
  </a>
  <div class="bevel"></div>

  <button id="clear-notifications" mat-flat-button color="primary" (click)="clearNotifications()">
    Clear
  </button>

  <div
    *ngFor="let notification of notifications$ | async"
    class="notification"
    (click)="openNotification(notification)"
  >
    <h4>{{ notification.text }}</h4>
    <p>{{ notification.description }}</p>
    <hr/>
  </div>
</div>
