import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { User } from 'src/app/ngrx-store/components/user/user.model';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, ofType } from '@ngrx/effects';
import { selectConnections } from '../../../ngrx-store/components/connection/connection.selectors';
import {
  sendConnectionSuccess,
  initiateConnectionRequest,
  sendConnectionError,
} from 'src/app/ngrx-store/components/connection/connection.actions';
import { Connection } from 'src/app/ngrx-store/components/connection/connection.model';
import { getConnections } from '../../../ngrx-store/components/connection/connection.actions';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() users: User[] = [];
  @Input() maxCount = 9;

  loading = false;

  showCount = 9;
  shownUsers: User[] = [];

  errorSubscription: Subscription;
  pendingConnecting = false;

  connections$ = this.store.select(selectConnections);

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private snackbar: MatSnackBar,
    private actions$: Actions
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.users) {
      this.shownUsers = changes.users.currentValue.slice(0, this.showCount);
    }
  }

  showMore() {
    this.showCount = this.showCount + this.maxCount;
    this.shownUsers = this.users.slice(0, this.showCount);
  }

  ngOnInit(): void {
    this.showCount = this.maxCount;
    this.store.dispatch(getConnections({filter: undefined}));

    this.errorSubscription = this.actions$.pipe(ofType(sendConnectionError)).subscribe(({ error }) => {
      this.pendingConnecting = false;
      this.snackbar.open(
        'An error occured trying to send a connection request to this user. Please try again later',
        '',
        {
          duration: 5000,
          panelClass: ['failed-snackbar'],
        }
      );
    });
    this.errorSubscription.add(
      this.actions$.pipe(ofType(sendConnectionSuccess)).subscribe(() => {
        this.pendingConnecting = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.errorSubscription.unsubscribe();
  }

  connectWith(user: User, event: Event) {
    this.store.dispatch(initiateConnectionRequest({ id: user.id }));
    this.pendingConnecting = true;
  }

  hasConnection(user: User, connections: Connection[]) {
    return connections
    ? connections?.filter(c => c.user.id === user.id && !c.isPending).length > 0
    : false;
  }

  hasPendingConnection(user: User, connections: Connection[]) {
    return connections
    ? connections?.filter(c => c.user.id === user.id && c.isPending && c.isOutgoing).length > 0
    : false;
  }
}
