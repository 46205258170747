import { createAction, props } from '@ngrx/store';
import { Company } from 'src/app/ngrx-store/components/company/company.model';

export enum PostCompanyActionTypes {
  NEW_POST = '[Company] Post new company',
  NEW_SUCCESS = '[Company] Post new success',
  NEW_ERROR = '[Company] Post new error',
  NEW_CLEAR = '[Company] Post new clear',

  DETAILS_POST = '[Company] Post details company',
  DETAILS_SUCCESS = '[Company] Post details success',
  DETAILS_ERROR = '[Company] Post details error',
  DETAILS_CLEAR = '[Company] Post details clear',
}

export const postNewCompany = createAction(PostCompanyActionTypes.NEW_POST);
export const postNewCompanySuccess = createAction(
  PostCompanyActionTypes.NEW_SUCCESS,
  props<{ company: Company }>()
);
export const postNewCompanyError = createAction(
  PostCompanyActionTypes.NEW_ERROR,
  props<{ error: string }>()
);
export const postNewCompanyClear = createAction(
  PostCompanyActionTypes.NEW_CLEAR
);

export const postDetailsCompany = createAction(
  PostCompanyActionTypes.DETAILS_POST
);
export const postDetailsCompanySuccess = createAction(
  PostCompanyActionTypes.DETAILS_SUCCESS,
  props<{ company: Company }>()
);
export const postDetailsCompanyError = createAction(
  PostCompanyActionTypes.DETAILS_ERROR,
  props<{ error: string }>()
);
