import { createAction, props } from '@ngrx/store';
import { EducationForm } from './forms.education.model';
import { Education } from '../../user/user.model';

export enum EditEducationActionTypes {
  PREFILL = '[UserForms] Prefill edit education',
  CLEAR = '[UserForms] Clear edit education',
  SUBMIT = '[UserForms] Submit edit education',
}

export const prefillEducationForm = createAction(EditEducationActionTypes.PREFILL, props<{ education: Education, index: number }>());
export const submitEducationForm = createAction(EditEducationActionTypes.SUBMIT, props<{ formValues: EducationForm }>());
export const clearEducationForm = createAction(EditEducationActionTypes.CLEAR);
