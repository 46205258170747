import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { User } from 'src/app/ngrx-store/components/user/user.model';
import { MatDialog } from '@angular/material/dialog';
import { ProfileDialogComponent } from '../profile-dialog/profile-dialog.component';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, ofType } from '@ngrx/effects';
import {
  sendConnectionError,
  removeConnectionSuccess,
  removeConnection,
  initiateConnectionRequest,
} from 'src/app/ngrx-store/components/connection/connection.actions';
import { Connection } from 'src/app/ngrx-store/components/connection/connection.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-connection-list',
  templateUrl: './connection-list.component.html',
  styleUrls: ['./connection-list.component.scss'],
})
export class ConnectionListComponent implements OnInit, OnDestroy {
  @Input() connections: Connection[] = [];
  @Input() noConnections: User[] = [];
  @Input() self?: User;
  @Input() page = 0;
  @Output() showMore: EventEmitter<any> = new EventEmitter();

  loading = false;

  private subscriptions = new Subscription();
  pendingRemove = false;

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private snackbar: MatSnackBar,
    private actions$: Actions,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    const errorSubscription = this.actions$
      .pipe(ofType(sendConnectionError))
      .subscribe(({ error }) => {
        this.pendingRemove = false;
        this.snackbar.open(
          'An error occured trying to break the connection to this user. Please try again later',
          '',
          {
            duration: 5000,
            panelClass: ['failed-snackbar'],
          }
        );
      });
    this.subscriptions.add(errorSubscription);
    this.subscriptions.add(
      this.actions$.pipe(ofType(removeConnectionSuccess)).subscribe(() => {
        this.pendingRemove = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openProfile(profile: User, connection?: Connection) {
    if (!this.loading) {
      this.loading = true;

      this.userService.getUserById(profile.id).subscribe((data) => {
        this.loading = false;
        this.dialog.open(ProfileDialogComponent, {
          data: {
            profile: data,
            connection,
          },
        });
      });
    }
  }

  removeConnection(user: User) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to remove this connection?',
        callback: (confirmed: boolean) => {
          if (confirmed) {
            this.store.dispatch(removeConnection({ userId: user.id }));
            this.pendingRemove = true;
          }
        },
      },
    });
  }

  addConnection(user: User) {
    this.store.dispatch(initiateConnectionRequest({ id: user.id }));
  }
}
