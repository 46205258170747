<div
  id="loading-wrapper"
  class="container vh-100 d-flex align-items-center justify-content-center"
  *ngIf="loadingCount > 0; else loaded"
>
  <mat-spinner [diameter]="65"></mat-spinner>
</div>

<ng-template #loaded>
  <div
    *ngIf="onboardingModel$ | async as onboardingModel"
    style="position: relative; overflow-y: auto; overflow-x: hidden"
  >
    <div *ngIf="onboardingModel.stepperIndex != '2'" class="logout-link">
      <a (click)="logout()">Log out</a>
    </div>
    <div *ngIf="userModel$ | async as userModel">
      <app-horizontal-stepper
        linear
        #stepper
        [selectedIndex]="onboardingModel.stepperIndex"
        (selectionChange)="onStepChange($event)"
        [progressBarEnabled]="false"
      >
        <!-- =============== DETAILS STEP =============== -->
        <app-step
          [title]="'Personal details'"
          *ngIf="personalDetailsForm$ | async as personalDetailsForm"
          [completed]="personalDetailsForm.isValid"
        >
          <app-onboarding-step1
            (submitStep)="submitStep()"
            [submittingStep]="submittingStep"
          ></app-onboarding-step1>
        </app-step>

        <!-- =============== LETS FIND YOUR MATCH STEP =============== -->
        <app-step [completed]="true">
          <app-onboarding-step2
            (submitStep)="submitStep()"
          ></app-onboarding-step2>
        </app-step>

        <!-- =============== INTERESTS STEP =============== -->
        <app-step
          *ngIf="findMatchForm$ | async as findMatchForm"
          [completed]="onboardingModel.isLastQuestion"
        >
          <app-onboarding-step3
            (previousStepMatchFinder)="previousStepMatchFinder()"
            (submitStep3)="submitStep()"
            [submittingStep]="submittingStep"
          ></app-onboarding-step3>
        </app-step>

        <!-- =============== PERMISSIONS STEP =============== -->
        <app-step [title]="'Permissions'" [completed]="true">
          <app-onboarding-step4></app-onboarding-step4>
        </app-step>
      </app-horizontal-stepper>
    </div>
  </div>
</ng-template>
