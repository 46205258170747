import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {BlogItem} from '../ngrx-store/components/blogitem/blogitem.model';

@Injectable()
export class BlogItemService {
  constructor(
    protected http: HttpClient
  ) {}

  public getBlogItems(): Observable<BlogItem[]> {
    return this.http.get<BlogItem[]>(`${environment.apiBaseUrl}blog`);
  }

  getBlogItemById(id: string) {
    return this.http.get<BlogItem[]>(`${environment.apiBaseUrl}blog/${id}`);
  }
}
