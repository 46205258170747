import { createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';
import { VacancyState, Vacancy } from './vacancy.model';
import { selectCompanies } from '../company/company.selectors';
import { Company } from '../company/company.model';
import { User } from '../user/user.model';
import { selectUser } from '../user/user.selectors';

const selectVacancyState = (state: AppState) => state.vacancyState;

// these vacancies are already filtered on expiration in the backend
export const selectVacancies = createSelector(
  selectVacancyState,
  selectCompanies,
  selectUser,
  (state: VacancyState, companies?: Company[], user?: User) => {
    return state.vacancies
      .map((v) => {
        const interest = state.interests.find((i) => i.id === v.id);
        const company = companies?.find((c) => c.id === v.companyId);
        return { ...v, interested: interest?.interested, company, matchScore: vacancyMatchScore(v, user) };
      })
      .sort((a, b) => a.matchScore - b.matchScore);
  }
);

function vacancyMatchScore(vacancy: Vacancy, user?: User) {
  let count = 0;
  if (vacancy.careerPhase === user?.yearsExperience) {
    count += 1;
  }
  for (const digitalArea of vacancy.requiredDigitalAreas) {
    if (user?.userBackground?.digitalAreas.includes(digitalArea)) {
      count += 1;
    }
  }
  for (const industry of vacancy.requiredIndustries) {
    if (user?.userBackground?.industries.includes(industry)) {
      count += 1;
    }
  }
  return count;
}

export const selectVacanciesForCompanyId = (companyId?: string) =>
  createSelector(selectVacancies, (vacancies: Vacancy[]) => {
    if (!companyId) {
      return [];
    }

    return vacancies
      .filter(vacancy => vacancy.companyId === companyId)
      .sort(
        (v1, v2) =>
          new Date(v1.expirationDate).getTime() -
          new Date(v2.expirationDate).getTime()
      );
  });

export const selectVacanciesForCompanyIdAsAdmin = (companyId?: string) =>
  createSelector(selectVacancyState, ({vacanciesForCompany}) => {
    if (!companyId) {
      return [];
    }

    return vacanciesForCompany[companyId];
  });
