import { User } from '../user/user.model';

export interface MatchState {
  mentors: User[] | undefined;
  mentees: User[] | undefined;
  members: User[] | undefined;
}

export interface SearchFilter {
  educationNames?: string[] | undefined;
  industryInterestNames?: string[] | undefined;
  industryExperienceNames?: string[] | undefined;
  digitalAreaInterestNames?: string[] | undefined;
  digitalAreaExpertiseNames?: string[] | undefined;
  region?: string | undefined;
  workExperience?: number | undefined;
  name?: string | undefined;
  company?: string | undefined;
  country?: string | undefined;
  page?: number | undefined;
  profilePhoto?: boolean | undefined;
}

export const NoFilter: SearchFilter = {
  educationNames: undefined,
  industryInterestNames: undefined,
  industryExperienceNames: undefined,
  digitalAreaInterestNames: undefined,
  digitalAreaExpertiseNames: undefined,
  region: undefined,
  workExperience: undefined,
  name: undefined,
  company: undefined,
  country: undefined,
  page: undefined,
  profilePhoto: undefined
};
