import { createAction, props } from '@ngrx/store';
import { UserEntity } from '../../components/userEntities/userEntities.model';

export enum GetDigitalAreasActionTypes {
  GET = '[Digital Areas] Get Digital Areas',
  SUCCESS = '[Digital Areas] Get Digital Areas success',
  ERROR = '[Digital Areas] Get Digital Areas error',
  CLEAR = '[Digital Areas] Get Digital Areas clear'
}

export enum GetIndustriesActionTypes {
  GET = '[Industries] Get Industries',
  SUCCESS = '[Industries] Get Industries success',
  ERROR = '[Industries] Get Industries error',
  CLEAR = '[Industries] Get Industries clear'
}

export const getDigitalAreas = createAction(GetDigitalAreasActionTypes.GET);
export const getDigitalAreasSuccess = createAction(GetDigitalAreasActionTypes.SUCCESS, props<{ digitalAreas: UserEntity[] }>());
export const getDigitalAreasError = createAction(GetDigitalAreasActionTypes.ERROR, props<{ error: string }>());
export const getDigitalAreasClear = createAction(GetDigitalAreasActionTypes.CLEAR);


export const getIndustries = createAction(GetIndustriesActionTypes.GET);
export const getIndustriesSuccess = createAction(GetIndustriesActionTypes.SUCCESS, props<{ industries: UserEntity[] }>());
export const getIndustriesError = createAction(GetIndustriesActionTypes.ERROR, props<{ error: string }>());
export const getIndustriesClear = createAction(GetIndustriesActionTypes.CLEAR);
