import { Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ScannedActionsSubject, Store} from '@ngrx/store';
import { AppState } from 'src/app/ngrx-store/app.state';
import {
  selectActiveQuestionIndex,
  selectOnboardingModel,
} from 'src/app/ngrx-store/components/onboarding/onboarding.selectors';
import {
  selectDigitalAreas,
  selectIndustries,
} from 'src/app/ngrx-store/components/userEntities/userEntities.selectors';
import {
  addAreaBackground,
  addDigitalAreaInterest,
  addIndustryBackground,
  addIndustryInterest,
  removeAreaBackground,
  removeDigitalAreaInterest,
  removeIndustryBackground,
  removeIndustryInterest,
  setFindInRegion,
  setFindMoreExperienced,
  submitFindMatchForm,
} from 'src/app/ngrx-store/components/userForms/onboardingFindMatch/forms.findMatch.actions';
import { FindMatchForm } from 'src/app/ngrx-store/components/userForms/onboardingFindMatch/forms.findMatch.model';
import { UserRole } from 'src/app/ngrx-store/components/userForms/userForms.model';
import { selectFindMatchForm } from 'src/app/ngrx-store/components/userForms/userForms.selectors';
import {postUser} from 'src/app/ngrx-store/requests/user/post-user/post-user.actions';
import {Subscription} from 'rxjs';
import {MatStepper} from '@angular/material/stepper';
import {previousQuestion} from '../../../../ngrx-store/components/onboarding/onboarding.actions';
import {ofType} from '@ngrx/effects';

@Component({
  selector: 'app-onboarding-step3',
  templateUrl: './onboarding-step3.component.html',
  styleUrls: ['./onboarding-step3.component.scss'],
})
export class OnboardingStep3Component {
  public onboardingModel$ = this.store.select(selectOnboardingModel);
  public findMatchForm$ = this.store.select(selectFindMatchForm);
  public industries$ = this.store.select(selectIndustries);
  public digitalAreas$ = this.store.select(selectDigitalAreas);

  public roles = UserRole;
  public findMoreExperiencedSelected = false;
  public findInRegionSelected = false;

  subscriptions: Subscription;

  @ViewChild('findyourmatchstepper') stepper: MatStepper;
  @Input() submittingStep = false;
  @Output() submitStep3: EventEmitter<any> = new EventEmitter();
  @Output() previousStepMatchFinder: EventEmitter<any> = new EventEmitter();

  constructor(private actions$: ScannedActionsSubject,
              private store: Store<AppState>) {
    this.subscriptions = this.store.select(selectActiveQuestionIndex).subscribe(() => this.nextPage());
    this.subscriptions.add(this.actions$.pipe(ofType(previousQuestion)).subscribe(() => this.previous()));
  }

  private nextPage() {
    if (this.stepper && this.stepper.selectedIndex < this.stepper.steps.length - 1) {
      this.stepper.next();

      window.scroll({
        top: 0,
        left: 0
      });
    }
  }

  private previous() {
    this.stepper.previous();

    window.scroll({
      top: 0,
      left: 0
    });
  }

  public previousStep() {
    this.previousStepMatchFinder.emit();
  }

  public submitFormAndPost(formValues: FindMatchForm) {
    this.store.dispatch(submitFindMatchForm({ formValues }));
    this.store.dispatch(postUser());
    this.submitStep3.emit();
  }

  /* -------------------- DIGITAL AREAS -------------------- */
  public addDigitalAreaBackground(digitalArea: string) {
    this.store.dispatch(addAreaBackground({ digitalArea: { name: digitalArea } }));
  }

  public removeDigitalAreaBackground(digitalArea: string) {
    this.store.dispatch(removeAreaBackground({ digitalArea: { name: digitalArea } }));
  }

  public addDigitalAreaInterest(digitalArea: string) {
    this.store.dispatch(addDigitalAreaInterest({ digitalArea: { name: digitalArea } }));
  }

  public removeDigitalAreaInterest(digitalArea: string) {
    this.store.dispatch(removeDigitalAreaInterest({ digitalArea: { name: digitalArea } }));
  }

  /* -------------------- INDUSTRY -------------------- */
  public addIndustryBackground(industry: string) {
    this.store.dispatch(addIndustryBackground({ industry: { name: industry } }));
  }

  public removeIndustryBackground(industry: string) {
    this.store.dispatch(removeIndustryBackground({ industry: { name: industry } }));
  }

  public addIndustryInterest(industry: string) {
    this.store.dispatch(addIndustryInterest({ industry: { name: industry } }));
  }

  public removeIndustryInterest(industry: string) {
    this.store.dispatch(removeIndustryInterest({ industry: { name: industry } }));
  }

  /* -------------------- PREFERENCES -------------------- */
  public findMoreExperienced(value: boolean) {
    this.findMoreExperiencedSelected = true;
    this.store.dispatch(setFindMoreExperienced({ value }));
  }

  public findInRegion(value: boolean) {
    this.findInRegionSelected = true;
    this.store.dispatch(setFindInRegion({ value }));
  }
}
