import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { MatchesComponent } from './matches.component';

const routes: Routes = [
  {
    path: '',
    component: MatchesComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [MatchesComponent],
})
export class MatchesModule {}
