import {createAction, props} from '@ngrx/store';
import {OnboardingStep} from './onboarding.model';

export enum OnboardingActionTypes {
  SHOWSTEP = '[Onboarding] Show step',
  NEXTSTEP = '[Onboarding] Next step',
  STEPPERINDEX = '[Onboarding] Set stepper index',
  NEXTSTEPPERINDEX = '[Onboarding] Increase stepper index by one',
  NEXTQUESTION = '[Onboarding] Next question',
  PREVQUESTION = '[Onboarding] Previous question',
  FINISH_ONBOADING = '[Onboarding] Finish Onboarding',
  ONBOARDING_AGAIN = '[Onboarding] Do onboarding again',
}

export const showStep = createAction(OnboardingActionTypes.SHOWSTEP, props<{ step: OnboardingStep }>());
export const nextOnboardingStep = createAction(OnboardingActionTypes.NEXTSTEP);
export const setStepperIndex = createAction(OnboardingActionTypes.STEPPERINDEX, props<{ stepIndex: number }>());
export const nextStepperIndex = createAction(OnboardingActionTypes.NEXTSTEPPERINDEX);
export const nextQuestion = createAction(OnboardingActionTypes.NEXTQUESTION);
export const previousQuestion = createAction(OnboardingActionTypes.PREVQUESTION);

export const finishOnboarding = createAction(OnboardingActionTypes.FINISH_ONBOADING, props<{
  finished: boolean,
  newsletter: boolean,
  platform: boolean,
  shareData: boolean
}>());
export const onboardingAgain = createAction(OnboardingActionTypes.ONBOARDING_AGAIN);
