import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx-store/app.state';
import {
  selectUser,
  selectUserProfileCompleteness,
} from '../../ngrx-store/components/user/user.selectors';
import { AppRoutes } from 'src/app/app-routing.model';
import { selectEvent } from '../../ngrx-store/components/event/event.selectors';
import { getEvents } from '../../ngrx-store/requests/event/get-event.actions';
import { environment } from '../../../environments/environment';
import { selectCompanies } from 'src/app/ngrx-store/components/company/company.selectors';
import { getCompanies } from '../../ngrx-store/requests/company/get-company/get-company.actions';
import { getConnections } from '../../ngrx-store/components/connection/connection.actions';
import { selectVacancies } from '../../ngrx-store/components/vacancy/vacancy.selectors';
import {
  getVacancies,
  getVacanciesInterests,
} from 'src/app/ngrx-store/components/vacancy/vacancy.actions';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [MessageService]
})
export class DashboardComponent implements OnInit, OnDestroy {
  public AppRoutes = AppRoutes;
  public eventState$ = this.store.select(selectEvent);
  public userModel$ = this.store.select(selectUser);
  public companiesState$ = this.store.select(selectCompanies);
  public vacancyState$ = this.store.select(selectVacancies);
  public profileCompleteness = 0;

  public isEventsCollapsed = false;
  public isVacanciesCollapsed = false;

  public enableNonAdminFeatures = environment.featureToggles.enableNonAdminFeatures;

  private subscriptions = new Subscription();
  constructor(
    private store: Store<AppState>) {

    const userProfileCompletenessSubscription = this.store
      .select(selectUserProfileCompleteness)
      .subscribe((percentage) => (this.profileCompleteness = percentage));
    this.subscriptions.add(userProfileCompletenessSubscription);
  }

  ngOnInit(): void {
    this.store.dispatch(getCompanies());
    this.store.dispatch(getConnections({ filter: undefined }));
    this.store.dispatch(getEvents());
    this.store.dispatch(getVacancies());
    this.store.dispatch(getVacanciesInterests());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
