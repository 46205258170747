import { StoryState} from './story.model';
import {createReducer, on} from '@ngrx/store';
import {getStories, getStoriesSuccess} from '../../requests/story/story.actions';

export const initialStoryState: StoryState = {
  stories: []
};

export const storyReducer = createReducer<StoryState>(
  initialStoryState,

  on(getStories,
    (state, action) => ({
      ...state,
      stories: []
    })),

  on(getStoriesSuccess,
    (state, action) => ({
      ...state,
      stories: action.stories,
    })
  )
);
