import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { NgrxFormsModule } from 'ngrx-forms';
import { ToastModule } from 'primeng/toast';
import { EducationComponent } from './education.component';
import { SharedFormsModule } from '../../shared/forms/shared.forms.module';

const routes: Routes = [
  {
    path: '',
    component: EducationComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, NgrxFormsModule, SharedFormsModule, ToastModule],
  declarations: [EducationComponent],
})
export class EducationModule {}
