<h1 md-dialog-title>Add to company</h1>
<div md-dialog-content>
  <div *ngIf="companies$ | async as companies; else loading">
    <div class="d-flex justify-content-between align-items-center" *ngFor="let company of companies">
      <span class="font-weight-bold text-primary"> {{ company.name }} </span>
      <button
      mat-flat-button
      color="primary"
      class="btn-xs my-1 ms-4"
      mat-dialog-close
      (click)="callback(company.id, company.name)"
      data-cy="modal-company-selection-button"
    >
      +
    </button>
    </div>

  </div>
  <ng-template #loading>
    <mat-spinner
    mode="indeterminate"
    diameter="50"
  ></mat-spinner>
  </ng-template>
</div>
<div md-dialog-actions class="mt-3 d-flex justify-content-center">
  <button
    mat-stroked-button
    color="primary"
    class="btn-xs my-1"
    mat-dialog-close
  >
    Cancel
  </button>
</div>

