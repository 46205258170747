import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { AppRouteUrl } from './app-routing.model';
import {
  getUserError,
  getUserSuccess,
  checkNewNotifications,
  getUserUnauth,
} from './ngrx-store/requests/user/get-user/get-user.actions';
import { Store } from '@ngrx/store';
import { AppState } from './ngrx-store/app.state';
import {
  selectAdminPartner,
  selectUserNotificationCount,
} from './ngrx-store/components/user/user.selectors';
import { AppRoutes } from 'src/app/app-routing.model';
import { UserService } from './services/user.service';
import { MatStepper } from '@angular/material/stepper';
import { selectCompany } from './ngrx-store/components/company/company.selectors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  activePage: AppRouteUrl = 'profile';
  loadingUser = true;

  public AppRoutes = AppRoutes;
  public enableNonAdminFeatures = environment.featureToggles.enableNonAdminFeatures;

  @ViewChild('verticalstepper') stepper: MatStepper;
  admin: { isAdmin?: boolean; isPartner?: boolean };

  notificationCount$ = this.store.select(selectUserNotificationCount);
  company$ = this.store.select(selectCompany);

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private actions$: Actions,
    private userService: UserService,
    // Needs to be initialized on startup, do not remove
    private cdr: ChangeDetectorRef,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activePage = this.router.url.substring(1) as AppRouteUrl;
      }
    });
    this.actions$.pipe(ofType(getUserSuccess)).subscribe(() => {
      this.loadingUser = false;
      localStorage.setItem('norefresh', 'false');
      this.store.dispatch(checkNewNotifications());
    });
  }

  ngOnInit() {
    this.actions$.pipe(ofType(getUserError)).subscribe(({ error }) => {
      const refresh = localStorage.getItem('norefresh');
      localStorage.setItem('norefresh', 'true');

      if (refresh !== 'true') {
        window.location.reload();
      }
    });

    this.actions$.pipe(ofType(getUserUnauth)).subscribe(() => {
      this.userService.clear('/.auth/login').then();
    });

    this.store.select(selectAdminPartner).subscribe((a) => {
      this.admin = a;
      this.cdr.detectChanges();
    });
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  openMenu() {
    this.stepper.next();
  }

  closeMenu() {
    this.stepper.previous();
  }

  logout() {
    this.userService.logout();
  }
}
