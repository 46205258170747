<div *ngIf="profileEditForm$ | async as form">
  <table class="w-100" *ngIf="self">
    <tr>
      <td></td>
      <td>
        <a [routerLink]="'../edit'" class="float-end edit-link">
          Edit
          <img
            class="edit-pencil"
            src="../../../../assets/images/dashboard/pencil.svg"
          />
        </a>
      </td>
    </tr>
  </table>
  <div class="align-center w-100">
    <img
      *ngIf="user?.avatarRef; else noAvatar"
      [src]="user.avatarRef | safe : 'url'"
      class="profile-picture"
    />
    <ng-template #noAvatar>
      <img
        src="../../../../assets/images/dashboard/account_circle.svg"
        class="profile-picture"
      />
    </ng-template>
    <p class="size-16 font-weight-700">{{ user?.role | userRole }}</p>
    <p class="user-name font-weight-600">
      {{ user?.firstName }} {{ user?.lastName }}
    </p>
    <p *ngIf="user?.functionLabel" class="size-14 font-weight-600">
      {{ user?.functionLabel || "Unknown position" }}
    </p>
    <p class="font-weight-600">{{ user?.yearsExperience | experience }}</p>
    <div *ngIf="!hideConnectButton">
      <ng-container *ngIf="!self; else isSelf">
        <ng-container *ngIf="connection; else notConnected">
          <ng-container *ngIf="connection.isPending; else accepted">
            <ng-container *ngIf="connection.isOutgoing; else incoming">
              <p class="pending-request">Request pending</p>
            </ng-container>
            <ng-template #incoming>
              <p class="pending-request">
                {{ user?.firstName }} sent you a connection request
              </p>
              <button
                class="btn-sm mx-auto"
                mat-flat-button
                color="primary"
                (click)="acceptRequest()"
              >
                Accept
              </button>
              <button
                class="btn-sm mx-auto deny-button"
                mat-flat-button
                color="primary"
                (click)="denyRequest()"
              >
                Deny
              </button>
            </ng-template>
          </ng-container>
          <ng-template #accepted>
            <a
              *ngIf="connection.user.email"
              [href]="'mailto:' + connection.user.email"
            >
              <button
                class="btn-sm mx-auto deny-button"
                mat-flat-button
                color="primary"
              >
                Send email
              </button>
            </a>
          </ng-template>
        </ng-container>
        <ng-template #notConnected>
          <button
            class="btn-sm mx-auto"
            id="connect-button"
            mat-flat-button
            color="primary"
            (click)="connectWith($event)"
          >
            Connect
          </button>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="adminPartner$ | async as adminPartner">
        <div class="d-flex w-100 mb-2 flex-column" *ngIf="adminPartner.isAdmin">
          <button
            (click)="copyProfileUrlToClipboard()"
            class="btn-sm mx-auto deny-button"
            mat-flat-button
            color="primary"
          >
            Share
          </button>
          <div *ngIf="showCopiedText">Copied to clipboard!</div>
        </div>
      </ng-container>
    </div>
    <ng-template #isSelf>
      <mat-progress-bar
        class="progress-bar"
        mode="determinate"
        value="{{ profileCompleteness }}"
      ></mat-progress-bar>
      <ng-container *ngIf="profileCompleteness < 100; else profileComplete"
        >Profile complete for {{ profileCompleteness }}%</ng-container
      >
      <ng-template #profileComplete
        >Profile complete for {{ profileCompleteness }}%, good job!</ng-template
      >
    </ng-template>
  </div>
  <ng-container *ngIf="self || user.about">
    <div class="bevel"></div>
    <table class="w-100">
      <tr>
        <td>
          <p class="section-title">About me</p>
        </td>
        <td *ngIf="self">
          <div
            *ngIf="!modifyAboutMe"
            (click)="modifyAboutMe = true"
            class="edit-cell edit-link"
          >
            Edit
            <img
              class="edit-pencil"
              src="../../../../assets/images/dashboard/pencil.svg"
            />
          </div>
          <div
            *ngIf="modifyAboutMe"
            (click)="modifyAboutMe = false; saveProfile()"
            class="edit-cell edit-link"
          >
            Save
          </div>
        </td>
      </tr>
    </table>
    <div *ngIf="!modifyAboutMe; else aboutme">
      <p *ngIf="user.about; else aboutPlaceholder">{{ user.about }}</p>
      <ng-template #aboutPlaceholder>
        <button
          mat-flat-button
          class="btn-sm mx-auto no-aboutme-button"
          color="primary"
          (click)="modifyAboutMe = true"
        >
          Add about me
        </button>
      </ng-template>
    </div>
    <ng-template #aboutme>
      <app-input-text-area
        [form]="form"
        [formControl]="form.controls.about"
        [placeholder]="'Write something about yourself. What inspires you?'"
      ></app-input-text-area>
    </ng-template>
  </ng-container>

  <div class="bevel"></div>
  <table class="w-100">
    <tr class="w-100">
      <td class="w-50" *ngIf="user?.country === 'Netherlands'">
        <h3 class="section-title">Region</h3>
      </td>
      <td class="">
        <h3 class="section-title">Country</h3>
      </td>
      <td *ngIf="self">
        <a [routerLink]="'../edit'" class="float-end edit-link">
          <div class="edit-cell edit-link">
            Edit
            <img
              class="edit-pencil"
              src="../../../../assets/images/dashboard/pencil.svg"
            />
          </div>
        </a>
      </td>
    </tr>
    <tr>
      <td *ngIf="user?.country === 'Netherlands'">
        <p>{{ user?.region }}</p>
      </td>
      <td>
        <p>{{ user?.country }}</p>
      </td>
      <td *ngIf="self"></td>
    </tr>
  </table>
</div>
