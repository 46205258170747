import { Component, Input } from '@angular/core';
import { Education } from '../../../ngrx-store/components/education/education.model';

@Component({
  selector: 'app-educationcard',
  templateUrl: './educationcard.component.html',
  styleUrls: ['./educationcard.component.scss']
})
export class EducationcardComponent {
  @Input() education: Education;
}
