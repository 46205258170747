import { Action, createReducer, on } from '@ngrx/store';
import {
  addArrayControl,
  createFormGroupState,
  FormGroupState,
  formStateReducer,
  removeArrayControl,
  setValue,
  updateGroup,
  reset,
} from 'ngrx-forms';
import { getUserSuccess } from 'src/app/ngrx-store/requests/user/get-user/get-user.actions';
import { User } from '../../user/user.model';
import {
  addAreaBackground,
  addDigitalAreaInterest,
  addIndustryBackground,
  addIndustryInterest,
  removeAreaBackground,
  removeDigitalAreaInterest,
  removeIndustryBackground,
  removeIndustryInterest,
} from '../onboardingFindMatch/forms.findMatch.actions';
import { UserFormsState } from '../userForms.model';
import { clearEditAttributeListForm, submitEditAttributeListForm } from './forms.editAttributeList.actions';
import { AttributeListForm } from './forms.editAttributeList.model';
import * as _ from 'underscore';
import { postUserSuccess } from 'src/app/ngrx-store/requests/user/post-user/post-user.actions';

export const initialEditAttributeListForm: AttributeListForm = {
  digitalArea: '',
  digitalAreaBackgrounds: [],
  digitalAreaInterests: [],
  industry: '',
  industryBackgrounds: [],
  industryInterests: [],
};

export const initialEditAttributeListFormState = createFormGroupState<AttributeListForm>(
  'ATTRIBUTE_LIST_FORM',
  initialEditAttributeListForm
);

const updateForm = createReducer<FormGroupState<AttributeListForm>>(
  initialEditAttributeListFormState,
  on(clearEditAttributeListForm, (state) => initialEditAttributeListFormState),
  on(submitEditAttributeListForm, (state) => ({ ...reset(state) })),
  on(getUserSuccess, (state, action) => prefillForm(state, action.user)),
  on(postUserSuccess, (state, action) => prefillForm(state, action.user)),
  on(addAreaBackground, (state, action) =>
    updateGroup<AttributeListForm>(state, {
      digitalArea: setValue(''),
      digitalAreaBackgrounds: addArrayControl(action.digitalArea),
    })
  ),
  on(removeAreaBackground, (state, action) =>
    updateGroup<AttributeListForm>(state, {
      digitalAreaBackgrounds: removeArrayControl(
        _.findIndex(state.value.digitalAreaBackgrounds, (s) => s.name === action.digitalArea.name)
      ),
    })
  ),
  on(addIndustryBackground, (state, action) =>
    updateGroup<AttributeListForm>(state, {
      industry: setValue(''),
      industryBackgrounds: addArrayControl(action.industry),
    })
  ),
  on(removeIndustryBackground, (state, action) =>
    updateGroup<AttributeListForm>(state, {
      industryBackgrounds: removeArrayControl(
        _.findIndex(state.value.industryBackgrounds, (s) => s.name === action.industry.name)
      ),
    })
  ),
  on(addDigitalAreaInterest, (state, action) =>
    updateGroup<AttributeListForm>(state, {
      digitalArea: setValue(''),
      digitalAreaInterests: addArrayControl(action.digitalArea),
    })
  ),
  on(removeDigitalAreaInterest, (state, action) =>
    updateGroup<AttributeListForm>(state, {
      digitalAreaInterests: removeArrayControl(
        _.findIndex(state.value.digitalAreaInterests, (s) => s.name === action.digitalArea.name)
      ),
    })
  ),
  on(addIndustryInterest, (state, action) =>
    updateGroup<AttributeListForm>(state, {
      industry: setValue(''),
      industryInterests: addArrayControl(action.industry),
    })
  ),
  on(removeIndustryInterest, (state, action) => {
    return updateGroup<AttributeListForm>(state, {
      industryInterests: removeArrayControl(
        _.findIndex(state.value.industryInterests, (s) => s.name === action.industry.name)
      ),
    });
  })
);

const prefillForm = (state: FormGroupState<AttributeListForm>, user: User) => {
  if (!user) {
    return state;
  }

  return updateGroup<AttributeListForm>(state, {
    digitalAreaBackgrounds: (control) => {
      return user.userBackground?.digitalAreas
        ? setValue(user.userBackground.digitalAreas)(control)
        : setValue(control.value)(control);
    },
    industryBackgrounds: (control) => {
      return user.userBackground?.industries
        ? setValue(user.userBackground.industries)(control)
        : setValue(control.value)(control);
    },
    digitalAreaInterests: (control) => {
      return user.userInterests ? setValue(user.userInterests.digitalAreas)(control) : setValue(control.value)(control);
    },
    industryInterests: (control) => {
      return user.userInterests ? setValue(user.userInterests.industries)(control) : setValue(control.value)(control);
    },
  });
};

const validateForm = (form: FormGroupState<AttributeListForm>, state: UserFormsState) => {
  return form;
};

export const editAttributeListReducer = (state: UserFormsState, action: Action) => {
  const editAttributeListForm: FormGroupState<AttributeListForm> = state.attributeListForm;

  // use the out of the box reducer to process form actions into the store
  let form = formStateReducer(editAttributeListForm, action);

  // Process non-form actions
  form = updateForm(form, action);

  // form validation using the new state
  form = validateForm(form, state);

  return form === editAttributeListForm ? editAttributeListForm : form;
};
