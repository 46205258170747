import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EventService} from '../../../services/event.service';
import {
  getEvents,
  getEventsError,
  getEventsSuccess, getPreviousEvents, getPreviousEventsError, getPreviousEventsSuccess,
  getRegistration, getRegistrationError, getRegistrationSuccess,
  registerError,
  registerEvent,
  registerSuccess, signOutError, signOutEvent, signOutSuccess
} from './get-event.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {of} from 'rxjs';

@Injectable()
export class GetEventEffects {
  constructor(private actions$: Actions,
              private eventService: EventService) {
  }

  getEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEvents),
      switchMap(() => {
        return this.eventService.getEvents().pipe(
          map((result) => getEventsSuccess({events: result})),
          catchError((error: HttpErrorResponse) => of(getEventsError({error: error.message})))
        );
      })
    ));

  getPreviousEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPreviousEvents),
      switchMap(() => {
        return this.eventService.getPreviousEvents().pipe(
          map((result) => getPreviousEventsSuccess({previousEvents: result})),
          catchError((error: HttpErrorResponse) => of(getPreviousEventsError({error: error.message})))
        );
      })
    ));

  registerForEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registerEvent),
      switchMap((action) => {
        return this.eventService.registerForEvent(action.eventId).pipe(
          map((result) => registerSuccess({status: result})),
          catchError((error: HttpErrorResponse) => of(registerError({error: error.message})))
        );
      })
    ));

  signOutForEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signOutEvent),
      switchMap((action) => {
        return this.eventService.signOutForEvent(action.eventId).pipe(
          map((result) => signOutSuccess({status: result})),
          catchError((error: HttpErrorResponse) => of(signOutError({error: error.message})))
        );
      })
    ));

  getRegistrationForEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRegistration),
      switchMap((action) => {
        return this.eventService.getRegistration(action.eventId).pipe(
          map((result) => getRegistrationSuccess({status: result})),
          catchError((error: HttpErrorResponse) => of(getRegistrationError({error: error.message})))
        );
      })
    ));
}
