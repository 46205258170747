<div class="align-center w-100">
  <button
    *ngIf="!isToggleChecked || !clickedButton; else flatConfirm"
    mat-stroked-button
    class="btn-sm mx-1 my-1"
    color="primary"
    (click)="onClick(true)"
  >
    {{ confirmText }}
  </button>
  <ng-template #flatConfirm>
    <button
      mat-flat-button
      class="btn-sm mx-1 my-1"
      color="primary"
      (click)="onClick(true)"
    >
      {{ confirmText }}
    </button>
  </ng-template>
  <br>
  <button
    *ngIf="isToggleChecked || !clickedButton; else flatDecline"
    mat-stroked-button
    class="btn-sm mx-1 my-1"
    color="primary"
    (click)="onClick(false)"
  >
    {{ declineText }}
  </button>
  <ng-template #flatDecline>
    <button
      mat-flat-button
      class="btn-sm mx-1 my-1"
      color="primary"
      (click)="onClick(false)"
    >
      {{ declineText }}
    </button>
  </ng-template>
</div>
