<div *ngIf="blogItems.length == 0; else blogItemCards">
  <div class="align-center">
    <mat-card appearance="outlined" class="match-card float-none">
      <mat-card-content class="profile-card-content">
        No blogs were found... <br>
        Try to check again later.
      </mat-card-content>
    </mat-card>
  </div>
</div>
<ng-template #blogItemCards>
  <div class="match-cards">
    <div *ngFor="let blogItem of blogItems"> <!-- do not slice here, please pass sliced lists to this component -->
      <app-blogitemcard [blogitem]="blogItem"></app-blogitemcard>
    </div>
  </div>
  <br>
  <div class="all-matches-button" *ngIf="viewAllButton">
    <a [routerLink]="'/' + AppRoutes.BLOGITEMS">
      <button class="btn-sm mx-auto" mat-flat-button color="primary">View all blogs</button>
    </a>
  </div>
</ng-template>

