import { createAction, props } from '@ngrx/store';
import { AttributeListForm } from './forms.editAttributeList.model';

export enum EditAttributeListActionTypes {
  CLEAR = '[UserForms] Clear Attribute list',
  SUBMIT = '[UserForms] Submit Attribute list',
}

export const submitEditAttributeListForm = createAction(EditAttributeListActionTypes.SUBMIT, props<{ formValues: AttributeListForm }>());
export const clearEditAttributeListForm = createAction(EditAttributeListActionTypes.CLEAR);
