import { Component, Input } from '@angular/core';
import { Connection } from 'src/app/ngrx-store/components/connection/connection.model';
import { User } from 'src/app/ngrx-store/components/user/user.model';
import { Store } from '@ngrx/store';
import { selectUser } from 'src/app/ngrx-store/components/user/user.selectors';
import { AppState } from 'src/app/ngrx-store/app.state';

@Component({
  selector: 'app-profile-overview',
  templateUrl: './profile-overview.component.html',
  styleUrls: ['./profile-overview.component.scss'],
})
export class ProfileOverviewComponent {
  @Input() user: User;
  @Input() connection?: Connection;

  public self$ = this.store$.select(selectUser);

  constructor(private store$: Store<AppState>) {}
}
